import React from "react"
import NumberFormat from 'react-number-format'

const TableItem = (props) => {
    return (
        <tr key={props.item.id}>
            <td>{props.item.date_transaction}</td>
            <td>{props.item.day}</td>
            <td>{props.item.domestic.qty}</td>
            <td>
                <NumberFormat 
                    value={props.item.domestic.amount}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>

            {/* For Domestik Grup */}
            <td>{props.item.domestic_group.qty}</td>
            <td>
                <NumberFormat 
                    value={props.item.domestic_group.amount}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>

            <td>{props.item.asing.qty}</td>
            <td>
                <NumberFormat 
                    value={props.item.asing.amount}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>

            {/* For Asing Grup */}
            <td>{props.item.asing_group.qty}</td>
            <td>
                <NumberFormat 
                    value={props.item.asing_group.amount}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>

            <td>{props.item.mobil.qty}</td>
            <td>
                <NumberFormat 
                    value={props.item.mobil.amount}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>
            <td>{props.item.sepeda_motor.qty}</td>
            <td>
                <NumberFormat 
                    value={props.item.sepeda_motor.amount}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>
            <td>{props.item.sepeda.qty}</td>
            <td>
                <NumberFormat 
                    value={props.item.sepeda.amount}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>
            
            <td>
                <NumberFormat 
                    value={props.item.total.perDay}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>
            {/* <td>{"-"}</td> */}
            <td>
                <NumberFormat 
                    value={props.item.total.dayVisitor}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>
            <td>
                <NumberFormat 
                    value={props.item.total.dayNonVisitor}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>
        </tr>
    )
}

export default TableItem
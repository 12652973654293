import React from "react";
import PropTypes from "prop-types"

const LoadingTableSponsor = ({ column = 0 }) => {
    const items = []
    for (let i = 0; i < column; i++) {
        items.push(
            <td key={i}>
                <div className="ph-item p-0 mb-0 border-0" style={{ height: 50 }}>
                    <div className="ph-picture mb-0">
                        <div className="ph-col-12"></div>
                    </div>
                </div>
            </td>
        )
    }
    return (
        <tr>
            {items}
        </tr>
    );
}
LoadingTableSponsor.propTypes = {
    column: PropTypes.number
}
export default LoadingTableSponsor;
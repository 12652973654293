// import { storageRef } from "../config/constants";
import { storage } from "firebase";

import { stripString, splitSlash, guidGenerator } from "./common";

export const storageRef = storage().ref();

const getFilePath = (id, filePath, file) => {
  const fileExt = splitSlash(file.type);
  const stripName = stripString(file.name);
  const imgName = `${id}_${stripName}_${guidGenerator()}`;

  return `${filePath}/${imgName}.${fileExt}`;
};

export const uploadGallery = async (id, filePath, files) => {
  const promises = [];
  for (let i = 0; i < files.length; i++) {
    const metadata = { contentType: files[i].type };
    const path = getFilePath(id, filePath, files[i]);
    const p = storageRef.child(path).put(files[i], metadata);
    promises.push(p);
  }
  try {
    const dataSnapshot = await Promise.all(promises);
    const temp = [];
    for (let j = 0; j < dataSnapshot.length; j++) {
      temp.push({
        name: dataSnapshot[j].ref.name.toString(),
        path: dataSnapshot[j].ref.fullPath.toString(),
        url: await dataSnapshot[j].ref.getDownloadURL(),
      });
    }
    return temp;
  }
  catch (e) {
    console.error(e);
  }
};

const getFilePathBase64 = (id, filePath, file) => {
  // const fileExt = splitSlash(file.type);
  // const stripName = stripString(file.name);
  const imgName = `${id}_${guidGenerator()}`;

  return `${filePath}/${imgName}.jpeg`;
};

export const uploadGalleryBase64 = async (id, filePath, files) => {
  const promises = [];
  console.log('files', files)
  for (let i = 0; i < files.length; i++) {
    const metadata = { contentType: 'image/jpeg' };
    const path = getFilePathBase64(id, filePath, files[i]);
    const p = storageRef.child(path).putString(files[i], 'data_url');
    promises.push(p);
  }
  try {
    const dataSnapshot = await Promise.all(promises);
    const temp = [];
    for (let j = 0; j < dataSnapshot.length; j++) {
      temp.push({
        name: dataSnapshot[j].ref.name.toString(),
        path: dataSnapshot[j].ref.fullPath.toString(),
        url: await dataSnapshot[j].ref.getDownloadURL(),
      });
    }
    return temp;
  }
  catch (e) {
    console.error("error upload images: ", e);
  }
};
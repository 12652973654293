import React, { Component } from 'react';
import moment from 'moment';
import TableItem from "./TableItem"
// import { auth, db } from "../../config/constants";

class DataTable extends Component {
    renderItem = (item, index) => {
        return (
            <TableItem
                key={item.id}
                index={index}
                item={item}
            />
        )
    }
    render() {
        return (
            <table className="table table-bordered m-a-0">
                <thead style={{ color: "#579264" }}>
                    <tr>
                        <th scope="col" className="text-center">Tanggal</th>
                        <th scope="col" className="text-center">Tiket</th>
                        <th scope="col" className="text-center">FnB</th>
                        <th scope="col" className="text-center">Penyewaan Fasilitas</th>
                        <th scope="col" className="text-center">Paket Tur</th>
                        <th scope="col" className="text-center">Suvenir & Produk</th>
                        <th scope="col" className="text-center">Keanggotaan</th>
                        <th scope="col" className="text-center">Total</th>
                    </tr>
                </thead>
                <tbody style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                    {
                        this.props.data.map(this.renderItem)
                    }
                </tbody>
            </table>
        )
    }
}

export default DataTable
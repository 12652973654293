import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { withFirebase } from "react-redux-firebase";

class ThumbnailPreview extends Component {
    state = {
        loading: true,
        thumb: undefined,
        show: false
    };

    handleShow = () => {
        this.setState({
            show: true
        })
    }

    handleClose = () => {
        this.setState({
            show: false
        })
    }

    componentWillMount() {
        if (this.props.file.name && typeof this.props.file.name == 'string') {
            this.setState({ loading: true }, () => {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.setState({ loading: false, thumb: e.target.result });
                };
                reader.readAsDataURL(this.props.file);
            });
        } else if (typeof this.props.file === "string") {
            // console.log("Read from database")
            this.setState({ loading: false, thumb: this.props.file });
        }
    }

    onDownload = async () => {
        try {
            var storage = this.props.firebase.storage();
            const ref = storage.refFromURL(this.props.thumb)
            const url = await ref.getDownloadURL()
            // This can be downloaded directly:
            var xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = function(event) {
                var blob = xhr.response;
                // console.log('blob', blob)
            };
            xhr.open('GET', url);
            xhr.send();
            return window.open( url, '_blank');
        } catch (error) {
            console.error(error)
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
                case 'storage/object-not-found':
                    // File doesn't exist
                    this.setState({
                        errorMessage: "File doesn't exist."
                    })
                break;

                case 'storage/unauthorized':
                    this.setState({
                        errorMessage: "You are not authorized to download this file."
                    })
                break;

                case 'storage/canceled':
                    this.setState({
                        errorMessage: "User has canceled file download."
                    })
                break;

                case 'storage/unknown':
                    this.setState({
                        errorMessage: "Unknown error has occured. Please try again."
                    })
                break;
            }
        }
    }

    render() {
        const { file, canDelete = true } = this.props;
        const { loading, thumb } = this.state;

        if (!file) {
            return <p>Error loading image!</p>;
        }

        if (loading) {
            return (
                <img
                    src={require("../../assets/icon/spinner.gif")}
                    alt="Loading"
                    height={200}
                    width={200}
                />
            );
        }
        return (
            <React.Fragment>
                {/* <a href={thumb} target="_blank" download="image"> */}
                <a  onClick={this.handleShow} target="_blank" download="image">
                    <div style={{ position: 'relative', display: "inline-block" }}>
                        <img src={thumb} alt={file.name} style={{ width: 130, height: 130, objectFit: 'cover', margin: 5 }} />
                    </div>
                </a>
                <Modal show={this.state.show} onHide={this.handleClose} animation={false} id="img-modal">
                    <Modal.Header style={{ justifyContent: "flex-end" }} />
                    <Modal.Body>
                        <img src={thumb} alt={file.name} className="w-100" />
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withFirebase(ThumbnailPreview)
import React from 'react';
import Select from 'react-select';

class SelectMenu extends React.Component {
    state = { selectedOptions: null }
    handleChange = (selectedItem) => {
      this.setState({ selectedOptions: selectedItem });
      // console.log('selectedItem', selectedItem)
      const value = selectedItem === null ? '' : selectedItem.value;
      // console.log('value', value)
      this.props.onChange(value)
    };
    UNSAFE_componentWillReceiveProps(nextProps) {
      if(nextProps.value !== this.props.value) {
        for (let i = 0; i < this.props.items.length; i++) {
          const element = this.props.items[i];
          if(nextProps.value === element.value) {

            this.setState({
              selectedOptions: element
            })
          }
        }
      }
      console.log("kesini")
    }
    componentDidMount() {
      if(this.props.value) {
        console.log('this.props.value', this.props.value)
        for (let i = 0; i < this.props.items.length; i++) {
          const element = this.props.items[i];
          if(this.props.value === element.value) {
            this.setState({
              selectedOptions: element
            })
          }
        }
      }
    }
    render() {
      const { items, currentRefinement, placeholder, disabled } = this.props;
      console.log('this.state.selectedOptions', this.state.selectedOptions)
      return (
        <Select
          isDisabled={disabled}
          onChange={this.handleChange}
          options={items}
          className="field-box"
          value={this.state.selectedOptions}
          placeholder={placeholder}
          clearValue={this.clearQuery}
        />
      );
    }
}

const customStyles = {
  option: () => ({
    fontSize: 13,
    padding: 10,
  }),
}

export default SelectMenu;

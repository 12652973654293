import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'

import SubmitButton from '../../components/SubmitButton'
import LoaderModal from '../../components/LoaderModal'
import validate from './validate'
import TextInput from '../../components/Form/TextInput'
import TextAreaInput from '../../components/Form/TextAreaInput'
import AvatarPicker from '../../components/AvatarPicker'
import RadioButton from '../../components/Form/RadioButton'
import SelectMenu from '../../components/Form/SelectMenuInput'
import DatePicker from '../../components/Form/DatePicker'
import SelectPositionInput from '../../components/Form/SelectPositionInput'

let UpdateForm = ({ 
  handleSubmit, 
  pristine, 
  submitting, 
  valid, 
  region,
  change,
  departementList, 
  subDepartmentList, 
  subDepartment,
  ...otherProps 
}) => {
  return (
    <React.Fragment>

      <form role="form" onSubmit={handleSubmit}>
        <div className="col-sm-12">
          <div className="p-5" style={{ borderRadius: 5 }}>
            
            <Field
              component={AvatarPicker}
              label="Avatar"
              name="avatar"
            />
            <Field
              name="nik"
              component={TextInput}
              placeholder="NIK"
              label="NIK"
              style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
            />
            <Field
              name="employeeNumber"
              component={TextInput}
              placeholder="No Karyawan"
              label="No Karyawan"
              style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
            />
            <Field
              name="npwp"
              component={TextInput}
              placeholder="NPWP"
              label="NPWP"
              style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
            />
            <Field
              name="familyCard"
              component={TextInput}
              placeholder="Kartu Keluarga"
              label="Kartu Keluarga"
              style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
            />
            <Field
              name="workDate"
              component={DatePicker}
              isOutsideDays={true}
              placeholder="Tanggal Masuk Kerja"
              label="Tanggal Masuk Kerja"
              style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
            />

            <hr />
            
            <Field
              name="name"
              component={TextInput}
              placeholder="Nama"
              label="Nama"
              style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
            />
            
            <Field
              name="gender"
              component={RadioButton}
              placeholder="Jenis Kelamin"
              label="Jenis Kelamin"
              data={[
                { name: "Pria", value: "Pria"},
                { name: "Wanita", value: "Wanita"},
              ]}
              style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
            />
{/*              
            <Field
              name="position"
              component={TextInput}
              placeholder="Jabatan"
              label="Jabatan"
              style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
            /> */}


            <div className="form-group row mb-4">
              <label for="input-Jabatan" class="col-md-2 form-control-label" style={{color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold"}}>Departemen</label>
              <div className="col-md-4">
                <Field
                  component={SelectMenu}
                  label="Department"
                  placeholder="Please select department"
                  items={departementList}
                  name="department"
                />
              </div>
            </div>

            {
              subDepartmentList.length > 0 &&
              <div className="form-group row mb-4">
                <label for="input-Jabatan" class="col-md-2 form-control-label" style={{color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold"}}>Sub Departemen</label>
                <div className="col-md-4">
                  <Field
                    component={SelectMenu}
                    label="Sub Department"
                    placeholder="Please select sub department"
                    items={subDepartmentList}
                    name="subDepartment"
                  />
                </div>
              </div>
            }

            
            {
              subDepartment && 
              <div className="form-group row mb-4">
                <label for="input-Jabatan" class="col-md-2 form-control-label" style={{color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold"}}>Jabatan</label>
                <div className="col-md-4">
                  <Field
                    component={SelectPositionInput}
                    label="Jabatan"
                    placeholder="Please select Jabatan"
                    name="position"
                    change={change}
                    region={region}
                    dataId={subDepartment}
                  />
                </div>
              </div>
            }
            
            <Field
              name="birth.city"
              component={TextInput}
              placeholder="Tempat Lahir"
              label="Tempat Lahir"
              style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
            />
            <Field
              name="birth.dob"
              component={DatePicker}
              placeholder="Tanggal Lahir"
              label="Tanggal Lahir"
              isOutsideDays={true}
              style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
            />
            
            
            
            <div className="form-group row mb-4">
              <label for="input-Jabatan" class="col-md-2 form-control-label" style={{color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold"}}>Marital Status</label>
              <div className="col-md-4">
                <Field
                  component={SelectMenu}
                  label="Marital Status"
                  placeholder="Please select marital status"
                  items={[
                    { label: "Belum Menikah", value: "Belum Menikah" },
                    { label: "Sudah Menikah", value: "Sudah Menikah" }
                  ]}
                  name="maritalStatus"
                />
              </div>
            </div>
            
            <Field
              name="telephone"
              component={TextInput}
              placeholder="No Telepon"
              label="No Telepon"
              style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
            />

            <hr />

            <Field
              name="address"
              component={TextAreaInput}
              placeholder="Alamat"
              label="Alamat"
              rows={3}
              style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
            />
            
            <Field
              name="sector"
              component={TextInput}
              placeholder="Sector Kebun Raya"
              label="Sector Kebun Raya"
              style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
            />


            <div className="col-lg-6 col-xs-12 px-0">
              <div className="col-lg-3 col-xs-12 px-0 mt-5 text-center">
                <SubmitButton disabled={!valid || submitting} />
              </div>
              <LoaderModal submitting={submitting} />
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  )
}

UpdateForm = reduxForm({
  form: 'updateEmployeeForm',
  validate,
  enableReinitialize: true,
})(UpdateForm)
const selector = formValueSelector('updateEmployeeForm')

UpdateForm = connect(
  state => {

    const region = selector(state, 'region') || "";
    const department = selector(state, 'department') || "";
    const departementList = selector(state, 'departementList') || [];
    const subDepartment = selector(state, 'subDepartment') || "";

    let subDepartmentList = selector(state, 'subDepartmentList') || []
    
    if (department) {
      for (let i = 0; i < departementList.length; i++) {
        const element = departementList[i];
        if (department == element.value) {
          subDepartmentList = element.departementList.map((item) => {
            return { label: item, value: item }
          })
          break;
        }
      }
    }
    
    return {
      region,
      departementList,
      subDepartment,
      subDepartmentList
    }
  }
)(UpdateForm)

export default connect()(UpdateForm)
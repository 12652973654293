import React from "react"
import ErrorMessage from "./ErrorMessage"

const RadioButton = (field) => (
  <div className="form-group row mb-4">
    <label 
      htmlFor={`input-${field.label}`} 
      className="col-md-2 form-control-label" 
      style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold" }}
    >
      {field.label}
    </label>
    <div className="col-md-10">
      {field.data && field.data.map((item, index) => (
        <label className="radio-inline mr-2" key={index}>
          <input
            {...field.input}
            type="radio"
            checked={item.value === field.input.value}
            id={`input-${field.label}-${index+1}`}
            value={item.value}
          />
          {item.name}
        </label>
      ))}
      <ErrorMessage {...field} />
    </div>
  </div>
)

export default RadioButton
import React, { Component } from 'react';
import NumberFormat from 'react-number-format'
import TableItem from "./TableItem"
import LoadingTableSponsor from '../../../../components/loading-table';

class DataTable extends Component {
    state = {
        total: 0,
    }
    renderItem = (item, index) => {
        return (
            <TableItem
                key={item.id}
                index={index}
                item={item}
            />
        )
    }
    render() {
        return (
            <table className="table table-bordered m-a-0">
                <thead style={{ color: "#579264"}}>
                    <tr>
                        <th scope="col" rowSpan="2" className="text-center" style={{ verticalAlign: "middle" }}>Tanggal</th>
                        <th scope="col" colSpan="2" className="text-center">Domestik</th>
                        <th scope="col" colSpan="2" className="text-center">Asing</th>
                        <th></th>
                    </tr>
                    <tr>
                        
                        <th scope="col" className="text-center">LBR</th>
                        <th scope="col" className="text-center">UANG</th>
                        <th scope="col" className="text-center">LBR</th>
                        <th scope="col" className="text-center">UANG</th>
                        <th scope="col" className="text-center">Jumlah</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.props.loading ?
                            <React.Fragment>
                                <LoadingTableSponsor column={6} />
                                <LoadingTableSponsor column={6} />
                                <LoadingTableSponsor column={6} />
                                <LoadingTableSponsor column={6} />
                            </React.Fragment>
                            :
                                this.props.data.length == 0 ? 
                                <tr>
                                    <td className="text-center" colSpan="9">
                                        <p>Tidak ada data!</p>
                                    </td>
                                </tr>
                            : this.props.data.map(this.renderItem)
                    }
                </tbody>
                <tfoot>
                    {
                        this.props.loading ?
                        <LoadingTableSponsor column={6} />
                    :
                        <tr className="font-weight-bold">
                            <td className="text-center">JUMLAH</td>
                            <td className="text-center">{this.props.total.domestic.qty}</td>
                            <td className="text-center">
                                <NumberFormat 
                                    value={this.props.total.domestic.assurance}
                                    displayType={'text'} 
                                    thousandSeparator={true} 
                                    prefix={'Rp'} 
                                    suffix={",-"} 
                                    defaultValue="0" 
                                />
                            </td>
                            <td className="text-center">{this.props.total.asing.qty}</td>
                            <td className="text-center">
                                <NumberFormat 
                                    value={this.props.total.asing.assurance}
                                    displayType={'text'} 
                                    thousandSeparator={true} 
                                    prefix={'Rp'} 
                                    suffix={",-"} 
                                    defaultValue="0" 
                                />
                            </td>
                            <td className="text-center">
                                <NumberFormat 
                                    value={
                                        this.props.total.domestic.assurance +
                                        this.props.total.asing.assurance
                                    }
                                    displayType={'text'} 
                                    thousandSeparator={true} 
                                    prefix={'Rp'} 
                                    suffix={",-"} 
                                    defaultValue="0" 
                                />
                            </td>
                        </tr>
                    }
                </tfoot>
            </table>
        )
    }
}

export default DataTable
import moment from 'moment'

export const stripString = title => title.toLowerCase().replace(/ /g, "");

export const guidGenerator = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);

export const splitSlash = str => str.split("/")[1];

const ENTER_BUTTON = 13;

export const onKeyPress = (event) => { 
  if (event.target.type !== 'textarea' && event.which === ENTER_BUTTON) {
    event.preventDefault();
  }
};

export const validateDate = (values = {start: null, end: null}) => {
  let errors = {};

  if (!values.start) {
    errors.start = 'Start Date is Required';
  }
  if (!values.end) {
    errors.end = 'End Date is Required';
  }
  return errors;
}

// FORMAT CURRENCY //
export const currencyFormatter = new Intl.NumberFormat('id-ID', {
  style: 'currency',
  currency: 'IDR',
  minimumFractionDigits: 2
})

export const getFormData = (data) => {
  const dataParams = Object.keys(data).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
  }).join('&');
  return dataParams;
}

export function upperFirstCase(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}


/* common array methods */
    
export function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export function compare( a, b ) {
  if ( a.productName < b.productName ) {
    return -1;
  }
  if ( a.productName > b.productName ) {
    return 1;
  }
  return 0;
}

/* format data json to csv.. from scratch */
export function pivot(arr) {
  let mp = new Map();
  
  function setValue(a, path, val) {
      if (Object(val) !== val) { // primitive value
          let pathStr = path.join('.');
          // pathStr = capitalizeFirstLetter(pathStr)
          // console.log('pathStr', pathStr)
          let i = (mp.has(pathStr) ? mp : mp.set(pathStr, mp.size)).get(pathStr);
          a[i] = val;
      } else {
          for (let key in val) {
              setValue(a, key == '0' ? path : path.concat(key), val[key]);
          }
      }
      return a;
  }
  
  let result = arr.map( obj => setValue([], [], obj) );
  return [[...mp.keys()], ...result];
}

export function toCsv(arr) {
  return arr.map( row => 
      row.map ( val => isNaN(val) ? JSON.stringify(val) : +val ).join(',')
  ).join('\n');
}

export function getAllDays(start, end) {
    
  let duration = moment.duration(moment(end).diff(start)).asDays();
  //loop bulan
  let arrDays = []
  for (let i = 0; i <= duration; i++) {
    arrDays.push(moment(start).startOf('day').add(i, 'day').format("YYYY-MM-DD"))
  }
  return arrDays;
}
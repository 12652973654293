import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import firebase from 'firebase';

import DataTable from './DataTable';
import DownloadReport from '../../../components/DownloadReport'
import DatePicker from '../../../components/DatePicker'

class NonFnbRevenue extends Component {
    state = {
        data: [],
        fileName: "nonfnb-4region-data",
        loading: true,
        startDate: moment(),
        endDate: moment(),
        regionList: [
            'Bogor',
            'Cibodas',
            'Purwodadi',
            'Bali'
        ],
        region: 'Bogor'
    }

    getDataFasilitas = async (date) => {
        const snapshots =
            await firebase.firestore().collection("region")
                .doc(this.state.region.toLowerCase())
                .collection('dataFasilitas')
                .where('date', "==", moment(date).tz("Asia/Jakarta").startOf('day').toDate())
                .limit(1)
                .get()
        if (snapshots.empty) {
            return 0;
        }
        let amount = 0
        let qty = 0

        snapshots.forEach(element => {

            const { totalQty = 0, totalSubTotal = 0 } = element.data()

            amount = totalSubTotal
            qty = totalQty

        });

        return { 
            // ticket, 
            amount, 
            qty 
        }
    }

    getDataMerchandise = async (date) => {
        const snapshots =
            await firebase.firestore().collection("region")
                .doc(this.state.region.toLowerCase())
                .collection('dataMerchandise')
                .where('date', "==", moment(date).tz("Asia/Jakarta").startOf('day').toDate())
                .limit(1)
                .get()
        if (snapshots.empty) {
            return 0;
        }
        let amount = 0
        let qty = 0


        snapshots.forEach(element => {

            const { totalQty = 0, totalSubTotal = 0 } = element.data()


            amount = totalSubTotal
            qty = totalQty

        });

        return { 
            // ticket, 
            amount, 
            qty 
        }
    }

    // fetchDataEvent = async () => {
    //     this.setState({
    //         loading: true,
    //         data: [],
    //     })
    //     try {

    //         const promiseAllData = await Promise.all([this.getDataFasilitas(), this.getDataMerchandise()])

    //         let dataFasilitas = promiseAllData[0]
    //         let dataMerchandise = promiseAllData[1]
    //         let duration = parseInt(moment.duration(this.state.endDate.diff(this.state.startDate)).asDays());

    //         let allData = []
    //         if (dataFasilitas && dataMerchandise) {
    //             for (let i = 0; i <= duration; i++) {
    //                 allData.push({
    //                     date: moment(this.state.startDate).add(i, 'day').format("D MMMM YYYY"),
    //                     day: moment(this.state.startDate).add(i, 'day').format("dddd"),
    //                     dataFasilitas: dataFasilitas[i],
    //                     dataMerchandise: dataMerchandise[i],
    //                     total: dataFasilitas[i] + dataMerchandise[i]
    //                 })
    //             }
    //         }

    //         const customFields = [
    //             { label: 'Tanggal', value: 'date' },
    //             { label: 'Hari', value: 'day' },
    //             { label: 'Sewa Fasilitas', value: 'dataFasilitas' },
    //             { label: 'Merchandise', value: 'dataMerchandise' },
    //             { label: 'Total', value: 'total' },
    //         ]

    //         this.setState({
    //             data: allData,
    //             customFields,
    //             loading: false
    //         })
    //     } catch (error) {
    //         console.error(error)
    //         this.setState({
    //             data: [],
    //             loading: false
    //         })
    //     }
    // }
    fetchDataEvent = async () => {
        this.setState({
            loading: true,
            data: [],
        })
        const startDate = moment(this.state.startDate).startOf('day')
        const endDate = moment(this.state.endDate).startOf('day')
        const timeDiff = endDate - startDate;
        const duration = moment.duration(timeDiff).asDays();
        try {

            const arrDays = []
            for (let i = 0; i <= duration; i++) {
                arrDays.push(moment(startDate)
                    .startOf('day')
                    .add(i, 'day'))
            }
            const tempData = []
            for (let i = 0; i < arrDays.length; i++) {
                const currDate = arrDays[i];
                let dataMerch = await this.getDataMerchandise(currDate)
                let dataFasilitas = await this.getDataFasilitas(currDate)
                const currData = {
                    date: moment(currDate).format("D MMMM YYYY"),
                    day: moment(currDate).format("dddd"),
                    dataFasilitas: dataFasilitas.amount,
                    dataMerchandise: dataMerch.amount,
                    total: dataFasilitas.amount + dataMerch.amount
                }
                tempData.push(currData)
            }
            // console.log('tempData', tempData)

            const customFields = [
                { label: 'Tanggal', value: 'date' },
                { label: 'Hari', value: 'day' },
                { label: 'Sewa Fasilitas', value: 'dataFasilitas' },
                { label: 'Merchandise', value: 'dataMerchandise' },
                { label: 'Total', value: 'total' },
            ]

            this.setState({
                data: tempData,
                customFields,
                loading: false
            })
        } catch (error) {
            console.error(error)
            this.setState({
                data: [],
                loading: false
            })
        }
    }
    onDatesChange = ({ startDate, endDate }) => {
        this.setState({ startDate, endDate }, () => this.fetchDataEvent())
    }
    componentDidMount() {
        this.fetchDataEvent();
    }
    onChangeRegion = (e) => {
        this.setState({ region: e.target.value }, () => this.fetchDataEvent())
    }
    render() {
        return (
            <React.Fragment>
                <div className="box-header px-5 mt-3" style={{ backgroundColor: "#EFF2F7" }}>
                    <h3 style={{ color: "#3B4859" }}>REVENUE (SUMMARY NON FNB)</h3>
                </div>
                <div className="container-fluid p-5 spacing-resp">
                    <div className="row mb-4">
                        <div className="col-lg-4 col-md-6 mt-3">
                            <div className="row">
                                <div className="col-6"><h6 style={{ fontSize: "0.8rem", color: "#3B4859" }}>Pilih Tanggal</h6></div>
                            </div>
                            <DatePicker
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                onDatesChange={this.onDatesChange}
                            />
                        </div>

                        <div className="col-md-2 d-flex align-items-end mt-3">
                            <select className="form-control input-c" value={this.state.region} onChange={this.onChangeRegion} style={{ borderRadius: "6px", border: "1px solid #A9A9AB" }}>
                                {
                                    this.state.regionList.map((region, i) => (<option key={i} value={region}>{region}</option>))
                                }
                            </select>
                        </div>

                        <div className="col-lg-3 col-md-6 d-flex align-items-end mt-3">
                            <DownloadReport
                                data={this.state.data}
                                fields={this.state.customFields}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                fileName={this.state.fileName}
                            />
                        </div>
                    </div>

                    <div className="box mb-0 p-3 mt-5" style={{ borderRadius: 10 }}>
                        <div className="table-responsive">
                            <DataTable data={this.state.data} loading={this.state.loading} />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect()
)(NonFnbRevenue)
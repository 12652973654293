import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom"
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'

import SimpleReactLightbox from "simple-react-lightbox";
import store, { rrfProps } from '../redux/store'

import SigninScreen from '../screens/Auth/SigninScreen'
import ForgotPasswordScreen from '../screens/Auth/ForgotPasswordScreen'
import NotFound from '../screens/NotFound'

import { UserIsAuthenticated } from './PrivateRoute'
import MainRouter from './MainRouter'

const App = () => (
  <SimpleReactLightbox>
    <div className="app" id="app" style={{ backgroundColor: "#F9FAFC"}}>
      <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          <BrowserRouter>
            <Switch>
              <Route exact path="/" render={() => <Redirect to="/dashboard"/>} />
              <Route path="/dashboard" component={UserIsAuthenticated(MainRouter)} />
              <Route exact path="/signin" component={SigninScreen} />
              <Route exact path="/forgot_password" component={ForgotPasswordScreen} />
              <Route component={NotFound} />
            </Switch>
          </BrowserRouter>
        </ReactReduxFirebaseProvider>
      </Provider>
    </div>
  </SimpleReactLightbox>
)

export default App

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import firebase from 'firebase'
import { setFilter } from "../redux/actions";
import Logo from '../assets/logo/logo_small.png';
import { loadRegionState, saveRegionState } from '../utils/localStorage'
import { upperFirstCase } from '../helpers/common'

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const SidebarTop = ({ auth, profile, setFilter, region }) => {
    const [selectedRegion, setSelectedRegion] = useState(region)
    const [employee, setEmployee] = useState({
        region: ""
    })
    const [regionList, setRegionList] = useState([
        'Bogor',
        'Cibodas',
        'Purwodadi',
        'Bali'
    ])
    useEffect(() => {
        async function fetchData() {
            try {
                if (auth.uid) {
                    // console.log(firebase.auth().currentUser.uid)
                    const idTokenResult = await firebase.auth().currentUser.getIdTokenResult(true)

                    // let userRegion = capitalizeFirstLetter(loadRegionState())
                    // setSelectedRegion(userRegion)
                    // console.log(`userRegion`, userRegion)
                    const employeeSnapshot = await firebase.firestore().collectionGroup("employee").where("uid", "==", auth.uid).get();

                    const tempEmployee = {
                        id: employeeSnapshot.docs[0].id,
                        ...employeeSnapshot.docs[0].data()
                    }

                    // console.log(`tempEmployee`, tempEmployee)

                    if (tempEmployee.region) {
                        let userRegion = capitalizeFirstLetter(tempEmployee.region)
                        let regions = regionList.filter((item) => item === userRegion)

                        setRegionList(regions)
                        saveRegionState(tempEmployee.region)
                        setFilter(userRegion)
                        setEmployee(tempEmployee.region)
                    } else {
                        if (idTokenResult.claims.admin == true) {
                            // super admin
                            if(!tempEmployee.region) {
                                
                                setEmployee({
                                    ...employee,
                                    isAdmin: idTokenResult.claims.admin
                                })
                            }
                            if (!region) {
                                saveRegionState(regionList[0])
                                setFilter(regionList[0])
                            }
                        }

                    }
                }
                
            } catch (error) {

                // console.log(error);
            }
        }
        fetchData()
    }, [auth])
    // console.log(employee);
    const onChange = (e) => {
        if (profile.token && profile.token.claims.admin === true) {
            setSelectedRegion(e.target.value)
            saveRegionState(e.target.value)
            setFilter(e.target.value)
        }
    }
    // console.log("SELECTED REGION: ", selectedRegion)
    return (
        <React.Fragment>
            <div className="navbar-empty">
                <a className="navbar-brand">
                    {/* <img src={Logo} alt="." className="img-fluid" /> */}
                    {/* <span className="block _500">{ auth.displayName || "Ilham"}</span> */}
                </a>
            </div>

            <div className="navbar no-radius" style={{ justifyContent: "center" }}>
                <Link className="navbar-brand" style={{ display: "flex", alignContent: "center", }} to="/dashboard">
                    <img src={Logo} alt="." className="img-fluid mx-auto" />
                    {/* <span className="ml-2" style={{ cursor: "pointer" }}>KEBUN RAYA</span> */}
                    {/* <span className="block _500 mt-3">{ auth.displayName || "Admin"}</span> */}
                </Link>
                {/* <p className="block _500 mt-3">{ auth.email || ""}</p> */}
                {/* <Link 
                        to="/dashboard/profile" 
                        as="button" 
                        role="button" 
                        className="btn w-100"
                        style={{ fontSize: "0.8em", color: "white", backgroundColor: "transparent", border: "1px solid white" }}
                    >
                        View Profile
                    </Link> */}
            </div>
            <div className="px-3 pt-2">
                {
                    ((profile.token && profile.token.claims.admin === true)
                    )
                        ?
                        employee.region ?
                            <p className="text-center">
                                {upperFirstCase(selectedRegion)}
                            </p>
                            :
                            <select
                                className="form-control input-c"
                                style={{ borderRadius: "6px", border: "1px solid #A9A9AB", fontSize: "0.875rem", fontFamily: "Montserrat", padding: ".4rem" }}
                                onChange={onChange}
                                value={selectedRegion}
                                disabled={employee.isAdmin !== true}
                            >
                                {
                                    regionList.map((region, i) => (<option key={i} value={region}>{region}</option>))
                                }
                            </select>
                        :
                        employee.region ?
                            <p className="text-center">
                                {upperFirstCase(selectedRegion)}
                            </p>
                            : null
                }
            </div>
        </React.Fragment>
    )

}

const mapStateToProps = state => {
    // let { employee = [] } = state.firestore.ordered
    // let getEmployee = {}
    // if (employee.length > 0) {
    //     getEmployee = employee[0]
    // }
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        region: state.region,
        // employee: getEmployee || {}
    }
}

export default compose(
    connect(mapStateToProps, { setFilter }),
    firestoreConnect(),
    // firestoreConnect((props) => [
    //     { 
    //         collectionGroup: 'employee', 
    //         where: ["uid", "==", props.auth.uid], storeAs: "employee" 
    //     }
    // ]),
    withRouter
)(SidebarTop)
import React from 'react';
import Select from 'react-select';

class SelectAreaInput extends React.Component {
    state = { selectedOptions: null }
    handleChange = (selectedItem) => {
      const { input: { onChange } } = this.props
      this.setState({ selectedOptions: selectedItem });
      // console.log('selectedItem', selectedItem)
      const value = selectedItem === null ? '' : selectedItem.value;
      // console.log('value', value)
      onChange(value)
    };
    UNSAFE_componentWillReceiveProps(nextProps) {
      const { input: { value: newValue } } = nextProps
      const { input: { value } } = this.props
      if (newValue !== value) {
        if (!newValue) {
          this.setState({
            selectedOptions: null
          })
          return;
        }
        this.setState({
          selectedOptions: {
            label: newValue,
            value: newValue
          }
        })
      }
    }
    componentDidMount() {
      const { input: { onChange, value } } = this.props
      if (!value) {
        this.setState({
          selectedOptions: null
        })
        return;
      }
      this.setState({
        selectedOptions: {
          label: value,
          value: value
        }
      })
    }
    render() {
      const { items, currentRefinement, placeholder, disabled, meta } = this.props;
      // console.log('this.state.selectedOptions', this.state.selectedOptions)
      console.log('this.props', this.props)
      return (
        <React.Fragment>
        <Select
          isDisabled={disabled}
          onChange={this.handleChange}
          options={items}
          className="field-box"
          value={this.state.selectedOptions}
          placeholder={placeholder}
          clearValue={this.clearQuery}
        />
        {meta.error ?
             <div className="text-danger" style={{ fontSize: "0.9em" }}>
                 {meta.error}
             </div>
         : null}
        </React.Fragment>
      );
    }
}

const customStyles = {
  option: () => ({
    fontSize: 13,
    padding: 10,
  }),
}

export default SelectAreaInput;

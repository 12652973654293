import React from "react"
import NumberFormat from 'react-number-format'

const TableItem = (props) => {
    let total = props.item.domestic_group.assurance + props.item.asing_group.assurance
    return (
        <tr key={props.item.id} className="text-center">
            <td>{props.item.date_transaction}</td>
            <td>{props.item.domestic_group.qty || "0"}</td>
            <td>
                <NumberFormat 
                    value={props.item.domestic_group.assurance}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>
            <td>{props.item.asing_group.qty || "0"}</td>
            <td>
                <NumberFormat 
                    value={props.item.asing_group.assurance}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>
            <td>
                <NumberFormat 
                    value={total}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>
        </tr>
    )
}

export default TableItem
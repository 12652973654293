import React, { Component } from 'react';
import NumberFormat from 'react-number-format'
import moment from 'moment'
import LoadingTableSponsor from '../../../../../components/loading-table';
import TableItem from "./TableItem"

class DataTable extends Component {
    renderItem = (item, index) => {
        return (
            <TableItem
                key={item.id}
                index={index}
                item={item}
            />
        )
    }
    render() {
        const month = moment(this.props.date).format('MMMM')
        return (
            <table className="table table-bordered m-a-0">
                <thead style={{ color: "#579264"}}>
                    <tr>
                        <th scope="col" colSpan="2" className="text-center" style={{ verticalAlign: "middle" }}>Periode {month} 2020</th>
                        <th scope="col" colSpan="2" className="text-center">Domestik (Rp 15.000)</th>
                        <th scope="col" colSpan="2" className="text-center">Domestik Grup</th>
                        <th scope="col" colSpan="2" className="text-center">Asing (Rp 30.000)</th>
                        <th scope="col" colSpan="2" className="text-center">Asing Grup</th>
                        <th scope="col" colSpan="2" className="text-center">Mobil (Rp 30.000)</th>
                        <th scope="col" colSpan="2" className="text-center">Motor (Rp 5.000)</th>
                        <th scope="col" colSpan="2" className="text-center">Sepeda (Rp 5.000)</th>
                        <th scope="col" colSpan="3"></th>
                    </tr>
                    <tr>
                        <th scope="col" className="text-center px-5" style={{ verticalAlign: "middle" }}>Tanggal</th>
                        <th scope="col" className="text-center" style={{ verticalAlign: "middle" }}>Hari</th>
                        <th scope="col" className="text-center" style={{ verticalAlign: "middle" }}>Qty</th>
                        <th scope="col" className="text-center" style={{ verticalAlign: "middle" }}>Harga</th>
                        <th scope="col" className="text-center" style={{ verticalAlign: "middle" }}>Qty</th>
                        <th scope="col" className="text-center" style={{ verticalAlign: "middle" }}>Harga</th>
                        <th scope="col" className="text-center" style={{ verticalAlign: "middle" }}>Qty</th>
                        <th scope="col" className="text-center" style={{ verticalAlign: "middle" }}>Harga</th>
                        <th scope="col" className="text-center" style={{ verticalAlign: "middle" }}>Qty</th>
                        <th scope="col" className="text-center" style={{ verticalAlign: "middle" }}>Harga</th>
                        <th scope="col" className="text-center" style={{ verticalAlign: "middle" }}>Qty</th>
                        <th scope="col" className="text-center" style={{ verticalAlign: "middle" }}>Harga</th>
                        <th scope="col" className="text-center" style={{ verticalAlign: "middle" }}>Qty</th>
                        <th scope="col" className="text-center" style={{ verticalAlign: "middle" }}>Harga</th>
                        <th scope="col" className="text-center" style={{ verticalAlign: "middle" }}>Qty</th>
                        <th scope="col" className="text-center" style={{ verticalAlign: "middle" }}>Harga</th>
                        <th scope="col" className="text-center" style={{ verticalAlign: "middle" }}>Total Per Hari</th>
                        <th scope="col" className="text-center" style={{ verticalAlign: "middle" }}>Total Harian Pengunjung</th>
                        <th scope="col" className="text-center" style={{ verticalAlign: "middle" }}>Total Harian Non Pengunjung</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.props.loading ?
                            <React.Fragment>
                                <LoadingTableSponsor column={19} />
                                <LoadingTableSponsor column={19} />
                                <LoadingTableSponsor column={19} />
                                <LoadingTableSponsor column={19} />
                            </React.Fragment>
                            :
                                this.props.data.length == 0 ? 
                                <tr>
                                    <td style={{textAlign: "center!important"}}>
                                        <p>Tidak ada data!</p>
                                    </td>
                                </tr>
                            : this.props.data.map(this.renderItem)
                    }
                </tbody>
                <tfoot>
                    {
                        this.props.loading ?
                        <LoadingTableSponsor column={19} />
                    :
                        <tr className="font-weight-bold">
                            <td className="text-center"></td>
                            <td className="text-center">JUMLAH</td>
                            <td className="text-center">{this.props.total.domestic.qty}</td>
                            <td className="text-center">
                                <NumberFormat 
                                    value={this.props.total.domestic.amount}
                                    displayType={'text'} 
                                    thousandSeparator={true} 
                                    prefix={'Rp'} 
                                    suffix={",-"} 
                                    defaultValue="0" 
                                />
                            </td>
                            <td className="text-center">{this.props.total.domestic_group.qty}</td>
                            <td className="text-center">
                                <NumberFormat 
                                    value={this.props.total.domestic_group.amount}
                                    displayType={'text'} 
                                    thousandSeparator={true} 
                                    prefix={'Rp'} 
                                    suffix={",-"} 
                                    defaultValue="0" 
                                />
                            </td>
                            <td className="text-center">{this.props.total.asing.qty}</td>
                            <td className="text-center">
                                <NumberFormat 
                                    value={this.props.total.asing.amount}
                                    displayType={'text'} 
                                    thousandSeparator={true} 
                                    prefix={'Rp'} 
                                    suffix={",-"} 
                                    defaultValue="0" 
                                />
                            </td>
                            <td className="text-center">{this.props.total.asing_group.qty}</td>
                            <td className="text-center">
                                <NumberFormat 
                                    value={this.props.total.asing_group.amount}
                                    displayType={'text'} 
                                    thousandSeparator={true} 
                                    prefix={'Rp'} 
                                    suffix={",-"} 
                                    defaultValue="0" 
                                />
                            </td>
                            <td className="text-center">{this.props.total.mobil.qty}</td>
                            <td className="text-center">
                                <NumberFormat 
                                    value={this.props.total.mobil.amount}
                                    displayType={'text'} 
                                    thousandSeparator={true} 
                                    prefix={'Rp'} 
                                    suffix={",-"} 
                                    defaultValue="0" 
                                />
                            </td>
                            <td className="text-center">{this.props.total.sepeda_motor.qty}</td>
                            <td className="text-center">
                                <NumberFormat 
                                    value={this.props.total.sepeda_motor.amount}
                                    displayType={'text'} 
                                    thousandSeparator={true} 
                                    prefix={'Rp'} 
                                    suffix={",-"} 
                                    defaultValue="0" 
                                />
                            </td>
                            <td className="text-center">{this.props.total.sepeda.qty}</td>
                            <td className="text-center">
                                <NumberFormat 
                                    value={this.props.total.sepeda.amount}
                                    displayType={'text'} 
                                    thousandSeparator={true} 
                                    prefix={'Rp'} 
                                    suffix={",-"} 
                                    defaultValue="0" 
                                />
                            </td>
                            <td className="text-center">
                                <NumberFormat 
                                    value={this.props.total.all.perDay}
                                    displayType={'text'} 
                                    thousandSeparator={true} 
                                    prefix={'Rp'} 
                                    suffix={",-"} 
                                    defaultValue="0" 
                                />
                            </td>
                            <td className="text-center">
                                <NumberFormat 
                                    value={this.props.total.all.dayVisitor}
                                    displayType={'text'} 
                                    thousandSeparator={true} 
                                    prefix={'Rp'} 
                                    suffix={",-"} 
                                    defaultValue="0" 
                                />
                            </td>
                            <td className="text-center">
                                <NumberFormat 
                                    value={this.props.total.all.dayNonVisitor}
                                    displayType={'text'} 
                                    thousandSeparator={true} 
                                    prefix={'Rp'} 
                                    suffix={",-"} 
                                    defaultValue="0" 
                                />
                            </td>
                        </tr>
                    }
                </tfoot>
            </table>
        )
    }
}

export default DataTable
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Alert } from "react-bootstrap"

import { compose } from 'redux'
import { firebaseConnect } from 'react-redux-firebase'
import { Link } from 'react-router-dom'

class ForgotPasswordScreen extends Component {
  state = {
    email: '',
    alertInfo: false,
    successInfo: false
  }
  onSubmit = async (e) => {
    e.preventDefault()
    try {
      await this.props.firebase.resetPassword(this.state.email)
      console.log("Forget Successful!")
      this.setState({ successInfo: true, email: '' })
    } catch (e) {
      if(this.props.authError) {
        this.setState({ alertInfo: true, email: '' })
      }
    }
  }

  render() {
    if (this.props.authExists) {
      this.props.history.push('/dashboard') 
    }
    return (
      <div className="center-block w-xxl w-auto-xs p-y-md">
        <div className="p-a-md box-color r box-shadow-z1 text-color m-a">
        <Alert show={this.state.alertInfo} variant="danger" dismissible onClose={() => this.setState({ alertInfo: false })}>
          Failed to forgot password. Please try again.
        </Alert>
        <Alert show={this.state.successInfo} variant="success" dismissible onClose={() => this.setState({ successInfo: false })}>
          Password reset email sent to {this.state.email}. Please check your email.
        </Alert>
        <div className="m-b">
          Forgot your password?
          <p className="text-xs m-t">Enter your email address below and we will send you instructions on how to change your password.</p>
        </div>
        <form name="reset" onSubmit={this.onSubmit}>
          <div className="md-form-group">
            <input type="email" value={this.state.email} className="md-input" required onChange={(e) => this.setState({ email: e.target.value, alertInfo: false, successInfo: false })} />
            <label>Your Email</label>
          </div>
          <button type="submit" className="btn primary btn-block p-x-md">Send</button>
        </form>
        </div>
        <p id="alerts-container"></p>
        <div className="p-v-lg text-center">Return to <Link to="/signin" className="text-primary _600">Sign in</Link></div>    
      </div>
    )
  }

}


const mapStateToProps = state => {
    return { auth: state.firebase.auth, authError: state.firebase.authError }
}

export default compose(
    connect(mapStateToProps),
    firebaseConnect()
)(ForgotPasswordScreen)
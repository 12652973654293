import React from "react"
import ErrorMessage from "./ErrorMessage"

const TextAreaDescription = (field) => (
  <div className="form-group mb-4">
    <label 
      htmlFor={`input-${field.label}`} 
      className="form-control-label mt-5" 
      style={{ color: "#579264", fontWeight: "bold" }}
    >
      {field.label}
    </label>
    <div>
      <textarea
        {...field.input}
        type={field.type || "text"}
        placeholder={field.placeholder}
        className="form-control" 
        style={{ backgroundColor: "transparent!important", border: "1px dotted grey", fontSize: "0.9em" }}
        rows={field.rows ||2}
        id={`input-${field.label}`} autoComplete={field.autocomplete || "on"}
      />
      <ErrorMessage {...field} />
    </div>
  </div>
)

export default TextAreaDescription
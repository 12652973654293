import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import { firestore } from 'firebase';

async function getUserData(props) {
    try {
        
        const snapshot = await firestore().collection('region').doc(props.region.toLowerCase()).collection('employee').doc(props.uid).get();
        if (!snapshot.exists) {
            return {}
        }
        return {
            id: snapshot.id,
            ...snapshot.data()
        }
    } catch (error) {
        console.error(error)
    }
}

const SubTicketMenu = () => {
    return (
        <div id="collapseTicket" className="collapse">
            <ul>
                <li className="py-1">
                    <NavLink activeClassName="nav-active" exact={true} to="/dashboard/revenue/ticket">
                        <span className="nav-text">Summary Tiket</span>
                    </NavLink>
                </li>
                <li className="py-1">
                    <NavLink to="/dashboard/revenue/ticket/analytics" activeClassName="nav-active">
                        <span className="nav-text">Analitik</span>
                    </NavLink>
                </li>
                <li className="py-1">
                    <NavLink to="/dashboard/revenue/ticket/report" exact={true} activeClassName="nav-active">
                        <span className="nav-text">Laporan</span>
                    </NavLink>
                    <ul>
                        <li className="py-1">
                            <NavLink to="/dashboard/revenue/ticket/report/bogor" activeClassName="nav-active">
                                <span style={{ borderBottom: "none" }} className="nav-text">Detail</span>
                            </NavLink>
                        </li>
                    </ul>
                </li>
                <li className="py-1">
                    <NavLink to="/dashboard/revenue/ticket/visitor" activeClassName="nav-active">
                        <span className="nav-text">Pengunjung</span>
                    </NavLink>
                </li>
                <li className="py-1">
                    <NavLink to="/dashboard/revenue/ticket/report_day" activeClassName="nav-active">
                        <span className="nav-text">Harian per KR</span>
                    </NavLink>
                </li>
                <li className="py-1">
                    <NavLink to="/dashboard/revenue/ticket/revenue/insurance" activeClassName="nav-active">
                        <span className="nav-text">Asuransi</span>
                    </NavLink>
                </li>
                <li className="py-1">
                    <NavLink to="/dashboard/revenue/retribution" activeClassName="nav-active">
                        <span className="nav-text">Retribusi</span>
                    </NavLink>
                </li>
                {/* <li className="py-1">
                    <NavLink to="/dashboard/revenue/ticket/nett" activeClassName="nav-active">
                        <span className="nav-text">Nett 4 KR</span>
                    </NavLink>
                    <ul>
                        <li className="py-1">
                            <NavLink to="/dashboard/revenue/ticket/nett/bogor" activeClassName="nav-active">
                                <span className="nav-text">Nett per KR</span>
                            </NavLink>
                        </li>
                    </ul>
                </li> */}
            </ul>
        </div>
    )
}

const SubFnbMenu = () => {
    return (
        <div className="collapse" id="collapseFnb">
            <ul>
                {/* <li className="py-1">
                    <NavLink to="/dashboard/revenue/fnb/analytics" activeClassName="nav-active">
                        <span className="nav-text">Analitik</span>
                    </NavLink>
                </li> */}
                <li className="py-1">
                    <NavLink to="/dashboard/revenue/fnb" exact={true} activeClassName="nav-active">
                        <span className="nav-text">
                            Summary FnB
                        </span>
                    </NavLink>
                </li>
                <li className="py-1">
                    <NavLink to="/dashboard/revenue/fnb/report" exact={true} activeClassName="nav-active">
                        <span className="nav-text">Laporan</span>
                    </NavLink>
                    <ul>
                        <li className="py-1">
                            <NavLink to="/dashboard/revenue/fnb/report/bogor" activeClassName="nav-active">
                                <span className="nav-text">Detail</span>
                            </NavLink>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    )
}

const FacilitiesEmployee = (props) => {
    const uidDate = moment().format("DDMMYYYY")
    return (
        <div id="collapseFacilities" className="collapse">
            <ul>
                <li className="py-1">
                    <NavLink to={`/dashboard/facilities_employee/${props.uid}/activities/${uidDate}`} exact={true} activeClassName="nav-active">
                        <span className="nav-text">
                            Kegiatan
                        </span>
                    </NavLink>
                </li>
                <li className="py-1">
                    <NavLink to={`/dashboard/facilities_employee/history/${props.uid}`} activeClassName="nav-active">
                        <span className="nav-text">Laporan Kegiatan</span>
                    </NavLink>
                </li>
            </ul>
        </div>
    )
}

const ListKaryawan = (props) => {
    return (
        <div id="collapseFacilities" className="collapse">
            <ul>
                <li className="py-1">
                    <NavLink to="/dashboard/facilities_employee/employee_list" exact={true} activeClassName="nav-active">
                        <span className="nav-text">List Karyawan</span>
                    </NavLink>
                </li>
            </ul>
        </div>
    )
}

const SubNonFnbMenu = () => {
    return (
        <div className="collapse" id="collapseNonFnb">
            <ul>
                <li className="py-1">
                    <NavLink to="/dashboard/revenue/non_fnb" exact={true} activeClassName="nav-active">
                        <span className="nav-text">
                            Summary Non FnB
                        </span>
                    </NavLink>
                </li>
                <li className="py-1">
                    <NavLink to="/dashboard/revenue/non_fnb/merchandise" exact={true} activeClassName="nav-active">
                        <span className="nav-text">Merchandise</span>
                    </NavLink>
                    <ul>
                        <li className="py-1">
                            <NavLink to="/dashboard/revenue/non_fnb/merchandise/report" exact={true} activeClassName="nav-active">
                                <span className="nav-text">Laporan</span>
                            </NavLink>
                            <ul>
                                <li className="py-1">
                                    <NavLink to="/dashboard/revenue/non_fnb/merchandise/report/bogor" activeClassName="nav-active">
                                        <span className="nav-text">Detail</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>

                <li className="py-1">
                    <NavLink to="/dashboard/revenue/non_fnb/rent_facilities" exact={true} activeClassName="nav-active">
                        <span className="nav-text">Sewa Fasilitas</span>
                    </NavLink>
                    <ul>
                        <li className="py-1">
                            <NavLink to="/dashboard/revenue/non_fnb/rent_facilities/report" exact={true} activeClassName="nav-active">
                                <span className="nav-text">Laporan</span>
                            </NavLink>
                            <ul>
                                <li className="py-1">
                                    <NavLink to="/dashboard/revenue/non_fnb/rent_facilities/report/bogor" activeClassName="nav-active">
                                        <span className="nav-text">Detail</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    )
}

class SidebarNav extends React.Component {
    state = {
        userData: {
            roleId: ""
        }
    }
    async componentDidMount() {
        try {
            if (this.props.region) {
                const userData = await getUserData(this.props)
                this.setState({ userData })
            }
        } catch (error) {
            console.error(error)
        }
    }
    render() {

        const { employee = {}, ...props } = this.props;
        return (
            <div className="hide-scroll" data-flex>
                <nav className="scroll nav-light">
                    <ul className="nav mt-2" style={{ padding: "0 0.3rem" }}>
                        {
                            ((props.profile.token && props.profile.token.claims.admin === true) ||
                                (props.profile.token && props.profile.token.claims.department === "Finance")) ?
                                <React.Fragment>
                                    <li className="py-1">
                                        <NavLink activeClassName="nav-active" exact={true} to="/dashboard">
                                            <span style={{ borderBottom: "1.2px solid #7C7C7C" }} className="nav-text">Dashboard</span>
                                        </NavLink>
                                    </li>
                                    <li className="py-1">
                                        <NavLink exact={true} to="#">
                                            <span style={{ borderBottom: "1.2px solid #7C7C7C" }} className="nav-text">Revenue</span>
                                        </NavLink>
                                        <ul>
                                            <li className="py-1">
                                                <a data-toggle="collapse" data-target="#collapseTicket" aria-controls="collapseTicket" aria-expanded="false">
                                                    <span style={{ borderBottom: "1px solid #7C7C7C", fontWeight: 500 }} className="nav-text">
                                                        Tiket <i className="fa fa-plus float-right mt-1" style={{ fontSize: 9 }}></i>
                                                    </span>
                                                </a>
                                                <SubTicketMenu />
                                            </li>

                                            <li className="py-1">
                                                <a data-toggle="collapse" data-target="#collapseFnb" aria-controls="collapseFnb" aria-expanded="false">
                                                    <span className="nav-text" style={{ fontWeight: 500 }}>FnB <i className="fa fa-plus float-right mt-1" style={{ fontSize: 9 }}></i></span>
                                                </a>
                                                <SubFnbMenu />
                                            </li>

                                            <li className="py-1">
                                                <a data-toggle="collapse" data-target="#collapseNonFnb" aria-controls="collapseNonFnb" aria-expanded="false">
                                                    <span style={{ borderBottom: "1px solid #7C7C7C", fontWeight: 500 }} className="nav-text">Non FnB <i className="fa fa-plus float-right mt-1" style={{ fontSize: 9 }}></i></span>
                                                </a>
                                                <SubNonFnbMenu />
                                            </li>

                                            {/* <li className="py-1">
                                        <NavLink to="/dashboard/revenue/merchandise" activeClassName="nav-active">
                                            <span className="nav-text">Merchandise</span>
                                        </NavLink>
                                    </li> */}

                                        </ul>
                                    </li>


                                </React.Fragment>
                                : null
                        }

                        {
                            ((props.profile.token && props.profile.token.claims.admin === true) ||
                                (props.profile.token && props.profile.token.claims.department === "Human Resource")

                            )
                                ?
                                <li className="py-1">
                                    <NavLink activeClassName="nav-active" to="/dashboard/employee">
                                        <span style={{ borderTop: "1.2px solid #7C7C7C", borderBottom: "1.2px solid #7C7C7C" }} className="nav-text">Informasi Karyawan</span>
                                    </NavLink>
                                </li>
                                : null
                        }
                        {
                            ((props.profile.token && props.profile.token.claims.admin == true) ||
                                (props.profile.token && props.profile.token.claims.department == "Konservasi")) ?
                                <React.Fragment>
                                    <li className="py-1">
                                        <NavLink activeClassName="nav-active" exact={true} to="/dashboard/plant_info">
                                            <span style={{ borderBottom: "1.2px solid #7C7C7C" }} className="nav-text">Konservasi Tumbuhan</span>
                                        </NavLink>
                                    </li>
                                    <li className="py-1">
                                        <NavLink activeClassName="nav-active" to="/dashboard/plant_info/kompos">
                                            <span style={{ borderBottom: "1.2px solid #7C7C7C" }} className="nav-text">Kompos</span>
                                        </NavLink>
                                    </li>
                                    <li className="py-1">
                                        <NavLink activeClassName="nav-active" to="/dashboard/maintenance">
                                            <span style={{ borderBottom: "1.2px solid #7C7C7C" }} className="nav-text">Kegiatan Harian</span>
                                        </NavLink>
                                    </li>
                                </React.Fragment>
                                : null
                        }
                        {
                            ((props.profile.token && props.profile.token.claims.admin == true) ||
                                (props.profile.token && props.profile.token.claims.department == "Maintenance Infrastructure & GA") ||
                                (props.profile.token && props.profile.token.claims.department == "Konservasi")) ?
                                
                                <>
                                    <li className="py-1">
                                        {
                                            (props.profile.token && props.profile.token.claims.admin == true) ||
                                                this.state.userData.roleId === "f1" ||
                                                this.state.userData.roleId === "f2" ||
                                                this.state.userData.roleId === "f3" ||
                                                this.state.userData.roleId === "f4" ? 

                                            <a data-toggle="collapse" data-target="#collapseFacilities" aria-controls="collapseFacilities" aria-expanded="false">
                                                <span style={{ borderBottom: "1px solid #7C7C7C", fontWeight: 500 }} className="nav-text">
                                                    Fasilitas <i className="fa fa-plus float-right mt-1" style={{ fontSize: 9 }}></i>
                                                </span>
                                            </a>

                                            : null
                                        }
                                        {
                                            (props.profile.token && props.profile.token.claims.admin == true) ||
                                            this.state.userData.roleId === "f2" ||
                                            this.state.userData.roleId === "f3" ||
                                            this.state.userData.roleId === "f4" ? 

                                                <FacilitiesEmployee {...props} />
                                                
                                            : null
                                        }
                                        {
                                            (this.state.userData.roleId === "f1" || 
                                            this.state.userData.roleId === "f2") 
                                            ? 

                                                <ListKaryawan />
                                                
                                            : null
                                        }
                                    </li>
                                </>
                                :
                                null
                        }
                    </ul>
                </nav>
            </div>
        )

    }
}

const mapStateToProps = state => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        uid: state.firebase.auth.uid,
        region: state.region
    }
}


export default connect(mapStateToProps)(SidebarNav)
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firebaseConnect } from 'react-redux-firebase'

const SidebarFooter = ({ auth }) => {
    return (
        <div className="b-t">
            <div className="nav-fold">
                <a href="#">
                    <span className="pull-left"></span>
                    <span className="clear hidden-folded p-x">
                        <span className="block _500">{ auth.displayName || "Ilham"}</span>
                    </span>
                </a>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return { auth: state.firebase.auth }
}

export default compose(
    connect(mapStateToProps),
    firebaseConnect()
)(SidebarFooter)
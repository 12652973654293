import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withFirestore } from 'react-redux-firebase'
import { convertToRaw } from 'draft-js';

import AddSession from './AddForm';
import ModalError from '../../components/ModalError';
import { uploadBanner } from './uploadBanner'
import moment from 'moment'

class AddScreen extends Component {
  static propTypes = {
    uid: PropTypes.string,
    firestore: PropTypes.shape({
      add: PropTypes.func.isRequired
    }).isRequired
  }

  state = {
    errorInfo: false,
    data: {
      name: '',
      species: '',
      englishName: '',
      latinName: '',
      genus: '',
      species: '',
      sectorList: [],
      sector: "",
      vak: '',
      collectionNumber: '',
      specimenTotal: '',
      status: '',
      habitus: '',
      deployment: '',
      usability: '',
      description: '',
      place: '',
      region: '',
    }
  }

  handleErrorInfo = () => {
    this.setState({ errorInfo: !this.state.errorInfo });
  }

  createFormData = ({ avatar, species, genus, region, fetchArea, sectorList, areaList, ...restData }) => {
    const data = {
      timestamp: new Date(),
      species: typeof species.getCurrentContent == "function" ? convertToRaw(species.getCurrentContent()) : species,
      genus: typeof genus.getCurrentContent == "function" ? convertToRaw(genus.getCurrentContent()) : genus,
      region: this.props.region.toLowerCase(),
      userId: this.props.uid,
      ...restData
    };
    return data;
  }
  onSubmit = async (values) => {
    try {
      const formData = this.createFormData(values)
      console.log('formData', formData)
      const { accessToken } = this.props.auth.stsTokenManager;

      // console.log('accessToken', accessToken)

      const docSnapshot = await this.props.firestore.add({ 
        collection: 'region',
        doc: this.props.region.toLowerCase(),
        subcollections: [{ collection: 'plants' }]
      }, formData);

      // console.log('Successfully add new data: ', docSnapshot.id)

      if (values.avatar) {
        const path = `region/${this.props.region.toLowerCase()}/plants/${docSnapshot.id}`
        const imagesUploaded = await uploadBanner(path, values.avatar);
          
        await this.props.firestore.update({ 
          collection: 'region',
          doc: this.props.region.toLowerCase(), // masih hardcode wilayah
          subcollections: [{ collection: 'plants', doc: docSnapshot.id }]
        }, { avatar: imagesUploaded });
      }
      this.props.history.goBack();
    } catch (error) {
      console.log('error', error)
      this.setState({ errorInfo: true })

      // console.error("Failed to add plant data: ", error);
    }
  }

  goBack = () => {
    this.props.history.push(`/dashboard/plant_info`)
  }

  componentDidMount() {
    this.fetchData(this.props)
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.region !== this.props.region) {
      this.fetchData(nextProps)
    }
  }
  fetchData = async (props) => {
    try {
      this.setState({ 
        data: {
          taskList: [],
          description: "",
          images: [],
          arealist: [],
          sectorList: [],
          area: "" 
        }, 
      })
      const docSnapshots = await this.props.firestore.get({
        collection: 'region',
        doc: props.region.toLowerCase(), // masih hardcode wilayah
        subcollections: [{ 
          collection: 'sector',
        }],
      });
      if (docSnapshots.empty) {
        console.log("kosong")
        this.setState({
          data: {
            ...this.state.data, 
            sector: '', 
            areaList: [], 
            sectorList: [], 
          }
        })
        return;
      }
      let sectorList = []
      docSnapshots.forEach((doc) => {
        sectorList.push({
          id: doc.id,
          label:doc.data().name,
          value: doc.data().name,
          ...doc.data()
        })
      })
      // const defaultSector = sectorList[0]
      this.setState({
        data: {
          ...this.state.data, 
          // sector: defaultSector, 
          sectorList,
          region: props.region,
          // fetchArea: this.fetchArea,
        }
      })
    } catch (error) {
      this.setState({
        data: {
          ...this.state.data, 
          sector: '', 
          sectorList: [], 
        }
      })
    }
  }

  render() {
    if (!this.props.uid) return null
    return (
      <React.Fragment>
        <div className="box-header px-5 mt-3" style={{ backgroundColor: "#EFF2F7" }}>
          <div className="row">
            <a onClick={this.goBack}><i className="fa fa-arrow-left" aria-hidden="true"></i></a>
            <h3 className="ml-3 mt-1" style={{ color: "#3B4859" }}>TAMBAH TUMBUHAN</h3>
          </div>
        </div>
        {
          this.state.errorInfo &&
          <ModalError
            errorInfo={this.state.errorInfo}
            handleErrorInfo={this.handleErrorInfo}
          />
        }
        <div className="box-body">
          <AddSession onSubmit={this.onSubmit} initialValues={this.state.data} />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    uid: state.firebase.auth.uid,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    region: state.region,
  }
}

export default compose(
  connect(mapStateToProps),
  withFirestore,
)(AddScreen)
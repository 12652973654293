/* eslint-disable no-bitwise */
export const stripString = title => title.toLowerCase().replace(/ /g, "");
export const guidGenerator = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
export const splitSlash = str => str.split("/")[1];

export const totalValuesFromObj = (obj) => {
    let total = 0
    for (var key in obj) {
        total += obj[key];
    };
    return total;
}
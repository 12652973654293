import React, { Component } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import { convertFromRaw } from 'draft-js';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ErrorMessage from './ErrorMessage';


export default class DraftInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
    };
  }

  onEditorStateChange = (editorState) => {  
    const { input: { value, onChange } } = this.props

    onChange(editorState)
    this.setState({ editorState });
  };
  componentDidMount() {
    const { input: { value, onChange } } = this.props
    if (typeof value == "object") {
      this.setState({
        editorState: EditorState.createWithContent(convertFromRaw(value)),
      });
    }
  }
  render() {
    const { editorState } = this.state;
    const field = this.props;
    return (
        <div className="form-group mb-4">
          <label 
          htmlFor={`input-${field.label}`} 
          className="form-control-label" 
          style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold" }}
          >
            {field.label}
          </label>
          <Editor
            toolbar={{ 
              options: ['inline' ],
              inline: { options: ['bold', 'italic']}
            }}
            editorState={editorState}
            wrapperClassName="demo-wrapper"
            editorClassName="form-control field-box"
            onEditorStateChange={this.onEditorStateChange}
            placeholder={field.label}
          />
        <ErrorMessage {...field} />
      </div>
    )
  }
}
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withFirestore } from 'react-redux-firebase'

import AddSession from './AddForm';
import ModalError from '../../../components/ModalError';
import { uploadGalleryBase64 } from '../../../helpers/uploadGallery';

const composeList = [
  { id: "penggilingan_daun", label: "Penggilingan Daun", qty: "", description: "" },
  { id: "fermentasi_daun_rumput", label: "Fermentasi Daun/Rumput", qty: "", description: "" },
  { id: "pebalikan_hasil_fermentasi", label: "Pebalikan Hasil Fermentasi", qty: "", description: "" },
  { id: "penggilingan_kompos", label: "Penggilingan Kompos", qty: "", description: "" },
  { id: "penyaringan_kompos", label: "Penyaringan Kompos", qty: "", description: "" },
  { id: "pengemasan_kompos", label: "Pengemasan Kompos", qty: "", description: "" },
  { id: "pemanenan_kompos_matang", label: "Pemanenan Kompos Matang", qty: "", description: "" },
  { id: "others", label: "", qty: "", description: "" },
]

class AddScreen extends Component {
  static propTypes = {
    uid: PropTypes.string,
    firestore: PropTypes.shape({
      add: PropTypes.func.isRequired
    }).isRequired
  }

  state = {
    errorInfo: false,
    collection: "compose",
    data: {
      composeList: composeList,
      images: []
    }
  }

  handleErrorInfo = () => {
    this.setState({ errorInfo: !this.state.errorInfo });
  }

  createFormData = ({ images, ...restData }) => {
    const data = {
      timestamp: new Date(),
      date: new Date(),
      userId: this.props.uid,
      ...restData
    };
    return data;
  }

  getDataParams = (data) => {
    const dataParams = Object.keys(data).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
    }).join('&');
    return dataParams;
  }

  onSubmit = async (values) => {
    try {
      const formData = this.createFormData(values)
      const docSnapshot = await this.props.firestore.add({ 
        collection: 'region',
        doc: this.props.region.toLowerCase(),
        subcollections: [{ collection: this.state.collection }]
      }, formData);
      
      if (values.images) {
        const path = `region/${this.props.region.toLowerCase()}/${this.state.collection}/${docSnapshot.id}`
        const imagesUploaded = await uploadGalleryBase64(docSnapshot.id, path, values.images);
          
        await this.props.firestore.update({ 
          collection: 'region',
          doc: this.props.region.toLowerCase(),
          subcollections: [{ collection: this.state.collection, doc: docSnapshot.id, }],
        }, {images: imagesUploaded});

      }
      this.props.history.goBack();
    } catch (error) {
      this.setState({ errorInfo: true })
    }
  }

  goBack = () => {
    this.props.history.push(`/dashboard/plant_info/kompos`)
  }

  render() {
    if (!this.props.uid) return null
    return (
      <React.Fragment>
        <div className="box-header px-5 mt-3" style={{ backgroundColor: "#EFF2F7" }}>
          <div className="row">
            <a onClick={this.goBack}><i class="fa fa-arrow-left" aria-hidden="true"></i></a>
            <h3 className="ml-3 mt-1" style={{ color: "#3B4859" }}>KOMPOS</h3>
          </div>
        </div>
        {
          this.state.errorInfo &&
          <ModalError
            errorInfo={this.state.errorInfo}
            handleErrorInfo={this.handleErrorInfo}
            error={this.state.error}
          />
        }
        <div className="box-body">
          <AddSession onSubmit={this.onSubmit} initialValues={this.state.data} />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    uid: state.firebase.auth.uid,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    region: state.region
  }
}

export default compose(
  connect(mapStateToProps),
  withFirestore,
)(AddScreen)
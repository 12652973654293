import React, { Component } from 'react';
import NumberFormat from 'react-number-format'
import TableItem from "./TableItem"
import LoadingTableSponsor from '../../../components/loading-table';

class DataTable extends Component {
    renderItem = (item, index) => {
        return (
            <TableItem
                key={item.id}
                index={index}
                item={item}
            />
        )
    }
    render() {
        return (
            <table className="table table-bordered m-a-0">
                <thead style={{ color: "#579264" }}>
                    <tr>
                        <th scope="col" rowSpan="2" className="text-center px-5" style={{ verticalAlign: "middle" }}>Tanggal</th>
                        <th scope="col" colSpan="3" className="text-center">Domestik</th>
                        <th scope="col" colSpan="3" className="text-center">Domestik Grup</th>
                        <th scope="col" colSpan="3" className="text-center">Asing Grup</th>
                        <th scope="col" colSpan="3" className="text-center">Asing</th>
                        <th scope="col" rowSpan="2" className="text-center" style={{ verticalAlign: "middle" }}>Jumlah</th>
                    </tr>
                    <tr>    
                        <th scope="col" className="text-center">LBR</th>
                        <th scope="col" className="text-center">TARIF</th>
                        <th scope="col" className="text-center">UANG</th>
                        <th scope="col" className="text-center">LBR</th>
                        <th scope="col" className="text-center">TARIF</th>
                        <th scope="col" className="text-center">UANG</th>
                        <th scope="col" className="text-center">LBR</th>
                        <th scope="col" className="text-center">TARIF</th>
                        <th scope="col" className="text-center">UANG</th>
                        <th scope="col" className="text-center">LBR</th>
                        <th scope="col" className="text-center">TARIF</th>
                        <th scope="col" className="text-center">UANG</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.props.loading ?
                            <React.Fragment>
                                <LoadingTableSponsor column={14} />
                                <LoadingTableSponsor column={14} />
                                <LoadingTableSponsor column={14} />
                                <LoadingTableSponsor column={14} />
                            </React.Fragment>
                            :
                                this.props.data.length == 0 ? 
                                <tr>
                                    <td className="text-center" colSpan="14">
                                        <p>Tidak ada data!</p>
                                    </td>
                                </tr>
                            : this.props.data.map(this.renderItem)
                    }
                </tbody>
                <tfoot>
                    <tr className="font-weight-bold">
                        <td className="text-center">JUMLAH</td>
                        <td className="text-center">{this.props.total.domestic.qty}</td>
                        <td className="text-center"></td>
                        <td className="text-center">
                            <NumberFormat 
                                value={this.props.total.domestic.tax}
                                displayType={'text'} 
                                thousandSeparator={true} 
                                prefix={'Rp'} 
                                suffix={",-"} 
                                defaultValue="0" 
                            />
                        </td>
                        <td className="text-center">{this.props.total.domestic_group.qty}</td>
                        <td className="text-center"></td>
                        <td className="text-center">
                            <NumberFormat 
                                value={this.props.total.domestic_group.tax}
                                displayType={'text'} 
                                thousandSeparator={true} 
                                prefix={'Rp'} 
                                suffix={",-"} 
                                defaultValue="0" 
                            />
                        </td>
                        <td className="text-center">{this.props.total.asing_group.qty}</td>
                        <td className="text-center"></td>
                        <td className="text-center">
                            <NumberFormat 
                                value={this.props.total.asing_group.tax}
                                displayType={'text'} 
                                thousandSeparator={true} 
                                prefix={'Rp'} 
                                suffix={",-"} 
                                defaultValue="0" 
                            />
                        </td>
                        <td className="text-center">{this.props.total.asing.qty}</td>
                        <td className="text-center"></td>
                        <td className="text-center">
                            <NumberFormat 
                                value={this.props.total.asing.tax}
                                displayType={'text'} 
                                thousandSeparator={true} 
                                prefix={'Rp'} 
                                suffix={",-"} 
                                defaultValue="0" 
                            />
                        </td>
                        <td className="text-center">
                            <NumberFormat 
                                value={this.props.total.total_tax}
                                displayType={'text'} 
                                thousandSeparator={true} 
                                prefix={'Rp'} 
                                suffix={",-"} 
                                defaultValue="0" 
                            />
                        </td>
                    </tr>
                </tfoot>
            </table>
        )
    }
}

export default DataTable
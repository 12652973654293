import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector, FieldArray } from 'redux-form'

import GalleryPicker from '../../../components/Form/GalleryPicker'
import SubmitButton from '../../../components/SubmitButton'
import LoaderModal from '../../../components/LoaderModal'
import validate from './validate'
import moment from 'moment'
import ComposeList from '../../../components/Form/ComposeList'

let AddForm = ({ handleSubmit, pristine, submitting, valid, composeList }) => {
  return (
    <React.Fragment>
      <div className="col-12" style={{ marginTop: 15 }}>
        {/* <div className="" style={{ borderRadius: 5 }}>
          <div className="box-header">
            <h2 className="m-2" style={{ fontWeight: "bold" }}>LAPORAN</h2>
          </div>
        </div> */}
      </div>
      <div className="box">      
        <div className="form-group row px-5 pb-2 pt-4 ml-2">
          <h5 
            htmlFor={`input-region`} 
            className="col-md-2" 
            style={{ color: "#579264", fontWeight: "bold" }}
          >
            Tanggal
          </h5>
          <div className="col-md-10">
            <p id={`input-region`} style={{ 
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "20px",
              lineHeight: "30px",
              letterSpacing: "0.15px",
              color: "#979D99" 
            }}>{moment().locale("id").format("DD MMMM YYYY")}</p>
          </div>
        </div>
      </div>
      
      <form role="form" onSubmit={handleSubmit}>
        <div className="col-sm-12 box">
          <div className="px-5 pb-5" style={{ borderRadius: 5 }}>
            <table class="table mt-4" style={{ color: "rgba(124, 122, 122, 0.87)" }}>
              <thead>
                <tr>
                  <th scope="col">Nama</th>
                  <th scope="col">Jumlah</th>
                  <th scope="col">Satuan</th>
                  <th scope="col">Keterangan</th>
                </tr>
              </thead>
              <tbody>
                <FieldArray name="composeList" component={ComposeList} data={composeList} />
              </tbody>
            </table>
          </div>
        </div>

        <div className="col-sm-12 box p-5">
          <Field
            component={GalleryPicker}
            label="Foto"
            name="images"
          />
        </div>

        <div className="col-lg-6 col-xs-12 px-0">
          <div className="col-lg-3 col-xs-12 px-0 mt-5 text-center">
            <SubmitButton disabled={!valid || submitting} />
          </div>
          <LoaderModal submitting={submitting} />
        </div>
      </form>
    </React.Fragment>
  )
}

AddForm = reduxForm({
  form: 'addKomposForm',
  validate,
  enableReinitialize: true,
})(AddForm)


const selector = formValueSelector('addKomposForm')
AddForm = connect(
  state => {
    const composeList = selector(state, 'composeList') || [];
    
    return {
      composeList,
    }
  }
)(AddForm)
  
export default AddForm
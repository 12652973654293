import React, { Component } from 'react';
import TableItem from "./TableItem"
import LoadingTableSponsor from '../../../components/loading-table';

class DataTable extends Component {
    static defaultProps = {
        data: []
    }
    renderItem = (item, index) => {
        return (
            <TableItem
                key={item.id}
                index={index}
                item={item}
            />
        )
    }
    render() {
        return (
            <table className="table table-bordered m-a-0">
                <thead style={{ color: "#579264" }}>
                    <tr>
                        <th scope="col" className="text-center">Tanggal</th>
                        <th scope="col" className="text-center">Hari</th>
                        <th scope="col" className="text-center">Bogor</th>
                        <th scope="col" className="text-center">Cibodas</th>
                        <th scope="col" className="text-center">Purwodadi</th>
                        <th scope="col" className="text-center">Bali</th>
                        <th scope="col" className="text-center">Total</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.props.loading ?
                            <React.Fragment>
                                <LoadingTableSponsor column={7} />
                                <LoadingTableSponsor column={7} />
                                <LoadingTableSponsor column={7} />
                                <LoadingTableSponsor column={7} />
                            </React.Fragment>
                            :
                                this.props.data.length == 0 ? 
                                <tr>
                                    <td className="text-center" colSpan="9">
                                        <p>Tidak ada data!</p>
                                    </td>
                                </tr>
                            : this.props.data.map(this.renderItem)
                    }
                </tbody>
            </table>
        )
    }
}

export default DataTable
import React, { Component } from 'react';

import { connect } from 'react-redux'
import { compose } from 'redux'
import { firebaseConnect } from 'react-redux-firebase'
// import moment from 'moment';
// import { Calendar } from 'react-calendar';

import DataTable from './DataTable';
// import { auth, db } from "../../config/constants";
// import {
//     BarChart, ResponsiveContainer, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
// } from 'recharts';
const axios = require('axios').default;


const data = [
    {
        transactionNo: '00101',
        ticketType: 'Domestic',
        paymentMethod: 'Debit',
        qty: 100,
        price: 100000,
        pmiCharge: 100000, insurance: 0,
        tax: 0,
        total: 0,
    },
    {
        transactionNo: '00202',
        date: 'Jan 2 2020',
        ticketType: 'Domestic',
        paymentMethod: 'Debit',
        qty: 100,
        price: 100000,
        pmiCharge: 100000, insurance: 0,
        tax: 0,
        total: 0,
    },
    {
        transactionNo: '00303',
        date: 'Jan 3 2020',
        ticketType: 'Domestic',
        paymentMethod: 'Debit',
        qty: 100,
        price: 100000,
        pmiCharge: 100000, insurance: 0,
        tax: 0,
        total: 0,
    },
    {
        transactionNo: '00404',
        date: 'Jan 4 2020',
        ticketType: 'Domestic',
        paymentMethod: 'Debit',
        qty: 100,
        price: 100000,
        pmiCharge: 100000, insurance: 0,
        tax: 0,
        total: 0,
    },
    {
        transactionNo: '00505',
        date: 'Jan 5 2020',
        ticketType: 'Domestic',
        paymentMethod: 'Debit',
        qty: 100,
        price: 100000,
        pmiCharge: 100000, insurance: 0,
        tax: 0,
        total: 0,
    },
    {
        transactionNo: '00101',
        date: 'Jan 6 2020',
        ticketType: 'Domestic',
        paymentMethod: 'Debit',
        qty: 100,
        price: 100000,
        pmiCharge: 100000, insurance: 0,
        tax: 0,
        total: 0,
    },
    {
        transactionNo: '00101',
        date: 'Jan 7 2020',
        ticketType: 'Domestic',
        paymentMethod: 'Debit',
        qty: 100,
        price: 100000,
        pmiCharge: 100000, insurance: 0,
        tax: 0,
        total: 0,
    },
    {
        transactionNo: '00101',
        date: 'Jan 8 2020',
        ticketType: 'Domestic',
        paymentMethod: 'Debit',
        qty: 100,
        price: 100000,
        pmiCharge: 100000, insurance: 0,
        tax: 0,
        total: 0,
    },
    {
        transactionNo: '00101',
        date: 'Jan 9 2020',
        ticketType: 'Domestic',
        paymentMethod: 'Debit',
        qty: 100,
        price: 100000,
        pmiCharge: 100000, insurance: 0,
        tax: 0,
        total: 0,
    },
    {
        transactionNo: '00101',
        date: 'Jan 10 2020',
        ticketType: 'Domestic',
        paymentMethod: 'Debit',
        qty: 100,
        price: 100000,
        pmiCharge: 100000, insurance: 0,
        tax: 0,
        total: 0,
    }
];

class TicketingDetail extends Component {
    state = {
        date: new Date(),
        dataTicket: [],
        loading: true,
    }
    onChange = date => this.setState({ date })
    onClickDay = (value) => alert('Happy Birthday To Jane Doe')
    fetchDataEvent = async () => {
      this.setState({ loading: true })
      const { accessToken } = this.props.auth.stsTokenManager;
        console.log('accessToken', accessToken)
      try {
        const response = await axios({
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + accessToken
            },
            url: 'https://us-central1-krmos-sandbox.cloudfunctions.net/revenue/parse-api',
            data: {
                "merchant_code": "194151",
                "date_start": "2019-12-11",
                "date_end": "2019-12-12",
                "limitData": 1000,
            }
        })
        const { data, status } = response
        let dataTicket = []
        dataTicket = data.data
        console.log('dataTicket', dataTicket)
        this.setState({
            dataTicket,
          loading: false
        })
        console.log('data', data)
      } catch (error) {
        console.error(error)
        this.setState({
            dataTicket: [],
          loading: false
        })
      }
    }
    componentDidMount() {
        this.fetchDataEvent();
    }
    onChange = (value) => {
        this.setState({ selectedOption: value })
    }
    render() {
        return (
            <React.Fragment>
                <div className="container py-5">
                    <div className="row">
                        <div className="col-md-2 col-sm-12 mt-4">
                            <p>
                                01/01/2020
                            </p>
                        </div>
                    </div>
                    {/* <div className="row">
                    <div className="col-md-2 col-sm-12 mt-4">
                        <div className="dropdown">
                            <button 
                                className="btn border dropdown-toggle w-100" 
                                type="button" id="dropdownMenuButton" 
                                data-toggle="dropdown" 
                                aria-haspopup="true" 
                                aria-expanded="false"
                                style={{ backgroundColor: "transparent", fontSize: "0.8em", border:"1px solid rgba(124, 122, 122, 0.87)" }}
                            >
                                Semua Wilayah
                            </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a className="dropdown-item" href="#">Bogor</a>
                                    <a className="dropdown-item" href="#">Bali</a>
                                    <a className="dropdown-item" href="#">Something else here</a>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className="py-3">
                        <div className="box py-3 mb-0">
                            <div className="table-responsive mt-3">
                                <DataTable data={this.state.dataTicket} loading={this.state.loading} />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


const mapStateToProps = state => {
    return { 
        auth: state.firebase.auth, 
        profile: state.firebase.profile
    }
}

export default compose(
    connect(mapStateToProps),
    firebaseConnect()
)(TicketingDetail)
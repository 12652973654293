import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import moment from 'moment'
import { withFirestore } from 'react-redux-firebase'
import queryString from 'query-string'
import AddSession from './AddForm';
import ModalError from '../../components/ModalError';
import { firestore } from 'firebase'
import { getUserData } from '../../helpers/fetchUserData'

const areaList = [
  { id: "Ruang Titan Arum", label: "Ruang Titan Arum", value: "Ruang Titan Arum"},
  { id: "Ruang Lodoice", label: "Ruang Lodoice", value: "Ruang Lodoice"},
  { id: "Toilet Lt 3", label: "Toilet Lt 3", value: "Toilet Lt 3"},
  { id: "Toilet Lt 2", label: "Toilet Lt 2", value: "Toilet Lt 2"},
  { id: "Lobby", label: "Lobby", value: "Lobby"},
  { id: "Perpustakaan", label: "Perpustakaan", value: "Perpustakaan"},
]

const initialData = {
  task: "",
  sector: "",
  areaList,
  taskList: [],
  area: "",
  description: "",
  type: "harian",
  status: "started"
}

class AddScreen extends Component {
  
  state = {
    errorInfo: false,
    data: initialData
  }

  handleErrorInfo = () => {
    this.setState({ errorInfo: !this.state.errorInfo });
  }

  createFormData = ({ 
    arealist,
    taskList,
    fetchArea,
    areaList,
    sectorList,
    ...restData
  }) => {
    const data = {
      timestamp: new Date(),
      startTime: new Date(),
      ...restData
    };
    return data;
  }

  async addDataFacilityReport(facilityData, ref) {
    /* add report data in report subcollection of facility */

    await ref.set(facilityData, { merge: true })
  }

  async addDataReport(formData, ref) {
    const reportSnapshot = await ref.collection("report").add(formData);
    await ref.collection("report").doc(reportSnapshot.id).set({
      id: reportSnapshot.id
    }, { merge: true })
    console.log("report id: ", reportSnapshot.id)
  }
  
  onSubmit = async (values) => {
    try {
      const formData = this.createFormData(values)
      
      const { region, uid } = this.props;

      const { activityId } = this.props.match.params
      if (!activityId) {
        throw new Error("no activityId")
      }
      
      const regionRef = firestore().collection("region").doc(region.toLowerCase())
      const employeeRef = regionRef.collection("employee").doc(uid)
      const facilityReportRef = employeeRef.collection("facilityReport").doc(activityId)

      const facilityData = {
        uid: activityId,
        timestamp: new Date(),
        isAvailable: false
      }

      await this.addDataFacilityReport(facilityData, facilityReportRef)

      await this.addDataReport(formData, facilityReportRef)

      this.goBack();
    } catch (error) {
      console.error("error: ", error)
      this.setState({ errorInfo: true })
    }
  }

  fetchTaskList = async (props) => {
    try {
      const userData = await getUserData(this.props.region, this.props.uid)
      
      const regionRef = firestore().collection("region").doc(props.region.toLowerCase())
      const docSnapshots = 
        await regionRef.collection("tasklistFacilities").where("positionId", "==", userData.position).get()

      if (docSnapshots.empty) {
        console.log("kosong")
        return;
      }
      const taskList = []
      const snapshotData = {
        id: docSnapshots.docs[0].id,
        ...docSnapshots.docs[0].data()
      }
      snapshotData.taskList.forEach((item) => {
        taskList.push({
          id: item,
          label: item,
          value: item,
        })
      })

      this.setState({
        data: {
          ...this.state.data, 
          taskList,
        }
      })

    } catch (error) {
      this.setState({
        data: {
          ...this.state.data,
          taskList: [], 
        }
      })
    }
  }
  fetchSectorFacilities = async (props) => {
    try {
      const docSnapshots = await this.props.firestore.get({
        collection: 'region',
        doc: props.region.toLowerCase(),
        subcollections: [{ 
          collection: 'sectorFacilities',
        }],
      });

      if (docSnapshots.empty) {
        return;
      }
      
      const sectorList = []
      docSnapshots.forEach((doc) => {
        const { name, facilitiesArea } = doc.data();
        sectorList.push({
          id: name,
          label: name,
          value: name,
          facilitiesArea: facilitiesArea
        })
      })

      this.setState({
        data: {
          ...this.state.data, 
          sectorList,
        }
      })

    } catch (error) {
      this.setState({
        data: {
          ...this.state.data,
          sectorList: [], 
        }
      })
    }
  }

  fetchTypeActivities() {    
    const values = queryString.parse(this.props.location.search) || "harian"
    if (values.type) {
      this.setState({ data: { ...this.state.data, type: values.type }})
    }
  }

  componentDidMount() {
    this.fetchTypeActivities()
    this.fetchTaskList(this.props)
    this.fetchSectorFacilities(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.region !==  this.props.region) {
      this.fetchTaskList(nextProps)
    }
  }

  goBack = () => {
    const { activityId } = this.props.match.params
    this.props.history.push(`/dashboard/facilities_employee/${this.props.uid}/activities/${activityId}`)
  }

  render() {
    return (
      <React.Fragment>
        <div className="box-header px-5 mt-3" style={{ backgroundColor: "#EFF2F7" }}>
          <div className="row">
            <a onClick={this.goBack}><i class="fa fa-arrow-left" aria-hidden="true"></i></a>
            <h3 className="ml-3 mt-1" style={{ color: "#3B4859" }}>LAPORAN HARIAN</h3>
          </div>
        </div>

        {
          this.state.errorInfo &&
          <ModalError
            errorInfo={this.state.errorInfo}
            handleErrorInfo={this.handleErrorInfo}
          />
        }
        <div className="container-fluid p-5 spacing-resp">
          <AddSession 
            onSubmit={this.onSubmit} 
            initialValues={this.state.data} 
          />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    uid: state.firebase.auth.uid,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    region: state.region
  }
}

export default compose(
  connect(mapStateToProps),
  withFirestore,
)(AddScreen)
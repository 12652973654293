import React, { Component } from 'react'
import moment from 'moment-timezone'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import DataTable from './DataTable'
import DataTableRekap from './DataTableRekap'

import DownloadReport from '../../../components/DownloadReport'
import DatePicker from '../../../components/DatePicker'

const sampleDataRekap = [
        {
            name: "Domestik",
            qty: 0,
            tariff: 0,
            tax: 0
        },
        {
            name: "Domestik Grup",
            qty: 0,
            tariff: 0,
            tax: 0
        },
        {
            name: "Asing",
            qty: 0,
            tariff: 0,
            tax: 0
        },
        {
            name: "Asing Grup",
            qty: 0,
            tariff: 0,
            tax: 0
        }
]

class TicketRevenue extends Component {
    state = {
        date: new Date(),
        dataTicket: [],
        dataRekap: sampleDataRekap,
        fileName: "",
        dataRekapTotal: {
            name: "JUMLAH",
            total_qty: 0,
            total_tax: 0
        },
        showDownload: false,
        loading: true,
        startDate: moment(),
        // endDate: moment().startOf('month').add('2', 'day'),
        endDate: moment(),
        regionList: [
            'Bogor',
            'Cibodas',
            'Purwodadi',
            'Bali'
        ],
        region: 'bogor',
        total: {
            asing: {
                qty: 0,
                tax: 0
            },
            asing_group: {
                qty: 0,
                tax: 0
            },
            domestic: {
                qty: 0,
                tax: 0
            },
            domestic_group: {
                qty: 0,
                tax: 0
            },
            total_tax: 0,
            total_qty: 0
        }
    }
    fetchDataEvent = async () => {
        this.setState({ loading: true, dataTicket: [] })
        try {
            const snapshots = await this.props.firestore.get({
                collection: 'region',
                doc: this.state.region.toLowerCase(),
                subcollections: [{
                    collection: 'revenueTickets',
                    orderBy: [
                        ['timestamp', 'asc']
                    ],
                    where: [
                        ['timestamp', '>=', moment(this.state.startDate).tz("Asia/Jakarta").startOf('day').toDate()],
                        ['timestamp', '<=', moment(this.state.endDate).tz("Asia/Jakarta").startOf('day').toDate()]
                    ]
                }]
            });
            let dataTicket = []

            let total = this.state.total
            snapshots.forEach((snap) => {
                let { tariff_transactions, total_tax = 0, total_qty = 0 } = snap.data().tariff
                let ticketData = {
                    id: snap.id,
                    date_transaction: moment(snap.data().timestamp.toDate()).format("DD MMMM YYYY"),
                    asing: {
                        qty: 0,
                        tax: 0,
                        tariff: 0,
                    },
                    asing_group: {
                        qty: 0,
                        tax: 0,
                        tariff: 0,
                    },
                    domestic: {
                        qty: 0,
                        tax: 0,
                        tariff: 0,
                    },
                    domestic_group: {
                        qty: 0,
                        tax: 0,
                        tariff: 0,
                    },
                    total_tax,
                    total_qty,
                }

                for (let i = 0; i < tariff_transactions.length; i++) {
                    const item = tariff_transactions[i];
                    switch (item.tariff) {
                        case "Asing":
                            ticketData.asing = item
                            break;
                        case "Asing Group":
                            ticketData.asing_group = item
                            break;
                        case "Domestik":
                            ticketData.domestic = item
                            break;
                        case "Domestik Group":
                            ticketData.domestic_group = item
                            break;
                        default:
                            break;
                    }
                }
                switch (this.state.region.toLowerCase()) {
                    case "bogor":
                        ticketData.domestic.tariff = 1000
                        ticketData.domestic_group.tariff = 1000
                        ticketData.asing_group.tariff = 2000
                        ticketData.asing.tariff = 2000
                        break;
                    case "cibodas":
                        ticketData.domestic.tariff = 7000
                        ticketData.domestic_group.tariff = 7000
                        ticketData.asing_group.tariff = 7000
                        ticketData.asing.tariff = 7000
                        break;
                    case "purwodadi":
                        ticketData.domestic.tariff = 0
                        ticketData.domestic_group.tariff = 0
                        ticketData.asing_group.tariff = 0
                        ticketData.asing.tariff = 0
                        break;
                    case "bali":
                        ticketData.domestic.tariff = 500
                        ticketData.domestic_group.tariff = 500
                        ticketData.asing_group.tariff = 1500
                        ticketData.asing.tariff = 1500
                        break;
                
                    default:
                        break;
                }

                dataTicket.push(ticketData)
            })

            for (let i = 0; i < dataTicket.length; i++) {
                const ticket = dataTicket[i];
                total = {
                    asing: {
                        qty: (total.asing.qty || 0) + (ticket.asing.qty || 0),
                        tax: (total.asing.tax || 0) + (ticket.asing.tax || 0)
                    },
                    asing_group: {
                        qty: (total.asing_group.qty || 0) + (ticket.asing_group.qty || 0),
                        tax: (total.asing_group.tax || 0) + (ticket.asing_group.tax || 0)
                    },
                    domestic: {
                        qty: (total.domestic.qty || 0) + (ticket.domestic.qty || 0),
                        tax: (total.domestic.tax || 0) + (ticket.domestic.tax || 0)
                    },
                    domestic_group: {
                        qty: (total.domestic_group.qty || 0) + (ticket.domestic_group.qty || 0),
                        tax: (total.domestic_group.tax || 0) + (ticket.domestic_group.tax || 0)
                    },
                    total_tax: total.total_tax + ticket.total_tax,
                    total_qty: total.total_qty + ticket.total_qty,
                }
                switch (this.state.region.toLowerCase()) {
                    case "bogor":
                        total.domestic.tariff = 1000
                        total.domestic_group.tariff = 1000
                        total.asing_group.tariff = 2000
                        total.asing.tariff = 2000
                        break;
                    case "cibodas":
                        total.domestic.tariff = 7000
                        total.domestic_group.tariff = 7000
                        total.asing_group.tariff = 7000
                        total.asing.tariff = 7000
                        break;
                    case "purwodadi":
                        total.domestic.tariff = 0
                        total.domestic_group.tariff = 0
                        total.asing_group.tariff = 0
                        total.asing.tariff = 0
                        break;
                    case "bali":
                        total.domestic.tariff = 500
                        total.domestic_group.tariff = 500
                        total.asing_group.tariff = 1500
                        total.asing.tariff = 1500
                        break;
                
                    default:
                        break;
                }
            }
            
            let tempDataRekap = this.state.dataRekap
            tempDataRekap[0] = { ...tempDataRekap[0], ...total.domestic }
            tempDataRekap[1] = { ...tempDataRekap[1], ...total.domestic_group }
            tempDataRekap[2] = { ...tempDataRekap[2], ...total.asing }
            tempDataRekap[3] = { ...tempDataRekap[3], ...total.asing_group }
            let total_tariff = 0
            for (let i = 0; i < tempDataRekap.length; i++) {
                const element = tempDataRekap[i];
                total_tariff += element.tariff
            }
            let tempDataRekapTotal = { 
                total_tax: total.total_tax,
                total_qty: total.total_qty,
                total_tariff,
            }

            const customFields = [
                { label: 'Tanggal', value: 'date_transaction' },
                { label: 'Domestik LBR', value: 'domestic.qty' },
                { label: 'Domestik TARIF', value: 'domestic.tariff' },
                { label: 'Domestik UANG', value: 'domestic.tax' },
                { label: 'Domestik Grup LBR', value: 'domestic_group.qty' },
                { label: 'Domestik Grup TARIF', value: 'domestic_group.tariff' },
                { label: 'Domestik Grup UANG', value: 'domestic_group.tax' },
                { label: 'Asing LBR', value: 'asing.qty' },
                { label: 'Asing TARIF', value: 'asing.tariff' },
                { label: 'Asing UANG', value: 'asing.tax' },
                { label: 'Asing Grup LBR', value: 'asing_group.qty' },
                { label: 'Asing TARIF', value: 'asing.tariff' },
                { label: 'Asing Grup UANG', value: 'asing_group.tax' },
                { label: 'Jumlah', value: 'total_tax' },
            ]

            this.setState({
                dataTicket,
                customFields,
                fileName: "retribution-data-" + this.state.region.toLowerCase(),
                dataRekapTotal: tempDataRekapTotal,
                total,
                loading: false
            })
        } catch (error) {
            console.error(error)
            this.setState({
                dataTicket: [],
                loading: false
            })
        }
    }
    
    componentDidMount() {
        this.fetchDataEvent();
    }

    onDatesChange = ({ startDate, endDate }) => {
        this.setState({ startDate, endDate }, () => this.fetchDataEvent())
    }

    onChangeRegion = (e) => {
        this.setState({ region: e.target.value }, () => this.fetchDataEvent())
    }

    render() {
        return (
            <React.Fragment>
                <div className="box-header px-5 mt-3" style={{ backgroundColor: "#EFF2F7" }}>
                    <h3 style={{ color: "#3B4859" }}>REVENUE (RETRIBUSI)</h3>
                </div>
                <div className="container-fluid p-5 spacing-resp">
                    <div className="row mb-4">
                        <div className="col-lg-4 col-md-6 mt-3">
                            <div className="row">
                                <div className="col-5"><h6 style={{ fontSize: "0.8rem", color: "#3B4859" }}>Dari</h6></div>
                                <div className="col-5"><h6 style={{ fontSize: "0.8rem", color: "#3B4859" }}>Sampai</h6></div>
                            </div>
                            <DatePicker 
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                onDatesChange={this.onDatesChange} 
                            />
                        </div>
                        <div className="col-md-2 d-flex align-items-end mt-3">
                            <select className="form-control input-c" value={this.state.region} onChange={this.onChangeRegion} style={{ borderRadius: "6px", border: "1px solid #A9A9AB" }}>
                                {
                                    this.state.regionList.map((region, i) => (<option key={i} value={region}>{region}</option>))
                                }
                            </select>
                        </div>
                        <div className="col-lg-3 col-md-6 d-flex align-items-end mt-3">
                            <DownloadReport
                                data={this.state.dataTicket}
                                fields={this.state.customFields}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                fileName={this.state.fileName}
                            />
                        </div>
                    </div>

                    <div className="py-3">
                        <div className="box p-3 mb-0" style={{ borderRadius: 10 }}>
                            <div className="table-responsive">
                                <DataTable data={this.state.dataTicket} region={this.state.region} loading={this.state.loading} total={this.state.total} />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 px-0 mt-5">
                        <div className="box p-3 mb-0" style={{ borderRadius: 10 }}>
                            <div className="table-responsive">
                                <DataTableRekap data={this.state.dataRekap} dataRekapTotal={this.state.dataRekapTotal} loading={this.state.loading} />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => {
    return {
        auth: state.firebase.auth, 
        profile: state.firebase.profile,
        region: state.region
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect()
)(TicketRevenue)
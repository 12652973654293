import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector, Tooltip, Cell, ResponsiveContainer, Label } from 'recharts';
import moment from 'moment'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import { compose } from 'redux'
// import * as axios from 'axios'
// import { getFormData } from '../../../../helpers/common';

const data = [
  { id: 'bogor', name: 'Kebun Raya Bogor', value: 500 },
  { id: 'cibodas', name: 'Kebun Raya Cibodas', value: 200 },
  { id: 'purwodadi', name: 'Kebun Raya Purwodadi', value: 300 },
  { id: 'bali', name: 'Kebun Raya Bali', value: 400 },
];
const COLORS = ['#D7CABF', '#00ACD9', '#B3B731', '#E4A314'];

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
    fill, payload, percent, value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={2} textAnchor="middle" fill="black">{payload.value}</text>
      <text x={cx} y={cy} dy={17} textAnchor="middle" fill="#C4C4C4">Total</text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

class PieChartTicket extends PureComponent {
  state = {
    activeIndex: null,
    loading: true,
    dataTicket: [],
    total: 0,
  };

  onPieEnter = (data, index) => {
    this.setState({
      activeIndex: index,
    });
  };

  onPieLeave = () => {
    this.setState({
      activeIndex: null
    })
  }
  fetchDataEvent = (props) => {
    this.setState({ loading: true })
    let tempData = data
    for (let index = 0; index < tempData.length; index++) {
      let element = data[index];
      let value = 0
      for (let j = 0; j < props.data.length; j++) {
        const item = props.data[j];
        if (item.region === element.id) {  
          const { total_qty = 0 } = item.daily
          value = value + total_qty
        }
      }
      element.value = value
    }
    console.log('tempData', tempData)
    const total = tempData.reduce((a, b) => a + b.value, 0)

    setInterval(() => {
      this.setState({ dataTicket: tempData, total, loading: false })
    }, 5000);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      this.fetchDataEvent(nextProps);
    }
  }

  componentDidMount() {
    this.fetchDataEvent(this.props);
  }

  render() {
    if (this.state.loading) {
      return <p>Loading...</p>
    }
    return (
      <ResponsiveContainer contentStyle={{ justifyContent: "center" }} maxHeight={300} aspect={4.0/3.0}>
        <PieChart>
          <Pie
            isAnimationActive
            activeIndex={this.state.activeIndex}
            activeShape={renderActiveShape}
            data={this.state.dataTicket}
            innerRadius={50}
            outerRadius={100}
            dataKey="value"
            onMouseEnter={this.onPieEnter}
            onMouseLeave={this.onPieLeave}
          >
            {
              data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} fillOpacity="1" />)
            }
            {
              this.state.activeIndex==null ?
                <Label value={this.state.total} position="center">
                  {/* TOTAL */}
                </Label>
                // <Label position="center"
                //   content={<customLabel valueAll={this.state.total} totalText="TOTAL" />}>
                // </Label>
              :
                <div />
            }            
          </Pie>
          <Tooltip 
            wrapperStyle={{ top: "20px" }}
            contentStyle={{ backgroundColor: "#626060", borderRadius: "5px", top: "20px" }} 
            itemStyle={{ color: "white" }}
          />
        </PieChart>
      </ResponsiveContainer>
    );
  }
  // fetchDataEvent = async (props) => {
    //     this.setState({ loading: true, dataTicket: [] })
    //     const { accessToken } = this.props.auth.stsTokenManager;
    //     const data = {
    //         "region": "",
    //         "date_start": moment(props.startDate).format("YYYY-MM-DD").toString(),
    //         "date_end": moment(props.endDate).format("YYYY-MM-DD").toString()
    //     }
    //     const formData = getFormData(data)
    //     try {
    //         const response = await axios({
    //             method: 'POST',
    //             headers: {
    //                 'Authorization': 'Bearer ' + accessToken
    //             },
    //             url: 'https://us-central1-krmos-sandbox.cloudfunctions.net/revenue/api/summary/all_region/total',
    //             data: formData
    //         })
    //         const { data } = response
    //         let dataTicket = []
    //         console.log('data', data)
    //         // dataTicket = data.data.daily.daily_transactions
    //         if (data == null) data = []
    
    //         data.forEach((element) => {
    //           dataTicket.push({
    //             name: element.name,
    //             value: element.data.daily.total_qty
    //           })
    //         })
    //         const total = dataTicket.reduce((a, b) => a + b.value, 0)
    //         console.log('TOTAL', total);      
    //         this.setState({
    //           dataTicket: dataTicket,
    //           total: total,
    //           loading: false
    //         })
    //     } catch (error) {
    //         console.error(error)
    //         this.setState({
    //           dataTicket: [],
    //           loading: false
    //         })
    //     }
    // }
}

const mapStateToProps = state => ({ 
  profile: state.firebase.profile, 
  auth: state.firebase.auth
})

export default compose(
  connect(mapStateToProps),
  firebaseConnect()
)(PieChartTicket)
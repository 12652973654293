import React, { Component } from "react";

export default class Thumb extends Component {
    state = {
        loading: true,
        thumb: undefined,
    };

    componentWillMount() {
        if (this.props.file.name && typeof this.props.file.name == 'string') {
            this.setState({ loading: true }, () => {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.setState({ loading: false, thumb: e.target.result });
                };
                reader.readAsDataURL(this.props.file);
            });
        } else if (typeof this.props.file === "string") {
            this.setState({ loading: false, thumb: this.props.file });
        }
    }

    render() {
        const { file } = this.props;
        const { loading, thumb } = this.state;

        if (!file) {
            return <p>Error loading image!</p>;
        }

        if (loading) {
            return (
                <img
                    src={require("../../assets/icon/spinner.gif")}
                    alt="Loading"
                    height={200}
                    width={200}
                />
            );
        }
        return (
            <div style={{ position: 'relative', display: "inline-block" }}>
                <img src={thumb} alt={file.name} style={{ width: 130, height: 150, objectFit: 'cover', margin: 10 }} />
                <a onClick={this.props.onDelete}>  <i style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                }} className="fa fa-times-circle close" />
                </a>
            </div>
        );
    }
}

import React from "react"
import { Switch, Route } from 'react-router-dom'

import MaintenanceScreen from '../../screens/Maintenance/TableListScreen';
import AddMaintenanceDataScreen from '../../screens/Maintenance/AddScreen';
import MaintenanceDetailScreen from '../../screens/Maintenance/Detail';
import UpdateMaintenanceDataScreen from '../../screens/Maintenance/UpdateScreen';

const MaintenanceNav = (props) => (
    <Switch>
        <Route 
            exact 
            path={`${props.match.path}/`}
            render={(routeProps) => <MaintenanceScreen {...routeProps} />}
        /> 
        <Route 
            exact 
            path={`${props.match.path}/add`}
            render={(routeProps) => <AddMaintenanceDataScreen {...routeProps} />}
        />
        <Route 
            exact 
            path={`${props.match.path}/:sessionId`}
            render={(routeProps) => <MaintenanceDetailScreen {...routeProps} />}
        />
        <Route 
            exact 
            path={`${props.match.path}/:sessionId/update`}
            render={(routeProps) => <UpdateMaintenanceDataScreen {...routeProps} />}
        />
    </Switch>
)

export default MaintenanceNav
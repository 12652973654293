import React from "react"
import { Switch, Route } from 'react-router-dom'

// import AddPlantInfoScreen from '../../screens/PlantInfo/AddScreen';
import ProfileScreen from '../../screens/Profile';
import UpdateProfileScreen from '../../screens/Profile/UpdateScreen';

const ProfileNav = (props) => (
    <Switch>
        <Route 
            exact 
            path={`${props.match.path}/`}
            render={(routeProps) => <ProfileScreen {...routeProps} />}
        /> 
        <Route 
            exact 
            path={`${props.match.path}/:sessionId/update`}
            render={(routeProps) => <UpdateProfileScreen {...routeProps} />}
        />
    </Switch>
)

export default ProfileNav
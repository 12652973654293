import React, { Component } from "react"
import { Modal } from "react-bootstrap"
import { withRouter } from "react-router-dom"

import taskList from "../../data/taskListData.json"

class Add extends Component {
    state = {
        show: false,
        search: "",
        data: taskList,
        checkedData: [],
        isOtherChecked: false,
        otherInput: '',
        otherEmployeeInput: '',
        otherEmployeeDescription: '',
        errorOtherInput: '',
    }
    
    handleShow = () => this.setState({ show: true })
    handleClose = () => this.setState({ show: false })

    onSubmit = async () => {
        let canSubmit = this.state.data.some((item) => item.checked)
        if (!canSubmit) {
            this.setState({ error: "You must add atleast one task!"})
            return;
        } 
        else if(
            canSubmit && 
            this.state.isOtherChecked && 
            this.state.otherInput == "" && 
            this.state.otherEmployeeInput == "" && 
            this.state.otherEmployeeDescription == ""
        ) {
           this.setState({ errorOtherInput: "You must fill input." })
           return
        } else {
            let tempData = this.state.data
            const optionsArr = tempData.filter((item) => item.checked).map((item) => {
                if(item.id == "others" && this.state.otherInput != "") {
                    return {
                        id: "others", 
                        label: "Others (isi sendiri)",
                        value: this.state.otherInput, 
                        employeeName: this.state.otherEmployeeInput,
                        description: this.state.otherEmployeeDescription
                    }
                }
                return item
            })

            const { input: { value, onChange } } = this.props
            onChange(optionsArr)
            this.handleClose()
        }
    }
    handleChangeCheckbox = (event, id) => {
        let tempData = this.state.data
        let isOtherChecked = this.state.isOtherChecked
        for (let i = 0; i < tempData.length; i++) {
            const element = tempData[i];
            if (element.id === id) {
                element.checked = event.target.checked
                if (event.target.checked == false) {
                    element.employeeName = ""
                    element.description = ""
                }
                if (element.id === "others") {
                    isOtherChecked = !isOtherChecked;
                    element.value = ""
                    element.description = ""
                }
                break;
            }
        }
        this.setState({
            data: tempData,
            isOtherChecked,
            error: "", 
            errorOtherInput: "",
            otherEmployeeDescription: ""
        })
    }
    handleChangeInput = (type, event, id) => {
        let tempData = this.state.data
        let isOtherChecked = this.state.isOtherChecked
        for (let i = 0; i < tempData.length; i++) {
            const element = tempData[i];
            if (element.id === id) {
                element[type] = event.target.value
            }
        }
        this.setState({
            data: tempData,
            isOtherChecked,
            error: "", 
            errorOtherInput: "" 
        })
    }
    handleChangeOtherInput = (event) => {
        this.setState({ 
            otherInput: event.target.value, 
            error: "", 
            errorOtherInput: "" 
        })
    }
    componentDidMount() {
        const { input: { value, onChange } } = this.props
        let tempData = this.state.data
        let otherInput = this.state.otherInput
        let isOtherChecked = this.state.isOtherChecked
        let otherEmployeeInput = ''
        let otherEmployeeDescription = ''

        for (let i = 0; i < tempData.length; i++) {
            let option = tempData[i];
            for (let j = 0; j < value.length; j++) {
                const currentData = value[j];
                if (currentData.id === option.id) {
                    if (currentData.id === "others") {
                        isOtherChecked = true
                        otherInput = currentData.value
                        otherEmployeeInput = currentData.employeeName
                        otherEmployeeDescription = currentData.description
                    }
                    option.checked = true
                    option.employeeName = currentData.employeeName
                    option.description = currentData.description
                }
            }
        }

        this.setState({ 
            data: tempData, 
            error: "", 
            errorOtherInput: "", 
            isOtherChecked, 
            otherInput, 
            otherEmployeeInput, 
            otherEmployeeDescription  
        })
    }
    
    render() {
        const { input: { value, onChange } } = this.props
        return (
            <React.Fragment>
                <button
                    onClick={this.handleShow}
                    type="button"
                    role="button"
                    className="btn btn-danger"
                    style={{ borderRadius: 0, backgroundColor: "#D4252A", fontSize: "0.8em" }}
                >
                    + Tambah Daftar Pekerjaan
                </button>
                <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header style={{ paddingTop: 30, paddingBottom: 30 }} closeButton>
                        <Modal.Title h2>
                            <p 
                                className="ml-4 mb-0" 
                                style={{ color: "rgba(124, 122, 122, 0.87)" }}
                            >
                                <b>Tambah / Edit Daftar Pekerjaan</b>
                            </p>
                         </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container">
                            <div className="box-body">
                                {/* <input 
                                    type="text" 
                                    className="form-control field-box" 
                                    placeholder="Search..." 
                                    name="search" 
                                    value={this.state.search} 
                                    onChange={this.onChangeSearchText} 
                                /> */}
                                <h6 
                                    
                                    style={{ color: "rgba(124, 122, 122, 0.87)", borderColor: "transparent" }}
                                >
                                    <b>Pekerjaan Terpilih</b>
                                </h6>
                                <small className="text-danger">{this.state.error}</small>
                                <div className="w-100" style={{ overflow: "auto", height: 400 }}>
                                    <table class="table mt-4" style={{ color: "rgba(124, 122, 122, 0.87)" }}>
                                        <thead>
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col">Nama Kegiatan</th>
                                                <th scope="col">Keterangan</th>
                                                <th scope="col">Karyawan</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.data.map((item) => (
                                                    <tr key={item.id}>
                                                        <td className="text-center">
                                                            {/* <a><i className="fa fa-plus" /></a> */}
                                                            <input 
                                                                type="checkbox" 
                                                                checked={item.checked} 
                                                                onChange={(event) =>this.handleChangeCheckbox(event, item.id)}>
                                                            </input>
                                                        </td>
                                                        {
                                                            item.id !== "others" ?
                                                            <td style={{ cursor: "pointer" }}>{item.label}</td>
                                                            :
                                                            
                                                            <td>
                                                                <input
                                                                    type={"text"}
                                                                    placeholder="Lainnya"
                                                                    className="form-control" 
                                                                    style={{ fontSize: "0.9em" }}
                                                                    value={this.state.otherInput}
                                                                    disabled={this.state.isOtherChecked !== true}
                                                                    onChange={this.handleChangeOtherInput}
                                                                />
                                                                <small className="text-danger"> {this.state.errorOtherInput}</small>
                                                            </td>
                                                        }
                                                        <td>
                                                        {
                                                            item.id !== "others" ?
                                                            <input
                                                                type={"text"}
                                                                placeholder="Masukkan Keterangan"
                                                                className={`form-control `} 
                                                                style={{ fontSize: "0.9em" }}
                                                                disabled={item.checked !== true}
                                                                value={item.description}
                                                                onChange={(event) => this.handleChangeInput("description", event, item.id)}
                                                            />
                                                            :
                                                            <input
                                                                type={"text"}
                                                                placeholder="Masukkan Keterangan"
                                                                className={`form-control `} 
                                                                style={{ fontSize: "0.9em" }}
                                                                disabled={this.state.isOtherChecked === false}
                                                                value={this.state.otherEmployeeDescription}
                                                                onChange={(event) => this.setState({ otherEmployeeDescription: event.target.value })}
                                                            />

                                                        }
                                                        </td>
                                                        <td>
                                                        {
                                                            item.id !== "others" ?
                                                            <>
                                                            <input
                                                                type={"text"}
                                                                placeholder="Masukkan Nama"
                                                                className={`form-control `} 
                                                                style={{ fontSize: "0.9em" }}
                                                                disabled={item.checked !== true}
                                                                value={item.employeeName}
                                                                onChange={(event) => this.handleChangeInput("employeeName", event, item.id)}
                                                            />
                                                            <small className="text-danger"> {this.state.errorOtherInput}</small>

                                                            </>
                                                            :
                                                            <input
                                                                type={"text"}
                                                                placeholder="Masukkan Nama"
                                                                className={`form-control `} 
                                                                style={{ fontSize: "0.9em" }}
                                                                disabled={this.state.isOtherChecked === false}
                                                                value={this.state.otherEmployeeInput}
                                                                onChange={(event) => this.setState({ otherEmployeeInput: event.target.value })}
                                                            />

                                                        }
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <Modal.Footer style={{ paddingTop: 30, paddingBottom: 30 }}>
                            <button 
                                type="button"
                                onClick={()=> this.onSubmit()}
                                className="btn white text-white w-100 mx-5"
                                style={{ cursor: "pointer", fontSize: "0.8em", backgroundColor: "#188715", borderRadius: 0 }}
                            >
                                Tambah
                            </button>
                            <button
                                type="button"
                                onClick={this.handleClose}
                                className="btn white w-100 mx-5"
                                style={{ cursor: "pointer", fontSize: "0.8em", borderRadius: 0 }}
                            >
                                Batal
                            </button>
                        </Modal.Footer>
                    </Modal.Body>
                </Modal>

            </React.Fragment>
        )
    }
}

export default withRouter(Add)
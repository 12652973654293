import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { reset } from 'redux-form'
import { withFirestore } from 'react-redux-firebase'

import UpdateSession from './UpdateForm';
import ModalError from '../../../../components/ModalError';

class UpdateScreen extends Component {
  
  state = {
    errorInfo: false,
    category: "All",
    isLoading: true,
    data: {
      description: "",
      uidSector: "",
      uidPlant: "",
    }
  }

  handleErrorInfo = () => {
    this.setState({ errorInfo: !this.state.errorInfo });
  }
  
  createFormData = (values) => {

    const { sessionId } = this.props.match.params;
    const { selectedRegion } = this.props.location.state;
    const data = {
      timestamp: new Date(),
      date: new Date(),
      uidSector: selectedRegion,
      uidPlant: sessionId,
      // animalId: values.animalId, plantID
      description: values.description,
    };  

    return data;
  }

  onSubmit = async (values) => {
    const formData = this.createFormData(values)
    console.log('formData', formData)

    const { sessionId, reportId } = this.props.match.params;
    const { selectedRegion } = this.props.location.state;
    console.log('sessionId', sessionId)
    console.log('selectedRegion', selectedRegion)
    try {
      await this.props.firestore.update({ 
        collection: 'region',
        doc: selectedRegion,
        subcollections: [{ 
          collection: 'plants', 
          doc: sessionId,
          subcollections: [{ 
            collection: 'dailyReport',
            doc: reportId
          }]
        }]
      }, formData);

      console.log("Document updated with an ID: ", reportId);
      
      // this.props.fetchData();
      // this.props.dispatch(reset('addDailyReportForm'));
      this.props.history.goBack()

    } catch (error) {
      this.setState({ errorInfo: true })

      console.error("Failed to add data: ", error);
    }
  }
  fetchData = async () => {

    try {
      const { sessionId, reportId } = this.props.match.params;
      const { selectedRegion } = this.props.location.state;
      console.log('sessionId', sessionId)
      const snapshot = await this.props.firestore.get({ 
        collection: 'region',
        doc: selectedRegion,
        subcollections: [{ 
          collection: 'plants', 
          doc: sessionId,
          subcollections: [{ 
            collection: 'dailyReport',
            doc: reportId
          }]
        }]
      });
      if (snapshot.exists) {
        const { ...restData } = snapshot.data()
        let data = {
          ...restData
        }

        console.log('data', data)
        this.setState({ data: {...this.state.data, ...data } });
      } else {
        console.log("Doesnt exists")
      }
    } catch (error) {
      console.error(error)
    }

    this.setState({ loading: false })
  }
  componentDidMount() {
    this.fetchData()
  }
  render() {
    if (!this.props.uid) return null
    return (
      <React.Fragment>
        {
          this.state.errorInfo &&
            <ModalError 
              errorInfo={this.state.errorInfo}
              handleErrorInfo={this.handleErrorInfo}
            />
        }          
        <div className="box-body p-0">
          <UpdateSession 
            onSubmit={this.onSubmit} 
            initialValues={this.state.data} 
          />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    uid: state.firebase.auth.uid,
  }
}

export default compose(
  connect(mapStateToProps),
  withFirestore,
)(UpdateScreen)
import React, { Component } from 'react'
import moment from 'moment-timezone'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import DataTable from './DataTable'
import DataTableDomestik from './DataTableDomestik'
import DataTableVehicle from './DataTableVehicle'
import DataTableSubTotal from './DataTableSubTotal'

import DownloadReport from '../../../../components/DownloadReport'
import DatePicker from '../../../../components/DatePicker'

const dataSubTotal = [
    {
        name: "Asuransi Jiwa (Domestik)",
        qty: 0,
        assurance: 0
    },
    {
        name: "Asuransi Jiwa (Asing)",
        qty: 0,
        assurance: 0
    },
    {
        name: "Asuransi Mobil",
        qty: 0,
        assurance: 0
    },
    {
        name: "Asuransi Parkir Motor",
        qty: 0,
        assurance: 0
    }
]

class TicketRevenueInsurance extends Component {
    state = {
        dataSubTotal: dataSubTotal,
        dataTicket: [],
        fileName: "",
        loading: true,
        showDownload: false,
        startDate: moment(),
        endDate: moment(),
        regionList: [
            'Bogor',
            'Cibodas',
            'Purwodadi',
            'Bali'
        ],
        region: 'bogor',
        total: {
            asing: {
                qty: 0,
                assurance: 0
            },
            asing_group: {
                qty: 0,
                assurance: 0
            },
            domestic: {
                qty: 0,
                assurance: 0
            },
            domestic_group: {
                qty: 0,
                assurance: 0
            },
            mobil: {
                qty: 0,
                assurance: 0
            },
            sepeda: {
                qty: 0,
                assurance: 0
            },
            sepeda_motor: {
                qty: 0,
                assurance: 0
            }
        }
    }

    fetchDataEvent = async () => {
        this.setState({ loading: true, dataTicket: [] })
        try {
            const snapshots = await this.props.firestore.get({
                collection: 'region',
                doc: this.state.region.toLowerCase(),
                subcollections: [{
                    collection: 'revenueTickets',
                    orderBy: [
                        ['timestamp', 'asc']
                    ],
                    where: [
                        ['timestamp', '>=', moment(this.state.startDate).tz("Asia/Jakarta").startOf('day').toDate()],
                        ['timestamp', '<=', moment(this.state.endDate).tz("Asia/Jakarta").startOf('day').toDate()]
                    ]
                }]
            });
            let dataTicket = []

            let total = {
                asing: {
                    qty: 0,
                    assurance: 0
                },
                asing_group: {
                    qty: 0,
                    assurance: 0
                },
                domestic: {
                    qty: 0,
                    assurance: 0
                },
                domestic_group: {
                    qty: 0,
                    assurance: 0
                },
                mobil: {
                    qty: 0,
                    assurance: 0
                },
                sepeda: {
                    qty: 0,
                    assurance: 0
                },
                sepeda_motor: {
                    qty: 0,
                    assurance: 0
                }
            }
            snapshots.forEach((snap) => {
                let { tariff_transactions } = snap.data().tariff
                let ticketData = {
                    id: snap.id,
                    date_transaction: moment(snap.data().timestamp.toDate()).format("DD MMMM YYYY"),
                    asing: {
                        qty: 0,
                        assurance: 0
                    },
                    asing_group: {
                        qty: 0,
                        assurance: 0
                    },
                    domestic: {
                        qty: 0,
                        assurance: 0
                    },
                    domestic_group: {
                        qty: 0,
                        assurance: 0
                    },
                    mobil: {
                        qty: 0,
                        assurance: 0
                    },
                    sepeda: {
                        qty: 0,
                        assurance: 0
                    },
                    sepeda_motor: {
                        qty: 0,
                        assurance: 0
                    },
                    // total: 0,
                }

                for (let index = 0; index < tariff_transactions.length; index++) {
                    const item = tariff_transactions[index];
                    switch (item.tariff) {
                        case "Asing":
                            ticketData.asing = item
                            break;
                        case "Asing Group":
                            ticketData.asing_group = item
                            break;
                        case "Domestik":
                            ticketData.domestic = item
                            break;
                        case "Domestik Group":
                            ticketData.domestic_group = item
                            break;
                        case "Mobil":
                            ticketData.mobil = item
                            break;
                        case "Sepeda Motor":
                            ticketData.sepeda_motor = item
                            break;
                        default:
                            break;
                    }
                }

                dataTicket.push(ticketData)
            })

            for (let i = 0; i < dataTicket.length; i++) {
                const element = dataTicket[i];
                total = {
                    asing: {
                        qty: (total.asing.qty || 0) + (element.asing.qty || 0),
                        assurance: (total.asing.assurance || 0) + (element.asing.assurance || 0)
                    },
                    asing_group: {
                        qty: (total.asing_group.qty || 0) + (element.asing_group.qty || 0),
                        assurance: (total.asing_group.assurance || 0) + (element.asing_group.assurance || 0)
                    },
                    domestic: {
                        qty: (total.domestic.qty || 0) + (element.domestic.qty || 0),
                        assurance: (total.domestic.assurance || 0) + (element.domestic.assurance || 0)
                    },
                    domestic_group: {
                        qty: (total.domestic_group.qty || 0) + (element.domestic_group.qty || 0),
                        assurance: (total.domestic_group.assurance || 0) + (element.domestic_group.assurance || 0)
                    },
                    mobil: {
                        qty: (total.mobil.qty || 0) + (element.mobil.qty || 0),
                        assurance: (total.mobil.assurance || 0) + (element.mobil.assurance || 0)
                    },
                    sepeda_motor: {
                        qty: (total.sepeda_motor.qty || 0) + (element.sepeda_motor.qty || 0),
                        assurance: (total.sepeda_motor.assurance || 0) + (element.sepeda_motor.assurance || 0)
                    }
                }
            }

            // let { dataSubTotal } = this.state
            let tempSubTotal = this.state.dataSubTotal
            tempSubTotal[0] = { 
                ...tempSubTotal[0],
                ...total.domestic,
            }
            tempSubTotal[1] = { 
                ...tempSubTotal[1],
                ...total.asing,
            }
            tempSubTotal[2] = { 
                ...tempSubTotal[2],
                ...total.mobil,
            }
            tempSubTotal[3] = { 
                ...tempSubTotal[3],
                ...total.sepeda_motor,
            }

            const customFields = [
                { label: 'Tanggal', value: 'date_transaction' },
                { label: 'Domestik LBR', value: 'domestic.qty' },
                { label: 'Domestik UANG', value: 'domestic.assurance' },
                { label: 'Asing LBR', value: 'asing.qty' },
                { label: 'Asing UANG', value: 'asing.assurance' },
                { label: 'Domestik Grup LBR', value: 'domestic_group.qty' },
                { label: 'Domestik Grup UANG', value: 'domestic_group.assurance' },
                { label: 'Asing Grup LBR', value: 'asing_group.qty' },
                { label: 'Asing Grup UANG', value: 'asing_group.assurance' },
                { label: 'Mobil LBR', value: 'mobil.qty' },
                { label: 'Mobil UANG', value: 'mobil.assurance' },
                { label: 'Motor LBR', value: 'sepeda_motor.qty' },
                { label: 'Motor UANG', value: 'sepeda_motor.assurance' },
                // { label: 'Total', value: 'total' },
            ]

            this.setState({
                dataTicket,
                dataSubTotal: tempSubTotal,
                customFields,
                fileName: "insurance-data-" + this.state.region.toLowerCase(),
                total,
                loading: false
            })
        } catch (error) {
            console.error(error)
            this.setState({
                dataTicket: [],
                loading: false
            })
        }
    }
    componentDidMount() {
        this.fetchDataEvent();
    }
    
    onDatesChange = ({ startDate, endDate }) => {
        this.setState({ startDate, endDate }, () => this.fetchDataEvent())
    }
    onChangeRegion = (e) => {
        this.setState({ region: e.target.value }, () => this.fetchDataEvent())
    }
    render() {
        return (
            <React.Fragment>
                <div className="box-header px-5 mt-3" style={{ backgroundColor: "#EFF2F7" }}>
                    <h3 style={{ color: "#3B4859" }}>REVENUE (ASURANSI)</h3>
                </div>
                <div className="container-fluid p-5 spacing-resp">
                    <div className="row mb-4">
                        <div className="col-lg-4 col-md-6 mt-3">
                            <div className="row">
                                <div className="col-5"><h6 style={{ fontSize: "0.8rem", color: "#3B4859" }}>Dari</h6></div>
                                <div className="col-5"><h6 style={{ fontSize: "0.8rem", color: "#3B4859" }}>Sampai</h6></div>
                            </div>
                            <DatePicker 
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                onDatesChange={this.onDatesChange} 
                            />
                        </div>
                        <div className="col-md-2 d-flex align-items-end mt-3">
                            <select className="form-control input-c" value={this.state.region} onChange={this.onChangeRegion} style={{ borderRadius: "6px", border: "1px solid #A9A9AB" }}>
                                {
                                    this.state.regionList.map((region, i) => (<option key={i} value={region}>{region}</option>))
                                }
                            </select>
                        </div>
                        <div className="col-lg-3 col-md-6 d-flex align-items-end mt-3">
                            <DownloadReport
                                data={this.state.dataTicket}
                                fields={this.state.customFields}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                fileName={this.state.fileName}
                            />
                        </div>
                    </div>

                    <div className="py-3 mt-2">
                        <div className="box p-3 mb-0" style={{ borderRadius: 10 }}>
                            <div className="table-responsive">
                                <DataTable 
                                    data={this.state.dataTicket} 
                                    loading={this.state.loading} 
                                    total={this.state.total} 
                                />
                            </div>
                        </div>

                        <div className="box p-3 mb-0 mt-5" style={{ borderRadius: 10 }}>
                            <div className="table-responsive">
                                <DataTableDomestik 
                                    data={this.state.dataTicket} 
                                    loading={this.state.loading} 
                                    total={this.state.total} 
                                />
                            </div>
                        </div>

                        <div className="mt-5">
                            <div className="box p-3 mb-0" style={{ borderRadius: 10 }}>
                                <div className="table-responsive">
                                    <DataTableVehicle
                                        data={this.state.dataTicket}
                                        loading={this.state.loading}
                                        total={this.state.total}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 px-0 mt-5">
                            <div className="box p-3 mb-0" style={{ borderRadius: 10 }}>
                                <div className="table-responsive">
                                    <DataTableSubTotal
                                        data={this.state.dataSubTotal}
                                        loading={this.state.loading}
                                        total={this.state.total}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        region: state.region
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect()
)(TicketRevenueInsurance)
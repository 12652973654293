import React from 'react'

const Footer = () => (
  <div className="p-2 text-xs" style={{ backgroundColor: "#F9FAFC !important"}}>
    <div className="pull-right text-muted py-1">
      &copy; Copyright <strong>Oloop Studio</strong> <span className="hidden-xs-down">- Built with Love v1.1.3</span>
      <a><i className="fa fa-long-arrow-up p-x-sm"></i></a>
    </div>
  </div>
)

export default Footer
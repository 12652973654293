var firebaseConfig = {
  apiKey: "AIzaSyBo1WiMDjkA8k3vBYar1nnrP8FAKbmD1O4",
  authDomain: "krmos-sandbox.firebaseapp.com",
  databaseURL: "https://krmos-sandbox.firebaseio.com",
  projectId: "krmos-sandbox",
  storageBucket: "krmos-sandbox.appspot.com",
  messagingSenderId: "961173928287",
  appId: "1:961173928287:web:efe62c0ef16e1ca2d45eea",
  measurementId: "G-9Z30EZDDDC"
};
export default firebaseConfig
import React from 'react'
import { Modal } from "react-bootstrap"
import PropTypes from "prop-types"

const ModalError = ({
    errorInfo,
    handleErrorInfo,
    error
}) => (
    <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={errorInfo} 
        onHide={handleErrorInfo}
        >
        <Modal.Header className="danger" closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            Oh snap! You got an error!
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            
            <p>{error ? error : "An error occured while processing your request! Please try again."}</p>
        </Modal.Body>
        <Modal.Footer>
            <button class="btn primary" onClick={() => handleErrorInfo()}>OK</button>
        </Modal.Footer>
    </Modal>
)

ModalError.propTypes = {
    errorInfo: PropTypes.bool.isRequired,
    handleErrorInfo: PropTypes.func.isRequired,
}

export default ModalError;
import React from 'react'
import { reduxForm, FieldArray, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'

import SubmitButton from '../../../../components/SubmitButton'
import LoaderModal from '../../../../components/LoaderModal'
import validate from './validate'
import MenuInput from '../../../../components/MenuInput';

let UpdateForm = ({ handleSubmit, pristine, submitting, valid, nickName, animalId, species, ...otherProps }) => {

  return (
    <div className="col-12">
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-4" style={{ marginTop: 15 }}>
          <div className="box" style={{ borderRadius: 5 }}>
            <div className="box-header">
              <h2 style={{ color: "#159588", fontWeight: "bold" }}>Update Fertilizer Plan</h2>
            </div>
          </div>

          {/* <div className="box" style={{ borderRadius: 5 }}>
            <div className="box-header p-4">
              <label htmlFor="placeInput" style={{ color: "#159588", fontWeight: "bold", fontSize: '0.875rem' }}>
                Animal Name
              </label>
              <div className="input-group mb-4">
                <p className="my-0" style={{ fontWeight: 'bold' }}>{nickName}</p>
              </div>

              <label htmlFor="placeInput" style={{ color: "#159588", fontWeight: "bold", fontSize: '0.875rem' }}>
                Animal Id
              </label>
              <div className="input-group mb-4">
                <p className="my-0" style={{ fontWeight: 'bold' }}>{animalId}</p>
              </div>

              <label htmlFor="placeInput" style={{ color: "#159588", fontWeight: "bold", fontSize: '0.875rem' }}>
                Animal Species  
              </label>
              <div className="input-group mb-3">
                <p className="my-0" style={{ fontWeight: 'bold' }}>{species}</p>
              </div>
            </div>
          </div> */}
        </div>

        <div className="col-md-8" style={{ marginTop: 15 }}>
          <div className="box p-4" style={{ borderRadius: 5 }}>
            <FieldArray name="detail" component={MenuInput} label="Food Menu" />
          </div>  
        </div>
      </div>

      <div className="col-12 text-right">
        <div className="col-lg-3 col-xs-12 px-0 mt-5">
          <SubmitButton disabled={!valid || submitting} />
        </div>
        <LoaderModal submitting={submitting} />
      </div>
      </form>
    </div>
  )
}

UpdateForm = reduxForm({
  form: 'updateMealPlanForm',
  validate,
  enableReinitialize: true,
})(UpdateForm)

const selector = formValueSelector('updateMealPlanForm')
UpdateForm = connect(
  state => {

    const nickName = selector(state, 'nickName') || "";
    const animalId = selector(state, 'animalId') || "";
    const species = selector(state, 'species') || "";
    
    return {
      nickName,
      animalId,
      species,
    }
  }
)(UpdateForm)

export default UpdateForm
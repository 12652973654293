import React from "react"
import NumberFormat from "react-number-format"
import ErrorMessage from "./ErrorMessage"

const NumberInput = (field) => {
  const { input: { value, onChange } } = field
  const onValueChange = (numberValues) => {
    const { formattedValue, value } = numberValues;
    onChange(parseInt(value))
  }
  return (
    <div className="form-group row mb-4">
      <label 
        htmlFor={`input-${field.label}`} 
        className="col-md-2 form-control-label" 
        style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold" }}
      >
        {field.label}
      </label>
      <div className="col-md-10">
        <NumberFormat
          {...field.input}
          id={`input-${field.label}`}
          style={{ fontSize: "0.9em", backgroundColor: "#f0f0f0" }}
          className="form-control field-box"
          placeholder={field.placeholder}
          onValueChange={onValueChange}
        />
        <ErrorMessage {...field} />
      </div>
    </div>
  )
}

export default NumberInput
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withFirestore } from 'react-redux-firebase'
import { Modal, Button } from 'react-bootstrap'

import html2canvas from 'html2canvas'
import jsPdf from 'jspdf'
import moment from 'moment'

import LoadingScreen from '../../../components/LoadingScreen'
import ModalError from '../../../components/ModalError'
// import Thumb from '../../../components/Form/Thumbnail'
import ThumbnailPreview from '../../../components/Form/ThumbnailPreview'

const collection = "region"

class DetailScreen extends Component {
  state = {
    data: {
      description: "",
      images: [],
      date: ""
    },
    isDownload: false,
    canEdit: false,
    collection: "region",
    subcollection: "compose",
    loading: true,
    errorInfo: false,
  }

  setCanEdit = (state) => this.setState({ canEdit: state })

  gotoUpdatePage = () => {
    const { sessionId } = this.props.match.params;
    this.props.history.push(`/dashboard/plant_info/kompos/${sessionId}/update`)
  }

  savePdf = async () => {
    this.setState({ isDownload: true })
    const domElement = document.getElementById('detail-page')
    try {

      const canvas = await html2canvas(domElement, {
        onclone: (document) => {
          document.getElementById('detail-page').style.backgroundColor = '#ffff'
          if (this.state.canEdit) document.getElementById('edit-button').style.backgroundColor = 'hidden'
          document.getElementById('download-button').style.visibility = 'hidden'
        }
      });
      const img = await canvas.toDataURL('image/png')

      const pdf = new jsPdf()
      const imgProps = pdf.getImageProperties(img);
      const width = pdf.internal.pageSize.getWidth();
      const height = (imgProps.height * width) / imgProps.width;

      pdf.addImage(img, 'JPEG', 0, 0, width, height)
      setTimeout(() => {
        pdf.save(`report-kompos-${moment().format("DD MMMM YYYY")}.pdf`)
      }, 4000);
      this.setState({ isDownload: false })
    } catch (error) {
      console.error(error)
      this.setState({ isDownload: false })
    }
  }

  componentDidMount() {
    this.fetchData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.fetchData(nextProps)
  }

  fetchData = async (props) => {
    try {
      const { sessionId } = this.props.match.params;
      const snapshot = await this.props.firestore.get({
        collection: collection,
        doc: props.region.toLowerCase(),
        subcollections: [{
          collection: this.state.subcollection,
          doc: sessionId
        }],
      })

      if (!snapshot.exists) {
        this.setState({ loading: false })
        return;
      }

      const {
        images = [],
        date,
        ...restData
      } = snapshot.data()

      let imageUrls = images.map((e) => e.url)

      const toDataURL = url => fetch(url)
        .then(response => response.blob())
        .then(blob => new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onloadend = () => resolve(reader.result)
          reader.onerror = reject
          reader.readAsDataURL(blob)
        })
          .then((value) => Promise.resolve(value)))

      let imgBase64 = []

      for (let i = 0; i < imageUrls.length; i++) {
        const url = imageUrls[i];
        imgBase64.push(await toDataURL(url))
      }

      let data = {
        id: snapshot.id,
        images: imgBase64,
        imgBase64,
        date: date ? moment(date.toDate()).format("DD MMMM YYYY") : "-",
        ...restData
      }
      const dataDate = moment(date.toDate()).hour(23).minute(59).second(59).toDate()
      const currentDate = moment().hour(0).minute(0).second(0).toDate();
      if (currentDate > dataDate) {
        this.setCanEdit(false)
      } else {
        this.setCanEdit(true)
      }

      this.setState({
        data: {
          ...this.state.data,
          ...data
        },
        loading: false
      });
    } catch (error) {
      this.setState({ loading: false })
    }

  }

  goBack = () => {
    this.props.history.push(`/dashboard/plant_info/kompos`)
  }

  render() {
    if (!this.props.uid) return null
    if (this.state.loading) return <LoadingScreen />
    const { data } = this.state;
    return (
      <div id="detail-page">
        <div className="box-header px-5 mt-3" style={{ backgroundColor: "#EFF2F7" }}>
          <div className="row">
            <a onClick={this.goBack}><i class="fa fa-arrow-left" aria-hidden="true"></i></a>
            <h3 className="ml-3 mt-1" style={{ color: "#3B4859" }}>KOMPOS</h3>
          </div>
        </div>
        <div className="box-body">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-md-10 box p-4" style={{ borderRadius: 5 }}>
                <div className="row">
                  <div className="col-md-4 d-flex align-items-center">
                    <h6
                      className="mb-0"
                      style={{ color: "#579264", fontWeight: "bold" }}
                    >
                      Tanggal
                    </h6>
                  </div>
                  <div className="col-md-4 d-flex align-items-center">
                    <h6 className="mb-0" style={{color: "#979D99"}}>{data.date}</h6>
                  </div>
                  
                  <div className="col-md-2 d-flex align-items-center">
                    {
                      this.state.isDownload !== true ?
                    <button
                      type="button"
                      id="download-button"
                      onClick={this.savePdf}
                      className="md-btn md-raised m-b-sm w-xs success"
                      style={{ width: "100%", backgroundColor: "#22313F", borderRadius: 5, fontWeight: "inherit", fontSize: "0.8em" }}>
                        {/* <i className="fa fa-download mr-1"></i> */}
                      Download
                    </button>
                   :
                    null
                  }
                    </div>
                </div>
              </div>
              <div className="col-md-2 align-items-center p-4 ">
                {
                  ((this.props.profile.token && this.props.profile.token.claims.admin === true) ||
                  (this.props.profile.token && this.props.profile.token.claims.department === "Konservasi")) &&
                  this.state.canEdit && this.state.isDownload !== true ?
                    <button
                      type="button"
                      id="edit-button"
                      onClick={this.gotoUpdatePage}
                      className="md-btn md-raised m-b-sm w-xs success"
                      style={{ width: "100%", backgroundColor: "#579264", borderRadius: 5, fontWeight: "inherit", fontSize: "0.8em" }}>
                      Edit
                    </button>
                   :
                    null
                  }
              </div>
            </div>
          </div>

          <div className="col-sm-12 box">
            <div className="px-5 pb-5" style={{ borderRadius: 5 }}>
              <table class="table mt-4" style={{ color: "rgba(124, 122, 122, 0.87)" }}>
                <thead>
                  <tr>
                    <th scope="col">Nama</th>
                    <th scope="col">Jumlah</th>
                    <th scope="col">Satuan</th>
                    <th scope="col">Keterangan</th>
                  </tr>
                </thead>
                <tbody>
                  {data.composeList.map((item, index) =>
                    <tr key={index}>
                      <td>{item.label}</td>
                      <td>{item.qty}</td>
                      <td>Kg</td>
                      <td>{item.description}</td>
                    </tr>)}
                </tbody>
              </table>
            </div>
          </div>

          <div className="col-sm-12 box py-3" style={{ borderRadius: 5 }}>
            <div className="margin-top-40">
              <h6 style={{ color: '#579264' }}><b>Foto</b></h6>
              <div className="" id="inputGallery">
                {
                  data.images && data.images.length === 0 ?
                    <p
                      style={{ color: "rgba(156, 151, 151, 0.87)" }}
                      className="text-center mt-3"
                    >
                      Tidak ada foto.
                  </p>
                    :
                    data.images.map((file) => <ThumbnailPreview file={file} key={file.name ? file.name : file} />)
                }
              </div>
            </div>
          </div>
        </div>
        <LoadingModal isDownload={this.state.isDownload} />
      </div>
    )
  }
}


function LoadingModal(props) {
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  React.useEffect(() => {
    setShow(props.isDownload)
  }, [props.isDownload])
  // console.log('show', show)
  return (
    show ?
      <>
        <Button variant="primary" onClick={handleShow}>
          Launch demo modal
      </Button>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            {/* <Modal.Title>Modal heading</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>Loading...</Modal.Body>
        </Modal>
      </>
      :
      null
  );
}

const mapStateToProps = state => {
  return {
    uid: state.firebase.auth.uid,
    region: state.region,
    profile: state.firebase.profile
  }
}

export default compose(
  connect(mapStateToProps),
  withFirestore,
)(DetailScreen)
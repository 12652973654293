export const saveRegionState = (state) => {
    try {
    //   const serializedState = JSON.stringify(state);
      localStorage.setItem('region', state);
    } catch (err) {
      // Ignore write errors.
    }
  
};

export const loadRegionState = () => {
    try {
      const serializedState = localStorage.getItem('region');
      if (serializedState === null) {
        return undefined;
      }
      return serializedState;
    } catch (err) {
      return undefined;
    }
  };
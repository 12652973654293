import React, { Component } from "react"


class AddList extends Component {
    state = {
        data: [],
        checkedData: [],
    }
    handleChangeCheckbox = (event, id) => {
        let tempData = this.state.data
        for (let i = 0; i < tempData.length; i++) {
            const element = tempData[i];
            if (element.id === id) {
                element.checked = event.target.checked
                break;
            }
        }
        this.setState({ data: tempData })
        this.onSubmit()
    }

    onSubmit = async () => {
        let canSubmit = this.state.data.some((item) => item.checked)
        if (!canSubmit) {
            this.setState({ error: "You must AddList atleast one task!" })
            return;
        } else {
            let tempData = this.state.data
            const optionsArr = tempData.filter((item) => item.checked)

            const { input: { value, onChange } } = this.props
            onChange(optionsArr)
        }
    }

    componentDidMount() {
        const { input: { value } } = this.props
        const tempData = this.props.areaList
        console.log('this.props', this.props)
        for (let i = 0; i < tempData.length; i++) {
            let option = tempData[i];
            for (let j = 0; j < value.length; j++) {
                const currentData = value[j];
                if (currentData.label === option.label) {
                    option.checked = true
                    option.value = currentData.value
                    break;
                }
            }
        }
        this.setState({ data: tempData, error: "" })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.arealist !== this.props.areaList) {
            
            const { input: { value } } = nextProps
            const tempData = nextProps.areaList
            for (let i = 0; i < tempData.length; i++) {
                let option = tempData[i];
                for (let j = 0; j < value.length; j++) {
                    const currentData = value[j];
                    if (currentData.label === option.label) {
                        option.checked = true
                        option.value = currentData.value
                        break;
                    }
                }
            }
            this.setState({ data: tempData, error: "" })
        }
    }

    render() {
        let { input } = this.props;
        return (
            this.state.data.map((item) => (
                <label key={item.id} className="mb-2 d-flex align-items-center">
                    <input 
                        className="mr-4" 
                        type="checkbox"
                        checked={item.checked}
                        value={item.value}
                        
                        onChange={(event) => {
                            const newValue = [...input.value];
                            if (event.target.checked) {
                                newValue.push(item.value);
                            } else {
                                newValue.splice(newValue.indexOf(item.value), 1);
                            }

                            return input.onChange(newValue);
                        }}
                        // onChange={(event) => this.handleChangeCheckbox(event, item.id)} 
                    />{item.label}
                </label>
            ))
        )
    }
}

export default AddList
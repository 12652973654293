import React, { useState, useEffect } from "react"
import { Button, Modal } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import { SingleDatePicker } from 'react-dates';

const Delete = (props) => {

    const [show, setShow] = useState(false);
    const [dataRef, setDataRef] = useState(null);
    const [loading, setLoading] = useState(false);
    const [id, setId] = useState("");
    const history = useHistory()

    const handleShow = () => {
        setShow(true);
        setDataRef(props.dataRef)
    }
    const handleClose = () => setShow(false);
    console.log('dataRef', dataRef)
    async function deleteData() {
        setLoading(true)
        await dataRef.delete()
        console.log("data has been deleted!", props.id)
        setLoading(false)
        window.location.reload()
        handleClose()
    };
    return (
        <React.Fragment>
            <a onClick={handleShow} style={{ cursor: 'pointer' }} className="active">
                <button className="btn danger" style={{ fontSize: "0.8em" }}>
                    <i className="fa fa-trash"></i>
                </button>
            </a>

            <Modal aria-labelledby="contained-modal-title-vcenter" centered show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>
                            <p className="mb-0">Are you sure you want to delete this data?</p>
                    </Modal.Title>
                </Modal.Header>
                {
                    loading == false ?
                        <>
                            <Modal.Body className="text-center">
                                <lottie-player
                                    src="https://assets4.lottiefiles.com/packages/lf20_VZWGZs.json"  
                                    background="transparent"  
                                    speed="1"  
                                    style={{width: 200, height: 200}} loop autoplay 
                                >
                                </lottie-player>
                            </Modal.Body>
                            <Modal.Footer className="justify-content-center">
                                <button
                                    type="button"
                                    onClick={deleteData}
                                    disabled={loading}
                                    className="btn white text-white w-100 m-3"
                                    style={{ backgroundColor: "#D4252A", fontSize: "0.8em", borderRadius: 0 }}
                                >
                                    Delete
                                </button>
                                <button
                                    type="button"
                                    onClick={handleClose}
                                    className="btn white w-100 m-3"
                                    style={{ fontSize: "0.8em", borderRadius: 0 }}
                                >
                                    Cancel
                                </button>
                            </Modal.Footer>
                        </>
                    :
                    <div className="w-100 d-flex justify-content-center">
                        <lottie-player
                            src="https://assets7.lottiefiles.com/datafiles/YpOCRxpifd1Xs1n/data.json"  
                            background="transparent"  
                            speed="3"  
                            style={{ width: 300, height: 300 }}
                            loop autoplay >
                        </lottie-player>
                    </div>
                }
            </Modal>
        </React.Fragment>
    )
}

export default Delete
import React, { Component } from "react"
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect, isLoaded, isEmpty, firebaseConnect } from 'react-redux-firebase'
import Avatar from '../../assets/img/placeholder_person.png';
// import { auth, db } from "../../config/constants";
// import DataTable from "./dataTable";

class Profile extends Component {
    state = {
        currentStatus: "accepted",
        dataCount: 0,
        isLoading: false,
        dataUser: {
            id: null,
            name: "-",
            position: "-",
            phone: "-",
            email: "-",
            avatar: Avatar,
        }
    };

    fetchDataUser = async (props) => {
        this.setState({ isLoading: true })
        const { auth } = props;
        console.log('auth.uid', auth.uid)
        try {
            const snapshots = await this.props.firestore.get({
                // collection: 'region',
                // doc: region,
                collectionGroup: 'employee',
                where: ['uid', '==', auth.uid],
            });

            if (snapshots.empty) {
                this.setState({
                    isLoading: false
                })
                return null
            }

            let dataUser = { 
                id: snapshots.docs[0].id, 
                ...snapshots.docs[0].data(), 
                avatar: snapshots.docs[0].data().avatar ? snapshots.docs[0].data().avatar.url : Avatar
            }
            console.log('dataUser', dataUser)
            this.setState({
                dataUser,
                isLoading: false
            })
        } catch (error) {
            console.error(error)
            this.setState({
                isLoading: false
            })
        }
    }

    componentDidMount() {
        this.fetchDataUser(this.props)
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.auth !== this.props.auth) {
            this.fetchDataUser(nextProps)
        }
    }
    render() {
        return (
            <div className="container mt-5">
                <div className="box-body">
                    <div className="row">
                        <div className="col-md-4 text-center" style={{ display: "block" }}>

                            <div className="d-flex justify-content-center align-items-center">
                                <img src={this.state.dataUser.avatar} className="circle-dot border-0" alt="Banner Preview" style={{ objectFit: 'cover' }} />
                            </div>
                            <h5 className="text-center mt-3"><b>{this.state.dataUser.name}</b></h5>
                            <button 
                                type="button" 
                                className="btn white text-white w-50 mt-4" 
                                style={{ backgroundColor: "green", fontSize: "0.8em" }}
                                onClick={() => this.props.history.push(`/dashboard/profile/${this.state.dataUser.id}/update`)}
                            >
                                Edit
                            </button>
                        </div>

                        <div className="col-md-6 pt-4">
                            <div className="row">
                                <div className="col-md-2">
                                    <p><b>Name</b></p>
                                </div>
                                <div className="col-md-2">
                                    <p>{this.state.dataUser.name}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <p><b>Position</b></p>
                                </div>
                                <div className="col-md-2">
                                    <p>{this.state.dataUser.position}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <p><b>Phone</b></p>
                                </div>
                                <div className="col-md-2">
                                    <p>{this.state.dataUser.phone}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <p><b>Email</b></p>
                                </div>
                                <div className="col-md-2">
                                    <p>{this.state.dataUser.email}</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-2">
                            {/* <button 
                                onClick={this.onLogoutClick}
                                type="button" 
                                className="btn white text-white w-100" 
                                style={{ cursor: "pointer", backgroundColor: "#D4252A", fontSize: "0.8em", borderRadius: 0 }}
                            >
                                Logout
                            </button> */}
                        </div>
                    </div>

                    {/* <div className="container mt-5">
                        <h5 className="ml-3"><b>My Data</b></h5>
                        <DataTable />
                    </div> */}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        region: state.region
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect()
)(Profile)
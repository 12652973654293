import React, { Component } from "react"


class MultiCheckboxInput extends Component {
    state = {
        data: [],
        checkedData: [],
    }

    componentDidMount() {
        const { input: { value } } = this.props
        let tempData = this.props.areaList

        for (let i = 0; i < tempData.length; i++) {
            let option = tempData[i];
            for (let j = 0; j < value.length; j++) {
                const currentData = value[j];
                if (currentData === option.label) {
                    option.checked = true
                    option.value = currentData
                    break;
                } else {
                    option.checked = false
                }
            }
        }
        this.setState({ data: tempData, error: "" })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { input: { value } } = nextProps
        let tempData = this.props.areaList

        for (let i = 0; i < tempData.length; i++) {
            let option = tempData[i];
            for (let j = 0; j < value.length; j++) {
                const currentData = value[j];
                if (currentData === option.label) {
                    option.checked = true
                    option.value = currentData
                    break;
                } else {
                    option.checked = false
                }
            }
        }
        this.setState({ data: tempData, error: "" })
    }

    onChangeCheckbox = (event, item) => {
        let { input } = this.props;
        let newValue = [...input.value];
        if (event.target.checked) {
            newValue.push(item.value);
        } else {
            newValue.splice(newValue.indexOf(item.value), 1);
        }

        return input.onChange(newValue);
    }

    render() {
        return (
            this.state.data.map((item) => (
                <label key={item.id} className="mb-2 d-flex align-items-center">
                    <input 
                        className="mr-4" 
                        type="checkbox"
                        checked={item.checked}
                        value={item.value}
                        
                        onChange={(event) => this.onChangeCheckbox(event, item)}
                        // onChange={(event) => this.handleChangeCheckbox(event, item.id)} 
                    />{item.label}
                </label>
            ))
        )
    }
}

export default MultiCheckboxInput
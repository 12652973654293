import React from "react"
import NumberFormat from 'react-number-format'

const TableItem = (props) => {
    return (
        <tr key={props.item.id}>
            <td>{props.item.transaction_number}</td>
            <td>{props.item.tariff}</td>
            <td>{props.item.payment}</td>
            <td>{props.item.qty}</td>
            <td>{props.item.price}</td>
            <td>{props.item.assurance}</td>
            <td>{props.item.pmiCharge}</td>
            <td>{props.item.tax}</td>
            <td>{props.item.total}</td>
        </tr>
    )
}

export default TableItem
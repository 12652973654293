import React, { Component } from 'react';
import moment from 'moment';
import TableItem from "./TableItem"
import LoadingTableTicket from "../../../components/LoadingTableTicket"

// import { auth, db } from "../../config/constants";

class DataTable extends Component {
    renderItem = (item, index) => {
        return (
            <TableItem
                key={item.id}
                index={index}
                item={item}
            />
        )
    }
    render() {
        return (
            <table className="table table-bordered m-a-0">
                <thead style={{ color: "#579264"}}>
                    <tr>
                        <th scope="col" className="text-center">Nomor Transaksi.</th>
                        <th scope="col" className="text-center">Tipe Tiket</th>
                        <th scope="col" className="text-center">Metode Pembayaran</th>
                        <th scope="col" className="text-center">Jumlah</th>
                        <th scope="col" className="text-center">Harga</th>
                        <th scope="col" className="text-center">Asuransi</th>
                        <th scope="col" className="text-center">Pajak PMI</th>
                        <th scope="col" className="text-center">Pajak</th>
                        <th scope="col" className="text-center">Total</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.props.loading ?
                            <React.Fragment>
                                <LoadingTableTicket />
                                <LoadingTableTicket />
                                <LoadingTableTicket />
                                <LoadingTableTicket />
                            </React.Fragment>
                            :
                                this.props.data.length == 0 ? 
                                <tr>
                                    <td className="text-center" colSpan="9">
                                        <p>Tidak ada data!</p>
                                    </td>
                                </tr>
                            : this.props.data.map(this.renderItem)
                    }
                </tbody>
            </table>
        )
    }
}

export default DataTable
import React, { Component } from 'react'
import { Alert } from "react-bootstrap"
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firebaseConnect } from 'react-redux-firebase'
import { reset } from 'redux-form';
import { UserIsNotAuthenticated } from '../../navigation/PrivateRoute'
import SigninForm from './SigninForm';
import KRIcon from '../../assets/logo/logo.png';

const bgImage = require('../../assets/icon/bg-login.png');

class SigninScreen extends Component {
  static propTypes = {
    firebase: PropTypes.shape({
      login: PropTypes.func.isRequired,
      createUser: PropTypes.func.isRequired,
    }),
  }

  state = {
    formData: {
      email: '',
      password: '',
    },
    alertInfo: false,
  }

  onSubmit = async (values) => {

    const formData = {
      email: values.email,
      password: values.password
    }

    try {

      await this.props.firebase.login(formData)
      this.props.history.push('/dashboard')

    } catch (error) {
      this.setState({ alertInfo: true });

      console.error(error)
      this.props.reset('signinForm') // Change to your form name

    }
    
  }

  // register = () => {

  //   const formData = {
  //     email: this.state.email,
  //     password: this.state.password
  //   }

  //   try {

  //     this.props.firebase.createUser(formData)
  //     this.props.history.push('/dashboard')

  //   } catch (error) {
  //     this.setState({ alertInfo: true });
  //     console.error(error)
      
  //   }


  // }

  render() {

    return ( 
      <div className="login-panel" style={{ backgroundImage: `url(${bgImage})`, backgroundSize: 'cover', height: "100%" }}>
        <div className="bg-gradient" style={{ backgroundSize: 'cover'}}>
          <div className="container">
            <div className="col-12 py-5 text-center">
              <img src={KRIcon} className="img-fluid mx-auto my-5" alt="Logo Kebun Raya System" />
              <h3 className="mb-5">Management Operational System</h3>
              <div className="p-a-md box-color w-75 r box-shadow-z1 text-color mx-auto">
                <div className="m-b text-sm">
                  <b>
                    Sign in with your Account
                  </b>
                </div>
                <Alert show={this.state.alertInfo} variant="danger" dismissible onClose={() => this.setState({ alertInfo: false })}>
                  Wrong Username/Password
                </Alert>
                <SigninForm onSubmit={this.onSubmit} initialValues={this.state.formData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return { auth: state.firebase.auth }
}

const mapDispatchToProps = { reset }

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  UserIsNotAuthenticated,
  firebaseConnect(),
)(SigninScreen)
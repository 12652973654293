import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withFirestore } from 'react-redux-firebase'
import { convertToRaw } from 'draft-js';

import {uploadBanner} from './uploadBanner'
import UpdateSession from './UpdateForm'
import LoadingScreen from '../../components/LoadingScreen'
import ModalError from '../../components/ModalError'
import moment from "moment"
const collection = "plantInfo"

class UpdateScreen extends Component {
  static propTypes = {
    uid: PropTypes.string,
    firestore: PropTypes.shape({
      update: PropTypes.func.isRequired
    }).isRequired
  }
  
  state = {
    data: {
      name: '',
      species: '',
      englishName: '',
      latinName: '',
      genus: '',
      species: '',
      sectorList: [],
      sector: "",
      vak: '',
      collectionNumber: '',
      specimenTotal: '',
      status: '',
      habitus: '',
      deployment: '',
      usability: '',
      description: '',
      place: '',
      region: '',
      areaList: []
    },
    loading: true,
    errorInfo: false,
  }

  handleErrorInfo = () => {
    this.setState({ errorInfo: !this.state.errorInfo });
  }

  createFormData = ({ avatar, genus, species, fetchArea, sectorList, areaList, ...values }) => {
    const data = {
      ...values,
      species: typeof species.getCurrentContent == "function" ? convertToRaw(species.getCurrentContent()) : species,
      genus: typeof genus.getCurrentContent == "function" ? convertToRaw(genus.getCurrentContent()) : genus,
    };
    return data;
  }

  onSubmit = async (values) => {
    const { sessionId } = this.props.match.params

    const formData = this.createFormData(values)
    console.log("formData:", formData)

    try {
      await this.props.firestore.update({ 
        collection: 'region',
        doc: this.props.region.toLowerCase(),
        subcollections: [{ collection: 'plants', doc: sessionId }]
      }, formData);
      console.log('Successfully update Plant data');

      if (typeof values.avatar !== 'string') {
        const path = `region/${this.props.region.toLowerCase()}/plants/${sessionId}`
        const snapshotImage = await uploadBanner(path, values.avatar)
        // console.log('snapshotImage', snapshotImage)
        await this.props.firestore.update({ 
          collection: 'region',
          doc: this.props.region.toLowerCase(),
          subcollections: [{ collection: 'plants', doc: sessionId }]
        }, {avatar: snapshotImage});
      }

      this.goBack()
    } catch (error) {
      this.setState({ errorInfo: true })

      console.error("Failed to update Plant Data: ", error)

    }
  }


  componentDidMount() {
    this.fetchData();
  }


  fetchDataSector = async () => {
    try {
      const docSnapshots = await this.props.firestore.get({
        collection: 'region',
        doc: this.props.region.toLowerCase(), // masih hardcode wilayah
        subcollections: [{ 
          collection: 'sector',
        }],
      });
      if (docSnapshots.empty) {
        console.log("kosong")
        this.setState({
          data: {
            ...this.state.data, 
            sector: '', 
            areaList: [], 
            sectorList: [], 
          }
        })
        return;
      }
      let sectorList = []
      docSnapshots.forEach((doc) => {
        sectorList.push({
          id: doc.id,
          label:doc.data().name,
          value: doc.data().name,
          ...doc.data()
        })
      })
      
      let selectedSector = null
      for (let i = 0; i < sectorList.length; i++) {
        const element = sectorList[i];
        if (this.state.data.sector == element.name) {
          selectedSector = element
          break;
        }
      }
      let areaList = []
      if (selectedSector.maintenanceArea) {
        areaList = selectedSector.maintenanceArea.map((item) => {
          return { label: item, value: item }
        })
      }
      console.log('areaList', areaList)
      this.setState({
        data: {
          ...this.state.data,
          sectorList,
          areaList
        }
      })
    } catch (error) {
      this.setState({
        data: {
          ...this.state.data,
          // selectedSector: null,
          sector: '', 
          sectorList: [], 
        }
      })
    }
  }

  fetchData = async () => {

    try {
      const { sessionId } = this.props.match.params;
      console.log('sessionId', sessionId)
      const snapshot = await this.props.firestore.get({ 
        collection: 'region',
        doc: this.props.region.toLowerCase(),
        subcollections: [{ collection: 'plants', doc: sessionId }]
      });
      if (snapshot.exists) {
        const { 
          avatar,
          timestamp,
          ...restData 
        } = snapshot.data()
        let data = {
          id: snapshot.id,
          avatar: avatar ? avatar.url : "",
          ...restData
        }
        console.log('data', data)

        console.log('data', data)
        this.setState({ data: {...this.state.data, ...data } }, () => {
          this.fetchDataSector();
        });
      } else {
        console.log("Doesnt exists")
      }
    } catch (error) {
      console.error(error)
    }

    this.setState({ loading: false })
  }

  goBack = () => {
    this.props.history.push({
      pathname: `/dashboard/plant_info/${this.state.data.id}`,
      state: { region: this.props.selectedOption }
    })
  }

  render() {
    if (!this.props.uid) return null
    if (this.state.loading) return <LoadingScreen />
    return (
      <React.Fragment>
        <div className="box-header px-5 mt-3" style={{ backgroundColor: "#EFF2F7" }}>
          <div className="row">
            <a onClick={this.goBack}><i class="fa fa-arrow-left" aria-hidden="true"></i></a>
            <h3 className="ml-3 mt-1" style={{ color: "#3B4859" }}>UPDATE PLANT</h3>
          </div>
        </div>
          {
            this.state.errorInfo &&
            <ModalError 
              errorInfo={this.state.errorInfo}
              handleErrorInfo={this.handleErrorInfo}
            />
          }
        <div className="box-body">
          <UpdateSession onSubmit={this.onSubmit} initialValues={this.state.data} />
        </div>
      </React.Fragment>

    )
  }
}

const mapStateToProps = state => {
  return {
    uid: state.firebase.auth.uid,
    region: state.region
  }
}

export default compose(
  connect(mapStateToProps),
  withFirestore,
)(UpdateScreen)
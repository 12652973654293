import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { firestore } from 'firebase'

import ListItem from './ListItem'
import LoadingTable from "../../../../components/loading-table"
import { getUserData } from '../../../../helpers/fetchUserData'

class EmployeeHistory extends Component {

    state = {
        loading: true,
        data: [],
        employeeData: {
            name: ""
        }
    }
    
    fetchData = async () => {
        this.setState({ isLoading: true, data: [] })
        const userData = await getUserData(this.props.region, this.props.uid)
        // console.log('userData', userData)
        if (userData.roleId == "f1" || userData.roleId == "f2") {
            const { employeeId } = this.props.match.params;
            const employeeData = await getUserData(this.props.region, employeeId)
            try {

                let arrData = []
                
                const snapshots = await firestore()
                    .collection('region')
                    .doc(this.props.region.toLowerCase())
                    .collection("employee")
                    .doc(employeeId)
                    .collection("facilityReport")
                    .orderBy('timestamp', 'desc')
                    .get()
                if (snapshots.empty) {
                    this.setState({
                        data: [],
                        isLoading: false
                    });
                    return;
                }
                snapshots.forEach((snap) => {
                    arrData.push({
                        id: snap.id,
                        display: moment(snap.data().timestamp.toDate()).format("DD MMMM YYYY"),
                        ...snap.data()
                    })
                })
                this.setState({
                    data: arrData,
                    uid: employeeId,
                    employeeData,
                    isLoading: false
                });
                    
            } catch (error) {
                console.error(error)
                this.setState({
                    data: [],
                    isLoading: false
                });
            }
        }
    }

    navigateDetail = id => {
        const { employeeId } = this.props.match.params;
        this.props.history.push(
            `/dashboard/facilities_employee/employee_list/${employeeId}/activities/${id}`
        )
    }

    renderListItem = (item) => (
        <ListItem
            key={item.id}
            navigateDetail={this.navigateDetail}
            dataRef={item.dataRef}
            {...item}
        />
    )

    componentDidMount() {
        this.fetchData(this.props);
    }

    goBack = () => {
        this.props.history.push({
          pathname: `/dashboard/facilities_employee/employee_list`,
          state: { region: this.props.selectedOption }
          
        })
    }
    

    render() {
        return (
            <React.Fragment>
                <div className="box-header px-5 mt-3" style={{ backgroundColor: "#EFF2F7" }}>
                    <div className="row">
                        <a onClick={this.goBack}><i class="fa fa-arrow-left" aria-hidden="true"></i></a>
                        <h3 className="ml-3 mt-1" style={{ color: "#3B4859" }}>LAPORAN KEGIATAN {this.state.employeeData.name &&"("+this.state.employeeData.name+")"}</h3>
                    </div>
                </div>
                <div className="container-fluid p-5 spacing-resp">
                    {/* <div className="box p-4" style={{ borderRadius: 10 }}>
                        <div className="row">
                            <div className="col-md-3">
                                <h6 className="mb-0 mt-2">
                                    <span style={{ color: "#579264", fontWeight: "bold" }}>Tanggal</span>
                                    <span style={{ color: "#979D99", fontWeight: "bold", marginLeft: 15 }}>{this.state.today}</span>
                                </h6>
                            </div>
                        </div>
                    </div> */}

                    <div className="box mb-0 p-4 mt-4" style={{ borderRadius: 10 }}>
                        <div className="table-responsive mt-5">
                            <table className="table table-no-bordered m-a-0">
                                <thead style={{ color: "#579264" }}>
                                    <tr>
                                        <th scope="col" className="text-center">Tanggal</th>
                                        <th scope="col" className="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                    {
                                        this.state.isLoading
                                            ?
                                            <React.Fragment>
                                                <LoadingTable column={2} />
                                                <LoadingTable column={2} />
                                                <LoadingTable column={2} />
                                                <LoadingTable column={2} />
                                            </React.Fragment>
                                            : this.state.data.length === 0
                                                ?
                                                <tr>
                                                    <td className="text-center" colSpan="9">
                                                        <p>Tidak ada data!</p>
                                                    </td>
                                                </tr>
                                                :
                                                this.state.data.map(this.renderListItem)
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        uid: state.firebase.auth.uid,
        region: state.region,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect(),
)(EmployeeHistory)
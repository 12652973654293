import React from "react"
import ErrorMessage from "./ErrorMessage"

const TextAreaReport = (field) => (
  <div className="form-group row mb-4">
    <h6 
      htmlFor={`input-${field.label}`} 
      className="form-control-label" 
      style={{ color: "rgb(87, 146, 100)", fontWeight: "bold" }}
    >
      {field.label}
    </h6>
    <textarea
      {...field.input}
      type={field.type || "text"}
      placeholder={field.placeholder}
      className="form-control" 
      style={{ fontSize: "0.9em" }}
      rows={field.rows ||2}
      id={`input-${field.label}`} autoComplete={field.autocomplete || "on"}
    />
    <ErrorMessage {...field} />
  </div>
)

export default TextAreaReport
import React, { Component } from 'react'
import { DateRangePicker, isInclusivelyAfterDay } from 'react-dates'
import moment from 'moment'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Modal } from 'react-bootstrap'

import DataTable from './DataTable'
import DownloadReport from '../../../../../components/DownloadReport'
import DatePicker from '../../../../../components/DatePicker';
import { fetchDataFacility } from '../../../../../utils/nonfnb'
import { totalValuesFromObj } from '../../../../../utils/common'

class TicketRevenueReport extends Component {
    state = {
        data: [],
        loading: true,
        fileName: 'fasilitas-report-data',
        showDownload: false,
        startDate: moment(),
        endDate: moment(),
        total: {
            totalQty: 0,
            totalAmount: 0,
            bogor: {
                qty: 0,
                amount: 0,
            },
            purwodadi: {
                qty: 0,
                amount: 0,
            },
            cibodas: {
                qty: 0,
                amount: 0,
            },
            bali: {
                qty: 0,
                amount: 0,
            },
        }
    }

    fetchDataEvent = async () => {
        this.setState({
            loading: true,
            data: [],
            total: {
                totalQty: 0,
                totalAmount: 0,
                bogor: {
                    qty: 0,
                    amount: 0,
                },
                purwodadi: {
                    qty: 0,
                    amount: 0,
                },
                cibodas: {
                    qty: 0,
                    amount: 0,
                },
                bali: {
                    qty: 0,
                    amount: 0,
                },
            }
        })
        try {
            const startDate = moment(this.state.startDate).startOf('day')
            const endDate = moment(this.state.endDate).startOf('day')
            const timeDiff = endDate - startDate;
            const duration = moment.duration(timeDiff).asDays();
            let tempData = []

            let total = {
                totalQty: 0,
                totalAmount: 0,
                bogor: {
                    qty: 0,
                    amount: 0,
                },
                purwodadi: {
                    qty: 0,
                    amount: 0,
                },
                cibodas: {
                    qty: 0,
                    amount: 0,
                },
                bali: {
                    qty: 0,
                    amount: 0,
                },
            }
            const arrDays = []
            for (let i = 0; i <= duration; i++) {
                arrDays.push(moment(startDate)
                .startOf('day')
                .add(i, 'day'))
            }
            // let tempData = []
            for (let i = 0; i < arrDays.length; i++) {
                const currDate = arrDays[i];
                let data = await fetchDataFacility(currDate)
                let totalQty = totalValuesFromObj(data.qty[0]);
                let totalAmount = totalValuesFromObj(data.amounts[0]);
                const currData = {
                    date: moment(currDate).format("D MMMM YYYY"),
                    day: moment(currDate).format("dddd"),
                    ...data.tickets,
                    totalQty,
                    totalAmount,
                }
                tempData.push(currData)

                total.totalQty = total.totalQty + totalQty
                total.totalAmount = total.totalAmount + totalAmount
                total.bogor.qty = total.bogor.qty + currData.bogor.qty
                total.bogor.amount = total.bogor.amount + currData.bogor.amount
                total.cibodas.qty = total.cibodas.qty + currData.cibodas.qty
                total.cibodas.amount = total.cibodas.amount + currData.cibodas.amount
                total.purwodadi.qty = total.purwodadi.qty + currData.purwodadi.qty
                total.purwodadi.amount = total.purwodadi.amount + currData.purwodadi.amount
                total.bali.qty = total.bali.qty + currData.bali.qty
                total.bali.amount = total.bali.amount + currData.bali.amount
            }
                     
            
            const customFields = [
                { label: 'Tanggal', value: 'date' },
                { label: 'Bogor Qty', value: 'bogor.qty' },
                { label: 'Bogor IDR', value: 'bogor.amount' },
                { label: 'Purwodadi Qty', value: 'purwodadi.qty' },
                { label: 'Purwodadi IDR', value: 'purwodadi.amount' },
                { label: 'Cibodas Qty', value: 'cibodas.qty' },
                { label: 'Cibodas IDR', value: 'cibodas.amount' },
                { label: 'Bali Qty', value: 'bali.qty' },
                { label: 'Bali IDR', value: 'bali.amount' },
                { label: 'Total Qty', value: 'totalQty' },
                { label: 'Total IDR', value: 'totalAmount' },
            ]

            this.setState({
                data: tempData,
                customFields,
                total,
                loading: false
            })
        } catch (error) {
            console.error(error)
            this.setState({
                data: [],
                loading: false
            })
        }
    }

    onDatesChange = ({ startDate, endDate }) => {
        this.setState({ startDate, endDate }, () => this.fetchDataEvent())
    }

    componentDidMount() {
        this.fetchDataEvent();
    }

    render() {
        return (
            <React.Fragment>
                <div className="box-header px-5 mt-3" style={{ backgroundColor: "#EFF2F7" }}>
                    <h3 style={{ color: "#3B4859" }}>REVENUE (SEWA FASILITAS LAPORAN)</h3>
                </div>
                <div className="container-fluid px-5 spacing-resp">
                    <div className="row mb-4">
                        <div className="col-lg-4 col-md-6 mt-5">
                            <div className="row">
                                <div className="col-6"><h6 style={{ fontSize: "0.8rem", color: "#3B4859" }}>Dari</h6></div>
                                <div className="col-6"><h6 style={{ fontSize: "0.8rem", color: "#3B4859" }}>Sampai</h6></div>
                            </div>
                            <DatePicker 
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                onDatesChange={this.onDatesChange} 
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 d-flex align-items-end mt-5">
                            <DownloadReport
                                data={this.state.data}
                                fields={this.state.customFields}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                fileName={this.state.fileName}
                            />
                        </div>
                    </div>

                    <div className="py-3 mt-4">
                        <div className="box p-3 mb-0" style={{ borderRadius: 10 }}>
                            <div className="table-responsive">
                                <DataTable 
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    data={this.state.data} 
                                    total={this.state.total} 
                                    loading={this.state.loading} 
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        region: state.region
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect()
)(TicketRevenueReport)
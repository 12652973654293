import React from 'react'

import SidebarTop from './SidebarTop';
import SidebarNav from './SidebarNav';
import SidebarFooter from './SidebarFooter';

const Sidebar = (props) => (
  <div className="left navside bg-sidebar dk" data-layout="column">
      <SidebarTop />
      <SidebarNav />
      {/* <SidebarFooter /> */}
  </div>
)

export default Sidebar

import React, { Component } from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import { Link } from "react-router-dom"
import { connect } from 'react-redux'
import moment from 'moment'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'

import ListItem from './ListItem'
import LoadingTable from "../../../components/loading-table"
import { firestore } from 'firebase'

class Activities extends Component {

    state = {
        loading: true,
        data: [],
        activeTab: "harian",
        today: moment().format("DD MMMM YYYY"),
        todayDate: moment().toDate()
    }

    getUserData = async (region) => {
        const snapshot = await firestore().collection('region').doc(region.toLowerCase()).collection('employee').doc(this.props.uid).get();
        if (!snapshot.exists) {
            return {}
        }
        return {
            id: snapshot.id,
            ...snapshot.data()
        }
    }
    fetchData = async (props) => {
        this.setState({ isLoading: true, data: [] })
        const userData = await this.getUserData(this.props.region)
        let uid = props.uid
        // if (userData.position == "f1" || userData.position == "f2") {
        //     const { userId } = this.props.match.params;
        //     uid = userId
        // }
        try {

            let arrDays = []
            
            const snapshots = await firestore()
                .collection('region')
                .doc(props.region.toLowerCase())
                .collection("employee")
                .doc(uid)
                .collection("facilityReport")
                .orderBy('timestamp', 'desc')
                .get()

            snapshots.forEach((snap) => {
                arrDays.push({
                    id: snap.id,
                    display: moment(snap.data().timestamp.toDate()).format("DD MMMM YYYY"),
                    ...snap.data()
                })
            })
            console.log('arrDays', arrDays)
            this.setState({
                data: arrDays,
                uid: uid,
                isLoading: false
            });
                
        } catch (error) {
            console.error(error)
        }
    }

    navigateDetail = sessionId => {
        this.props.history.push(`/dashboard/facilities_employee/${this.state.uid}/activities/${sessionId}`)
    }
    renderListItem = (item) => (
        <ListItem
            key={item.id}
            navigateDetail={this.navigateDetail}
            dataRef={item.dataRef}
            {...item}
        />
    )

    componentDidMount() {
        this.fetchData(this.props);
    }

    render() {
        return (
            <React.Fragment>
                <div className="box-header px-5 mt-3" style={{ backgroundColor: "#EFF2F7" }}>
                    <h3 style={{ color: "#3B4859" }}>LAPORAN KEGIATAN SAYA</h3>
                </div>
                <div className="container-fluid p-5 spacing-resp">
                    <div className="box p-4" style={{ borderRadius: 10 }}>
                        <div className="row">
                            <div className="col-md-3">
                                <h6 className="mb-0 mt-2">
                                    <span style={{ color: "#579264", fontWeight: "bold" }}>Tanggal</span>
                                    <span style={{ color: "#979D99", fontWeight: "bold", marginLeft: 15 }}>{this.state.today}</span>
                                </h6>
                            </div>
                        </div>
                    </div>

                    <div className="box mb-0 p-4 mt-4" style={{ borderRadius: 10 }}>
                        <div className="table-responsive mt-5">
                            <table className="table table-no-bordered m-a-0">
                                <thead style={{ color: "#579264" }}>
                                    <tr>
                                        <th scope="col" className="text-center">Tanggal</th>
                                        <th scope="col" className="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                    {
                                        this.state.isLoading
                                            ?
                                            <React.Fragment>
                                                <LoadingTable column={2} />
                                                <LoadingTable column={2} />
                                                <LoadingTable column={2} />
                                                <LoadingTable column={2} />
                                            </React.Fragment>
                                            : this.state.data.length === 0
                                                ?
                                                <tr>
                                                    <td className="text-center" colSpan="9">
                                                        <p>Tidak ada data!</p>
                                                    </td>
                                                </tr>
                                                :
                                                this.state.data.map(this.renderListItem)
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        uid: state.firebase.auth.uid,
        region: state.region,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect(),
)(Activities)
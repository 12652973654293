const { firestore } = require("firebase");

export const getUserData = async (region, uid) => {
    const snapshot = await firestore().collection('region').doc(region.toLowerCase()).collection('employee').doc(uid).get();
    if (!snapshot.exists) {
        return {}
    }
    return {
        id: snapshot.id,
        ...snapshot.data()
    }
}
import React from 'react'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import moment from 'moment'

import SubmitButton from '../../../../components/SubmitButton'
import LoaderModal from '../../../../components/LoaderModal'
import validate from './validate'
import TextArea from '../../../../components/Form/TextAreaInput';

let AddForm = ({ handleSubmit, pristine, submitting, valid, fields, form, dataAnimal, date, ...otherProps }) => {
  return (
    <React.Fragment>
      {/* <div className="col-12" style={{ marginTop: 15 }}>
        <div className="" style={{ borderRadius: 5 }}>
          <div className="box-header">
            <h2 className="m-2" style={{ fontWeight: "bold" }}>NEW DAILY REPORT</h2>
          </div>
        </div>
      </div> */}
      <form onSubmit={handleSubmit}>
        <div className="col-sm-12">
          <div className="p-5" style={{ borderRadius: 5 }}>
            
              <div className="px-4">
                <div className="col-12 px-0">
                  <Field name="activity" label="Kegiatan" 
                  placeholder="Laporan Kegiatan" component={TextArea} rows={4} /> 
                </div>
              </div>

              <div className="px-4">
                <div className="col-12 px-0">
                  <Field name="description" label="Keterangan" 
                  placeholder="Keterangan" component={TextArea} rows={4} /> 
                </div>
              </div>
            
            <div className="col-12 text-right mt-2">
              <div className="col-lg-2 col-xs-12 px-0 float-right">
                <SubmitButton disabled={!valid || submitting} />
              </div>
              <LoaderModal submitting={submitting} />
            </div>

          </div>
        </div>
      </form>
    </React.Fragment>
  )
}

AddForm = reduxForm({
  form: 'addDailyReportForm',
  validate,
  enableReinitialize: true,
})(AddForm)

export default AddForm
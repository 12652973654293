import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withFirestore } from 'react-redux-firebase'
import LoadingScreen from '../../components/LoadingScreen'
import ModalError from '../../components/ModalError'
import moment from 'moment'
import LoadingTableSponsor from '../../components/loading-table'

const collection = "region"

class UpdateScreen extends Component {
    state = {
        data: {
            id: "",
            nik: "",
            employeeNumber: "",
            npwp: "",
            familyCard: "",
            workDate: "",
            name: "",
            gender: "",
            position: "",
            department: "",
            workingStartDate: "",
            age: "",
            subDepartment: "",
            birth: {
                dob: "",
                city: ""
            },
            maritalStatus: "",
            telephone: "",
            address: "",
            sector: "",
            avatar: ""
        },
        selected: "biodata",
        loading: true,
        errorInfo: false,
    }

    handleErrorInfo = () => {
        this.setState({ errorInfo: !this.state.errorInfo });
    }

    componentDidMount() {
        this.fetchData();
        let { region = "" } = this.props.location.state;
        this.setState({
            data: { ...this.state.data, region }
        })
    }
    renderTableHead = () => (
      <tr style={{ color: "rgba(124, 122, 122, 0.87)" }}>
        <th>Date</th>
        <th>Shift</th>
        <th>Schedule In</th>
        <th>Schedule Out</th>
        <th>Check In</th>
        <th>Check Out</th>
      </tr>
    )

    editEmployee = () => {
        this.props.history.push({
            pathname: `/dashboard/employee/${this.state.data.id}/update`,
            state: { region: this.state.data.region }
        })
    }

    fetchData = async () => {
        try {
            const { sessionId } = this.props.match.params;
            console.log('sessionId', sessionId)
            const snapshot = await this.props.firestore.get({
                collection: collection,
                doc: this.props.region,
                subcollections: [{
                    collection: 'employee',
                    doc: sessionId
                }],
            })
            if (!snapshot.exists) {
                console.log("Doesnt exists")
            } else {
                const { avatar, birth, workDate, ...restData } = snapshot.data()
                
                let age = "-"
                if (birth.dob) {
                    let a = moment();
                    let b = moment(birth.dob.toDate(), 'YYYY');  
                    age = a.diff(b, 'years') + " Tahun"; // calculates patient's age in years
                }
                let workingStartDate = "-"
                if (workDate) {
                    let currentWork = moment();
                    let startWork = moment(workDate.toDate(), 'YYYY');  
                    workingStartDate = currentWork.diff(startWork, 'years') + " Tahun"; // calculates patient's age in years
                }
                
                let data = {
                    id: snapshot.id,
                    avatar: avatar ? avatar.url : "",
                    birth: {
                        dob: birth.dob ? moment(birth.dob.toDate()).format("DD MMMM YYYY") : "",
                        city: birth.city || "",
                    },
                    age,
                    workDate: workDate ? moment(workDate.toDate()).format("DD MMMM YYYY") : "",
                    workingStartDate, 
                    ...restData
                }
                this.setState({ data: { ...this.state.data, ...data } });
            }
        } catch (error) {
            console.error(error)
        }

        this.setState({ loading: false })
    }

    goBack = () => {
        this.props.history.push(`/dashboard/employee`)
    }

    selectTab = (selected) => this.setState({ selected })

    render() {
        if (!this.props.uid) return null
        if (this.state.loading) return <LoadingScreen />
        const { data } = this.state;
        return (
            <React.Fragment>
                <div className="box-header px-5 mt-3" style={{ backgroundColor: "#EFF2F7" }}>
                    <div className="row">
                        <a onClick={this.goBack}><i className="fa fa-arrow-left" aria-hidden="true"></i></a>
                        <h3 className="ml-3 mt-1" style={{ color: "#3B4859" }}>DETAIL KARYAWAN</h3>
                    </div>
                </div>
                <div className="box-body">
                    {
                        this.state.errorInfo &&
                        <ModalError
                            errorInfo={this.state.errorInfo}
                            handleErrorInfo={this.handleErrorInfo}
                        />
                    }
                    <div className="nav-active-border bottom" style={{ borderBottom: "1px solid #D9D2D2"}}>
                        <div className="nav nav-md">
                            <a 
                                className={`nav-link ${this.state.selected === "biodata" && "active"}`} 
                                // style={{ paddingRight: "100px" }}
                                onClick={() => this.selectTab("biodata")}>
                                Biodata
                            </a>
                            <a className={`nav-link ${this.state.selected === "absence" && "active"}`}  
                                // style={{ paddingRight: "100px"}}
                                onClick={() => this.selectTab("absence")}>
                                Kehadiran
                            </a>
                        </div>
                    </div>

                    {
                        this.state.selected === "biodata" &&
                        <div className="container">
                            <div className="col-md-6 offset-md-3">
                                <div className="d-flex justify-content-center mt-5">
                                    {this.state.data.avatar ?
                                        <div className="d-flex justify-content-center align-items-center">
                                            <img src={this.state.data.avatar} className="circle-dot border-0" alt="Banner Preview" style={{ objectFit: 'cover' }} />
                                        </div> :
                                        <div className="circle-dot d-flex justify-content-center align-items-center" style={{ backgroundColor: "rgba(124, 122, 122, 0.87)", borderStyle: "none" }}></div>}
                                    {this.state.loading && <p>Loading..</p>}
                                </div>
                                {

                                    ((this.props.profile.token && this.props.profile.token.claims.admin === true) || 
                                    (this.props.profile.token && this.props.profile.token.claims.department == "Human Resource"))
                                    ?
                                    <div className="d-flex justify-content-center mt-5 mb-5">
                                        <button className="md-btn md-raised m-b-sm w-xs kr-color" style={{ width: "150px", borderRadius: 5, fontSize: "0.7rem", fontWeight: "inherit" }} onClick={this.editEmployee}>
                                            <div style={{ color: "white", fontSize: '0.7rem' }}>
                                                <i className="fa fa-pencil-square-o" style={{ opacity: 1, marginRight: 5, color: "white" }} />
                                                    Edit
                                                </div>
                                        </button>
                                    </div>
                                    : <div className="my-4"></div>
                                }
                                    

                                <div className="row mb-2">
                                    <p className="col-md-4 offset-md-2" style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold" }}>NIK</p>
                                    <p className="col-md-4 offset-md-2" style={{ fontSize: "0.9em" }}>{data.nik}</p>
                                </div>

                                <div className="row mb-2">
                                    <p className="col-md-4 offset-md-2" style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold" }}>Nomor Karyawan</p>
                                    <p className="col-md-4 offset-md-2" style={{ fontSize: "0.9em" }}>{data.employeeNumber}</p>
                                </div>

                                <div className="row mb-2">
                                    <p className="col-md-4 offset-md-2" style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold" }}>NPWP</p>
                                    <p className="col-md-4 offset-md-2" style={{ fontSize: "0.9em" }}>{data.npwp}</p>
                                </div>
                                <div className="row mb-2">
                                    <p className="col-md-4 offset-md-2" style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold" }}>Kartu Keluarga</p>
                                    <p className="col-md-4 offset-md-2" style={{ fontSize: "0.9em" }}>{data.familyCard}</p>
                                </div>
                                <div className="row mb-2">
                                    <p className="col-md-4 offset-md-2" style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold" }}>Tanggal Masuk Kerja</p>
                                    <p className="col-md-4 offset-md-2" style={{ fontSize: "0.9em" }}>{data.workDate}</p>
                                </div>
                                <div className="row mb-2">
                                    <p className="col-md-4 offset-md-2" style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold" }}>Masa Bekerja</p>
                                    <p className="col-md-4 offset-md-2" style={{ fontSize: "0.9em" }}>{data.workingStartDate}</p>
                                </div>
                                <hr />
                                <div className="row mb-2">
                                    <p className="col-md-4 offset-md-2" style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold" }}>Nama</p>
                                    <p className="col-md-4 offset-md-2" style={{ fontSize: "0.9em" }}>{data.name}</p>
                                </div>
                                <div className="row mb-2">
                                    <p className="col-md-4 offset-md-2" style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold" }}>Jenis Kelamin</p>
                                    <p className="col-md-4 offset-md-2" style={{ fontSize: "0.9em" }}>{data.gender}</p>
                                </div>
                                <div className="row mb-2">
                                    <p className="col-md-4 offset-md-2" style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold" }}>Departemen</p>
                                    <p className="col-md-4 offset-md-2" style={{ fontSize: "0.9em" }}>{data.department}</p>
                                </div>
                                <div className="row mb-2">
                                    <p className="col-md-4 offset-md-2" style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold" }}>Sub Departemen</p>
                                    <p className="col-md-4 offset-md-2" style={{ fontSize: "0.9em" }}>{data.subDepartment}</p>
                                </div>
                                <div className="row mb-2">
                                    <p className="col-md-4 offset-md-2" style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold" }}>Jabatan</p>
                                    <p className="col-md-4 offset-md-2" style={{ fontSize: "0.9em" }}>{data.position}</p>
                                </div>
                                <div className="row mb-2">
                                    <p className="col-md-4 offset-md-2" style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold" }}>Tempat Lahir</p>
                                    <p className="col-md-4 offset-md-2" style={{ fontSize: "0.9em" }}>{data.birth.city}</p>
                                </div>
                                <div className="row mb-2">
                                    <p className="col-md-4 offset-md-2" style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold" }}>Tanggal Lahir</p>
                                    <p className="col-md-4 offset-md-2" style={{ fontSize: "0.9em" }}>{data.birth.dob}</p>
                                </div>
                                <div className="row mb-2">
                                    <p className="col-md-4 offset-md-2" style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold" }}>Usia</p>
                                    <p className="col-md-4 offset-md-2" style={{ fontSize: "0.9em" }}>{data.age}</p>
                                </div>
                                <div className="row mb-2">
                                    <p className="col-md-4 offset-md-2" style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold" }}>Status Perkawinan</p>
                                    <p className="col-md-4 offset-md-2" style={{ fontSize: "0.9em" }}>{data.maritalStatus}</p>
                                </div>
                                <div className="row mb-2">
                                    <p className="col-md-4 offset-md-2" style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold" }}>Telepon</p>
                                    <p className="col-md-4 offset-md-2" style={{ fontSize: "0.9em" }}>{data.telephone}</p>
                                </div>
                                <div className="row mb-2">
                                    <p className="col-md-4 offset-md-2" style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold" }}>Email</p>
                                    <p className="col-md-4 offset-md-2" style={{ fontSize: "0.9em" }}>{data.email}</p>
                                </div>
                                <hr />
                                <div className="row mb-2">
                                    <p className="col-md-4 offset-md-2" style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold" }}>Alamat</p>
                                    <p className="col-md-4 offset-md-2" style={{ fontSize: "0.9em" }}>{data.address}</p>
                                </div>
                                <div className="row mb-2">
                                    <p className="col-md-4 offset-md-2" style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold" }}>Sektor</p>
                                    <p className="col-md-4 offset-md-2" style={{ fontSize: "0.9em" }}>{data.sector}</p>
                                </div>
                                <div className="row mb-2">
                                    <p className="col-md-4 offset-md-2" style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold" }}>Wilayah</p>
                                    <p className="col-md-4 offset-md-2" style={{ fontSize: "0.9em" }}>{data.wilayah || "-"}</p>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        this.state.selected === "absence" &&
                        <div className="container mt-5">
                            <div className="row">
                                <div className="col-md-3">
                                    <select class="form-control input-c">
                                        <option>December</option>
                                        <option>Januari</option>
                                        <option>Februari</option>
                                        <option>Maret</option>
                                    </select>
                                </div>
                                <div className="col-md-3">
                                    <select class="form-control input-c">
                                        <option>2019</option>
                                        <option>2018</option>
                                        <option>2017</option>
                                        <option>2016</option>
                                    </select>
                                </div>
                                <div className="col-md-3">
                                    
                                    <button className="md-btn md-raised m-b-sm kr-color px-4" style={{ borderRadius: 5 }}>
                                        <div style={{ color: "white" }}>
                                            APPLY
                                        </div>
                                    </button>
                                </div>
                            </div>

                            <div className="table-responsive mt-5">
                                <table className="table" style={{ borderRadius: "10px"}}>
                                    <thead>
                                    {this.renderTableHead()}
                                    </thead>
                                    <tbody>
                                    { 
                                        this.state.isLoading 
                                        ? 
                                        <React.Fragment>
                                            <LoadingTableSponsor column={5} />
                                            <LoadingTableSponsor column={5} />
                                            <LoadingTableSponsor column={5} />
                                            <LoadingTableSponsor column={5} />
                                        </React.Fragment>
                                        : this.state.data.length == 0 
                                        ? 
                                        <tr>
                                            <td className="text-center" colSpan="9">
                                            <p>No data available!</p>
                                            </td>
                                        </tr>
                                        :
                                        <React.Fragment>
                                            
                                            <tr>
                                                <td>21/11/2019</td>
                                                <td>Pagi</td>
                                                <td>09:00</td>
                                                <td>17:00</td>
                                                <td>08:30</td>
                                                <td>16:30</td>
                                            </tr>
                                            <tr>
                                                <td>21/11/2019</td>
                                                <td>Pagi</td>
                                                <td>09:00</td>
                                                <td>17:00</td>
                                                <td>08:30</td>
                                                <td>16:30</td>
                                            </tr>
                                            <tr>
                                                <td>21/11/2019</td>
                                                <td>Pagi</td>
                                                <td>09:00</td>
                                                <td>17:00</td>
                                                <td>08:30</td>
                                                <td>16:30</td>
                                            </tr>
                                            <tr>
                                                <td>21/11/2019</td>
                                                <td>Pagi</td>
                                                <td>09:00</td>
                                                <td>17:00</td>
                                                <td>08:30</td>
                                                <td>16:30</td>
                                            </tr>
                                            <tr>
                                                <td>21/11/2019</td>
                                                <td>Pagi</td>
                                                <td>09:00</td>
                                                <td>17:00</td>
                                                <td>08:30</td>
                                                <td>16:30</td>
                                            </tr>
                                        </React.Fragment>
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        uid: state.firebase.auth.uid,
        profile: state.firebase.profile,
        region: state.region.toLowerCase(),
    }
}

export default compose(
    connect(mapStateToProps),
    withFirestore,
)(UpdateScreen)
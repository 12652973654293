import React, { Component } from 'react'
import moment from 'moment-timezone'
import axios from 'axios'

import DataTable from './DataTable'
import DownloadReport from '../../../../components/DownloadReport'
import DatePicker from '../../../../components/DatePicker'
import { withFirestore } from 'react-redux-firebase'
import { getAllDays } from '../../../../helpers/common'


class TicketRevenueReport extends Component {
    state = {
        fileName: "revenue4kr-report-data",
        dataTicket: [],
        customFields: [],
        startDate: moment(),
        endDate: moment(),
        showDownload: false,
        loading: true,
    }

    getRevenueTickets = async () => {
        const dataTicket = []
        const snapshots = await this.props.firestore.get({
            collectionGroup: 'revenueTickets',
            storeAs: 'all-revenueTickets',
            orderBy: ['timestamp', 'asc'],
            where: [
                ['timestamp', '>=', moment(this.state.startDate).tz("Asia/Jakarta").startOf('day').toDate()],
                ['timestamp', '<=', moment(this.state.endDate).tz("Asia/Jakarta").startOf('day').toDate()]
            ]
        });            
        snapshots.forEach(element => {
            const { daily } = element.data()
            dataTicket.push({
                id: element.id,
                region: element.ref.parent.parent.id,
                date_transaction: moment(element.data().timestamp.toDate()),
                qty: daily.total_qty,
                amount: daily.total_amount
            })
        });
        console.log(dataTicket)
        return dataTicket
    }

    filterDataByRegion = (days, dataTicket) => {
        let newData = []
        for (let j = 0; j < days.length; j++) {
            const day = days[j];
            function isSameDate(item) {                  
                // return moment(item.date_transaction, "YYYY-MM-DD").isSame(moment(day, "YYYY-MM-DD"))
                return moment(item.date_transaction, "YYYY-MM-DD").format("YYYY-MM-DD") == day
            }
            let objectData = {
                id: day,
                date_transaction: moment(day).format("DD MMM YYYY"),
                day: moment(day, "YYYY-MM-DD").format("dddd"),
                bogor: {
                    qty: 0,
                    amount: 0,
                },
                cibodas: {
                    qty: 0,
                    amount: 0,
                },
                purwodadi: {
                    qty: 0,
                    amount: 0,
                },
                bali: {
                    qty: 0,
                    amount: 0,
                },
                total_amount: 0,
                total_qty: 0,
            }
            for (let j = 0; j < dataTicket.length; j++) {
                const data = dataTicket[j];
                if (isSameDate(data)) {
                    objectData.total_amount += data.amount
                    objectData.total_qty += data.qty

                    if (data.region === "bogor") {
                        objectData.bogor = {
                            qty: data.qty,
                            amount: data.amount
                        }
                    } else if (data.region === "cibodas") {
                        objectData.cibodas = {
                            qty: data.qty,
                            amount: data.amount
                        }
                    } else if (data.region === "purwodadi") {
                        objectData.purwodadi = {
                            qty: data.qty,
                            amount: data.amount
                        }
                    } else if (data.region === "bali") {
                        objectData.bali = {
                            qty: data.qty,
                            amount: data.amount
                        }
                    }
                }
            }

            newData.push(objectData)
        }
        return newData
    }

    fetchDataEvent = async () => {
        this.setState({ loading: true, dataTicket: [] })
        let days = getAllDays(this.state.startDate, this.state.endDate)

        try {

            let rawData = await this.getRevenueTickets()

            let dataTicket = this.filterDataByRegion(days, rawData)

            const customFields = [
                { label: 'Tanggal', value: 'date_transaction' },
                { label: 'Bogor Qty', value: 'bogor.qty' },
                { label: 'Bogor IDR', value: 'bogor.amount' },
                { label: 'Purwodadi Qty', value: 'purwodadi.qty' },
                { label: 'Purwodadi IDR', value: 'purwodadi.amount' },
                { label: 'Cibodas Qty', value: 'cibodas.qty' },
                { label: 'Cibodas IDR', value: 'cibodas.amount' },
                { label: 'Bali Qty', value: 'bali.qty' },
                { label: 'Bali IDR', value: 'bali.amount' },
                { label: 'Total Pengunjung', value: 'total_qty'},
                { label: 'Total', value: 'total_amount' },
            ]


            this.setState({
                dataTicket,
                customFields,
                loading: false
            })
        
        } catch (error) {
            console.error(error)
            this.setState({
                dataTicket: [],
                loading: false
            })
        }
    }


    onDatesChange = ({ startDate, endDate }) => {
        this.setState({ startDate, endDate }, () => this.fetchDataEvent())
    }

    componentDidMount() {
        this.fetchDataEvent();
    }
    render() {
        return (
            <React.Fragment>
                <div className="box-header px-5 mt-3" style={{ backgroundColor: "#EFF2F7" }}>
                    <h3 style={{ color: "#3B4859" }}>REVENUE (LAPORAN)</h3>
                </div>
                <div className="container-fluid px-5 spacing-resp">
                    <div className="row mb-4">
                        <div className="col-lg-4 col-md-6 mt-5">
                            <div className="row">
                                <div className="col-5"><h6 style={{ fontSize: "0.8rem", color: "#3B4859" }}>Dari</h6></div>
                                <div className="col-5"><h6 style={{ fontSize: "0.8rem", color: "#3B4859" }}>Sampai</h6></div>
                            </div>
                            <DatePicker 
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                onDatesChange={this.onDatesChange} 
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 d-flex align-items-end mt-5">
                            <DownloadReport
                                data={this.state.dataTicket}
                                fields={this.state.customFields}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                fileName={this.state.fileName}
                            />
                        </div>
                    </div>

                    <div className="py-3 mt-4">
                        <div className="box p-3 mb-0" style={{ borderRadius: 10 }}>
                            <div className="table-responsive">
                                <DataTable 
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    data={this.state.dataTicket} 
                                    loading={this.state.loading} 
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withFirestore(TicketRevenueReport)
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withFirestore } from 'react-redux-firebase'
import { Modal, Button } from 'react-bootstrap'

import { SRLWrapper } from "simple-react-lightbox";
import html2canvas from 'html2canvas'
import jsPdf from 'jspdf'
import moment from 'moment'
 
import LoadingScreen from '../../components/LoadingScreen'
import ThumbnailGallery from '../../components/Form/ThumbnailGallery'

const collection = "region"

class DetailScreen extends Component {
  state = {
    data: {
      taskList: [],
      description: "",
      images: [],
      date: ""
    },
    isDownload: false,
    canEdit: false,
    collection: "region",
    subcollection: "maintenance",
    loading: true,
    errorInfo: false,
  }

  setCanEdit = (state) => this.setState({ canEdit: state })

  gotoUpdatePage = () => {
    const { sessionId } = this.props.match.params;
    this.props.history.push(`/dashboard/maintenance/${sessionId}/update`)
  }

  componentDidMount() {
    this.fetchData();
  }

  savePdf = async () => {
    this.setState({ isDownload: true })
    const domElement = document.getElementById('detail-page')
    try {

      const canvas = await html2canvas(domElement, { 
        onclone: (document) => {
          document.getElementById('detail-page').style.backgroundColor = '#ffff';
          if (this.state.canEdit) document.getElementById('edit-button').hidden = true;
          document.getElementById('download-button').style.visibility = 'hidden';
        },
        scale: 2,
      });
      const img = await canvas.toDataURL('image/png')

      const pdf = new jsPdf({
        compress: true
      })  
      const imgProps = pdf.getImageProperties(img);
      const width = pdf.internal.pageSize.getWidth();
      const height = (imgProps.height * width) / imgProps.width;

      pdf.addImage(img, 'JPEG', 0, 0, width, height, undefined, 'FAST')
      // setTimeout(() => {
        pdf.save(`report-kegiatan-harian-${moment().format("DD MMMM YYYY")}.pdf`)
        this.setState({ isDownload: false })
      // }, 4000);
    } catch (error) {
      console.error(error)
      this.setState({ isDownload: false })
    }
  }

  fetchData = async () => {
    try {
      const { sessionId } = this.props.match.params;
      const snapshot = await this.props.firestore.get({
        collection: collection,
        doc: this.props.region,
        subcollections: [{
          collection: this.state.subcollection,
          doc: sessionId
        }],
      })

      if (!snapshot.exists) {
        this.setState({ loading: false })
        return;
      }

      const {
        images = [],
        date,
        ...restData
      } = snapshot.data()

      let imageUrls = images.map((e) => e.url)

      const toDataURL = url => fetch(url)
        .then(response => response.blob())
        .then(blob => new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onloadend = () => resolve(reader.result)
          reader.onerror = reject
          reader.readAsDataURL(blob)
        })
        .then((value) => Promise.resolve(value)))
      let imgBase64 = []
      for (let i = 0; i < imageUrls.length; i++) {
        const url = imageUrls[i];
        imgBase64.push(await toDataURL(url))
      }

      // console.log('imgBase64', imgBase64)

      let data = {
        id: snapshot.id,
        images: imgBase64,
        imgBase64,
        date: date ? moment(date.toDate()).format("DD MMMM YYYY") : "-",
        ...restData
      }
      const dataDate = moment(date.toDate()).hour(23).minute(59).second(59).toDate()
      const currentDate = moment().hour(0).minute(0).second(0).toDate();
      if (currentDate > dataDate) {
        this.setCanEdit(false)
      } else {
        this.setCanEdit(true)
      }

      this.setState({ 
        data: { 
          ...this.state.data, 
          ...data
        }, 
        loading: false 
      });
    } catch (error) {
      this.setState({ loading: false })
    }

  }

  goBack = () => {
    this.props.history.push(`/dashboard/maintenance`)
  }

  render() {
    if (!this.props.uid) return null
    if (this.state.loading) return <LoadingScreen />
    const { data } = this.state;
    return (
      <div id="detail-page">
        <div className="box-header px-5 mt-3" style={{ backgroundColor: "#EFF2F7" }}>
          <div className="row">
            <a onClick={this.goBack}><i class="fa fa-arrow-left" aria-hidden="true"></i></a>
            <h3 className="ml-3 mt-1" style={{ color: "#3B4859" }}>PERAWATAN</h3>
          </div>
        </div>
        
        <div className="box-body">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-md-10 box p-4" style={{ borderRadius: 5 }}>
                <div className="row">
                  <div className="col-md-4 d-flex align-items-center">
                    <h6
                      className="mb-0"
                      style={{ color: "#579264", fontWeight: "bold" }}
                    >
                      Tanggal
                    </h6>
                  </div>
                  <div className="col-md-4 d-flex align-items-center">
                    <h6 className="mb-0" style={{color: "#979D99"}}>{data.date}</h6>
                  </div>
                  
                  <div className="col-md-2 d-flex align-items-center">
                    {
                      this.state.isDownload !== true ?
                    <button
                      type="button"
                      id="download-button"
                      onClick={this.savePdf}
                      className="md-btn md-raised m-b-sm w-xs success"
                      style={{ width: "100%", backgroundColor: "#22313F", borderRadius: 5, fontWeight: "inherit", fontSize: "0.8em" }}>
                        {/* <i className="fa fa-download mr-1"></i> */}
                      Download
                    </button>
                   :
                    null
                  }
                    </div>
                </div>
              </div>
              <div className="col-md-2 align-items-center p-4 ">
                {
                  ((this.props.profile.token && this.props.profile.token.claims.admin === true) ||
                  (this.props.profile.token && this.props.profile.token.claims.department === "Konservasi")) &&
                  this.state.canEdit && this.state.isDownload !== true ?
                    <button
                      type="button"
                      id="edit-button"
                      onClick={this.gotoUpdatePage}
                      className="md-btn md-raised m-b-sm w-xs success"
                      style={{ width: "100%", backgroundColor: "#579264", borderRadius: 5, fontWeight: "inherit", fontSize: "0.8em" }}>
                      Edit
                    </button>
                   :
                    null
                  }
              </div>
            </div>
          </div>
          <div className="box p-4" style={{ borderRadius: 5 }}>
            <div className="col-sm-10">
            <div className="row">
              <div className="col-md-4 d-flex align-items-center">
                <h6
                  className="mb-0"
                  style={{ color: "#579264", fontWeight: "bold" }}
                >
                  Sector
                </h6>
              </div>
              <div className="col-md-8 d-flex align-items-center">
                <h6 className="mb-0" style={{color: "#979D99"}}>{data.sector}</h6>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-4 d-flex align-items-center">
                <h6
                  className="mb-0"
                  style={{ color: "#579264", fontWeight: "bold" }}
                >
                  Area
                </h6>
              </div>
              <div className="col-md-8 d-flex align-items-center">
                <h6 className="mb-0" style={{color: "#979D99"}}>{data.area}</h6>
              </div>
            </div>
          </div>
          <div className="col-md-2 d-flex align-items-center"></div>
          </div>

          <div className="col-sm-12 box" style={{ borderRadius: 5 }}>
            <div className="row mb-4">
              <div className="col-md-6">
                <div className="table-responsive">
                  <table className="table table-no-bordered m-a-0">
                    <thead style={{ color: "#579264" }}>
                      <tr>
                        <th scope="col">Daftar Pekerjaan</th>
                        <th scope="col">Keterangan</th>
                        <th scope="col">Karyawan</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        data.taskList.length > 0 ?
                          data.taskList.map((item) =>(
                            <tr>
                              <td>
                                <p>{item.value}</p>
                              </td>
                              <td>
                                <p>{item.description}</p>
                              </td>
                              <td>
                                <p>{item.employeeName}</p>
                              </td>
                            </tr>
                          ))
                          :
                          <React.Fragment>
                            <p>Tidak ada daftar pekerjaan.</p>
                            <lottie-player
                              src="https://assets6.lottiefiles.com/packages/lf20_QJMen2.json"  
                              background="transparent" 
                              speed="1"
                              style={{width: 50, height: 50}} loop autoplay
                            >
                            </lottie-player>
                          </React.Fragment>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <SRLWrapper>
            <div className="col-sm-12 box py-3" style={{ borderRadius: 5 }}>
              <div className="margin-top-40">
                <h6 style={{ color: '#579264' }}><b>Foto</b></h6>
                <div className="" id="inputGallery">
                  {
                    data.images && data.images.length === 0 ?
                      <p
                        style={{ color: "rgba(156, 151, 151, 0.87)" }}
                        className="text-center mt-3"
                      >
                        Tidak ada foto.
                    </p>
                      :
                      data.images.map((file) => <ThumbnailGallery file={file} key={file.name ? file.name : file} />)
                  }
                </div>
              </div>
            </div>
          </SRLWrapper>

          <div className="col-sm-12 box py-3" style={{ borderRadius: 5 }}>
            <div className="mb-4">
              <h6 style={{ color: "#579264", fontWeight: "bold" }}>
                Catatan
              </h6>
            </div>
              <p style={{ fontSize: "0.9em" }}>{data.description}</p>
            </div>
        </div>
        <LoadingModal isDownload={this.state.isDownload} />
      </div>
    )
  }
}

function LoadingModal(props) {
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  React.useEffect(() => {
    setShow(props.isDownload)
  }, [props.isDownload])
  // console.log('show', show)
  return (
    show ?
      <>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body><h2 className="text-center">Loading...</h2></Modal.Body>
      </Modal>
      </>
    :
    null
  );
}


const mapStateToProps = state => {
  return {
    uid: state.firebase.auth.uid,
    profile: state.firebase.profile,
    region: state.region.toLowerCase()
  }
}

export default compose(
  connect(mapStateToProps),
  withFirestore,
)(DetailScreen)
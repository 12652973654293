import React, { useState, useEffect } from "react"
import { Button, Modal } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import { SingleDatePicker } from 'react-dates';

import { connect } from 'react-redux'
import { compose } from 'redux'
import { withFirestore } from 'react-redux-firebase'
import { getNewAccessToken } from "../../utils/firebaseAdmin";
const Delete = (props) => {

    const [show, setShow] = useState(false);
    const [deleteRef, setDeleteRef] = useState(null);
    const [loading, setLoading] = useState(false);
    const [uid, setUid] = useState(props.id || null);
    const history = useHistory()

    const handleShow = () => {
        setShow(true);
        setDeleteRef(props.deleteRef)
    }
    const handleClose = () => setShow(false);
    const getDataParams = (data) => {
      const dataParams = Object.keys(data).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
      }).join('&');
      return dataParams;
    }
    async function deleteData() {
        setLoading(true)
        const formData = {
            uid: uid
        }
        try {
            
        // console.log('formData', formData)
        const userParams = getDataParams(formData)
        // const { accessToken } = props.auth.stsTokenManager;

        // console.log('accessToken', accessToken)

            
        //get access token
        const { refreshToken } = props.auth.stsTokenManager;
        // console.log('refreshToken', refreshToken)
        const accessToken = await getNewAccessToken(refreshToken);
        // end get access token
  
        const url = 'https://us-central1-krmos-sandbox.cloudfunctions.net/employee/delete';
  
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${accessToken}`,
          },
          body: userParams,
        })

        console.log("data has been deleted!", props.id)
        window.location.reload()
        handleClose()
        } catch (error) {
            setLoading(false)
            window.location.reload()
            handleClose()
        }
    };
    return (
        <React.Fragment>
            <a onClick={handleShow} className="active">
                <button className="btn danger" style={{ fontSize: "0.8em" }}>
                    <i className="fa fa-trash"></i>
                </button>
            </a>

            <Modal aria-labelledby="contained-modal-title-vcenter" centered show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>
                            <p className="mb-0">Are you sure you want to delete this data?</p>
                    </Modal.Title>
                </Modal.Header>
                {
                    loading == false ?
                        <>
                            <Modal.Body className="text-center">
                                <lottie-player
                                    src="https://assets4.lottiefiles.com/packages/lf20_VZWGZs.json"  
                                    background="transparent"  
                                    speed="1"  
                                    style={{width: 200, height: 200}} loop autoplay 
                                >
                                </lottie-player>
                            </Modal.Body>
                            <Modal.Footer className="justify-content-center">
                                <button
                                    type="button"
                                    onClick={deleteData}
                                    disabled={loading}
                                    className="btn white text-white w-100 m-3"
                                    style={{ backgroundColor: "#D4252A", fontSize: "0.8em", borderRadius: 0 }}
                                >
                                    Delete
                                </button>
                                <button
                                    type="button"
                                    onClick={handleClose}
                                    className="btn white w-100 m-3"
                                    style={{ fontSize: "0.8em", borderRadius: 0 }}
                                >
                                    Cancel
                                </button>
                            </Modal.Footer>
                        </>
                    :
                    <div className="w-100 d-flex justify-content-center">
                        <lottie-player
                            src="https://assets7.lottiefiles.com/datafiles/YpOCRxpifd1Xs1n/data.json"  
                            background="transparent"  
                            speed="3"  
                            style={{ width: 300, height: 300 }}
                            loop autoplay >
                        </lottie-player>
                    </div>
                }
            </Modal>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
  return {
    uid: state.firebase.auth.uid,
    auth: state.firebase.auth,
    profile: state.firebase.profile
  }
}

export default compose(
  connect(mapStateToProps),
  withFirestore,
)(Delete)
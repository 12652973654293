import React from 'react'
import DataList from './DataList'

const renderTableHead = () => (
    <tr className="text-kr">
      <th>Tanggal</th>
      <th>Kegiatan</th>
      <th>Catatan</th>
      <th></th>
    </tr>
)
const DailyReport = (props) => (                  
    <div className="box p-4" style={{ borderRadius: 5 }}>
        <div className="table-responsive">
        <table className="table table-striped b-t">
            <thead>
                {renderTableHead()}
            </thead>
            <tbody>
            
            { props.loading &&
                <p className="ml-2">Loading...</p>
            }
            { props.loading === false && props.data.length == 0 && 
                <p className="ml-2">No Data.</p>
            }
            <DataList 
                data={props.data}
                handleAcceptInfo={() => props.handleAcceptInfo()}
                showErrorInfo={() => props.showErrorInfo()} 
            />
            </tbody>
        </table>
        </div>
    </div>
)

export default DailyReport
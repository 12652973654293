import React from "react"
import NumberFormat from 'react-number-format'

const TableItem = (props) => {
    return (
        <tr key={props.item.id}>
             <td className="text-center">{props.item.date}</td>
             <td className="text-center">
                <NumberFormat 
                    value={props.item.ticket} 
                    displayType={'text'} 
                    prefix={'Rp'}
                    suffix={',00'}
                    thousandSeparator={true}
                    defaultValue="0"
                />
            </td>
             <td className="text-center">
                <NumberFormat 
                    value={props.item.fnb} 
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",00"} 
                    defaultValue="0" 
                />
            </td>
             <td className="text-center">
                <NumberFormat 
                    value={props.item.facilitiesRent} 
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",00"} 
                    defaultValue="0" 
                />
            </td>
             <td className="text-center">
                <NumberFormat 
                    value={props.item.tourPack} 
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",00"} 
                    defaultValue="0" 
                />
            </td>
             <td className="text-center">
                <NumberFormat 
                    value={props.item.merchandiseProduct} 
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",00"} 
                    defaultValue="0" 
                />
            </td>
             <td className="text-center">
                <NumberFormat 
                    value={props.item.membership} 
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",00"} 
                    defaultValue="0" 
                />
            </td>
             <td className="text-center">
                <NumberFormat 
                    value={props.item.total} 
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",00"} 
                    defaultValue="0" 
                />
            </td>
        </tr>
    )
}

export default TableItem
import React from 'react'

import Avatar from './Avatar'

const Header = () => (
  <div className="navbar navbar-toggleable-sm flex-row align-items-center" style={{ backgroundColor: "white" }}>

    <a data-toggle="modal" data-target="#aside" className="hidden-lg-up mr-3">
      <i className="material-icons" style={{ color: "black" }}>&#xe5d2;</i>
    </a>
  
    <div className="mb-0 h5 no-wrap" id="pageTitle"></div>
    
    <ul className="nav navbar-nav ml-auto flex-row">
      <Avatar />

      <li className="nav-item hidden-md-up">
        <a className="nav-link pl-2" data-toggle="collapse" data-target="#collapse">
          <i className="material-icons">&#xe5d4;</i>
        </a>
      </li>
    </ul>
  </div>
)

export default Header
import React, { PureComponent } from 'react';
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList, ResponsiveContainer,
} from 'recharts';
import moment from 'moment'
import { connect } from 'react-redux'
import axios from 'axios'
import { getFormData } from '../../../../helpers/common';
import { Label } from 'react-bootstrap';
import { fetchDataReport } from '../../../../utils/report';

const renderCustomizedLabel = (props) => {
  const {
    x, y, width, height, value,
  } = props;
  const radius = 10;

  return (
    <g>
      {/* <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" /> */}
      {/* <text x={x + width / 2} y={y - radius} fill="#8998AC" textAnchor="middle" dominantBaseline="middle">
        {value.split(' ')[1]}
      </text> */}
      <text>{value}</text>
    </g>
  );
};

const renderCustomXLabel = (props) => {
  const {
    x, y, width, height, value,
  } = props;
  return (
    <g>
      <text>{value}</text>
    </g>
  );
};

class PosBarChart extends PureComponent {
  state = {
    loading: true,
    startDate: moment().startOf('year'),
    endDate: moment(),
    dataTicket: []
  }

  fetchData = async (props) => {
    this.setState({ loading: true, dataTicket: [] })

    const data = {
      region: props.region.toLowerCase(),
      date_start: moment(props.date).format("YYYY-MM-DD").toString(),
      date_end: moment(props.date).format("YYYY-MM-DD").toString()
    }
    
    // const formData = getFormData(data)

    if (props.region != "All Region") {
      this.fetchDataSelectedRegion(data)
    }
  }

  fetchDataSelectedRegion = async (data) => {
    
    const result = await fetchDataReport(data)
    // console.log('result', result)

    this.setState({
      dataTicket: result.device_transactions,
      loading: false
    })
  }

  // fetchDataAllRegion = async () => {
  //   for (let i = 0; i < this.props.regionList.length; i++) {
  //     const element = this.props.regionList[i];
      
  //   }
  //   const 
  // }

  // fetchDataSelectedRegion = async (formData) => {
  //   const { accessToken } = this.props.auth.stsTokenManager;
  //   try {
  //     const response = await axios({
  //       method: 'POST',
  //       headers: {
  //         'Authorization': 'Bearer ' + accessToken
  //       },
  //       url: 'https://us-central1-krmos-sandbox.cloudfunctions.net/revenue/api/device',
  //       data: formData
  //     })
  //     const { data } = response
  //     let dataTicket = []
  //     dataTicket = data.data.device_transactions

  //     if (dataTicket == null) {
  //       this.setState({
  //         dataTicket: [],
  //         loading: false
  //       })
  //       return 0;
  //     }

  //     let dataDevice = []
      
  //     dataTicket.forEach((element) => {
  //       const { tarif_transaction, ...restData } = element
  //       dataDevice.push(restData)
  //     })

  //     this.setState({
  //       dataTicket: dataDevice,
  //       loading: false
  //     })
  //   } catch (error) {
  //     console.error(error)
  //     this.setState({
  //       dataTicket: [],
  //       loading: false
  //     })
  //   }
  // }

  // fetchDataAllRegion = async (formData) => {
  //   const { accessToken } = this.props.auth.stsTokenManager;
  //   try {
  //     const response = await axios({
  //       method: 'POST',
  //       headers: {
  //         'Authorization': 'Bearer ' + accessToken
  //       },
  //       url: 'https://us-central1-krmos-sandbox.cloudfunctions.net/revenue/api/device/all',
  //       data: formData
  //     })
  //     const { data } = response

  //     let dataTicket = data

  //     if (dataTicket == null) {
  //       this.setState({
  //         dataTicket: [],
  //         loading: false
  //       })
  //       return 0;
  //     }

  //     this.setState({
  //       dataTicket,
  //       loading: false
  //     })
  //   } catch (error) {
  //     console.error(error)
  //     this.setState({
  //       dataTicket: [],
  //       loading: false
  //     })
  //   }
  // }

  componentDidMount() {
    this.fetchData(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log('nextProps', nextProps)
    if (nextProps.region !== this.props.region || nextProps.date !== this.props.date) {
      this.fetchData(nextProps)
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="ph-item p-0 mb-0 border-0">
          <div className="ph-picture mb-0" style={{ height: "500px" }}>
            <div className="ph-col-12"></div>
          </div>
        </div>
      )
    }
    return (
      <ResponsiveContainer maxHeight={500} aspect={4.0 / 3.0}>
        <BarChart
          data={this.state.dataTicket}
        >
          <CartesianGrid strokeDasharray="3 3" />
          {/* <XAxis
            dataKey="device"
            interval={0}
            tickMargin="10"
            padding="50"
          /> */}
          <XAxis dataKey="device" interval={0} angle={-30} />
          <YAxis type="number" domain={[0, 'dataMax + 1000']} label={{ value: 'Tiket Terjual', fontWeight: "bold", angle: -90, position: 'insideLeft' }} />
          <Tooltip cursor={{fill: 'transparent'}}/>
          {/* <Legend /> */}
          <Bar dataKey="qty" fill="#622C7C" minPointSize={5}>
            <LabelList dataKey="qty" content={renderCustomizedLabel} />
          </Bar>
          {/* <Bar dataKey="uv" fill="#82ca9d" minPointSize={10} /> */}
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

const mapStateToProps = state => ({ auth: state.firebase.auth })

export default connect(mapStateToProps)(PosBarChart)
import React from "react"
import NumberFormat from 'react-number-format'

const TableItem = (props) => {
    return (
        <tr key={props.item.id} className="text-center">
            <td>{props.item.date_transaction}</td>
            <td>{props.item.bogor.qty}</td>
            <td>
                <NumberFormat 
                    value={props.item.bogor.amount}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>
            <td>{props.item.purwodadi.qty}</td>
            <td>
                <NumberFormat 
                    value={props.item.purwodadi.amount}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>
            <td>{props.item.cibodas.qty}</td>
            <td>
                <NumberFormat 
                    value={props.item.cibodas.amount}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>
            <td>{props.item.bali.qty}</td>
            <td>
                <NumberFormat 
                    value={props.item.bali.amount}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>
            <td>{props.item.total_qty}</td>
            <td>
                <NumberFormat 
                    value={props.item.total_amount}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>
            {/* <td>
                <Link as="button" to="/dashboard/revenue/ticket/report/a01" type="button" class="btn btn-success" style={{backgroundColor: "#579264", fontSize: "0.7em"}}>Detail</Link>
            </td> */}
        </tr>
    )
}

export default TableItem
import { createStore } from 'redux'
import { createFirestoreInstance } from 'redux-firestore' // <- needed if using firestore


import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'

import firebaseConfig from '../firebaseConfig.js'
import { initialState, rootReducer } from './reducers'

firebase.initializeApp(firebaseConfig)
firebase.firestore()

// const enhancers = [
//   reduxFirestore(firebase),
//   reactReduxFirebase(firebase, {
//     userProfile: 'users',
//     useFirestoreForProfile: true,
//   })
// ]
// firebase.functions().useFunctionsEmulator('http://localhost:5001')
const rrfConfig = {
  // userProfile: 'users',
  userProfile: 'region/bogor/employee',
  useFirestoreForProfile: true,
  enableClaims: true,
}

const store = createStore(rootReducer, initialState)

export const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance // <- needed if using firestore
}

export default store
import React from 'react'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import moment from 'moment'

import SubmitButton from '../../../../components/SubmitButton'
import LoaderModal from '../../../../components/LoaderModal'
// import validate from './validate'
// import DatePicker from '../../components/DatePicker';
import TextArea from '../../../../components/Form/TextAreaInput';

let UpdateForm = ({ handleSubmit, pristine, submitting, valid, fields, form, dataAnimal, date, ...otherProps }) => {
  return (
    <React.Fragment>
      <div className="col-12" style={{ marginTop: 15 }}>
        <div className="" style={{ borderRadius: 5 }}>
          <div className="box-header">
            <h2 className="m-2" style={{ fontWeight: "bold" }}>UPDATE DAILY REPORT</h2>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="col-sm-12">
          <div className="p-5" style={{ borderRadius: 5 }}>
            
              <div className="p-4 aino-add-box">
                <div className="col-12 px-0">
                  <Field name="description" label="Report your activity" 
                  placeholder="Report your activity" component={TextArea} rows={4} /> 
                </div>
              </div>
            
            <div className="col-12 text-right mt-2">
              <div className="col-lg-2 col-xs-12 px-0 float-right">
                <SubmitButton disabled={!valid || submitting} />
              </div>
              <LoaderModal submitting={submitting} />
            </div>

          </div>
        </div>
      </form>
    </React.Fragment>
  )
}

UpdateForm = reduxForm({
  form: 'addDailyReportForm',
  // validate,
  enableReinitialize: true,
})(UpdateForm)

export default UpdateForm



{/* <div className="col-12 px-0">
<label for="basic-url green" style={{ color: "#159588", fontWeight: "bold" }}>
  Date
</label>
<div className="col-12 px-0">
  <p className="mb-3">
    {moment().format('DD MMMM YYYY')}
    </p>
  {/* <Field name="date" component={DatePicker} placeholder="Periode" className="form-control" disabled={true} value={date}/> */}
// </div>
// </div>
// </div>
// </div> */}
import React from 'react'
import { Modal } from 'react-bootstrap'
const LoaderModal = ({ submitting }) => {
    return (
        submitting === true &&
            <Modal show={submitting} onHide={() => {}}>
                <Modal.Header>
                {/* <Modal.Title>Modal heading</Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                <img
                    src={require("../assets/icon/spinner.gif")}
                    alt="Loading"
                    className="mx-auto d-block"
                />
                </Modal.Body>
                <Modal.Footer>
                {/* <Button variant="secondary" onClick={() => {}}>
                    Close
                </Button>
                <Button variant="primary" onClick={() => {}}>
                    Save Changes
                </Button> */}
                </Modal.Footer>
            </Modal>
    );
}

export default LoaderModal;
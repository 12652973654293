import React from "react";

const LoadingTableSponsor = () => (
    <tr>
        {/* <td><input type="checkbox"></input></td> */}
        <td>
            <div className="ph-item p-0 mb-0 border-0" style={{ height: 50 }}>
                <div className="ph-picture mb-0">
                    <div className="ph-col-12"></div>
                </div>
            </div>
        </td>
        <td>
            <div className="ph-item p-0 mb-0 border-0" style={{ height: 50 }}>
                <div className="ph-picture mb-0">
                    <div className="ph-col-12"></div>
                </div>
            </div>
        </td>
        <td>
            <div className="ph-item p-0 mb-0 border-0" style={{ height: 50 }}>
                <div className="ph-picture mb-0">
                    <div className="ph-col-12"></div>
                </div>
            </div>
        </td>
        <td>
            <div className="ph-item p-0 mb-0 border-0" style={{ height: 50 }}>
                <div className="ph-picture mb-0">
                    <div className="ph-col-12"></div>
                </div>
            </div>
        </td>
        <td>
            <div className="ph-item p-0 mb-0 border-0" style={{ height: 50 }}>
                <div className="ph-picture mb-0">
                    <div className="ph-col-12"></div>
                </div>
            </div>
        </td>
        <td>
            <div className="ph-item p-0 mb-0 border-0" style={{ height: 50 }}>
                <div className="ph-picture mb-0">
                    <div className="ph-col-12"></div>
                </div>
            </div>
        </td>
        <td>
            <div className="ph-item p-0 mb-0 border-0" style={{ height: 50 }}>
                <div className="ph-picture mb-0">
                    <div className="ph-col-12"></div>
                </div>
            </div>
        </td>
        <td>
            <div className="ph-item p-0 mb-0 border-0" style={{ height: 50 }}>
                <div className="ph-picture mb-0">
                    <div className="ph-col-12"></div>
                </div>
            </div>
        </td>
        <td>
            <div className="ph-item p-0 mb-0 border-0" style={{ height: 50 }}>
                <div className="ph-picture mb-0">
                    <div className="ph-col-12"></div>
                </div>
            </div>
        </td>
    </tr>
);

export default LoadingTableSponsor;
import firebase from 'firebase'
import moment from 'moment'

export async function fetchDataReport(data) {
    
    const snapshots = await firebase.firestore().collection("region")
        .doc(data.region.toLowerCase())
        .collection("deviceTransactions")
        .where("timestamp", "==", moment(data.date_start, "YYYY-MM-DD").tz("Asia/Jakarta").toDate())
        .get();

        if (snapshots.empty) {
            return null
        }
        
        const snapshotData = {
            id: snapshots.docs[0].id,
            ...snapshots.docs[0].data()
        }

        return snapshotData
        
}

export async function fetchDataPerReport(data) {
    
    const snapshots = await firebase.firestore().collection("region")
        .doc(data.region.toLowerCase())
        .collection("deviceTransactions")
        .get();

        if (snapshots.empty) {
            return null
        }
        const dataTicket = []
        // snapshots.forEach(element => {
        //     element.data().device_transactions.push({
        //         id: element.id,
        //         ...element.data()
        //     })
        // });
        for (let i = 0; i < snapshots.docs.length; i++) {
            const snap = snapshots.docs[i];
            if (snap.data().device_transactions) {
                
                for (let j = 0; j < snap.data().device_transactions.length; j++) {
                    const element = snap.data().device_transactions[j];
                    const { tarif_transaction, ...restData } = element
                    dataTicket.push(restData)
                }
            }
            
        }
        return dataTicket
        
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase'
import moment from 'moment'
import { SingleDatePicker, isInclusivelyAfterDay } from 'react-dates';

import NumberFormat from 'react-number-format'
import BarChartTicket from './BarChartTicket';
import BarChartFnB from './BarChartFnB';
import BarChartNonFnBFasilitas from './BarChartNonFnBFasilitas';
import { fetchRevenueAllRegion } from '../../utils/revenue'
import BarChartNonFnBMerch from './BarChartNonFnBMerch'

const initialData = {
  bogor: 0,
  cibodas: 0,
  purwodadi: 0,
  bali: 0
}

const initialDataRegion = {
  qty: 0,
  amount: 0,
}

const initialState = {
  date: moment(),
  dataTicket: {
    bogor: initialDataRegion,
    cibodas: initialDataRegion,
    purwodadi: initialDataRegion,
    bali: initialDataRegion,
  },
  dataQty: [initialData],
  amounts: [initialData],
  totalVisitor: 0,
  ticketSalesTotal: 0,
  months: 1,
  displayFormat: "DD/MM/YYYY",
  loading: true
}

class DashboardScreen extends Component {
  state = initialState

  setTotalVisitor = (totalVisitor) => this.setState({ totalVisitor })
  setDate = date => this.setState({ date }, () => this.fetchDataEvent())
  setOutsideRange = day => isInclusivelyAfterDay(day, moment().add(1, 'days'))
  setFocusChange = ({ focused }) => this.setState({ focused })

  componentDidMount() {
    this.fetchDataEvent();
  }

  fetchDataEvent = async () => {
    this.setState({
      loading: true,
      amounts: [initialData],
      dataQty: [initialData],
      dataTicket: {
        bogor: initialDataRegion,
        cibodas: initialDataRegion,
        purwodadi: initialDataRegion,
        bali: initialDataRegion
      }
    })

    try {

      let data = await fetchRevenueAllRegion(this.state.date)

      if (!data) {

        this.setState({
          dataTicket: {
            bogor: initialDataRegion,
            cibodas: initialDataRegion,
            purwodadi: initialDataRegion,
            bali: initialDataRegion,
          },
          dataQty: [initialData],
          amounts: [initialData],
          loading: false
        });
        return;
      }
      const { qty, amounts, tickets } = data

      this.setState({
        dataTicket: tickets,
        dataQty: qty,
        amounts,
        loading: false
      })

    } catch (error) {
      // console.error(error)
      this.setState({
        dataTicket: {},
        loading: false
      })
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="box-header px-5 mt-3" style={{ backgroundColor: "#EFF2F7" }}>
          <h3 style={{ color: "#3B4859" }}>DASHBOARD</h3>
        </div>
        <div className="container-fluid p-5 spacing-resp">
          <div className="col-md-3 px-0 mb-5">
            <SingleDatePicker
              numberOfMonths={this.state.months}
              withPortal
              displayFormat={this.state.displayFormat}
              isDayBlocked={() => false}
              isOutsideRange={() => false}
              enableOutsideDays
              date={this.state.date}
              onDateChange={this.setDate}
              focused={this.state.focused}
              isOutsideRange={this.setOutsideRange}
              onFocusChange={this.setFocusChange}
            />
          </div>

          <div className="col-12 py-4 box" style={{ borderRadius: 10 }}>
            <div className="col-12">
              <div className="box-header" style={{ borderBottom: "none !important" }}>
                <h2 style={{
                  color: "#3B4859",
                  fontWeight: 500,
                  fontSize: "20px",
                  lineHeight: "24px"
                }}>Tiket
                </h2>
              </div>
              <div className="row mt-4">
                <div className="col-lg-3 col-md-3 col-sm-12">
                  <div className="box p-a" style={{ borderRadius: 5, background: "#D7CABF", "boxShadow": "0px 2px 4px #EFF2F7", "borderRadius": "10px", color: "#ffff" }}>
                    <div className="container">
                      <div className="row">
                        <span className="text-sm ml-2" style={{ display: "flex", fontWeight: "bold", justifyContent: "center", alignItems: "center" }}>
                          BOGOR
                        </span>
                      </div>
                    </div>
                    <div className="clear">
                      <h4 className="m-a text-md">
                        {
                          this.state.loading ?
                            "loading..."
                            :
                            <NumberFormat
                              value={this.state.dataTicket.bogor.amount}
                              displayType='text'
                              thousandSeparator
                              prefix='Rp'
                              suffix=',-'
                              defaultValue='0'
                            />
                        }
                      </h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-12">
                  <div className="box p-a" style={{ borderRadius: 5, background: "#00ACD9", boxShadow: "0px 2px 4px #EFF2F7", borderRadius: "10px", color: "white" }}>
                    <div className="container">
                      <div className="row">
                        <span className="text-sm ml-2" style={{ display: "flex", fontWeight: "bold", justifyContent: "center", alignItems: "center" }}>
                          CIBODAS
                        </span>
                      </div>
                    </div>
                    <div className="clear">
                      <h4 className="m-a text-md">
                        {
                          this.state.loading ?
                            "loading..."
                            :
                            <NumberFormat
                              value={this.state.dataTicket.cibodas.amount}
                              displayType='text'
                              thousandSeparator
                              prefix='Rp'
                              suffix=',-'
                              defaultValue='0'
                            />
                        }</h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-12">
                  <div className="box p-a" style={{ borderRadius: 5, background: "#B3B731", boxShadow: "0px 2px 4px #EFF2F7", borderRadius: "10px", color: "white" }}>
                    <div className="container">
                      <div className="row">
                        <span className="text-sm ml-2" style={{ display: "flex", fontWeight: "bold", justifyContent: "center", alignItems: "center" }}>
                          PURWODADI
                        </span>
                      </div>
                    </div>
                    <div className="clear">
                      <h4 className="m-a text-md">
                        {
                          this.state.loading ?
                            "loading..."
                            :
                            <NumberFormat
                              value={this.state.dataTicket.purwodadi.amount}
                              displayType='text'
                              thousandSeparator
                              prefix='Rp'
                              suffix=',-'
                              defaultValue='0'
                            />
                        }</h4>
                    </div>
                  </div>
                </div>


                <div className="col-lg-3 col-md-3 col-sm-12">
                  <div className="box p-a" style={{ borderRadius: 5, background: "#E4A314", boxShadow: "0px 2px 4px #EFF2F7", borderRadius: "10px", color: "white" }}>
                    <div className="container">
                      <div className="row">
                        <span className="text-sm ml-2" style={{ display: "flex", fontWeight: "bold", justifyContent: "center", alignItems: "center" }}>
                          BALI
                        </span>
                      </div>
                    </div>
                    <div className="clear">
                      <h4 className="m-a text-md">
                        {
                          this.state.loading ?
                            "loading..."
                            :
                            <NumberFormat
                              value={this.state.dataTicket.bali.amount}
                              displayType='text'
                              thousandSeparator
                              prefix='Rp'
                              suffix=',-'
                              defaultValue='0'
                            />
                        }</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12">
              <BarChartTicket
                date={this.state.date}
                loading={this.state.loading}
                dataQty={this.state.dataQty}
                dataAmount={this.state.amounts}
              />
            </div>
          </div>

          <div className="col-12 py-4 box" style={{ borderRadius: 10 }}>
            

            <BarChartFnB date={this.state.date}/>
          </div>

          <div className="col-12 py-4 box" style={{ borderRadius: 10 }}>

              <BarChartNonFnBFasilitas date={this.state.date}/>
          </div>
          <div className="col-12 py-4 box" style={{ borderRadius: 10 }}>

              <BarChartNonFnBMerch date={this.state.date}/>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    region: state.region
  }
}

export default compose(
  connect(mapStateToProps),
  firebaseConnect(),
  firestoreConnect(),
)(DashboardScreen)
import React, { Component } from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label
} from 'recharts';
import { compose } from 'redux'
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase'
import moment from 'moment'
import Axios from 'axios';
import { connect } from 'react-redux'

const BarChartTicket = (props) => {
  if (props.loading) {
    return (
      <div className="ph-item p-0 mb-0 border-0">
        <div className="ph-picture mb-0" style={{ height: "500px" }}>
            <div className="ph-col-12"></div>
        </div>
      </div>
    )
  }
  return (
    <div className="row">
      <div className="col-md-6">
        {/* <div className="box-header" style={{ borderBottom: "none !important" }}>
          <h2 style={{
            color: "#3B4859",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "24px"
          }}>Tiket</h2>
          <small>Calculated in last 7 days</small>
        </div> */}
        <ResponsiveContainer maxHeight={500} aspect={4.0/3.0}>
          <BarChart
            data={props.dataQty}
            barSize={100}
            margin={{
              top: 35, 
              right: 30, 
              left: 20, 
              bottom: 5
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="timestamp">
              <Label value= "Jumlah Tiket" offset={0} position="insideBottom" />
            </XAxis>
            {/* <YAxis label={{ value: 'Jumlah Tiket', angle: -90, position: 'insideLeft' }} /> */}
            <Tooltip />
            <Legend />
            <Bar dataKey="bogor" fill="#D7CABF" name="Bogor" />
            <Bar dataKey="cibodas" fill="#00ACD9" name="Cibodas" />
            <Bar dataKey="purwodadi" fill="#B3B731" name="Purwodadi" />
            <Bar dataKey="bali" fill="#E4A314" name="Bali" />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className="col-md-6">
        {/* <div className="box-header" style={{ borderBottom: "none !important" }}>
          <h2 style={{
            color: "#3B4859",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "24px"
          }}>Penjualan</h2>
          <small>Calculated in last 7 days</small>
        </div> */}
        <ResponsiveContainer maxHeight={500} aspect={4.0/3.0}>
          <BarChart
            data={props.dataAmount}
            barSize={100}
            margin={{
              top: 35, 
              right: 30, 
              left: 20, 
              bottom: 5
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="timestamp">
              <Label value= "Jumlah Tiket" offset={0} position="insideBottom" />
            </XAxis>
            {/* <YAxis label={{ value: 'Penjualan Tiket(Rp)', angle: -90, position: 'insideLeft' }} /> */}
            <Tooltip />
            <Legend />
            <Bar dataKey="bogor" fill="#D7CABF" name="Bogor" />
            <Bar dataKey="cibodas" fill="#00ACD9" name="Cibodas" />
            <Bar dataKey="purwodadi" fill="#B3B731" name="Purwodadi" />
            <Bar dataKey="bali" fill="#E4A314" name="Bali" />
          </BarChart>
          </ResponsiveContainer>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    region: state.region
  }
}
export default compose(
  connect(mapStateToProps),
  firestoreConnect()
)(BarChartTicket)

import React, { Component } from 'react';
import { Link } from "react-router-dom"
import moment from 'moment'
import TableItem from "./TableItem"
import LoadingTableSponsor from '../../../../components/loading-table';

class DataTable extends Component {
    renderItem = (item, index) => {
        return (
            <TableItem
                key={item.id}
                index={index}
                item={item}
            />
        )
    }
    render() {
        let startDate = moment(this.props.startDate).format('YYYY-MMMM-DD')
        let endDate = moment(this.props.endDate).format('YYYY-MMMM-DD')
        return (
            <table className="table table-bordered m-a-0">
                <thead style={{ color: "#579264"}}>
                    <tr>
                        <th scope="col" rowSpan="2" className="text-center" style={{ verticalAlign: "middle" }}>Tanggal</th>
                        <th scope="col" colSpan="2" className="text-center btn-table">
                            <Link to={`/dashboard/revenue/ticket/report/bogor?startDate=${startDate}&endDate=${endDate}`}>Bogor</Link>
                        </th>
                        <th scope="col" colSpan="2" className="text-center btn-table">
                            <Link to={`/dashboard/revenue/ticket/report/purwodadi?startDate=${startDate}&endDate=${endDate}`}>Purwodadi</Link>
                        </th>
                        <th scope="col" colSpan="2" className="text-center btn-table">
                            <Link to={`/dashboard/revenue/ticket/report/cibodas?startDate=${startDate}&endDate=${endDate}`}>Cibodas</Link>
                        </th>
                        <th scope="col" colSpan="2" className="text-center btn-table">
                            <Link to={`/dashboard/revenue/ticket/report/bali?startDate=${startDate}&endDate=${endDate}`}>Bali</Link>
                        </th>
                        <th scope="col" rowSpan="2" className="text-center" style={{ verticalAlign: "middle" }}>Total Pengunjung</th>
                        <th scope="col" rowSpan="2" className="text-center" style={{ verticalAlign: "middle" }}>Total IDR</th>
                    </tr>
                    <tr>
                        <th scope="col" className="text-center">Qty</th>
                        <th scope="col" className="text-center">IDR</th>
                        <th scope="col" className="text-center">Qty</th>
                        <th scope="col" className="text-center">IDR</th>
                        <th scope="col" className="text-center">Qty</th>
                        <th scope="col" className="text-center">IDR</th>
                        <th scope="col" className="text-center">Qty</th>
                        <th scope="col" className="text-center">IDR</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.props.loading ?
                            <React.Fragment>
                                <LoadingTableSponsor column={11} />
                                <LoadingTableSponsor column={11} />
                                <LoadingTableSponsor column={11} />
                                <LoadingTableSponsor column={11} />
                            </React.Fragment>
                            :
                                this.props.data.length == 0 ? 
                                <tr>
                                    <td className="text-center" colSpan="11">
                                        <p>Tidak ada data!</p>
                                    </td>
                                </tr>
                            : this.props.data.map(this.renderItem)
                    }
                </tbody>
            </table>
        )
    }
}

export default DataTable
import React from "react"
import NumberFormat from 'react-number-format'
import moment from "moment";

const TableItem = (props) => {
    let day = moment(props.item.date_purchase, "YYYY-MM-DD").format("dddd");
    return (
        <tr key={props.item.id} className="text-center">
            <td>{props.item.date_transaction}</td>
            <td>{props.item.domestic.qty}</td>
            <td>{props.item.domestic.tariff}</td>
            <td>
                <NumberFormat 
                    value={props.item.domestic.tax}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>
            <td>{props.item.domestic_group.qty}</td>
            <td>{props.item.domestic_group.tariff}</td>
            <td>
                <NumberFormat 
                    value={props.item.domestic_group.tax}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>
            <td>{props.item.asing_group.qty}</td>
            <td>{props.item.asing_group.tariff}</td>
            <td>
                <NumberFormat 
                    value={props.item.asing_group.tax}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>
            <td>{props.item.asing.qty}</td>
            <td>{props.item.asing.tariff}</td>
            <td>
                <NumberFormat 
                    value={props.item.asing.tax}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>
            <td>
                <NumberFormat 
                    value={props.item.total_tax}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>
        </tr>
    )
}

export default TableItem
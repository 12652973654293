import React from "react"
import NumberFormat from 'react-number-format'

const TableItemVehicle = (props) => {
    let total = props.item.mobil.assurance + props.item.sepeda_motor.assurance
    return (
        <tr key={props.item.id} className="text-center">
            <td>{props.item.date_transaction}</td>
            <td>{props.item.mobil.qty}</td>
            <td>
                <NumberFormat 
                    value={props.item.mobil.assurance}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>
            <td>{props.item.sepeda_motor.qty}</td>
            <td>
                <NumberFormat 
                    value={props.item.sepeda_motor.assurance}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>
            <td className="text-center">
                <NumberFormat 
                    value={total}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>
        </tr>
    )
}

export default TableItemVehicle
import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'

import SubmitButton from '../../components/SubmitButton'
import LoaderModal from '../../components/LoaderModal'
import { validateUpdateForm } from './validate'
import TextAreaDescription from '../../components/Form/TextAreaDescription'
import GalleryPicker from '../../components/Form/GalleryPicker'

let UpdateForm = ({ handleSubmit, pristine, submitting, valid, taskList, startTime, region, task, sectorList, area, status, ...otherProps }) => {
  return (
    <React.Fragment>
      <div className="box mb-2" style={{ borderRadius: 10 }}>      
        <div className="form-group row py-3 ml-2">
          <div className="col-md-2">
            <h5 
              htmlFor={`input-region`} 
              className="mb-0"
              style={{ color: "#579264", fontWeight: "bold", lineHeight: "30px" }}
            >
              Tanggal
            </h5>
          </div>
          <div className="col-md-10">
            <p 
              id={`input-region`} 
              style={{ 
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "20px",
                lineHeight: "30px",
                letterSpacing: "0.15px",
                color: "#979D99" 
                }}
                className="mb-0"
              >
                {startTime}
              </p>
          </div>
        </div>
      </div>
      
      <form role="form" onSubmit={handleSubmit}>
        <div className="box mb-0 p-4 mt-4" style={{ borderRadius: 10 }}>
          <h5 className="mb-0" style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold" }}>{task}</h5>
          <hr className="mb-4" />

          <h6 style={{ color: "#579264", fontWeight: "bold" }}>Wilayah</h6>
          <p className="mt-4" style={{ color: "rgba(124, 122, 122, 0.87)", fontSize: "0.9em", fontWeight: "bold" }}>Wilayah 1</p>

          <h6 className="mt-5" style={{ color: "#579264", fontWeight: "bold" }}>Lokasi</h6>
          {
            area && area.map((item) => (
              <p key={item} className="mt-2" style={{ fontSize: "0.9em", fontWeight: "500" }}>{item}</p>
            ))
          }

          <Field
            component={GalleryPicker}
            label="Foto*"
            name="images"
          />

          <Field
            name="description"
            component={TextAreaDescription}
            placeholder="Description*"
            label="Description"
            rows={7}
            style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
          />

          <div className="col-lg-6 col-xs-12 px-0">
            <div className="col-lg-3 col-xs-12 px-0 mt-5 text-center">
              <SubmitButton disabled={!valid || submitting} label={ status === "started" ? "Laporkan" : "Simpan"} />
            </div>
            <LoaderModal submitting={submitting} />
          </div>
        </div>
      </form>
    </React.Fragment>
  )
}

UpdateForm = reduxForm({
  form: 'updateInfraForm',
  validate: validateUpdateForm,
  enableReinitialize: true,
})(UpdateForm)

const selector = formValueSelector('updateInfraForm')

UpdateForm = connect(state => 
  selector(state, 
    'sectorList', 
    'areaList',
    'taskList',
    'startTime',
    'task',
    'area',
    'status'
))(UpdateForm)


export default connect()(UpdateForm)
import React from 'react'

const LoadingScreen = () => {
    return (
      <div className="d-flex align-items-center justify-content-center" style={{ height: "100vh", backgroundColor: "white" }}>
        <lottie-player
          src="https://assets9.lottiefiles.com/temp/lf20_XOFhge.json"  
          background="transparent" 
          speed="1"
          style={{width: 500, height: 500}} loop autoplay
        >
        </lottie-player>
      </div>
    );
}

export default LoadingScreen
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withFirestore } from 'react-redux-firebase'

import UpdateSession from './UpdateForm';
import ModalError from '../../components/ModalError';
import { uploadBanner } from './uploadBanner'
import moment from 'moment'

const collection = "region"

var generatePwd = function () {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return Math.random().toString(36).substr(2, 9);
};

class UpdateScreen extends Component {
  static propTypes = {
    uid: PropTypes.string,
    firestore: PropTypes.shape({
      add: PropTypes.func.isRequired
    }).isRequired
  }

  state = {
    errorInfo: false,
    data: {
      nik: "",
      employeeNumber: "",
      npwp: "",
      familyCard: "",
      workDate: "",
      name: "",
      gender: "",
      position: "",
      department: "",
      subDepartment: "",
      birth: {
        dob: "",
        city: ""
      },
      maritalStatus: "",
      region: "bogor",
      telephone: "",
      email: "",
      address: "",
      sector: "",
      departementList: [],
      subDepartementList: [],
    }
  }

  handleErrorInfo = () => {
    this.setState({ errorInfo: !this.state.errorInfo });
  }

  createFormData = ({ 
    avatar,
    confirmPassword, 
    workDate, 
    departementList, 
    birth, 
    subDepartementList,
    positionList,
    ...restData 
  }) => {
    const data = {
      timestamp: new Date(),
      workDate: workDate ? moment(workDate).toDate(): "",
      birth: {
        dob: birth.dob ? moment(birth.dob).toDate(): "",
        city: birth.city,
      },
      password: generatePwd(),
      ...restData
    };
    return data;
  }

  fetchData = async () => {
    try {
      const { sessionId } = this.props.match.params;
      console.log('sessionId', sessionId)
      const snapshot =  await this.props.firestore.get({
        collection: collection,
        doc: this.props.region,
        subcollections: [{ 
          collection: 'employee',
          doc: sessionId
        }],
      })
      if (!snapshot.exists) {
        console.log("Doesnt exists")
      } else {
        const { avatar, birth, workDate, ...restData } = snapshot.data()
        console.log('snapshot.data()', snapshot.data())
        // const isValidDate = moment(new Date(undefined)).isValid() == false
        let data = {
          id: snapshot.id,
          avatar: avatar ? avatar.url : "",
          // dob: dob && typeof dob !== 'string' ? moment(dob.toDate()) : "",
          birth: {
            dob: birth.dob ? moment(birth.dob.toDate()) : "",
            city: birth.city,
          },
          workDate: workDate ? moment(workDate.toDate()) : "",
          ...restData
        }
        this.setState({ data: {...this.state.data, ...data } });
      }
    } catch (error) {
      console.error(error)
    }

    this.setState({ loading: false })
  }

  getDataParams = (data) => {
    const dataParams = Object.keys(data).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
    }).join('&');
    return dataParams;
  }

  onSubmit = async (values) => {
    const { sessionId } = this.props.match.params

    const formData = this.createFormData(values)
    console.log("formData:", formData)

    try {
      await this.props.firestore.update({
        collection: collection,
        doc: this.props.region,
        subcollections: [{ 
          collection: 'employee',
          doc: sessionId
        }],
      }, formData);
      console.log('Successfully update Meal Plan data');

      if (typeof values.avatar !== 'string') {
        const path = `region/${formData.region}/employee/${sessionId}`
        const snapshotImage = await uploadBanner(path, values.avatar)
        console.log('snapshotImage', snapshotImage)

        await this.props.firestore.update({
          collection: collection,
          doc: this.props.region,
          subcollections: [{ 
            collection: 'employee',
            doc: sessionId
          }],
        }, {avatar: snapshotImage});
      }

      return this.props.history.push({
        pathname: `/dashboard/employee/${sessionId}`,
        state: { region: this.props.region }
      })
    } catch (error) {
      this.setState({ errorInfo: true })

      console.error("Failed to update Ticketing Entry Data: ", error)

    }
  }

  uploadAvatar = async (values, responseJson) => {
    
    // console.log("there is avatar")
    const path = `region/${values.region}/employee/${responseJson.uid}`
    const imagesUploaded = await uploadBanner(path, values.avatar);

    await this.props.firestore.update({
      collection: 'region',
      doc: values.region,
      subcollections: [{ 
        collection: 'employee',
        doc: responseJson.uid
      }],
    }, { avatar: imagesUploaded });
    console.log("Avatar uploaded successfully!")
  }
  
  fetchDataDepartement = async () => {
    let { region = "" } = this.props;
    try {
      const snapshots = await this.props.firestore.get({
        collection: 'region',
        doc: region,
        subcollections: [{ 
          collection: 'departement',
        }],
      });
      // console.log("snapshots ", snapshots)
      let departementList = []
      if (snapshots.empty) {
        console.log("no data")
        return;
      }
      snapshots.forEach((doc) => {
        departementList.push({ 
          id: doc.id, 
          ...doc.data(), 
          label: doc.data().name, 
          value: doc.data().name, 
          dataRef: doc.ref 
        });
      });
      this.setState({
        data: {...this.state.data, region, departementList }
      })
    } catch (error) {
      console.error(error)
      this.setState({
        data: {
          ...this.state.data, 
          region,
        }
      })
    }
  }
  
  componentDidMount() {
    let { region = "" } = this.props;
    console.log(`region`, region)
    this.fetchData();
    this.fetchDataDepartement()
    this.setState({
      data: {...this.state.data, region }
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.region !== this.props.region) {
      this.setState({
        data: {
          ...this.state.data,
          region: nextProps.region,
        }
      })
    }
  }
  goBack = () => {
    const { sessionId } = this.props.match.params
    this.props.history.push(`/dashboard/employee/${sessionId}`)
  }

  render() {
    if (!this.props.uid) return null
    return (
      <React.Fragment>
        <div className="box-header px-5 mt-3" style={{ backgroundColor: "#EFF2F7" }}>
          <div className="row">
            <a onClick={this.goBack}><i class="fa fa-arrow-left" aria-hidden="true"></i></a>
            <h3 className="ml-3 mt-1" style={{ color: "#3B4859" }}>TAMBAH KARYAWAN</h3>
          </div>
        </div>
        {
          this.state.errorInfo &&
          <ModalError
            errorInfo={this.state.errorInfo}
            handleErrorInfo={this.handleErrorInfo}
            error={this.state.error}
          />
        }
        <div className="box-body">
          <UpdateSession onSubmit={this.onSubmit} initialValues={this.state.data} />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    uid: state.firebase.auth.uid,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    region: state.region.toLowerCase(),
  }
}

export default compose(
  connect(mapStateToProps),
  withFirestore,
)(UpdateScreen)
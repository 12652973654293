import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Link } from 'react-router-dom'

const ListItem = (props) => {
    return (
        <tr className="text-center">
            <td>{props.display}</td>
            <td onClick={() => props.navigateDetail(props.id)}>
                <button style={{ fontSize: "0.7rem", backgroundColor: "#579264", borderRadius: 10, cursor: "pointer" }} type="button" class="btn btn-primary">
                    Rangkuman
                </button>
            </td>
        </tr>
    )
}

ListItem.defaultProps = {
    id: "",
    name: "",
    position: "",
    email: "",
    sector: "",
}

ListItem.propTypes = {
    id: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    sector: PropTypes.string.isRequired,
}


const mapStateToProps = state => {
    return {
        uid: state.firebase.auth.uid,
        region: state.region,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default compose(
    connect(mapStateToProps)
)(ListItem)

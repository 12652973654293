import { firestore } from "firebase"

function emailIsValid (email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}
export default function validate(values) {
  
  const errors = {}
  console.log(values)
  if (!values.nik) {
    errors.nik = 'NIK is Required'
  }
  if (!values.employeeNumber) {
    errors.employeeNumber = 'Employee Number is Required'
  }
  if (!values.npwp) {
    errors.npwp = 'NPWP is Required'
  }
  if (!values.familyCard) {
    errors.familyCard = 'Kartu Keluarga is Required'
  }
  if (!values.workDate) {
    errors.workDate = 'Work Date is Required'
  }
  if (!values.name) {
    errors.name = 'Name is Required'
  }
  if (!values.gender) {
    errors.gender = 'Gender is Required'
  }
  if (!values.position) {
    errors.position = 'Position is Required'
  }
  if (!values.department) {
    errors.department = 'Department is Required'
  }
  if (!values.maritalStatus) {
    errors.maritalStatus = 'Pernikahan is Required'
  }
  if (!values.telephone) {
    errors.telephone = 'Telephone is Required'
  }
  if (!values.email) {
    errors.email = 'Email is Required'
  } else if(!emailIsValid(values.email)) {
    errors.email = 'Email is Not Valid.'
  }
  
  // if (!values.password) {
  //   errors.password = 'Password is Required'
  // } else if (values.password.length < 6) {
  //   errors.password = 'Password must be at least 6 characters'
  // }
  // if (!values.confirmPassword) {
  //   errors.confirmPassword = 'Confirm Password is Required'
  // } else if (values.confirmPassword.length < 6) {
  //   errors.confirmPassword = 'Password must be at least 6 characters'
  // } else if (values.password !== values.confirmPassword) {
  //   errors.confirmPassword = 'Password must same'
  // }

  if (!values.address) {
    errors.address = 'Address is Required'
  }
  if (!values.sector) {
    errors.sector = 'Sector is Required'
  }

  if (values.birth) {
    errors.birth = {}
    if (!values.birth.dob) {
      errors.birth.dob = 'Date Of Birth is Required'
    }
    if (!values.birth.city) {
      errors.birth.city = 'City of Birth is Required'
    }
    
    if (values.birth.dob && values.birth.city) {
      delete errors.birth;
    }
  }

  return errors
      
}


export async function asyncValidate(values) {
  console.log("running")
  try {
    const snapshots = await firestore().collection("region").doc(values.region).collection("employee").where("email", "==", values.email).get()
    if (snapshots.empty === false) {
      console.log("kesini")
      throw { email: 'Email has already been taken!'}
    } else {
      // throw { email: 'Email has already been taken!'}
    }
  }
  catch (error) {
    console.error(error)
    throw { email: error.email || 'Try Input Email Again' }
  }
  // })
}
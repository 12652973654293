import React from "react"
import NumberFormat from 'react-number-format'

const TableItemCategory = (props) => {
    return (
        <tr key={props.item.id} className="text-center">
            <td>{props.item.name}</td>
            <td>{props.item.total_qty}</td>
            <td>
                <NumberFormat 
                    value={props.item.total_amount || "-"}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>
            
        </tr>
    )
}

export default TableItemCategory
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { firestore } from 'firebase'
import { Link } from "react-router-dom"

import ListItem from './ListItem'
import LoadingTable from "../../../components/loading-table"
import { debounce } from 'lodash'

class Activities extends Component {
    state = {
        loading: true,
        data: [],
        selectedPosition: '',
        positionList: [],
        rawData: [],
        search: '',
    }
    getUserData = async (region) => {
        const snapshot = await firestore().collection('region').doc(region).collection('employee').doc(this.props.uid).get();
        if (!snapshot.exists) {
            return {}
        }
        return {
            id: snapshot.id,
            ...snapshot.data()
        }
    }

    fetchData = async () => {
        let data = []
        const region = this.props.region.toLowerCase()
        this.setState({ data: [], isLoading: true })

        const userData = await this.getUserData(region);

        const employeesRef = firestore().collection('region').doc(region).collection('employee')

        let ref = employeesRef.where('isAdmin', '==', false)

        if (userData.roleId == "f1") {
            //GM OR operational manager
            ref = ref.where('roleId', 'in', ['f2', 'f3', 'f4'])

        } else if (userData.roleId == "f2") {
            // SUPERVISOR ...
            ref = ref
                .where('subDepartment', "==", userData.subDepartment)
                .where('roleId', 'in', ['f4'])

        } else {
            this.setState({
                data: [],
                rawData: [],
                isLoading: false
            });
            return true;
        }

        const snapshots = await ref.orderBy('name').get()

        if (!snapshots.empty) {
            const lastVisible = snapshots.docs[snapshots.docs.length - 1];
            snapshots.forEach((doc) => {
                data.push({ id: doc.id, ...doc.data(), dataRef: doc.ref });
            });

            let positionList = data.map((item) => item.position)

            positionList = [...new Set(positionList)]
            this.setState({
                data,
                positionList,
                rawData: data,
                region,
                isLoading: false,
                lastVisible
            });
        } else {
            this.setState({
                data: [],
                rawData: [],
                isLoading: false
            });
        }
    }

    navigateDetail = sessionId => {
        this.props.history.push(`/dashboard/facilities_employee/employee_list/${sessionId}`)
    }

    onChangeFilterPosition = (e) => this.setState({ selectedPosition: e.target.value })
  
    filterPosition = (value) => {
      let selectedPosition = value
      this.setState({
        selectedPosition
      }, () => this.filter())
    }
  
    filter = () => {
      let tempData = this.state.rawData
  
      let filteredData = []
      const { selectedPosition } = this.state;
      if (!selectedPosition) {
        filteredData = this.state.rawData
      } else {
        filteredData = tempData.filter(({ position }) => position == selectedPosition)
      }
      this.setState({
        data: filteredData
      })
    }

    searchData = debounce(search => {
        const tempData = this.state.rawData
        const filtered = tempData.filter((item) => {
            return item.name.toLowerCase().includes(search.toLowerCase())
        })
        this.setState({ data: filtered })
    }, 250)

    onChangeText = (e) => {
        this.setState({ search: e.target.value })
        this.searchData(e.target.value)
    }

    renderListItem = (item) => (
        <ListItem
            key={item.id}
            navigateDetail={this.navigateDetail}
            dataRef={item.dataRef}
            {...item}
        />
    )

    componentDidMount() {
        this.fetchData();
    }

    render() {
        return (
            <React.Fragment>
                <div className="box-header px-5 mt-3" style={{ backgroundColor: "#EFF2F7" }}>
                    <h3 style={{ color: "#3B4859" }}>LIST KARYAWAN</h3>
                </div>
                <div className="container-fluid p-5 spacing-resp">
                    <div className="row mx-2">
                        <div className="col-10">
                            <div className="col-md-3 input-group md-form form-sm form-1 py-1 px-2" style={{ backgroundColor: "white", color: "#999999", border: "1px solid #999999", borderRadius: 7 }}>
                                <div className="input-group-prepend">
                                    <span className="input-group-text lighten-3">
                                        <i className="fas fa-search mt-1" style={{ color: "#999999", fontSize: "0.7rem" }} aria-hidden="true"></i>
                                    </span>
                                </div>
                                <input
                                    value={this.state.search}
                                    onChange={this.onChangeText}
                                    className="form-control my-0 py-1"
                                    style={{ borderColor: "transparent", fontSize: "0.8rem", color: "#999999", zIndex: "unset" }} type="text"
                                    placeholder="Cari nama karyawan"
                                    aria-label="Search"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="box mb-0 p-4 mt-3" style={{ borderRadius: 10 }}>
                        <div className="table-responsive">
                            <table className="table table-no-bordered m-a-0">
                                <thead style={{ color: "#579264" }}>
                                    <tr>
                                        <th scope="col" className="text-center">Nama Karyawan</th>
                                        <th scope="col" className="text-center">
                                            Jabatan
                                            <Link as="button" data-toggle="modal" data-target="#exampleModalCenter">
                                                <i style={{ fontSize: 10 }} className='mx-3 fa fa-sort-amount-desc'></i>
                                            </Link></th>
                                        <th scope="col" className="text-center">Aksi</th>
                                    </tr>
                                </thead>
                                <tbody style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                    {
                                        this.state.isLoading
                                            ?
                                            <React.Fragment>
                                                <LoadingTable column={3} />
                                                <LoadingTable column={3} />
                                                <LoadingTable column={3} />
                                                <LoadingTable column={3} />
                                            </React.Fragment>
                                            :
                                            this.state.data.map(this.renderListItem)
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content" style={{ borderRadius: 15 }}>
                            <div className="modal-header text-right" style={{ border: "none" }}>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body px-5">
                                <div className="text-center">
                                    <h6 style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold" }}>FILTER</h6>
                                </div>
                                <div className="mt-4">
                                    <p style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: 500 }}>Kategori Nama Jabatan</p>
                                    <select value={this.state.selectedPosition} onChange={this.onChangeFilterPosition} className="browser-default custom-select" style={{ width: "100%", color: "rgba(124, 122, 122, 0.87)" }}>
                                        <option value="">Semua Jabatan</option>
                                        {
                                            this.state.positionList.map((item) => (
                                                <option value={item}>{item}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="modal-footer mt-3 justify-content-center" style={{ border: "none" }}>
                                <button onClick={() => this.filterPosition(this.state.selectedPosition)} data-dismiss="modal" type="button" className="btn w-25" style={{ backgroundColor: "#579264", color: "white", fontSize: "0.8em" }}>
                                    Set
                                </button>
                                <button type="button" className="btn w-25" data-dismiss="modal" style={{ backgroundColor: "#969595", color: "white", fontSize: "0.8em" }}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        uid: state.firebase.auth.uid,
        region: state.region,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect(),
)(Activities)
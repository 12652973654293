import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withFirestore } from 'react-redux-firebase'

import UpdateSession from './UpdateForm'
import { deleteImages } from '../../helpers/deleteGallery'
import { uploadGalleryBase64 } from '../../helpers/uploadGallery'
import LoadingScreen from '../../components/LoadingScreen'
import ModalError from '../../components/ModalError'
import moment from 'moment'
import { firestore } from 'firebase'

class UpdateScreen extends Component {
  static propTypes = {
    uid: PropTypes.string,
    firestore: PropTypes.shape({
      update: PropTypes.func.isRequired
    }).isRequired
  }

  state = {
    errorInfo: false,
    loading: true,
    collection: "region",
    data: {
      arealist: [],
      sectorList: [],
      sector: "",
      area: [],
      status: "",
      description: "",
      images: [],
      imagesData: [],
      task: ""
    }
  }

  handleErrorInfo = () => {
    this.setState({ errorInfo: !this.state.errorInfo });
  }

  createFormData = ({ date, images, imagesData, sectorList, areaList, startTime, ...values }) => {
    let data = {
      ...values,
      status: "finished",
    };
    if (this.state.data.status == "started") {
      data.endTime = new Date()
    }
    return data;
  }

  updateUserStatus = async () => {

    const uidDate = moment().format("DDMMYYYY")

    const regionRef = firestore().collection("region").doc(this.props.region.toLowerCase())
    const employeeRef = regionRef.collection("employee").doc(this.props.uid)
    const facilityReportRef = employeeRef.collection("facilityReport").doc(uidDate)

    const queryReport = facilityReportRef.collection("report").where('status', '==', 'start')
    const reportSnaphots = await queryReport.get()

    if (reportSnaphots.empty) {
      await facilityReportRef.set({ isAvailable: true }, { merge: true })
    } else {
      await facilityReportRef.set({ isAvailable: false }, { merge: true })
    }

  }

  onSubmit = async (values) => {
    const { sessionId, activityId } = this.props.match.params
    const formData = this.createFormData(values)
    console.log('formData', formData)

    try {

      const sfDocRef = firestore()
        .collection('region')
        .doc(this.props.region.toLowerCase())
        .collection("employee")
        .doc(this.props.uid)
        .collection("facilityReport")
        .doc(activityId)
        .collection("report")
        .doc(sessionId)
      const sfDoc = await sfDocRef.get();

      if (!sfDoc.exists) {
        throw new Error("Document does not exist!");
      }
      await sfDocRef.set(formData, { merge: true })

      //update data      

      // if (values.isImageChanged === true) {
        console.log("true")
        const tempImages = values.images
        await deleteImages(values.imagesData)
        await sfDocRef.set({ images: [] }, { merge: true})
        const path = `${this.state.collection}/${this.props.region.toLowerCase()}/facilityReport/${activityId}/report/${sessionId}`
        const imagesUploaded = await uploadGalleryBase64(sessionId, path, values.images);


        await sfDocRef.set({ images: imagesUploaded }, { merge: true})
      // }
      await this.updateUserStatus();
      this.goBack()
    } catch (error) {
      console.error("error general upload: ", error)
      this.setState({
        errorInfo: true,
      })
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    try {
      const { sessionId, activityId } = this.props.match.params;       

      const regionRef = firestore().collection('region').doc(this.props.region.toLowerCase())
      const userRef = regionRef.collection("employee").doc(this.props.uid)
      const facilityReport = userRef.collection("facilityReport").doc(activityId)

      const snapshot = await facilityReport.collection("report").doc(sessionId).get()

      if (!snapshot.exists) {
        this.setState({ loading: false })
        // this.props.history.push('/dashboard/facilities/report')
        return;
      }

      let snapshotData = {
        id: snapshot.id,
        ...snapshot.data()
      }

      const {
        images = [],
        startTime,
        ...restData
      } = snapshotData
      let imageUrls = images.map((e) => e.url)
      let data = {
        id: snapshotData.id,
        images: imageUrls,
        imagesData: images,
        startTime: startTime ? moment(startTime.toDate()).format("DD MMMM YYYY") : "",
        ...restData
      }

      this.setState({
        data: {
          ...this.state.data,
          ...data
        }
      });

    } catch (error) {
      console.error(error)
      this.setState({
        errorInfo: true,
      })
    }

    this.setState({ loading: false })
  }
  goBack = () => {
    const { activityId, sessionId } = this.props.match.params
    
    const uidDate = activityId ? activityId : moment().format("DDMMYYYY");
    this.props.history.push({
      pathname: `/dashboard/facilities_employee/${this.props.uid}/activities/${uidDate}/report/${sessionId}`,
      state: { region: this.props.selectedOption }
      
    })
  }

  render() {
    if (!this.props.uid) return null
    if (this.state.loading) return <LoadingScreen />
    return (
      <React.Fragment>
        <div className="box-header px-5 mt-3" style={{ backgroundColor: "#EFF2F7" }}>
          <h3 style={{ color: "#3B4859" }}>LAPORAN HARIAN</h3>
        </div>
        <div className="container-fluid p-5 spacing-resp">
          <React.Fragment>
            {
              this.state.errorInfo &&
              <ModalError
                errorInfo={this.state.errorInfo}
                handleErrorInfo={this.handleErrorInfo}
              />
            }
          </React.Fragment>
          <React.Fragment>
            <UpdateSession onSubmit={this.onSubmit} initialValues={this.state.data} />
          </React.Fragment>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    uid: state.firebase.auth.uid,
    region: state.region,
  }
}

export default compose(
  connect(mapStateToProps),
  withFirestore,
)(UpdateScreen)
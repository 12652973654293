import React, { useState } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'

import GalleryPicker from '../../components/Form/GalleryPicker'
import SubmitButton from '../../components/SubmitButton'
import LoaderModal from '../../components/LoaderModal'
import validate from './validate'
import moment from 'moment'
import TaskList from './TaskList'
import TextAreaReport from '../../components/Form/TextAreaReport'
import SelectMenuInput from '../../components/Form/SelectMenuInput'
import SelectAreaInput from '../../components/Form/SelectAreaInput'
import { firestoreConnect } from 'react-redux-firebase'
import SelectSectorInput from '../../components/Form/SelectSectorInput'

let AddForm = ({ handleSubmit, submitting, taskList, sectorList, region, ...otherProps }) => {
  const [ areaList, setAreaList ] = useState([])
  const fetchArea = async (selectedItem) => {
    console.log('selectedItem', selectedItem)
    let { id, maintenanceArea } = selectedItem
    try {
      maintenanceArea.sort()
      let areaList = maintenanceArea.map((item) => {
        return { label: item, value: item }
      })
      setAreaList(areaList)
    } catch (error) {
      setAreaList([])
    }
  }
  return (
    <React.Fragment>
      <div className="col-12" style={{ marginTop: 15 }}>
        {/* <div className="" style={{ borderRadius: 5 }}>
          <div className="box-header">
            <h2 className="m-2" style={{ fontWeight: "bold" }}>LAPORAN</h2>
          </div>
        </div> */}
      </div>
      <div className="box">      
        <div className="form-group row px-5 pb-2 pt-4 ml-2">
          <h6 
            htmlFor={`input-region`} 
            className="col-md-2" 
            style={{ color: "#579264", fontWeight: "bold" }}
          >
            Tanggal
          </h6>
          <div className="col-md-10">
            <h6 id={`input-region`} style={{ 
              fontWeight: "bold",
              color: "#979D99" 
            }}>{moment().locale("id").format("DD MMMM YYYY")}</h6>
          </div>
        </div>
      </div>
      
      <form role="form" onSubmit={handleSubmit}>
        <div className="col-sm-12 box">
          <div className="px-5 pb-5 pt-3" style={{ borderRadius: 5 }}>
            <div className="form-group row mb-4">
              <div className="col-md-2 d-flex align-items-center">
                <h6 className="mb-0" style={{ color: "#579264", fontWeight: "bold" }}>
                  Sektor
                </h6>
              </div>
              <div className="col-md-4">
                <Field
                  component={SelectSectorInput}
                  label="Sektor"
                  placeholder="Pilih Sektor"
                  items={sectorList}
                  fetchData={fetchArea}
                  name="sector"
                  formName="addMaintenanceForm"
                  area="area"
                />
              </div>
            </div>
              <div className="form-group row mb-4">
                <div className="col-md-2 d-flex align-items-center">
                  <h6 className="mb-0" style={{ color: "#579264", fontWeight: "bold" }}>
                    Area
                  </h6>
                </div>
                <div className="col-md-4">
                  <Field
                    component={SelectAreaInput}
                    label="Area"
                    placeholder="Pilih area"
                    items={areaList}
                    name="area"
                  />
                </div>
              </div>
          </div>
        </div>
        {/* <div className="col-sm-12 box">
          <div className="px-5 py-4" style={{ borderRadius: 5 }}>
            <div className="form-group row pt-3">
              <h6 
               className="col-md-4" 
                style={{ color: "#579264", fontWeight: "bold" }}
              >
                Daftar Pekerjaan
              </h6>
              {
                taskList.length ?
                  <div className="col-md-4 mt-4">
                  {taskList.map((item) => (
                    <div className="row">
                      <p className="col-md-4">{item.value}</p>
                      <p className="col-md-4">{item.description}</p>
                      <p className="col-md-4">{item.employeeName}</p>
                    </div>
                  ))}
                  </div>
                :
                  <div className="col-md-4">
                    <p>Tidak ada daftar pekerjaan.</p>
                  </div>
              }
              <div className="col-md-4">
                <Field name="taskList" component={TaskList} />
              </div>
            </div>

          </div>
        </div> */}
        
        <div className="col-sm-12 box" style={{ borderRadius: 5 }}>
          <div className="px-5 pb-5 pt-3">
            <div className="row mb-4">
              <div className="col-md-8">
                <div className="table-responsive">
                  <table className="table table-no-bordered m-a-0">
                    <thead style={{ color: "#579264" }}>
                      <tr>
                        <th scope="col">Daftar Pekerjaan</th>
                        <th scope="col">Keterangan</th>	
                        <th scope="col">Karyawan</th>	
                      </tr>
                    </thead>
                    <tbody>
                      {
                        taskList.length ?
                        <React.Fragment>
                          {taskList.map((item) => (
                            <tr>
                              <td>
                                <p>{item.value}</p>
                              </td>
                              <td>
                                <p>{item.description}</p>
                              </td>
                              <td>
                                <p>{item.employeeName}</p>
                              </td>
                            </tr>
                          ))}
                        </React.Fragment>
                        :
                          <p className="text-center">Tidak ada daftar pekerjaan.</p>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-md-4">
                <Field name="taskList" component={TaskList} />
              </div>
            </div>
          </div>
        </div>
        <div className="box p-5">
          <Field
            component={GalleryPicker}
            label="Foto"
            name="images"
          />
        </div>
            
        <div className="col-sm-12 box">
         <div className="px-5 pb-5 pt-3" style={{ borderRadius: 5 }}>

          <Field
            name="description"
            component={TextAreaReport}
            placeholder="Catatan"
            label="Catatan"
            rows={4}
            style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
          />

            <div className="col-lg-6 col-xs-12 px-0">
              <div className="col-lg-3 col-xs-12 px-0 mt-5 text-center">
                <SubmitButton disabled={submitting} />
              </div>
              <LoaderModal submitting={submitting} />
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  )
}

AddForm = reduxForm({
  form: 'addMaintenanceForm',
  validate,
  enableReinitialize: true,
})(AddForm)


const selector = formValueSelector('addMaintenanceForm')
AddForm = connect(
  state => {

    const sectorList = selector(state, 'sectorList') || [];
    const taskList = selector(state, 'taskList') || [];
    const region = selector(state, 'region') || "";

    
    return {
      sectorList,
      region,
      taskList,
    }
  }
)(AddForm)

export default firestoreConnect()(AddForm)
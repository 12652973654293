import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withFirestore } from 'react-redux-firebase'

import {uploadBanner} from './uploadBanner'
import UpdateSession from './UpdateForm'
import LoadingScreen from '../../components/LoadingScreen'
import ModalError from '../../components/ModalError'
import moment from 'moment'

const collection = "region"

class UpdateScreen extends Component {
  static propTypes = {
    uid: PropTypes.string,
    firestore: PropTypes.shape({
      update: PropTypes.func.isRequired
    }).isRequired
  }
  
  state = {
    data: {
      nik: "-",
      employeeNumber: "-",
      npwp: "-",
      familyCard: "-",
      workDate: "-",
      name: "-",
      gender: "-",
      position: "-",
      department: "-",
      subDepartment: "-",
      birth: {
        dob: "-",
        city: "-"
      },
      maritalStatus: "-",
      telephone: "-",
      address: "-",
      sector: "-",
      avatar: "-",
      departementList: [],
      subDepartementList: [],
    },
    loading: true,
    errorInfo: false,
  }

  handleErrorInfo = () => {
    this.setState({ errorInfo: !this.state.errorInfo });
  }

  createFormData = ({ avatar, email, departementList, subDepartementList, birth, workDate, ...values }) => {
    const data = {
      ...values,
      workDate: workDate ? moment(workDate).toDate(): "",
      birth: {
        dob: birth.dob ? moment(birth.dob).toDate(): "",
        city: birth.city,
      },
    };
    return data;
  }

  onSubmit = async (values) => {
    const { sessionId } = this.props.match.params

    const formData = this.createFormData(values)
    console.log("formData:", formData)

    try {
      await this.props.firestore.update({
        collection: collection,
        doc: this.props.region,
        subcollections: [{ 
          collection: 'employee',
          doc: sessionId
        }],
      }, formData);
      console.log('Successfully update Meal Plan data');

      if (typeof values.avatar !== 'string') {
        const path = `region/${formData.region}/employee/${sessionId}`
        const snapshotImage = await uploadBanner(path, values.avatar)
        console.log('snapshotImage', snapshotImage)

        await this.props.firestore.update({
          collection: collection,
          doc: this.props.region,
          subcollections: [{ 
            collection: 'employee',
            doc: sessionId
          }],
        }, {avatar: snapshotImage});
      }

      return this.props.history.push({
        pathname: `/dashboard/profile`,
        state: { region: this.props.selectedOption }
      })
    } catch (error) {
      this.setState({ errorInfo: true })

      console.error("Failed to update Ticketing Entry Data: ", error)

    }
  }

  componentDidMount() {
    this.fetchData();
    this.fetchDataDepartement() 
  }

  fetchData = async () => {
    try {
      const { sessionId } = this.props.match.params;
      console.log('sessionId', sessionId)
      const snapshot =  await this.props.firestore.get({
        collection: collection,
        doc: this.props.region,
        subcollections: [{ 
          collection: 'employee',
          doc: sessionId
        }],
      })
      if (!snapshot.exists) {
        console.log("Doesnt exists")
      } else {
        const { avatar, birth, workDate, ...restData } = snapshot.data()
        console.log('snapshot.data()', snapshot.data())
        // const isValidDate = moment(new Date(undefined)).isValid() == false
        let data = {
          id: snapshot.id,
          avatar: avatar ? avatar.url : "",
          // dob: dob && typeof dob !== 'string' ? moment(dob.toDate()) : "",
          birth: {
            dob: birth.dob ? moment(birth.dob.toDate()) : "",
            city: birth.city,
          },
          workDate: workDate ? moment(workDate.toDate()) : "",
          ...restData
        }
        this.setState({ data: {...this.state.data, ...data } });
      }
    } catch (error) {
      console.error(error)
    }

    this.setState({ loading: false })
  }
  fetchDataDepartement = async () => {
    // let { region = "bogor" } = this.props.location.state;
    try {
      const snapshots = await this.props.firestore.get({
        collection: 'region',
        doc: "bogor",
        subcollections: [{ 
          collection: 'departement',
        }],
      });
      console.log("snapshots ", snapshots)
      let departementList = []
      if (snapshots.empty) {
        console.log("no data")
        return;
      }
      snapshots.forEach((doc) => {
        departementList.push({ 
          id: doc.id, 
          ...doc.data(), 
          label: doc.data().name, 
          value: doc.data().name, 
          dataRef: doc.ref 
        });
      });
      this.setState({
        data: {...this.state.data, departementList }
      })
    } catch (error) {
      console.error(error)
      this.setState({
        data: {
          ...this.state.data
        }
      })
    }
  }

  goBack = () => {
    this.props.history.push({
      pathname: `/dashboard/profile`
    })
  }

  render() {
    if (!this.props.uid) return null
    if (this.state.loading) return <LoadingScreen />
    return (
      <React.Fragment>
        <div className="box-header px-5 mt-3" style={{ backgroundColor: "#EFF2F7" }}>
          <div className="row">
            <a onClick={this.goBack}><i class="fa fa-arrow-left" aria-hidden="true"></i></a>
            <h3 className="ml-3 mt-1" style={{ color: "#3B4859" }}>UPDATE KARYAWAN</h3>
          </div>
        </div>
        <div className="box-body">
          {
            this.state.errorInfo &&
            <ModalError 
              errorInfo={this.state.errorInfo}
              handleErrorInfo={this.handleErrorInfo}
            />
          }
          <UpdateSession onSubmit={this.onSubmit} initialValues={this.state.data} />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    uid: state.firebase.auth.uid,
    region: state.region.toLowerCase()
  }
}

export default compose(
  connect(mapStateToProps),
  withFirestore,
)(UpdateScreen)
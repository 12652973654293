function emailIsValid (email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export default function validate(values) {
  
  const errors = {}
  
  if (!values.name) {
    errors.name = 'Nama wajib diisi.'
  }
  if (!values.species) {
    errors.species = 'Spesies wajib diisi.'
  }
  if (!values.englishName) {
    errors.englishName = 'Nama Inggris wajib diisi.'
  }
  if (!values.genus) {
    errors.genus = 'Genus wajib diisi.'
  }
  
  if (!values.vak) {
    errors.vak = 'Vak wajib diisi.'
  }
  if (!values.sector) {
    errors.sector = 'Wilayah wajib diisi.'
  }
  if (!values.collectionNumber) {
    errors.collectionNumber = 'Nomor Koleksi wajib diisi.'
  }
  if (!values.specimenTotal) {
    errors.specimenTotal = 'Total Spesimen wajib diisi.'
  }
  if (!values.status) {
    errors.status = 'Status wajib diisi.'
  }
  if (!values.deployment) {
    errors.deployment = 'Persebaran wajib diisi.'
  }
  if (!values.usability) {
    errors.usability = 'Kegunaan wajib diisi.'
  }
  if (!values.description) {
    errors.description = 'Ciri-ciri wajib diisi.'
  }
  if (!values.place) {
    errors.place = 'Habitat wajib diisi.'
  }
  return errors
      
}
import React from 'react'
import { Field, formValueSelector } from 'redux-form'
import JumlahInput from './JumlahInput';

const DisabledTextInput = ({ input, label, type, disabled,  meta: { touched, error } }) => (
  <React.Fragment>
    <input {...input} type={type} placeholder={label} style={{ color: "rgba(124, 122, 122, 0.87)", fontSize: "1em", backgroundColor: "transparent", borderColor: "transparent", pointerEvents: "none" }} className="form-control py-4" disabled={disabled} />
    {touched && error && <span>{error}</span>}
  </React.Fragment>
);

const TextInput = ({ input, label, type, disabled,  meta: { touched, error } }) => (
  <React.Fragment>
    <input {...input} type={type} placeholder={label} style={{ color: "rgba(124, 122, 122, 0.87)", fontSize: "1em" }} className="form-control field-box py-4" disabled={disabled} />
    {touched && error && <span>{error}</span>}
  </React.Fragment>
);

const TextInput2 = ({ input, label, type, disabled,  meta: { touched, error } }) => (
  <React.Fragment>
    <input {...input} type={type} placeholder={label} style={{ color: "rgba(124, 122, 122, 0.87)", fontSize: "1em", backgroundColor: "#E8E8E8" }} className="form-control py-4" />
    {touched && error && <span>{error}</span>}
  </React.Fragment>
);

const ComposeList = ({ fields, meta, label, data }) => {
  
  return (
    <React.Fragment>
        {fields.map((field, index) => 
        <tr key={index}>
          {data && data.length > 0 && data[index].id == "others" ?
            <td className="w-25 py-4">
              <Field
                name={`${field}.label`}
                type="text"
                component={TextInput}
                label="Nama Kegiatan"
              />
            </td>
            :
            <td className="w-25 py-4">
              <Field
                name={`${field}.label`}
                type="text"
                component={DisabledTextInput}
                label="Name"
                disabled
              />
            </td>
          }
          <td className="w-25 py-4">
            <Field
              name={`${field}.qty`}
              type="text"
              component={JumlahInput}
              // label="Jumlah"
            />
          </td>
          <td className="py-4">
            <label className="py-4">Kg</label>
          </td>
          <td className="py-4">
            <Field
              name={`${field}.description`}
              type="text"
              component={TextInput2}
              label="Keterangan"
            />
          </td>
        </tr>
    )}
     
    </React.Fragment>
  );
}
const selector = formValueSelector('myFormName')

export default ComposeList
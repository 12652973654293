import React from "react"
import { Switch, Route } from 'react-router-dom'

import PlantInfoScreen from '../../screens/PlantInfo/TableListScreen';
import AddPlantInfoScreen from '../../screens/PlantInfo/AddScreen';
import DetailPlantInfoScreen from '../../screens/PlantInfo/Detail';
import UpdatePlantInfoScreen from '../../screens/PlantInfo/UpdateScreen';
import DailyReportPlantScreen from '../../screens/PlantInfo/Detail/DailyReport/AddScreen';
import UpdateReportPlantScreen from '../../screens/PlantInfo/Detail/DailyReport/UpdateScreen';
import AddFertilizerPlanScreen from '../../screens/PlantInfo/Detail/FertilizerPlan/AddScreen';
import UpdateFertilizerPlanScreen from '../../screens/PlantInfo/Detail/FertilizerPlan/UpdateScreen';

import KomposScreen from '../../screens/PlantInfo/Kompos';
import KomposDetailScreen from '../../screens/PlantInfo/Kompos/Detail';
import UpdateKomposScreen from '../../screens/PlantInfo/Kompos/UpdateScreen';
import AddKomposScreen from '../../screens/PlantInfo/Kompos/AddScreen';

const PlantNav = (props) => (
    <Switch>
        <Route 
            exact 
            path={`${props.match.path}/`}
            render={(routeProps) => <PlantInfoScreen {...routeProps} />}
        /> 
        <Route 
            exact 
            path={`${props.match.path}/add`}
            render={(routeProps) => <AddPlantInfoScreen {...routeProps} />}
        />
        <Route 
            exact 
            path={`${props.match.path}/kompos`}
            render={(routeProps) => <KomposScreen {...routeProps} />}
        />
        <Route 
            exact 
            path={`${props.match.path}/kompos/add`}
            render={(routeProps) => <AddKomposScreen {...routeProps} />}
        />
        <Route 
            exact 
            path={`${props.match.path}/kompos/:sessionId`}
            render={(routeProps) => <KomposDetailScreen {...routeProps} />}
        />
        <Route 
            exact 
            path={`${props.match.path}/kompos/:sessionId/update`}
            render={(routeProps) => <UpdateKomposScreen {...routeProps} />}
        />
        <Route 
            exact 
            path={`${props.match.path}/:sessionId`}
            render={(routeProps) => <DetailPlantInfoScreen {...routeProps} />}
        />
        <Route 
            exact 
            path={`${props.match.path}/:sessionId/update`}
            render={(routeProps) => <UpdatePlantInfoScreen {...routeProps} />}
        />
        <Route 
            exact 
            path={`${props.match.path}/:sessionId/daily_report/add`}
            render={(routeProps) => <DailyReportPlantScreen {...routeProps} />}
        />
        <Route 
            exact 
            path={`${props.match.path}/:sessionId/daily_report/:reportId/update`}
            render={(routeProps) => <UpdateReportPlantScreen {...routeProps} />}
        />
        <Route 
            exact 
            path={`${props.match.path}/:sessionId/fertilizer_plan/add`}
            render={(routeProps) => <AddFertilizerPlanScreen {...routeProps} />}
        />
        <Route 
            exact 
            path={`${props.match.path}/:sessionId/fertilizer_plan/:planId/update`}
            render={(routeProps) => <UpdateFertilizerPlanScreen {...routeProps} />}
        />
    </Switch>
)

export default PlantNav
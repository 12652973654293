import React from 'react'
import Select from 'react-select'

class FilterMenu extends React.Component {
    state = { selectedOption: null }
    handleChange = (selectedItem) => {
      this.setState({ selectedOption: selectedItem });
      const value = selectedItem === null ? '' : selectedItem.value;

      this.props.onChange(value, selectedItem)
    };
    UNSAFE_componentWillReceiveProps(nextProps) {
      // if (nextProps.value != this.props.value) {
        for (let i = 0; i < nextProps.items.length; i++) {
          const element = nextProps.items[i];
          if (nextProps.value == element.value) {

            this.setState({
              selectedOption: element
            })
            return;
          }
        }
      // }
    }
    componentDidMount() {
      if(this.props.value) {
        for (let i = 0; i < this.props.items.length; i++) {
          const element = this.props.items[i];
          if(this.props.value == element.value) {
            this.setState({
              selectedOption: element
            })
            return;
          }
        }
      }
    }
    render() {
      const { items, currentRefinement, placeholder, disabled } = this.props;
      return (
        <Select
          isDisabled={disabled}
          onChange={this.handleChange}
          defaultValue={items[0]}
          options={items}
          className="field-box"
          value={this.state.selectedOption}
          placeholder={placeholder}
          // clearValue={this.clearQuery}
        />
      );
    }
}

const customStyles = {
  option: () => ({
    fontSize: 13,
    padding: 10,
  }),
}

export default FilterMenu;

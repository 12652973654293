import React, { PureComponent } from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import moment from 'moment';
import { withFirestore } from 'react-redux-firebase'

const data = [
  {
    name: '1', domestic: 11000, domestic_group: 12000, asing: 13000, asing_group: 9000
  },
  {
    name: '2', domestic: 12000, domestic_group: 12000, asing: 13000, asing_group: 8000
  },
  {
    name: '3', domestic: 11000, domestic_group: 12000, asing: 13000, asing_group: 12000
  },
  {
    name: '4', domestic: 11000, domestic_group: 12000, asing: 13000, asing_group: 12000
  },
  {
    name: '5', domestic: 11000, domestic_group: 12000, asing: 13000, asing_group: 12000
  },
];

class BarChartTicketWeekly extends PureComponent {

  state = {
    dataTicket: [],
    loading: true,
  }

  fetchDataEvent = async (props) => {
    let dataTicket = props.data
    this.setState({ loading: true, dataTicket: [] })
    try {
      // let currentMonth = parseInt(moment().startOf('month').format("M"))
      //loop bulan
      let arrWeeks = []
      const startWeekOfYear = moment().startOf('week').startOf('year')
      const week = moment().diff(startWeekOfYear, 'week')
      
      for (let i = 0; i < week; i++) {
        arrWeeks.push(parseInt(moment().startOf('week').startOf('year').add(i, 'week').format("w")))
      }
      

      let newData = []
      for (let i = 0; i < arrWeeks.length; i++) {
        let week = arrWeeks[i];
        let categories = {
          asing: 0,
          asing_group: 0,
          domestic: 0,
          domestic_group: 0
        }

        for (let j = 0; j < dataTicket.length; j++) {
          let ticket = dataTicket[j];
          let timestamp = ticket.timestamp.toDate()

          let currentWeek = parseInt(moment(timestamp).startOf('week').format("w"))
          if (week === currentWeek) {
            let { tariff_transactions } = ticket.tariff
            for (let index = 0; index < tariff_transactions.length; index++) {
              const element = tariff_transactions[index];
              switch (element.tariff) {
                case "Asing":
                  categories.asing = categories.asing + element.qty
                  break;
                case "Asing Group":
                  categories.asing_group = categories.asing_group + element.qty
                  break;
                case "Domestik":
                  categories.domestic = categories.domestic + element.qty
                  break;
                case "Domestik Group":
                  categories.domestic_group = categories.domestic_group + element.qty
                  break;
                default:
                  break;
              }
            }
          }
        }

        newData.push({
          name: week.toString(),
          ...categories
        })

      }
      this.setState({
        dataTicket: newData,
        loading: false
      })

    } catch (error) {
      console.error(error)
      this.setState({
        dataTicket: [],
        loading: false
      })
    }
  }

  componentDidMount() {
    this.fetchDataEvent(this.props)
  }
    
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      this.fetchDataEvent(nextProps)
    }
  }
  

  render() {
    if (this.state.loading) {
      return (
        <div className="ph-item p-0 mb-0 border-0">
          <div className="ph-picture mb-0" style={{ height: "500px" }}>
              <div className="ph-col-12"></div>
          </div>
        </div>
      )
    }
    return (
      <ResponsiveContainer maxHeight={500} aspect={4.0 / 3.0}>
        <BarChart
          data={this.state.dataTicket}
          barGap="0"
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis type="number" domain={[0, 'dataMax + 110']} label={{ value: 'Tiket Terjual', fontWeight: "bold", angle: -90, position: 'insideLeft' }} />
          <Tooltip />
          {/* <Legend /> */}
          <Bar dataKey="domestic" label="domestic" fill="#96CEB4" />
          <Bar dataKey="domestic_group" label="domestic Grup" fill="#FFEEAD" />
          <Bar dataKey="asing" label="Asing" fill="#FF6F69" />
          <Bar dataKey="asing_group" label="Asing Grup" fill="#D177FC" />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

export default withFirestore(BarChartTicketWeekly)
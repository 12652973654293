import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withFirestore } from 'react-redux-firebase'

import AddSession from './AddForm';
import ModalError from '../../components/ModalError';
import { uploadBanner } from './uploadBanner'
import { firestore } from 'firebase'
import moment from 'moment'
import Axios from 'axios'
import firebaseConfig from '../../firebaseConfig'
import { getNewAccessToken } from '../../utils/firebaseAdmin'

var generatePwd = function () {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return Math.random().toString(36).substr(2, 9);
};

const initialData = {
  nik: "",
  employeeNumber: "",
  npwp: "",
  familyCard: "",
  workDate: "",
  name: "",
  gender: "",
  position: "",
  department: "",
  subDepartment: "",
  birth: {
    dob: "",
    city: ""
  },
  maritalStatus: "",
  region: "",
  telephone: "",
  email: "",
  address: "",
  sector: "",
  departementList: [],
  subDepartementList: [],
  positionList: []
}

class AddScreen extends Component {

  state = {
    data: initialData,
    errorInfo: false
  }

  handleErrorInfo = () => {
    this.setState({ errorInfo: !this.state.errorInfo });
  }

  createFormData = ({
    avatar,
    confirmPassword,
    workDate,
    departementList,
    birth,
    subDepartementList,
    positionList,
    ...restData
  }) => {

    const data = {
      timestamp: new Date(),
      workDate: workDate ? moment(workDate).toDate() : "",
      birth: {
        dob: birth.dob ? moment(birth.dob).toDate() : "",
        city: birth.city,
      },
      password: generatePwd(),
      ...restData
    };
    return data;
  }

  getDataParams = (data) => {
    const dataParams = Object.keys(data).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
    }).join('&');
    return dataParams;
  }

  onSubmit = async (values) => {
    try {
      const snapshots = await firestore()
        .collection("region")
        .doc(values.region)
        .collection("employee")
        .where("email", "==", values.email)
        .get()
      var isAvailable = snapshots.empty
      if (isAvailable === false) {

        this.setState({ errorInfo: true, error: 'Email has already been taken!' })
        return;
      }

      const formData = this.createFormData(values)

      const userParams = this.getDataParams(formData)

      //get access token
      const { refreshToken } = this.props.auth.stsTokenManager;
      // console.log('refreshToken', refreshToken)
      const accessToken = await getNewAccessToken(refreshToken);
      // end get access token

      const url = 'https://us-central1-krmos-sandbox.cloudfunctions.net/employee/add';

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${accessToken}`,
        },
        body: userParams,
      })

      const responseJson = await response.json();

      if (response.status != 200) {
        throw new Error('Failed To create new User!');
      }

      if (values.avatar) {

        const path = `region/${values.region}/employee/${responseJson.uid}`
        const imagesUploaded = await uploadBanner(path, values.avatar);

        await this.props.firestore.update({
          collection: 'region',
          doc: values.region,
          subcollections: [{
            collection: 'employee',
            doc: responseJson.uid
          }],
        }, { avatar: imagesUploaded });
        console.log("Avatar uploaded successfully!")
      }
      this.props.history.push('/dashboard/employee')
    } catch (error) {
      this.setState({ errorInfo: true })

      console.error("Failed to add employee data: ", error);
    }
  }

  uploadAvatar = async (values, responseJson) => {

    // console.log("there is avatar")
    const path = `region/${values.region}/employee/${responseJson.uid}`
    const imagesUploaded = await uploadBanner(path, values.avatar);

    await this.props.firestore.update({
      collection: 'region',
      doc: values.region,
      subcollections: [{
        collection: 'employee',
        doc: responseJson.uid
      }],
    }, { avatar: imagesUploaded });
    console.log("Avatar uploaded successfully!")
  }

  fetchDataDepartement = async () => {
    let { region = "" } = this.props;
    try {
      const snapshots = await this.props.firestore.get({
        collection: 'region',
        doc: region,
        subcollections: [{
          collection: 'departement',
        }],
      });

      let departementList = []
      if (snapshots.empty) {
        console.log("no data")
        return;
      }
      snapshots.forEach((doc) => {
        departementList.push({
          id: doc.id,
          ...doc.data(),
          label: doc.data().name,
          value: doc.data().name,
          dataRef: doc.ref
        });
      });
      this.setState({
        data: { ...this.state.data, region, departementList }
      })
    } catch (error) {
      console.error(error)
      this.setState({
        data: {
          ...this.state.data,
          region,
        }
      })
    }
  }

  async componentDidMount() {
    let { region = "" } = this.props
    this.fetchDataDepartement()
    this.setState({
      data: {
        ...this.state.data,
        region
      }
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.region !== this.props.region) {
      this.setState({
        data: {
          ...this.state.data,
          region: nextProps.region,
        }
      })
    }
  }

  goBack = () => {
    this.props.history.push(`/dashboard/employee`)
  }

  render() {
    if (!this.props.uid) return null
    return (
      <React.Fragment>
        <div className="box-header px-5 mt-3" style={{ backgroundColor: "#EFF2F7" }}>
          <div className="row">
            <a onClick={this.goBack}><i class="fa fa-arrow-left" aria-hidden="true"></i></a>
            <h3 className="ml-3 mt-1" style={{ color: "#3B4859" }}>TAMBAH KARYAWAN</h3>
          </div>
        </div>
        {
          this.state.errorInfo &&
          <ModalError
            errorInfo={this.state.errorInfo}
            handleErrorInfo={this.handleErrorInfo}
            error={this.state.error}
          />
        }
        <div className="box-body">
          <AddSession onSubmit={this.onSubmit} initialValues={this.state.data} />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    uid: state.firebase.auth.uid,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    region: state.region.toLowerCase()
  }
}

export default compose(
  connect(mapStateToProps),
  withFirestore,
)(AddScreen)
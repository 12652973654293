export function validateAddForm(values) {
  
  const errors = {}
  if (!values.job) {
    errors.job = 'Job is Required'
  }
  if (!values.images) {
    errors.images = 'Images is Required'
  }

  return errors
      
}

export function validateUpdateForm(values) {
  
  const errors = {}
  if (!values.description) {
    errors.description = 'Description is Required'
  }
  if (!values.images) {
    errors.images = 'Images is Required'
  }

  return errors
      
}
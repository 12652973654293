import React, { Component } from 'react';
import { connect } from 'react-redux'
import { compose } from 'redux'
import moment from 'moment'
import { firestoreConnect } from 'react-redux-firebase'

import queryString from 'query-string'
import DataTable from './DataTable';
import DataTableDetail from './DataTableDetail';

import DownloadReport from '../../../../../components/DownloadReport'
import DatePicker from '../../../../../components/DatePicker'

class TicketingDetail extends Component {
    state = {
        customFields: [],
        startDate: moment(),
        endDate: moment(),
        fileName: "",
        dataTicket: [],
        loading: true,
        total: {
            asing: {
                qty: 0,
                amount: 0,
                tax: 0,
                assurance: 0
            },
            asing_group: {
                qty: 0,
                amount: 0
            },
            domestic: {
                qty: 0,
                amount: 0,
                tax: 0,
                assurance: 0
            },
            domestic_group: {
                qty: 0,
                amount: 0,
            },
            mobil: {
                qty: 0,
                amount: 0,
                tax: 0,
                assurance: 0
            },
            sepeda: {
                qty: 0,
                amount: 0,
                tax: 0
            },
            sepeda_motor: {
                qty: 0,
                amount: 0,
                tax: 0,
                assurance: 0
            },
            all: {
                perDay: 0,
                dayVisitor: 0,
                dayNonVisitor: 0,
                total_nett: 0
            }
        },
        regionList: [
            'Bogor',
            'Cibodas',
            'Purwodadi',
            'Bali'
        ],
        region: 'bogor'
    }

    filterByTicketCategory = (tariff_transactions, ticketData) => {
        let tempTicketData = ticketData
            //get transaction, store to ticket categories
            for (let index = 0; index < tariff_transactions.length; index++) {
                const item = tariff_transactions[index];
                const { qty, amount } = item
                switch (item.tariff) {
                    case "Asing":
                        tempTicketData.asing = item
                        break;
                    case "Asing Group":
                        tempTicketData.asing_group = item
                        break;
                    case "Domestik":
                        tempTicketData.domestic = item
                        break;
                    case "Domestik Group":
                        tempTicketData.domestic_group = item
                        break;
                    case "Mobil":
                        tempTicketData.mobil = item
                        break;
                    case "Sepeda":
                        tempTicketData.sepeda = item
                        break;
                    case "Sepeda Motor":
                        tempTicketData.sepeda_motor = item
                        break;
                    default:
                        break;
                }
            }
        return tempTicketData
    }

    fetchDataEvent = async () => {
        this.setState({ 
            loading: true, 
            dataTicket: [], 
            total: {
                asing: {
                    qty: 0,
                    amount: 0,
                    tax: 0,
                    assurance: 0
                },
                asing_group: {
                    qty: 0,
                    amount: 0
                },
                domestic: {
                    qty: 0,
                    amount: 0,
                    tax: 0,
                    assurance: 0
                },
                domestic_group: {
                    qty: 0,
                    amount: 0,
                },
                mobil: {
                    qty: 0,
                    amount: 0,
                    tax: 0,
                    assurance: 0
                },
                sepeda: {
                    qty: 0,
                    amount: 0,
                    tax: 0
                },
                sepeda_motor: {
                    qty: 0,
                    amount: 0,
                    tax: 0,
                    assurance: 0
                },
                all: {
                    perDay: 0,
                    dayVisitor: 0,
                    dayNonVisitor: 0,
                    total_nett: 0
                }
            }  
        })
        try {
            const snapshots = await this.props.firestore.get({
                collection: 'region',
                doc: this.state.region.toLowerCase(), //hardcode
                subcollections: [{
                    collection: 'revenueTickets',
                    orderBy: [
                        ['timestamp', 'asc']
                    ],
                    where: [
                        ['timestamp', '>=', moment(this.state.startDate).subtract(1, 'day').toDate()],
                        ['timestamp', '<=', moment(this.state.endDate).toDate()]
                    ]
                }]
            });
            let dataTicket = []

            snapshots.forEach((snap) => {
                const { tariff, timestamp } = snap.data()
                let { tariff_transactions, total_amount, total_nett } = tariff
                const day = timestamp ? moment(timestamp.toDate()).format("dddd") : "-"
                let ticketData = {
                    id: snap.id,
                    date_transaction: timestamp ? moment(timestamp.toDate()).format("DD MMMM YYYY") : "-",
                    day,
                    asing: {
                        qty: 0,
                        amount: 0,
                        tax: 0,
                        assurance: 0
                    },
                    asing_group: {
                        qty: 0,
                        amount: 0,
                        tax: 0
                    },
                    domestic: {
                        qty: 0,
                        amount: 0,
                        tax: 0,
                        assurance: 0
                    },
                    domestic_group: {
                        qty: 0,
                        amount: 0,
                        tax: 0
                    },
                    mobil: {
                        qty: 0,
                        amount: 0,
                        tax: 0,
                        assurance: 0
                    },
                    sepeda: {
                        qty: 0,
                        amount: 0,
                        tax: 0
                    },
                    sepeda_motor: {
                        qty: 0,
                        amount: 0,
                        tax: 0,
                        assurance: 0
                    },
                    total: {
                        perDay: total_amount,
                        dayVisitor: 0,
                        dayNonVisitor: 0,
                        total_nett
                    }
                }

                ticketData = this.filterByTicketCategory(tariff_transactions, ticketData)
                
                ticketData.total = {
                    ...ticketData.total,
                    dayVisitor: 
                        ticketData.domestic.amount + 
                        ticketData.domestic_group.amount + 
                        ticketData.asing_group.amount + 
                        ticketData.asing.amount + 
                        ticketData.asing_group.amount,
                    dayNonVisitor: 
                        ticketData.mobil.amount + 
                        ticketData.sepeda.amount + 
                        ticketData.sepeda_motor.amount,
                }

                dataTicket.push(ticketData)
            })

            //last row
            let total = this.state.total
            let detailTotal = this.state.detailTotal
            for (let i = 0; i < dataTicket.length; i++) {
                let element = dataTicket[i];
                //last column total
                total = {
                    asing: {
                        qty: total.asing.qty + element.asing.qty,
                        amount: total.asing.amount + element.asing.amount,
                        tax: total.asing.tax + element.asing.tax,
                        assurance: total.asing.assurance + element.asing.assurance
                    },
                    asing_group: {
                        qty: total.asing_group.qty + element.asing_group.qty,
                        amount: total.asing_group.amount + element.asing_group.amount
                    },
                    domestic: {
                        qty: total.domestic.qty + element.domestic.qty,
                        amount: total.domestic.amount + element.domestic.amount,
                        tax: total.domestic.tax + element.domestic.tax,
                        assurance: total.domestic.assurance + element.domestic.assurance
                    },
                    domestic_group: {
                        qty: total.domestic_group.qty + element.domestic_group.qty,
                        amount: total.domestic_group.amount + element.domestic_group.amount
                    },
                    mobil: {
                        qty: total.mobil.qty + element.mobil.qty,
                        amount: total.mobil.amount + element.mobil.amount,
                        tax: total.mobil.tax + element.mobil.tax,
                        assurance: total.mobil.assurance + element.mobil.assurance
                    },
                    sepeda: {
                        qty: total.sepeda.qty + element.sepeda.qty,
                        amount: total.sepeda.amount + element.sepeda.amount
                    },
                    sepeda_motor: {
                        qty: total.sepeda_motor.qty + element.sepeda_motor.qty,
                        amount: total.sepeda_motor.amount + element.sepeda_motor.amount,
                        tax: total.sepeda_motor.tax + element.sepeda_motor.tax,
                        assurance: total.sepeda_motor.assurance + element.sepeda_motor.assurance
                    },
                    all: {
                        perDay: total.all.perDay + element.total.perDay,
                        dayVisitor: total.all.dayVisitor + element.total.dayVisitor,
                        dayNonVisitor: total.all.dayNonVisitor + element.total.dayNonVisitor,
                        total_nett: total.all.total_nett + element.total.total_nett
                    }
                }
            }

            
            // qty: 0,
            // amount: 0,
            // tax: 0,
            // assurance: 0

            const customFields = [
                { label: 'Tanggal', value: 'date_transaction' },
                { label: 'Hari', value: 'day' },

                { label: 'Domestik Qty', value: 'domestic.qty' },
                { label: 'Domestik Harga', value: 'domestic.amount' },
                { label: 'Domestik Retribusi', value: 'domestic.tax' },
                { label: 'Domestik Asuransi', value: 'domestic.assurance' },

                { label: 'Domestik Grup Qty', value: 'domestic_group.qty' },
                { label: 'Domestik Grup Harga', value: 'domestic_group.amount' },
                { label: 'Domestik Retribusi', value: 'domestic_group.tax' },
                { label: 'Domestik Asuransi', value: 'domestic_group.assurance' },

                { label: 'Asing Qty', value: 'asing.qty' },
                { label: 'Asing Harga', value: 'asing.amount' },
                { label: 'Asing Retribusi', value: 'asing.tax' },
                { label: 'Asing Asuransi', value: 'asing.assurance' },
                
                { label: 'Asing Grup Qty', value: 'asing_group.qty' },
                { label: 'Asing Grup Harga', value: 'asing_group.amount' },
                { label: 'Asing Grup Retribusi', value: 'asing_group.tax' },
                { label: 'Asing Grup Asuransi', value: 'asing_group.assurance' },

                { label: 'Mobil Qty', value: 'mobil.qty' },
                { label: 'Mobil Harga', value: 'mobil.amount' },
                { label: 'Mobil Retribusi', value: 'mobil.tax' },
                { label: 'Mobil Asuransi', value: 'mobil.assurance' },
                
                { label: 'Motor Qty', value: 'sepeda_motor.qty' },
                { label: 'Motor Harga', value: 'sepeda_motor.amount' },
                { label: 'Motor Retribusi', value: 'sepeda_motor.tax' },
                { label: 'Motor Asuransi', value: 'sepeda_motor.assurance' },
                
                { label: 'Sepeda Qty', value: 'sepeda.qty' },
                { label: 'Sepeda Harga', value: 'sepeda.amount' },
                
            
                { label: 'Total Per Hari', value: 'total.perDay' },
                { label: 'Total Harian Pengunjung', value: 'total.dayVisitor' },
                { label: 'Total Harian Non Pengunjung', value: 'total.dayNonVisitor' },
                { label: 'Total Nett Harga', value: 'total.total_nett' },
            ]

            this.setState({
                dataTicket,
                customFields,
                fileName: "report-detail-" + this.state.region.toLowerCase(),
                total,
                loading: false
            })
        } catch (error) {
            console.error(error)
            this.setState({
                dataTicket: [],
                loading: false
            })
        }
    }
    
    componentDidMount() {
        let { region = "bogor" } = this.props.match.params;
        const values = queryString.parse(this.props.location.search)
        region = region.charAt(0).toUpperCase() + region.slice(1);
        this.setState({
            region,
            startDate: values.startDate ? moment(values.startDate, "YYYY-MMMM-DD") : moment(),
            endDate: values.endDate ? moment(values.endDate, "YYYY-MMMM-DD") : moment(),
        }, () => this.fetchDataEvent())
    }

    onDatesChange = ({ startDate, endDate }) => {
        this.setState({ startDate, endDate }, () => this.fetchDataEvent())
    }
    onChangeRegion = (e) => {
        this.setState({ region: e.target.value }, () => this.fetchDataEvent())
    }
    render() {
        return (
            <React.Fragment>
                <div className="box-header px-5 mt-3" style={{ backgroundColor: "#EFF2F7" }}>
                    <h3 style={{ color: "#3B4859" }}>REVENUE (LAPORAN DETAIL)</h3>
                </div>
                <div className="container-fluid px-5 spacing-resp">
                    <div className="row mb-4">
                        <div className="col-lg-4 col-md-6 mt-5">
                            <div className="row">
                                <div className="col-5"><h6 style={{ fontSize: "0.8rem", color: "#3B4859" }}>Dari</h6></div>
                                <div className="col-5"><h6 style={{ fontSize: "0.8rem", color: "#3B4859" }}>Sampai</h6></div>
                            </div>
                            <DatePicker 
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                onDatesChange={this.onDatesChange} 
                            />
                        </div>
                        <div className="col-md-2 d-flex align-items-end mt-5">
                            <select className="form-control input-c" value={this.state.region} onChange={this.onChangeRegion} style={{ borderRadius: "6px", border: "1px solid #A9A9AB" }}>
                                {
                                    this.state.regionList.map((region, i) => (<option key={i} value={region}>{region}</option>))
                                }
                            </select>
                        </div>
                        <div className="col-lg-3 col-md-6 d-flex align-items-end mt-5">
                            <DownloadReport
                                data={this.state.dataTicket}
                                fields={this.state.customFields}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                fileName={this.state.fileName}
                            />
                        </div>
                    </div>

                    <div className="py-3">
                        <div className="box p-3 mb-0" style={{ borderRadius: 10 }}>
                            <div className="table-responsive">
                                <DataTable date={this.state.startDate} data={this.state.dataTicket} loading={this.state.loading} total={this.state.total} />
                            </div>
                        </div>
                    </div>

                    <div className="py-3">
                        <div className="box p-3 mb-0" style={{ borderRadius: 10 }}>
                            <div className="table-responsive">
                                <DataTableDetail date={this.state.startDate} data={this.state.dataTicket} loading={this.state.loading} total={this.state.total} />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


const mapStateToProps = state => {
    return { 
        auth: state.firebase.auth, 
        profile: state.firebase.profile
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect()
)(TicketingDetail)
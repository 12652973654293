import React from "react"
import NumberFormat from 'react-number-format'
import { Link } from "react-router-dom"
import moment from "moment";

const TableItem = (props) => {
    let date_transaction = moment(props.item.date_purchase, "YYYY-MM-DD").format("DD MMM YYYY");
    let day = moment(props.item.date_purchase, "YYYY-MM-DD").format("dddd");
    return (
        <tr key={props.item.id} className="text-center">
            <td>{date_transaction}</td>
            <td>{day}</td>
            <td>
                <NumberFormat 
                    value={props.item.amount}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
                {/* <NumberFormat 
                    value={props.item.price}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                /> */}
            </td>
            <td>
                {/* <NumberFormat 
                    value={props.item.price}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                /> */}
            </td>
            <td>
                {/* <NumberFormat 
                    value={props.item.price}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                /> */}
            </td>
        </tr>
    )
}

{/* <tr key={props.item.id} className="text-center">
<td>{date_transaction}</td>
<td>{props.item.transaction_number}</td>
<td>-</td>
<td>{props.item.tariff}</td>
<td>{props.item.payment}</td>
<td>{props.item.qty || "-"}</td>
<td>
    <NumberFormat 
        value={props.item.price}
        displayType={'text'} 
        thousandSeparator={true} 
        prefix={'Rp'} 
        suffix={",-"} 
        defaultValue="0" 
    />
</td>
<td>-</td>
<td>
    <NumberFormat 
        value={props.item.assurance}
        displayType={'text'} 
        thousandSeparator={true} 
        prefix={'Rp'} 
        suffix={",-"} 
        defaultValue="0" 
    />
</td>
<td>
    <NumberFormat 
        value={props.item.tax}
        displayType={'text'} 
        thousandSeparator={true} 
        prefix={'Rp'} 
        suffix={",-"} 
        defaultValue="0" 
    />
</td>
<td>
    <NumberFormat 
        value={props.item.fix_price}
        displayType={'text'} 
        thousandSeparator={true} 
        prefix={'Rp'} 
        suffix={",-"} 
        defaultValue="0" 
    />
</td>
</tr> */}

export default TableItem
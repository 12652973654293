import React, { Component } from 'react'
import { DateRangePicker, SingleDatePicker, isInclusivelyAfterDay } from 'react-dates'

import moment from 'moment'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { firestoreConnect } from 'react-redux-firebase'
import BarChartTicket from './BarChartTicket'
import BarChartTicketWeekly from './BarChartTicketWeekly'
import BarChartTicketMonthly from './BarChartTicketMonthly'
import BarChartTicketYearly from './BarChartTicketYearly'
import PieChart from './PieChart'
import PosBarChart from './PosBarChart'
const data = [
    { id: 'bogor', name: 'Kebun Raya Bogor', value: 500 },
    { id: 'cibodas', name: 'Kebun Raya Cibodas', value: 200 },
    { id: 'purwodadi', name: 'Kebun Raya Purwodadi', value: 300 },
    { id: 'bali', name: 'Kebun Raya Bali', value: 400 },
  ];

const regionList = [
    'Bogor',
    'Cibodas',
    'Purwodadi',
    'Bali'
]
class TicketAnalytics extends Component {
    state = {
        startDate: moment().startOf('year'),
        posDate: moment(),
        endDate: moment(),
        dataTicket: [],
        dataAllRegion: [],
        region: 'All Region',
        // region: 'Bogor',
        regionList: [
            'All Region',
            'Bogor',
            'Cibodas',
            'Purwodadi',
            'Bali'
        ],
    }
    fetchRevenueSelectedRegion = async () => {
        const snapshots = await this.props.firestore
            .get({
            collection: 'region',
            doc: this.state.region.toLowerCase(), //hardcode
            subcollections: [{
                collection: 'revenueTickets',
                orderBy: [['timestamp', 'asc']],
            }]
        }, { source: 'cache' });
        const dataTicket = []
        snapshots.forEach(element => {
            dataTicket.push({
            id: element.id,
            ...element.data()
            })
        });
        return dataTicket
    }

    fetchRevenueAllRegion = async () => {
        const snapshots = await this.props.firestore
        .get({
            collectionGroup: 'revenueTickets',
            storeAs: 'all-revenueTickets',
        });
        const dataTicket = []
        snapshots.forEach(element => {
            dataTicket.push({
                region: element.ref.parent.parent.id,
                id: element.id,
                ...element.data()
            }) 
        });
        // console.log('dataTicket', dataTicket)
        this.setState({
            dataAllRegion: dataTicket,
        })
        return dataTicket
    }

    fetchData = async () => {
        this.setState({ dataTicket: [] })
        try {
            let dataTicket = []

            if (this.state.region === 'All Region') {
                console.log("all region")
                dataTicket = await this.fetchRevenueAllRegion()
            } else if(regionList.some(currRegion => currRegion === this.state.region)) {
                console.log("bukan")
                dataTicket = await this.fetchRevenueSelectedRegion()
            } else {
                console.log("bukan juga")
            }
            console.log(this.state.region)
            
            this.setState({
                dataTicket,
                loading: false
            })
            
        } catch (error) {
            console.error(error)
            this.setState({
                dataTicket: [],
                loading: false
            })
        }
    }
    onChangeRegion = (e) => {
        this.setState({ region: e.target.value }, () => this.fetchData())
    }
    handleFocusChange = focusedInput => {
        this.setState({ focusedInput: focusedInput });
    }
    componentDidMount() {
        this.fetchData()
    }
    onDateChange = (date) => {
        this.setState({ posDate: date })
    }


    restrictDateRange = (day) => {
        return isInclusivelyAfterDay(day, moment().add(1, 'days')) 
        || 
            day.isBefore(moment().startOf('year'))
        // || 
        //     day.isBefore(moment().startOf('month').add(-3, 'month'))
    }
    render() {
        return (
            <React.Fragment>
                <div className="box-header px-5 mt-3" style={{ backgroundColor: "#EFF2F7" }}>
                    <h3 style={{ color: "#3B4859" }}>REVENUE (ANALITIK)</h3>
                </div>
                <div className="container-fluid p-5 spacing-resp">                    
                    <div className="row mb-4">
                        <div className="col-lg-4 col-md-6">
                            <div className="row">
                                <div className="col-5"><h6 style={{ fontSize: "0.8rem", color: "#3B4859" }}>Dari</h6></div>
                                <div className="col-5"><h6 style={{ fontSize: "0.8rem", color: "#3B4859" }}>Sampai</h6></div>
                            </div>
                            <DateRangePicker
                                customArrowIcon={<i  style={{ fontSize: 10 }} className='mx-3 fas'>&#xf362;</i>}
                                startDateId="start-date-analytics"
                                endDateId="end-date-analytics"
                                displayFormat="DD/MM/YYYY"
                                disabled
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                onFocusChange={this.handleFocusChange}
                            />
                        </div>
                        <div className="col-md-3 offset-md-4">
                            <h5 className="mb-2" style={{ fontWeight: "bold", color: "#3B4859" }}>Pilih Kebun Raya:</h5>
                            <div>
                                <select
                                    className="form-control input-c"
                                    value={this.state.region} 
                                    onChange={this.onChangeRegion} 
                                    style={{ color: "#A9A9AB", borderRadius: "6px", border: "1.5px solid #A9A9AB" }}
                                >
                                    {
                                        this.state.regionList.map((region, i) => (<option key={i} value={region}>{region}</option>))
                                    }
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 py-4 box mt-4" style={{ borderRadius: 10 }}>
                        <div className="col-12">
                            <h2 style={{
                                color: "#3B4859",
                                fontWeight: "bold",
                                fontSize: "20px",
                                lineHeight: "24px"
                            }}>Jumlah Penjualan Tiket
                            </h2>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <PieChart
                                    data={this.state.dataAllRegion}
                                    startDate={this.state.startDate} 
                                    endDate={this.state.endDate} 
                                />
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div style={{ height: 20, width: 20, backgroundColor: "#D7CABF", borderRadius: 50 }}></div>
                                    <p className="ml-2" style={{ fontWeight: 500 }}>Kebun Raya Bogor</p>
                                </div>
                                <div className="row mt-3">
                                    <div style={{ height: 20, width: 20, backgroundColor: "#00ACD9", borderRadius: 50 }}></div>
                                    <p className="ml-2" style={{ fontWeight: 500 }}>Kebun Raya Cibodas</p>
                                </div>
                                <div className="row mt-3">
                                    <div style={{ height: 20, width: 20, backgroundColor: "#B3B731", borderRadius: 50 }}></div>
                                    <p className="ml-2" style={{ fontWeight: 500 }}>Kebun Raya Purwodadi</p>
                                </div>
                                <div className="row mt-3">
                                    <div style={{ height: 20, width: 20, backgroundColor: "#E4A314", borderRadius: 50 }}></div>
                                    <p className="ml-2" style={{ fontWeight: 500 }}>Kebun Raya Bali</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 py-4 box mt-4" style={{ borderRadius: 10 }}>
                        <div className="col-12">
                            <h2 style={{
                                color: "#3B4859",
                                fontWeight: "bold",
                                fontSize: "20px",
                                lineHeight: "24px"
                            }}>Total Tiket Non Member
                            </h2>
                            
                            <div className="col-md-8 mt-4">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="row mt-3">
                                            <div style={{ height: 20, width: 20, backgroundColor: "#96CEB4", borderRadius: 50 }}></div>
                                            <p className="ml-2" style={{ fontWeight: 500 }}>Domestik</p>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="row mt-3">
                                            <div style={{ height: 20, width: 20, backgroundColor: "#FFEEAD", borderRadius: 50 }}></div>
                                            <p className="ml-2" style={{ fontWeight: 500 }}>Domestik Grup</p>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="row mt-3">
                                            <div style={{ height: 20, width: 20, backgroundColor: "#FF6F69", borderRadius: 50 }}></div>
                                            <p className="ml-2" style={{ fontWeight: 500 }}>Asing</p>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="row mt-3">
                                            <div style={{ height: 20, width: 20, backgroundColor: "#D177FC", borderRadius: 50 }}></div>
                                            <p className="ml-2" style={{ fontWeight: 500 }}>Asing Grup</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 mb-5">
                                <h2 style={{
                                    color: "#3B4859",
                                    fontWeight: "bold",
                                    fontSize: "20px",
                                    lineHeight: "24px"
                                }}>Harian
                                </h2>
                            </div>

                        </div>

                        <BarChartTicket 
                            loading={this.state.loading} 
                            region={this.state.region} 
                            data={this.state.dataTicket} 
                        />

                        <div className="col-12 mb-5">
                            <h2 style={{
                                color: "#3B4859",
                                fontWeight: "bold",
                                fontSize: "20px",
                                lineHeight: "24px"
                            }}>Mingguan
                            </h2>
                        </div>

                        <BarChartTicketWeekly 
                            loading={this.state.loading} 
                            region={this.state.region} 
                            data={this.state.dataTicket} 
                        />

                        <div className="col-12 mb-5">
                            <h2 style={{
                                color: "#3B4859",
                                fontWeight: "bold",
                                fontSize: "20px",
                                lineHeight: "24px"
                            }}>Bulanan
                            </h2>
                        </div>

                        <BarChartTicketMonthly 
                            loading={this.state.loading} 
                            region={this.state.region} 
                            data={this.state.dataTicket} 
                        />

                        <div className="col-12 mb-5">
                            <h2 style={{
                                color: "#3B4859",
                                fontWeight: "bold",
                                fontSize: "20px",
                                lineHeight: "24px"
                            }}>Tahunan
                            </h2>
                        </div>

                        <BarChartTicketYearly 
                            loading={this.state.loading} 
                            region={this.state.region}
                            data={this.state.dataTicket} 
                        />
                    </div>

                    <div className="col-12 py-5 box my-4" style={{ borderRadius: 10 }}>
                        <div className="col-12 mb-5">
                            <h2 style={{
                                color: "#3B4859",
                                fontWeight: "bold",
                                fontSize: "20px",
                                lineHeight: "24px"
                            }}>POS
                            </h2>
                        </div>
                            
                        <SingleDatePicker
                            numberOfMonths={2}
                            // withPortal={true}
                            id="single_date_report" // PropTypes.string.isRequired,
                            displayFormat="DD/MM/YYYY"
                            isDayBlocked={() => false}
                            date={this.state.posDate}
                            // isOutsideRange={() => false}
                            enableOutsideDays={true}
                            isOutsideRange={this.restrictDateRange}
                            focused={this.state.focusedPosInput}
                            onDateChange={this.onDateChange}
                            onFocusChange={({ focused: focusedPosInput }) => this.setState({ focusedPosInput })}
                        />
                        {
                            this.state.region == "All Region" ?
                                <React.Fragment>
                                    <div style={{ maxWidth: "400px" }}>
                                        <h5 className="mb-2" style={{ fontWeight: "bold", color: "#3B4859" }}>Pilih Kebun Raya:</h5>
                                        <div>
                                            <select
                                                className="form-control input-c"
                                                value={this.state.region} 
                                                onChange={this.onChangeRegion} 
                                                style={{ color: "#A9A9AB", borderRadius: "6px", border: "1.5px solid #A9A9AB" }}
                                            >
                                                {
                                                    this.state.regionList.map((region, i) => (<option key={i} value={region}>{region}</option>))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <h1 className="mt-4 text-center">Please select the region.</h1>
                                </React.Fragment>
                                :
                                <PosBarChart region={this.state.region} regionList={regionList} date={this.state.posDate} />
                        }
                    </div>

                    {/* <div className="col-12 py-4 box mt-4" style={{ borderRadius: 10 }}>
                        <div className="row mb-5">
                            <div className="col-6">
                                <h2 style={{
                                    color: "#3B4859",
                                    fontWeight: "bold",
                                    fontSize: "20px",
                                    lineHeight: "24px"
                                }}>TURNSTILE GATE
                                </h2>
                            </div>
                            <div className="col-6">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="row mt-3">
                                            <div style={{ height: 20, width: 20, backgroundColor: "#2C6E7C", borderRadius: 50 }}></div>
                                            <p className="ml-2" style={{ fontWeight: 500 }}>Member</p>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="row mt-3">
                                            <div style={{ height: 20, width: 20, backgroundColor: "#FF3F3F", borderRadius: 50 }}></div>
                                            <p className="ml-2" style={{ fontWeight: 500 }}>Non Member</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <StackedBarChartTrunstile />
                        </div>
                    </div> */}

                    {/* <div className="col-12 py-4 box mt-4" style={{ borderRadius: 10 }}>
                        <div className="col-12 mb-5">
                            <h2 style={{
                                color: "#3B4859",
                                fontWeight: "bold",
                                fontSize: "20px",
                                lineHeight: "24px"
                            }}>BARRIER GATE
                            </h2>
                        </div>
                        <div className="container">
                            <BarrierBarChart />
                        </div>
                    </div> */}
                </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        region: state.region
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect()
)(TicketAnalytics)
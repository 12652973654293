import React, { useState } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'

import SubmitButton from '../../components/SubmitButton'
import LoaderModal from '../../components/LoaderModal'
import validate, { asyncValidate } from './validate'
import TextInput from '../../components/Form/TextInput'
import NumberInput from '../../components/Form/NumberInput'
import AvatarPicker from '../../components/AvatarPicker'
import RadioButton from '../../components/Form/RadioButton'
import DatePicker from '../../components/Form/DatePicker'
import SelectMenu from '../../components/Form/SelectMenuInput'
import SelectPositionInput from '../../components/Form/SelectPositionInput'
import { firestore } from 'firebase'

let AddForm = ({ 
  handleSubmit, 
  pristine, 
  submitting, 
  valid, 
  change,
  region = "", 
  subDepartment,
  departementList, 
  subDepartmentList,
  fetchData,
  ...otherProps 
}) => {
  let selectedRegion = region.charAt(0).toUpperCase() + region.substring(1)
  return (
    <React.Fragment>
      <form role="form" onSubmit={handleSubmit}>
        <div className="col-sm-12">
          <div className="p-5" style={{ borderRadius: 5 }}>
            <Field
              component={AvatarPicker}
              label="Avatar"
              name="avatar"
            />
            
            <div className="form-group row mb-4">
              <label 
                htmlFor={`input-region`} 
                className="col-md-2" 
                style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold" }}
              >
                Region
              </label>
              <div className="col-md-10">
                <p id={`input-region`} style={{ fontSize: "1.1em" }}>{selectedRegion}</p>
              </div>
            </div>
            <Field
              name="nik"
              component={NumberInput}
              placeholder="NIK"
              label="NIK"
              style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
            />
            <Field
              name="employeeNumber"
              component={NumberInput}
              placeholder="No Karyawan"
              label="No Karyawan"
              style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
            />
            <Field
              name="npwp"
              component={NumberInput}
              placeholder="NPWP"
              label="NPWP"
              style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
            />
            <Field
              name="familyCard"
              component={TextInput}
              placeholder="Kartu Keluarga"
              label="Kartu Keluarga"
              style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
            />
            <Field
              name="workDate"
              component={DatePicker}
              isOutsideDays={true}
              placeholder="Tgl Masuk Kerja"
              label="Tanggal Masuk Kerja"
              style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
            />

            <hr />
            
            <Field
              name="name"
              component={TextInput}
              placeholder="Nama"
              label="Nama"
              style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
            />
            
            <Field
              name="gender"
              component={RadioButton}
              placeholder="Jenis Kelamin"
              label="Jenis Kelamin"
              data={[
                { name: "Pria", value: "Pria"},
                { name: "Wanita", value: "Wanita"},
              ]}
              style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
            />
            

            <div className="form-group row mb-4">
              <label for="input-Jabatan" class="col-md-2 form-control-label" style={{color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold"}}>Departemen</label>
              <div className="col-md-4">
                <Field
                  component={SelectMenu}
                  label="Department"
                  placeholder="Please select department"
                  items={departementList}
                  name="department"
                />
              </div>
            </div>

            {
              subDepartmentList.length > 0 &&
              <div className="form-group row mb-4">
                <label for="input-Jabatan" class="col-md-2 form-control-label" style={{color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold"}}>Sub Departemen</label>
                <div className="col-md-4">
                  <Field
                    component={SelectMenu}
                    label="Sub Department"
                    placeholder="Please select sub department"
                    items={subDepartmentList}
                    // fetchData={fetchDataPosition}
                    name="subDepartment"
                  />
                </div>
              </div>
            }   
            {/* <Field
              name="position"
              component={TextInput}
              placeholder="Jabatan"
              label="Jabatan"
              style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
            /> */}
            {
              subDepartment && 
              <div className="form-group row mb-4">
                <label for="input-Jabatan" class="col-md-2 form-control-label" style={{color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold"}}>Jabatan</label>
                <div className="col-md-4">
                  <Field
                    component={SelectPositionInput}
                    label="Jabatan"
                    placeholder="Please select Jabatan"
                    name="position"
                    change={change}
                    region={region}
                    dataId={subDepartment}
                  />
                </div>
              </div>
            }
            
            <Field
              name="birth.dob"
              component={DatePicker}
              placeholder="Tanggal Lahir"
              label="Tanggal Lahir"
              isOutsideDays={true}
              style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
            />
            
            <Field
              name="birth.city"
              component={TextInput}
              placeholder="Tempat Lahir"
              label="Tempat Lahir"
              style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
            />
            
            <div className="form-group row mb-4">
              <label for="input-Jabatan" class="col-md-2 form-control-label" style={{color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold"}}>Marital Status</label>
              <div className="col-md-4">
                <Field
                  component={SelectMenu}
                  label="Marital Status"
                  placeholder="Please select marital status"
                  items={[
                    { label: "Belum Menikah", value: "Belum Menikah" },
                    { label: "Sudah Menikah", value: "Sudah Menikah" }
                  ]}
                  name="maritalStatus"
                />
              </div>
            </div>
            
            <Field
              name="telephone"
              component={NumberInput}
              placeholder="No Telepon"
              label="No Telepon"
              style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
            />
            <hr />
            <Field
              name="email"
              component={TextInput}
              placeholder="Email"
              label="Email"
              style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
            />
            
            <hr />

            <Field
              name="address"
              component={TextInput}
              placeholder="Alamat"
              label="Alamat"
              style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
            />
            
            <Field
              name="sector"
              component={TextInput}
              placeholder="Sector Kebun Raya"
              label="Sector Kebun Raya"
              style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
            />

            <div className="col-lg-6 col-xs-12 px-0">
              <div className="col-lg-3 col-xs-12 px-0 mt-5 text-center">
                <SubmitButton disabled={!valid || submitting} />
              </div>
              <LoaderModal submitting={submitting} />
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  )
}

AddForm = reduxForm({
  form: 'addEmployeeForm',
  validate,
  asyncValidate,
  asyncBlurFields: ['email'],
  enableReinitialize: true,
})(AddForm)

const selector = formValueSelector('addEmployeeForm')
AddForm = connect(
  state => {

    const region = selector(state, 'region') || "";
    const department = selector(state, 'department') || "";
    const positionList = selector(state, 'positionList') || [];
    const subDepartment = selector(state, 'subDepartment') || "";
    const departementList = selector(state, 'departementList') || [];

    let subDepartmentList = selector(state, 'subDepartmentList') || []
    
    if (department) {
      for (let i = 0; i < departementList.length; i++) {
        const element = departementList[i];
        if (department == element.value) {
          if (element.departementList) {
              
            subDepartmentList = element.departementList.map((item) => {
              return { label: item, value: item }
            })
            break;
          }
        }
      }
    }
    
    return {
      region,
      departementList,
      positionList,
      subDepartment,
      subDepartmentList
    }
  }
)(AddForm)

export default connect()(AddForm)
export default function validate(values) {
    
    const errors = {}
    
    if (!values.activity) {
      errors.species = 'Kegiatan wajib diisi.'
    }
    if (!values.description) {
      errors.name = 'Keterangan wajib diisi.'
    }

    return errors  
}
import React from 'react'
import NumberFormat from 'react-number-format'

const TableItem = (props) => {
    
    return (
        <tr key={props.item.id} className="text-center">
            <td>{props.item.date}</td>
            <td>{props.item.bogor && props.item.bogor.qty}</td>
            <td>
                <NumberFormat 
                    value={props.item.bogor && props.item.bogor.amount}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>
            <td>{props.item.purwodadi && props.item.purwodadi.qty}</td>
            <td>
                <NumberFormat 
                    value={props.item.purwodadi && props.item.purwodadi.amount}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>
            <td>{props.item.cibodas && props.item.cibodas.qty}</td>
            <td>
                <NumberFormat 
                    value={props.item.cibodas && props.item.cibodas.amount}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>
            <td>{props.item.bali && props.item.bali.qty}</td>
            <td>
                <NumberFormat 
                    value={props.item.bali &&props.item.bali.amount}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>
            <td>
                {props.item.totalQty || "0"}
            </td>
            <td>
                <NumberFormat 
                    value={props.item.totalAmount}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>
        </tr>
    )
}

export default TableItem
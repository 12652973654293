import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Link } from "react-router-dom"
import { withFirestore } from 'react-redux-firebase'
import { stateToHTML } from 'draft-js-export-html';
import { convertFromRaw } from 'draft-js';

import Delete from './Delete';
import DailyReport from './DailyReport'
import ModalError from '../../../components/ModalError';
import LoadingDetailAnimal from '../../../components/loading-detail-animal'
import placeholderPlant from "../../../assets/img/plant_placeholder.png"

class DetailScreen extends Component {
  state = {
    selectedRegion: '',
    dailyReportData: [],
    loading: true,
    errorInfo: false,
    data: {
      name: '',
      species: '',
      englishName: '',
      latinName: '',
      genus: '',
      dob: '',
      category: '',
      species: '',
      sector: '',
      vak: '',
      collectionNumber: '',
      specimenTotal: '',
      status: '',
      habitus: '',
      deployment: '',
      usability: '',
      description: '',
      place: '',
      dataRef: null,
    },
  }

  componentDidMount() {
    this.fetchData();
    this.fetchDailyReport();
  }

  fetchDailyReport = async () => {
    try {
      const { sessionId } = this.props.match.params;
      const { selectedRegion } = this.props.location.state;

      const snapshots = await this.props.firestore.get({
        collection: 'region',
        doc: selectedRegion,
        subcollections: [{
          collection: 'plants',
          doc: sessionId,
          subcollections: [{
            collection: 'dailyReport',
          }]
        }]
      });

      if (snapshots.empty) {
        this.setState({ loading: false })
        return;
      }

      let dailyReportData = [];

      snapshots.forEach((snap) => {
        dailyReportData.push({
          ...snap.data(),
          dataRef: snap.ref,
          id: snap.id,
        })
      })

      this.setState({ dailyReportData, loading: false });
    } catch (error) {
    }
  }

  fetchData = async () => {
    try {
      const { sessionId } = this.props.match.params;
      const { selectedRegion } = this.props.location.state;
      const snapshot = await this.props.firestore.get({
        collection: 'region',
        doc: selectedRegion,
        subcollections: [{ collection: 'plants', doc: sessionId }]
      });

      if (!snapshot.exists) {
        this.setState({ loading: false })
        return;
      }

      const {
        avatar,
        genus,
        species,
        ...restData
      } = snapshot.data()

      let data = {
        id: snapshot.id,
        avatar: avatar ? avatar.url : "",
        ...restData,
        dataRef: snapshot.ref,
        genus: typeof genus === "object" ? this.convertCommentFromJSONToHTML(genus) : "-",
        species: typeof genus === "object" ? this.convertCommentFromJSONToHTML(species) : "-",
      }

      this.setState({
        loading: false,
        data: { ...this.state.data, ...data },
        selectedRegion
      });

    } catch (error) {
      this.setState({ loading: false })
    }
  }

  handleImageError = () => {
    this.setState({
      data: {
        ...this.state.data,
        avatar: placeholderPlant
      }
    })
  }

  goBack = () => {
    this.props.history.push(`/dashboard/plant_info`)
  }

  convertCommentFromJSONToHTML = (text) => stateToHTML(convertFromRaw(text))

  render() {
    if (!this.props.uid) return null
    const { sessionId } = this.props.match.params;
    const { data, loading } = this.state;
    if (loading) {
      return (
        <div className="app-body">
          <LoadingDetailAnimal />
        </div>
      );
    }
    return (
      <React.Fragment>
        <div className="box-header px-5 mt-3" style={{ backgroundColor: "#EFF2F7" }}>
          <div className="row">
            <a onClick={this.goBack}><i class="fa fa-arrow-left" aria-hidden="true"></i></a>
            <h3 className="ml-3 mt-1" style={{ color: "#3B4859" }}>KONSERVASI TUMBUHAN</h3>
          </div>
        </div>
        {
          this.state.errorInfo &&
          <ModalError
            errorInfo={this.state.errorInfo}
            handleErrorInfo={this.handleErrorInfo}
          />
        }
        <div className="box-body">
          <div className="row">
            <div className="offset-lg-9 col-lg-3" style={{ paddingRight: "15px" }}>
              {
                ((this.props.profile.token && this.props.profile.token.claims.admin === true) ||
                  (this.props.profile.token && this.props.profile.token.claims.department === "Konservasi")
                ) ?
                  <Delete deleteRef={data.dataRef} />
                  :
                  null
              }
            </div>
          </div>
          <div className="col-12" style={{ marginTop: 15 }}>
            <div className="row">
              <div className="col-lg-8 col-md-12 col-sm-12">
                <div className="box p-4" style={{ borderRadius: 5 }}>
                  <h5 className="text-kr" style={{ fontWeight: "bold" }}>Detail Tumbuhan</h5>

                  <hr className="my-4" />

                  <div className="row">
                    <div className="col-lg-4">
                      <img src={data.avatar} onError={this.handleImageError} className="plant-detail" />
                    </div>

                    <div className="col-lg-8 col-md-12 col-sm-12 mt-sm-3">
                      <div className="row">
                        <div className="col-md-4 col-xs-12">
                          <h6 className="text-kr" style={{ fontWeight: "bold" }}>Vak</h6>
                          <p>{data.vak || "-"}</p>
                        </div>
                        <div className="col-md-4 col-xs-12">
                          <h6 className="text-kr" style={{ fontWeight: "bold" }}>No Koleksi</h6>
                          <p>{data.collectionNumber || "-"}</p>
                        </div>
                        <div className="col-md-4 col-xs-12">
                          <h6 className="text-kr" style={{ fontWeight: "bold" }}>Spesies</h6>
                          <div dangerouslySetInnerHTML={{ __html: data.species }}></div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4 col-xs-12">
                          <h6 className="text-kr" style={{ fontWeight: "bold" }}>Nama Lokal</h6>
                          <p>{data.name || "-"}</p>
                        </div>
                        <div className="col-md-4 col-xs-12">
                          <h6 className="text-kr" style={{ fontWeight: "bold" }}>Nama Inggris</h6>
                          <p>{data.englishName || "-"}</p>
                        </div>
                        {/* <div className="col-md-4 col-xs-12">
                          <h6 className="text-kr" style={{ fontWeight: "bold" }}>Nama Latin</h6>
                          <p>{data.latinName || "-"}</p>
                        </div> */}
                        <div className="col-md-4 col-xs-12">
                          <h6 className="text-kr" style={{ fontWeight: "bold" }}>Genus</h6>
                          <div dangerouslySetInnerHTML={{ __html: data.genus }}></div>
                        </div>
                      </div>

                      <div className="row mt-4">
                        {/* <div className="col-md-4 col-xs-12">
                          <h6 className="text-kr" style={{ fontWeight: "bold" }}>Genus</h6>
                          <div dangerouslySetInnerHTML={{ __html: data.genus }}></div>
                        </div> */}
                        <div className="col-md-4 col-xs-12">
                          <h6 className="text-kr" style={{ fontWeight: "bold" }}>Kategori</h6>
                          <p>{data.category || "-"}</p>
                        </div>
                        <div className="col-md-4 col-xs-12">
                          <h6 className="text-kr" style={{ fontWeight: "bold" }}>Sektor</h6>
                          <p>{data.sector || "-"}</p>
                        </div>
                        <div className="col-md-4 col-xs-12">
                          <h6 className="text-kr" style={{ fontWeight: "bold" }}>Jumlah Spesimen</h6>
                          <p>{data.specimenTotal || "-"}</p>
                        </div>
                      </div>

                      <div className="row mt-4">
                        {/* <div className="col-md-4 col-xs-12">
                          <h6 className="text-kr" style={{ fontWeight: "bold" }}>Sektor</h6>
                          <p>{data.sector || "-"}</p>
                        </div>
                        <div className="col-md-4 col-xs-12">
                          <h6 className="text-kr" style={{ fontWeight: "bold" }}>Jumlah Spesimen</h6>
                          <p>{data.specimenTotal  || "-"}</p>
                        </div> */}
                        <div className="col-md-4 col-xs-12">
                          <h6 className="text-kr" style={{ fontWeight: "bold" }}>Status</h6>
                          <p>{data.status || "-"}</p>
                        </div>
                        <div className="col-md-4 col-xs-12">
                          <h6 className="text-kr" style={{ fontWeight: "bold" }}>Habitus</h6>
                          <p>{data.habitus || "-"}</p>
                        </div>
                      </div>
                      {/* <div className="row mt-4">
                        <div className="col-md-4 col-xs-12">
                          <h6 className="text-kr" style={{ fontWeight: "bold" }}>Habitus</h6>
                          <p>{data.habitus  || "-"}</p>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12 pl-0 mt-4">
                    <ul className="nav navbar-nav mr-auto">

                      {
                        ((this.props.profile.token && this.props.profile.token.claims.admin === true) ||
                          (this.props.profile.token && this.props.profile.token.claims.department === "Konservasi")
                        ) ?
                          <li className="nav-item">
                            <Link to={`/dashboard/plant_info/${sessionId}/update`} className="nav-link pt-0">
                              <button className="md-btn md-raised m-b-sm w-xs kr-color" style={{ width: "100%", borderRadius: 5 }}>
                                <div style={{ color: "white", fontSize: '0.7rem', fontWeight: "100" }}>
                                  <i className="fa fa-pencil-square-o" style={{ opacity: 1, marginRight: 5, color: "white" }} />Edit
                              </div>
                              </button>
                            </Link>
                          </li>
                          : null
                      }
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12"></div>
            </div>

            <div className="box p-4" style={{ borderRadius: 5 }}>
              <div className="mt-2">
                <h6 className="text-kr" style={{ fontWeight: "bold" }}>Kondisi Tumbuhan</h6>
                {
                  data.conditions ?
                    <ul>
                      {data.conditions.map((item) => (
                        <li>{item || "-"}</li>
                      ))}
                    </ul>
                    : <p>-</p>
                }

              </div>
            </div>

            <div className="box p-4" style={{ borderRadius: 5 }}>
              <div className="mt-2">
                <h6 className="text-kr" style={{ fontWeight: "bold" }}>Persebaran</h6>
                <p>{data.deployment || "-"}</p>
              </div>
            </div>

            <div className="box p-4" style={{ borderRadius: 5 }}>
              <div className="mt-2">
                <h6 className="text-kr" style={{ fontWeight: "bold" }}>Ciri-ciri</h6>
                <p>{data.description || "-"}</p>
              </div>
            </div>

            <div className="box p-4" style={{ borderRadius: 5 }}>
              <div className="mt-2">
                <h6 className="text-kr" style={{ fontWeight: "bold" }}>Tempat Tumbuh</h6>
                <p>{data.place || "-"}</p>
              </div>
            </div>

            <div className="box p-4" style={{ borderRadius: 5 }}>
              <div className="mt-2">
                <h6 className="text-kr" style={{ fontWeight: "bold" }}>Kegunaan</h6>
                <p>{data.usability || "-"}</p>
              </div>
            </div>

            <div className="box" style={{ borderRadius: 5 }}>
              <div className="box-header">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 mb-3" style={{ display: "flex", alignItems: "center" }}>
                    <h2 className="text-kr" style={{ fontWeight: "bold" }}>Laporan</h2>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12" style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                    {
                      ((this.props.profile.token && this.props.profile.token.claims.admin === true) ||
                        (this.props.profile.token && this.props.profile.token.claims.department === "Konservasi"))
                        ?
                        <Link
                          to={{
                            pathname: `/dashboard/plant_info/${sessionId}/daily_report/add`,
                            state: { selectedRegion: this.state.selectedRegion }
                          }}
                          className="nav-link">
                          <button className="md-btn md-raised m-b-sm kr-color px-2" style={{ borderRadius: 5 }}>
                            <div style={{ color: "white", fontSize: '0.7rem', fontWeight: "100" }}>
                              <i className="fa fa-fw fa-plus" style={{ opacity: 1, marginRight: 5, color: "white" }} />
                              Tambah Laporan
                          </div>
                          </button>
                        </Link>
                        :
                        null
                    }
                  </div>
                </div>
              </div>
            </div>
            <DailyReport
              loading={this.state.loading}
              data={this.state.dailyReportData}
              handleAcceptInfo={() => this.state.handleAcceptInfo()}
              showErrorInfo={() => this.state.showErrorInfo()}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    uid: state.firebase.auth.uid,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}

export default compose(
  connect(mapStateToProps),
  withFirestore,
)(DetailScreen)

import React from "react"
import NumberFormat from 'react-number-format'

const TableItem = (props) => {
    return (
        <tr key={props.item.id}>
            <td>{props.item.date}</td>
            <td>{props.item.day}</td>
            <td>
                <NumberFormat 
                    value={props.item.dataFasilitas}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>
            <td>
                <NumberFormat 
                    value={props.item.dataMerchandise}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>
            <td>
                <NumberFormat 
                    value={props.item.total}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>
        </tr>
    )
}

export default TableItem
import React from "react";

const LoadingDetailAnimal = () => (
    <div className="col-12" style={{ marginTop: 30 }}>
        <div classname="row" style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="col-8">
                <div className="box p-5" style={{ borderRadius: 5 }}>
                    <div className="row">
                        <div className="col-md-5 col-sm-12 col-xs-12">
                            <div class="ph-item p-0 mb-0 border-0">
                                <div className="ph-picture mb-0">
                                    <div className="ph-col-12"></div>
                                </div>
                            </div>
                        </div>

                        <div className="col-1"></div>

                        <div className="col-6">
                            <div class="ph-item p-0 mb-0 border-0">
                                <div className="ph-picture mb-0" style={{ height: 40 }}></div>
                            </div>
                            
                            <div class="ph-item p-0 mt-4 mb-0 border-0">
                                <div className="ph-picture mb-0" style={{ height: 40 }}></div>
                            </div>

                            <div class="ph-item p-0 mt-4 mb-0 border-0">
                                <div className="ph-picture mb-0" style={{ height: 40 }}></div>
                            </div>

                            <div class="ph-item p-0 mt-4 mb-0 border-0">
                                <div className="ph-picture mb-0" style={{ height: 40 }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="col-4">
                <div className="box p-5" style={{ borderRadius: 5 }}>
                    <div className="col-12">
                        <div class="ph-item p-0 mb-0 border-0">
                            <div className="ph-picture mb-0" style={{ height: 40 }}></div>
                        </div>
                                
                        <div class="ph-item p-0 mt-4 mb-0 border-0">
                            <div className="ph-picture mb-0" style={{ height: 40 }}></div>
                        </div>

                        <div class="ph-item p-0 mt-4 mb-0 border-0">
                            <div className="ph-picture mb-0" style={{ height: 40 }}></div>
                        </div>

                        <div class="ph-item p-0 mt-4 mb-0 border-0">
                            <div className="ph-picture mb-0" style={{ height: 40 }}></div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    </div>
);

export default LoadingDetailAnimal;
import { combineReducers } from 'redux'
import { firebaseReducer } from 'react-redux-firebase'
import { reducer as formReducer } from 'redux-form'
import { firestoreReducer } from 'redux-firestore'
import { regionReducer } from './regionReducer'
import { loadRegionState } from '../utils/localStorage'

export const initialState = {
  region: loadRegionState()
}

export const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  form: formReducer,
  region: regionReducer,
})
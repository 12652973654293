import React, { Component } from 'react';
import { Link } from "react-router-dom"
import moment from "moment"
import NumberFormat from 'react-number-format'
import TableItem from "./TableItem"
import LoadingTableSponsor from '../../../../../components/loading-table';

class DataTable extends Component {
    renderItem = (item, index) => {
        return (
            <TableItem
                key={item.id}
                index={index}
                item={item}
            />
        )
    }
    render() {
        let startDate = moment(this.props.startDate).format('YYYY-MMMM-DD')
        let endDate = moment(this.props.endDate).format('YYYY-MMMM-DD')
        return (
            <table className="table table-bordered m-a-0">
                <thead style={{ color: "#579264"}}>
                    <tr>
                        <th scope="col" rowSpan="2" style={{ verticalAlign:"middle" }} className="text-center">Tanggal</th>
                        <th scope="col" colSpan="2" className="text-center btn-table">
                            <Link to={`/dashboard/revenue/non_fnb/merchandise/report/bogor?startDate=${startDate}&endDate=${endDate}`}>
                                Bogor
                            </Link>
                        </th>
                        <th scope="col" colSpan="2" className="text-center btn-table">
                            <Link to={`/dashboard/revenue/non_fnb/merchandise/report/purwodadi?startDate=${startDate}&endDate=${endDate}`} >
                                Purwodadi
                            </Link>
                        </th>
                        <th scope="col" colSpan="2" className="text-center btn-table">
                            <Link to={`/dashboard/revenue/non_fnb/merchandise/report/cibodas?startDate=${startDate}&endDate=${endDate}`}  >
                                Cibodas
                            </Link>
                        </th>
                        <th scope="col" colSpan="2" className="text-center btn-table">
                            <Link to={`/dashboard/revenue/non_fnb/merchandise/report/bali?startDate=${startDate}&endDate=${endDate}`}>
                                Bali
                            </Link>
                        </th>
                        <th scope="col" rowSpan="2" style={{ verticalAlign:"middle" }} className="text-center">Total Qty</th>
                        <th scope="col" rowSpan="2" style={{ verticalAlign:"middle" }} className="text-center">Total IDR</th>
                    </tr>
                    <tr>
                        <th scope="col" className="text-center">Qty</th>
                        <th scope="col" className="text-center">IDR</th>
                        <th scope="col" className="text-center">Qty</th>
                        <th scope="col" className="text-center">IDR</th>
                        <th scope="col" className="text-center">Qty</th>
                        <th scope="col" className="text-center">IDR</th>
                        <th scope="col" className="text-center">Qty</th>
                        <th scope="col" className="text-center">IDR</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.props.loading ?
                            <React.Fragment>
                                <LoadingTableSponsor column={11} />
                                <LoadingTableSponsor column={11} />
                                <LoadingTableSponsor column={11} />
                                <LoadingTableSponsor column={11} />
                            </React.Fragment>
                            :
                                this.props.data.length == 0 ? 
                                <tr>
                                    <td className="text-center" colSpan="11">
                                        <p>Tidak ada data!</p>
                                    </td>
                                </tr>
                            : this.props.data.map(this.renderItem)
                    }
                </tbody> 
                <tfoot>
                    <tr>
                        <td className="text-center" style={{ fontWeight: "bold" }}>Jumlah</td>
                        <td className="text-center">{this.props.total.bogor.qty}</td>
                        <td className="text-center">
                            <NumberFormat 
                                value={this.props.total.bogor.amount}
                                displayType={'text'} 
                                thousandSeparator={true} 
                                prefix={'Rp'} 
                                suffix={",-"} 
                                defaultValue="0" 
                            />
                        </td>
                        <td className="text-center">{this.props.total.purwodadi.qty}</td>
                        <td className="text-center">
                            <NumberFormat 
                                value={this.props.total.purwodadi.amount}
                                displayType={'text'} 
                                thousandSeparator={true} 
                                prefix={'Rp'} 
                                suffix={",-"} 
                                defaultValue="0" 
                            />
                        </td>
                        <td className="text-center">{this.props.total.cibodas.qty}</td>
                        <td className="text-center">
                            <NumberFormat 
                                value={this.props.total.cibodas.amount}
                                displayType={'text'} 
                                thousandSeparator={true} 
                                prefix={'Rp'} 
                                suffix={",-"} 
                                defaultValue="0" 
                            />
                        </td>
                        <td className="text-center">{this.props.total.bali.qty}</td>
                        <td className="text-center">
                            <NumberFormat 
                                value={this.props.total.bali.amount}
                                displayType={'text'} 
                                thousandSeparator={true} 
                                prefix={'Rp'} 
                                suffix={",-"} 
                                defaultValue="0" 
                            />
                        </td>
                        <td className="text-center">{this.props.total.totalQty}</td>
                        <td className="text-center">
                            <NumberFormat 
                                value={this.props.total.totalAmount}
                                displayType={'text'} 
                                thousandSeparator={true} 
                                prefix={'Rp'} 
                                suffix={",-"} 
                                defaultValue="0" 
                            />
                        </td>
                    </tr>
                </tfoot>
            </table>
        )
    }
}

export default DataTable

import firebase from 'firebase'
import moment from 'moment-timezone'

export async function fetchRevenueAllRegion(date) {
  
  console.log(moment(date).startOf('day').toDate())
    const snapshots =
      await firebase.firestore().collectionGroup('revenueTickets')
        .where('timestamp', "==", moment(date).tz("Asia/Jakarta").startOf('day').toDate())
        .get()
    if (snapshots.empty) {
      return null;
    }
    let tickets = {
      bogor: {
        region: "bogor",
        id: "",
        qty: 0,
        amount: 0
      },
      cibodas: {
        region: "cibodas",
        id: "",
        qty: 0,
        amount: 0
      },
      purwodadi: {
        region: "purwodadi",
        id: "",
        qty: 0,
        amount: 0
      },
      bali: {
        region: "bali",
        id: "",
        qty: 0,
        amount: 0
      }
    }
    let amounts = [{bogor: 0, cibodas: 0, purwodadi: 0, bali: 0}]
    let qty = [{bogor: 0, cibodas: 0, purwodadi: 0, bali: 0}]

    snapshots.forEach(element => {

      const region = element.ref.parent.parent.id
      const { daily } = element.data()

      const { total_qty = 0, total_amount = 0 } = daily
          
      tickets[region] = {
        region,
        id: element.id,
        qty: total_qty,
        amount: total_amount
      }
      // console.log(tickets);
      amounts[0][region] = element.data().daily.total_amount
      qty[0][region] = element.data().daily.total_qty

    });
    // console.log({ tickets, amounts, qty });
    return { tickets, amounts, qty }

  }
import React from "react"
import { Switch, Route } from 'react-router-dom'

import AllRegionScreen from '../../screens/Ticketing';
import TicketingDetailScreen from '../../screens/Ticketing/Detail';
// import UpdateEmployeeDataScreen from '../../screens/Employee/UpdateScreen';

const TicketingNav = (props) => (
    <Switch>
        <Route 
            exact 
            path={`${props.match.path}/`}
            render={(routeProps) => <AllRegionScreen {...routeProps} />}
            /> 
        />
        {/* <Route 
            exact 
            path={`${props.match.path}/ticket`}
            render={(routeProps) => <AddEmployeeDataScreen {...routeProps} />}
        /> */}
        <Route 
            exact 
            path={`${props.match.path}/:sessionId`}
            render={(routeProps) => <TicketingDetailScreen {...routeProps} />}
        />
    </Switch>
)

export default TicketingNav
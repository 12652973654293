import React, { Component } from 'react';
// import { DateRangePicker, SingleDatePicker } from 'react-dates';
import moment from 'moment';
import AllRegionTable from './AllRegion/DataTable';
import SelectedRegionTable from './SelectedRegion/DataTable';
import { Calendar } from 'react-calendar';
// import { ButtonToolbar } from 'react-bootstrap';
// import { auth, db } from "../../config/constants";
import {
    BarChart, ResponsiveContainer, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

import SelectMenu from "../../components/SelectMenu"
import { DateRangePicker, isInclusivelyAfterDay } from 'react-dates';

const data = [
    {
        date: 'Jan 1 2020', totalVisitor: 0, totalPrice: 0
    },
    {
        date: 'Jan 2 2020', totalVisitor: 0, totalPrice: 0
    },
    {
        date: 'Jan 3 2020', totalVisitor: 0, totalPrice: 0
    },
    {
        date: 'Jan 4 2020', totalVisitor: 0, totalPrice: 0
    },
    {
        date: 'Jan 5 2020', totalVisitor: 0, totalPrice: 0
    },
    {
        date: 'Jan 6 2020', totalVisitor: 0, totalPrice: 0
    },
    {
        date: 'Jan 7 2020', totalVisitor: 0, totalPrice: 0
    },
    {
        date: 'Jan 8 2020', totalVisitor: 0, totalPrice: 0
    },
    {
        date: 'Jan 9 2020', totalVisitor: 0, totalPrice: 0
    },
    {
        date: 'Jan 10 2020', totalVisitor: 0, totalPrice: 0
    }
];

const allData = [
    {
        date: 'Jan 1 2020', 
        region: [
            { name: "Bogor", qty: 999, totalPrice: 2000},
            { name: "Purwodadi", qty: 999, totalPrice: 2000},
            { name: "Cibodas", qty: 999, totalPrice: 2000},
            { name: "Bali", qty: 999, totalPrice: 2000},
        ],
        totalVisitor: 999,
        totalPrice: 9999,
    },
    {
        date: 'Jan 2 2020', 
        region: [
            { name: "Bogor", qty: 999, totalPrice: 2000},
            { name: "Purwodadi", qty: 999, totalPrice: 2000},
            { name: "Cibodas", qty: 999, totalPrice: 2000},
            { name: "Bali", qty: 999, totalPrice: 2000},
        ],
        totalVisitor: 999,
        totalPrice: 9999,
    },
    {
        date: 'Jan 3 2020', 
        region: [
            { name: "Bogor", qty: 999, totalPrice: 2000},
            { name: "Purwodadi", qty: 999, totalPrice: 2000},
            { name: "Cibodas", qty: 999, totalPrice: 2000},
            { name: "Bali", qty: 999, totalPrice: 2000},
        ],
        totalVisitor: 999,
        totalPrice: 9999,
    },
    {
        date: 'Jan 4 2020', 
        region: [
            { name: "Bogor", qty: 999, totalPrice: 2000},
            { name: "Purwodadi", qty: 999, totalPrice: 2000},
            { name: "Cibodas", qty: 999, totalPrice: 2000},
            { name: "Bali", qty: 999, totalPrice: 2000},
        ],
        totalVisitor: 999,
        totalPrice: 9999,
    },
    {
        date: 'Jan 5 2020', 
        region: [
            { name: "Bogor", qty: 999, totalPrice: 2000},
            { name: "Purwodadi", qty: 999, totalPrice: 2000},
            { name: "Cibodas", qty: 999, totalPrice: 2000},
            { name: "Bali", qty: 999, totalPrice: 2000},
        ],
        totalVisitor: 999,
        totalPrice: 9999,
    },
    {
        date: 'Jan 6 2020', 
        region: [
            { name: "Bogor", qty: 999, totalPrice: 2000},
            { name: "Purwodadi", qty: 999, totalPrice: 2000},
            { name: "Cibodas", qty: 999, totalPrice: 2000},
            { name: "Bali", qty: 999, totalPrice: 2000},
        ],
        totalVisitor: 999,
        totalPrice: 9999,
    },
    {
        date: 'Jan 7 2020', 
        region: [
            { name: "Bogor", qty: 999, totalPrice: 2000},
            { name: "Purwodadi", qty: 999, totalPrice: 2000},
            { name: "Cibodas", qty: 999, totalPrice: 2000},
            { name: "Bali", qty: 999, totalPrice: 2000},
        ],
        totalVisitor: 999,
        totalPrice: 9999,
    },
    {
        date: 'Jan 8 2020', 
        region: [
            { name: "Bogor", qty: 999, totalPrice: 2000},
            { name: "Purwodadi", qty: 999, totalPrice: 2000},
            { name: "Cibodas", qty: 999, totalPrice: 2000},
            { name: "Bali", qty: 999, totalPrice: 2000},
        ],
        totalVisitor: 999,
        totalPrice: 9999,
    },
    {
        date: 'Jan 9 2020', 
        region: [
            { name: "Bogor", qty: 999, totalPrice: 2000},
            { name: "Purwodadi", qty: 999, totalPrice: 2000},
            { name: "Cibodas", qty: 999, totalPrice: 2000},
            { name: "Bali", qty: 999, totalPrice: 2000},
        ],
        totalVisitor: 999,
        totalPrice: 9999,
    },
    {
        date: 'Jan 10 2020', 
        region: [
            { name: "Bogor", qty: 999, totalPrice: 2000},
            { name: "Purwodadi", qty: 999, totalPrice: 2000},
            { name: "Cibodas", qty: 999, totalPrice: 2000},
            { name: "Bali", qty: 999, totalPrice: 2000},
        ],
        totalVisitor: 999,
        totalPrice: 9999,
    }
];

const dataTotalPrice = [
    {
        date: 'Jan 1 2020',
        totalPrice: 400000,
    },
    {
        date: 'Jan 2 2020',
        totalPrice: 120000,
    },
    {
        date: 'Jan 3 2020',
        totalPrice: 500000,
    },
    {
        date: 'Jan 4 2020',
        totalPrice: 500000,
    },
    {
        date: 'Jan 5 2020',
        totalPrice: 400000,
    },
    {
        date: 'Jan 6 2020',
        totalPrice: 300000,
    },
    {
        date: 'Jan 7 2020',
        totalPrice: 250000,
    },
    {
        date: 'Jan 8 2020',
        totalPrice: 100000,
    },
    {
        date: 'Jan 9 2020',
        totalPrice: 700000,
    },
    {
        date: 'Jan 10 2020',
        totalPrice: 900000,
    }
];

const dataVisitor = [
    {
        date: 'Jan 1 2020',
        visitorTotal: 1000,
    },
    {
        date: 'Jan 2 2020',
        visitorTotal: 250,
    },
    {
        date: 'Jan 3 2020',
        visitorTotal: 500,
    },
    {
        date: 'Jan 4 2020',
        visitorTotal: 120,
    },
    {
        date: 'Jan 5 2020',
        visitorTotal: 550,
    },
    {
        date: 'Jan 6 2020',
        visitorTotal: 300,
    },
    {
        date: 'Jan 7 2020',
        visitorTotal: 450,
    },
    {
        date: 'Jan 8 2020',
        visitorTotal: 750,
    },
    {
        date: 'Jan 9 2020',
        visitorTotal: 900,
    },
    {
        date: 'Jan 10 2020',
        visitorTotal: 600,
    }
];

const options = [
    {
        label: "Semua Region", value: "All Region"
    },
    {
        label: "Bogor", value: "Bogor"
    }
]

export default class AllReportRevenue extends Component {
    state = {
        date: new Date(),
        dataEvent: data,
        selectedOption: options[0].value,
        startDate: null,
        endDate: null,
    }
    onClickDay = (value) => alert('Happy Birthday To Jane Doe')
    // fetchDataEvent = async () => {
    //   this.setState({ loading: true,  })
    //   try {
    //     const snapshots = await db.collection("events")
    //     //   .where("ownerId", "==", auth().currentUser.uid)
    //       .where("currentStatus.status", "==", "accepted")
    //       .orderBy("timestamp", "desc")
    //       .limit(3)
    //       .get();
    //     if (snapshots.empty) {
    //       this.setState({
    //         dataEvent: [],
    //         loading: false
    //       })
    //       return null
    //     }
    //     let dataEvent = [];
    //     snapshots.forEach((snap) => {
    //         const { timestamp, lastUpdate, eventDate, ...restData } = snap.data()
    //       dataEvent.push({
    //         id: snap.id,
    //         ...restData,
    //         timestamp: moment(timestamp.toDate()).format("DD MMMM YYYY"),
    //         lastUpdate: lastUpdate ? moment(lastUpdate.toDate()).format("DD MMMM YYYY") : "",
    //         eventDate: {
    //             start: eventDate ? eventDate.start ? moment(eventDate.start.toDate()).format("DD MMMM YYYY") : "-": "-",
    //             end: eventDate ? eventDate.end ? moment(eventDate.end.toDate()).format("DD MMMM YYYY") : "-": "-",
    //         },
    //         ref: snap.ref,
    //       })
    //     })
    //     console.log('dataEvent', dataEvent)
    //     this.setState({
    //       dataEvent,
    //       loading: false
    //     })
    //   } catch (error) {
    //     console.error(error)
    //     this.setState({
    //       data: [],
    //       loading: false
    //     })
    //   }
    // }
    componentDidMount() {
        //   this.fetchDataEvent();
    }
    onChange = (value) => {
        this.setState({ selectedOption : value})
    }
    onDatesChange = ({ startDate, endDate }) => {
        this.setState({ startDate, endDate })
    }
    render() {
        return (
            <React.Fragment>
                <div className="box-header px-5 mt-3" style={{ backgroundColor: "#EFF2F7" }}>
                    <h3 style={{ color: "#3B4859" }}>TICKETING</h3>
                </div>
                <div className="container-fluid ml-3 py-5">
                    <div className="row">
                        <div className="col-md-2 col-sm-12 mt-4">
                            <SelectMenu items={options} value={this.state.selectedOption} onChange={this.onChange} />
                        </div>
                    </div>
                    <div className="mt-3">
                        <DateRangePicker
                            numberOfMonths={2}
                            // withPortal={true}
                            displayFormat="DD/MM/YYYY"
                            isDayBlocked={() => false}  
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            isOutsideRange={() => false}
                            enableOutsideDays={true}
                            // isOutsideRange={day => isInclusivelyAfterDay(day, moment().add(1, 'days'))}
                            focusedInput={this.state.focusedInput}
                            onDatesChange={this.onDatesChange}
                            isOutsideRange={(day) => (
                                this.state.focusedInput === "endDate" && (day.isBefore(this.state.startDate) || day.isAfter(this.state.startDate.clone().add(10, 'days')))
                              )} 
                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                        />
                    </div>
                    {/* <div className="row">
                    <div className="col-md-2 col-sm-12 mt-4">
                        <div className="dropdown">
                            <button 
                                className="btn border dropdown-toggle w-100" 
                                type="button" id="dropdownMenuButton" 
                                data-toggle="dropdown" 
                                aria-haspopup="true" 
                                aria-expanded="false"
                                style={{ backgroundColor: "transparent", fontSize: "0.8em", border:"1px solid rgba(124, 122, 122, 0.87)" }}
                            >
                                Semua Wilayah
                            </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a className="dropdown-item" href="#">Bogor</a>
                                    <a className="dropdown-item" href="#">Bali</a>
                                    <a className="dropdown-item" href="#">Something else here</a>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className="py-3">
                        <div className="box py-3 mb-0">
                            <div className="box-header">
                                <h3><b>Total Ticket  (Per 10 Days)</b></h3>
                            </div>
                            <div className="box-body" style={{ fontSize: "0.7rem" }}>
                                <ResponsiveContainer
                                    width='100%'
                                    height={300}
                                >
                                    <BarChart
                                        height={300}
                                        data={dataTotalPrice}
                                        margin={{
                                            top: 5, right: 30, bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="date" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="totalPrice" fill="#50E3C2" />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                    <div className="py-3">
                        <div className="box py-3 mb-0">
                            <div className="box-header">
                                <h3><b>Total Pengunjung (Per 10 Days)</b></h3>
                            </div>
                            <div className="box-body">
                                <ResponsiveContainer
                                    width='100%'
                                    height={300}
                                >
                                    <BarChart
                                        height={300}
                                        data={dataVisitor}
                                        margin={{
                                            top: 5, right: 30, bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="date" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="visitorTotal" fill="#50E3C2" />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>

                    <div className="py-3">
                        <div className="box py-3 mb-0">
                            <div className="table-responsive mt-3">
                            {
                                this.state.selectedOption === "All Region" ?
                                    <AllRegionTable data={allData} />
                                    :
                                    <SelectedRegionTable data={this.state.dataEvent} />
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
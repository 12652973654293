import React, { Component } from 'react'

import { connect } from 'react-redux'
import { compose } from 'redux'
import moment from 'moment'
import { withFirestore } from 'react-redux-firebase'


import html2canvas from 'html2canvas'
import jsPdf from 'jspdf'
import ThumbnailPreview from '../../../../../components/Form/ThumbnailPreview'
import LoadingScreen from '../../../../../components/LoadingScreen'
import { firestore } from 'firebase'
import { Modal } from 'react-bootstrap'

class DetailScreen extends Component {
    state = {
        data: {
            description: "",
            images: [],
            date: "",
            job: ""
        },
        isDownload: false,
        canEdit: false,
        loading: true,
        errorInfo: false
    }

    setCanEdit = (state) => this.setState({ canEdit: state })

    componentDidMount() {
        this.fetchData();
    }
    savePdf = async () => {
        this.setState({ isDownload: true })
        const domElement = document.getElementById('detail-page')
        try {
    
          const canvas = await html2canvas(domElement, { 
            onclone: (document) => {
              document.getElementById('detail-page').style.backgroundColor = '#ffff';
              document.getElementById('download-button').style.visibility = 'hidden';
            },
            scale: 2,
          });
          const img = await canvas.toDataURL('image/png')
    
          const pdf = new jsPdf({
            compress: true
          })  
          const imgProps = pdf.getImageProperties(img);
          const width = pdf.internal.pageSize.getWidth();
          const height = (imgProps.height * width) / imgProps.width;
    
          pdf.addImage(img, 'JPEG', 0, 0, width, height, undefined, 'FAST')
          // setTimeout(() => {
            pdf.save(`report-fasilitas-kegiatan-harian-${moment().format("DD MMMM YYYY")}.pdf`)
            this.setState({ isDownload: false })
          // }, 4000);
        } catch (error) {
          console.error(error)
          this.setState({ isDownload: false })
        }
      }

    fetchData = async () => {
        try {
            const { employeeId, activityId, reportId } = this.props.match.params;

            const regionRef = firestore().collection("region").doc(this.props.region.toLowerCase())
            const userRef = regionRef.collection("employee").doc(employeeId)
            const facilityReport = userRef.collection("facilityReport").doc(activityId)

            const reportRef = facilityReport.collection("report").doc(reportId)
            const snapshot = await reportRef.get()

            if (!snapshot.exists) {
                this.setState({ loading: false })
                return;
            }

            const {
                images = [],
                date,
                startTime,
                ...restData
            } = snapshot.data()
            console.log('snapshot.data()', snapshot.data())

            let imageUrls = images.map((e) => e.url)

            const toDataURL = url => fetch(url)
                .then(response => response.blob())
                .then(blob => new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onloadend = () => resolve(reader.result)
                reader.onerror = reject
                reader.readAsDataURL(blob)
                })
                .then((value) => Promise.resolve(value)))
            let imgBase64 = []
            for (let i = 0; i < imageUrls.length; i++) {
                const url = imageUrls[i];
                imgBase64.push(await toDataURL(url))
            }

            let data = {
                id: snapshot.id,
                images: imgBase64,
                date: startTime ? moment(startTime.toDate()).format("DD MMMM YYYY") : "-",
                ...restData
            }
            console.log('data', data)
            const dataDate = startTime ? moment(startTime.toDate()).hour(23).minute(59).second(59).toDate(): ""
            const currentDate = moment().hour(0).minute(0).second(0).toDate();

            if (currentDate > dataDate) {
                this.setCanEdit(false)
            } else {
                this.setCanEdit(true)
            }

            this.setState({
                data: {
                    ...this.state.data,
                    ...data
                },
                loading: false
            });
        } catch (error) {
            this.setState({ loading: false })
        }

    }
    goBack = () => {
        const { employeeId, activityId } = this.props.match.params
        this.props.history.push({
          pathname: `/dashboard/facilities_employee/employee_list/${employeeId}/activities/${activityId}`,
          state: { region: this.props.selectedOption }
        })
    }

    render() {
        const { data } = this.state;
        if (this.state.loading) return <LoadingScreen />
        return (
            <div id="detail-page">          
                <div className="box-header px-5 mt-3" style={{ backgroundColor: "#EFF2F7" }}>
                    <div className="row">
                        <a onClick={this.goBack}><i class="fa fa-arrow-left" aria-hidden="true"></i></a>
                        <h3 className="ml-3 mt-1" style={{ color: "#3B4859" }}>LAPORAN {data.type ? data.type.toUpperCase() : ""}</h3>
                    </div>
                </div>
                <div className="container-fluid p-5 spacing-resp">
                    <div className="box mb-0 p-4" style={{ borderRadius: 10 }}>
                        <div className="row">
                            <div className="col-md-3">
                                <h6 className="mb-0 mt-2">
                                    <span style={{ color: "#579264", fontWeight: "bold" }}>Tanggal</span>
                                    <span style={{ color: "#979D99", fontWeight: "bold", marginLeft: 15 }}>{this.state.data.date}</span>
                                </h6>
                            </div>
                            <div className="col-md-3 offset-md-6">
                                
                                
                                {
                                    this.state.isDownload !== true ?
                                        <a href="#" className="nav-link">
                                            <button
                                                type="button"
                                                id="download-button"
                                                onClick={this.savePdf}
                                                className="md-btn m-b-sm md-raised success px-4"
                                                style={{ backgroundColor: "#22313F", borderRadius: 5, fontWeight: "inherit", fontSize: "0.8em", padding: ".5em 1em" }}>
                                                    {/* <i className="fa fa-download mr-1"></i> */}
                                                Download
                                            </button>
                                        </a>
                                    :
                                        null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="box mb-0 p-4 mt-4" style={{ borderRadius: 10 }}>
                        <h5 className="mb-0" style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold" }}>{this.state.data.task}</h5>
                        <hr className="mb-4" />

                        <h6 style={{ color: "#579264", fontWeight: "bold" }}>Wilayah</h6>
                        <p className="mt-4" style={{ color: "rgba(124, 122, 122, 0.87)", fontSize: "0.9em", fontWeight: "bold" }}>{this.state.data.sector}</p>
                        <h6 className="mt-5" style={{ color: "#579264", fontWeight: "bold" }}>Lokasi</h6>
                        {
                            this.state.data.area && this.state.data.area.map((item) => (
                                <p className="mt-2" style={{ fontSize: "0.9em", fontWeight: "500" }}>{item}</p>
                            ))
                        }
                        {/* <p className="mt-4" style={{ fontSize: "0.9em", fontWeight: "500" }}>Ruang Titan Arum</p> */}

                        <h6 className="mt-5" style={{ color: "#579264", fontWeight: "bold" }}>Foto*</h6>
                        <div className="" id="inputGallery">
                            {
                                data.images && data.images.length === 0 ?
                                    <p style={{ color: "rgba(156, 151, 151, 0.87)" }} className="text-center mt-3">
                                        Tidak ada foto.
                                    </p>
                                    :
                                    data.images.map((file) => <ThumbnailPreview file={file} key={file.name ? file.name : file} />)
                            }
                        </div>

                        <h6 className="mt-5" style={{ color: "#579264", fontWeight: "bold" }}>Deskripsi*</h6>
                        <div className="mt-3 p-3">
                            <p>{this.state.data.description}</p>
                        </div>
                    </div>
                    <LoadingModal isDownload={this.state.isDownload} />
                </div>
            </div>
        )
    }
}


function LoadingModal(props) {
    const [show, setShow] = React.useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    React.useEffect(() => {
      setShow(props.isDownload)
    }, [props.isDownload])
    // console.log('show', show)
    return (
      show ?
        <>
        {/* <Button variant="primary" onClick={handleShow}>
          Launch demo modal
        </Button> */}
  
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            {/* <Modal.Title>Modal heading</Modal.Title> */}
          </Modal.Header>
          <Modal.Body><h2 className="text-center">Loading...</h2></Modal.Body>
        </Modal>
        </>
      :
      null
    );
}

const mapStateToProps = state => {
    return {
        uid: state.firebase.auth.uid,
        region: state.region,
    }
}

export default compose(
    connect(mapStateToProps),
    withFirestore,
)(DetailScreen)
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withFirestore } from 'react-redux-firebase'

import { uploadBanner } from './uploadBanner'
import UpdateSession from './UpdateForm'
import { deleteImages } from '../../helpers/deleteGallery'
import { uploadGalleryBase64 } from '../../helpers/uploadGallery'
import LoadingScreen from '../../components/LoadingScreen'
import ModalError from '../../components/ModalError'
import moment from 'moment'

const collection = "region"

class UpdateScreen extends Component {
  static propTypes = {
    uid: PropTypes.string,
    firestore: PropTypes.shape({
      update: PropTypes.func.isRequired
    }).isRequired
  }

  state = {
    data: {
      taskList: [],
      description: "",
      images: [],
      sectorList: [],
      areaList: [],
      images: [],
      area: ""
    },
    collection: "region",
    loading: true,
    errorInfo: false,
  }

  handleErrorInfo = () => {
    this.setState({ errorInfo: !this.state.errorInfo });
  }

  createFormData = ({ date, images, imagesData, sectorList, areaList, ...values }) => {
    const data = {
      ...values,
      date: date ? moment().toDate() : "",
    };
    return data;
  }

  onSubmit = async (values) => {
    const { sessionId } = this.props.match.params
    const formData = this.createFormData(values)

    try {
      const sfDoc = await this.props.firestore.get({
        collection: this.state.collection,
        doc: this.props.region.toLowerCase(),
        subcollections: [{ collection: 'maintenance', doc: sessionId }],
      }, formData);

      if (!sfDoc.exists) {
        throw new Error("Document does not exist!");
      }

      //update data
      await this.props.firestore.update({ 
        collection: this.state.collection, 
        doc: this.props.region.toLowerCase(),
        subcollections: [{ collection: 'maintenance', doc: sessionId }],
      }, formData);
      console.log("Successfully update document field. doc id: ", sessionId);
    
      await deleteImages(values.imagesData);
      const path = `${this.state.collection}/${this.props.region.toLowerCase()}/maintenance/${sessionId}`
      const imagesUploaded = await uploadGalleryBase64(sessionId, path, values.images);
              
      await this.props.firestore.update({ 
        collection: this.state.collection, 
        doc: this.props.region.toLowerCase(),
        subcollections: [{ collection: 'maintenance', doc: sessionId }],
      }, { images: imagesUploaded });
      // console.log("Document Successfully updated! doc id: ", sessionId);
      this.goBack()
    } catch (error) {
      console.error("error", error)
      this.setState({
        errorInfo: true,
      })
    }
  }

  componentDidMount() {
    this.fetchData();
  }
  fetchDataSector = async () => {
    try {
      const docSnapshots = await this.props.firestore.get({
        collection: 'region',
        doc: this.props.region.toLowerCase(), // masih hardcode wilayah
        subcollections: [{ 
          collection: 'sector',
        }],
      });
      if (docSnapshots.empty) {
        console.log("kosong")
        this.setState({
          data: {
            ...this.state.data, 
            sector: '', 
            areaList: [], 
            sectorList: [], 
          }
        })
        return;
      }
      let sectorList = []
      docSnapshots.forEach((doc) => {
        sectorList.push({
          id: doc.id,
          label:doc.data().name,
          value: doc.data().name,
          ...doc.data()
        })
      })
      console.log('this.state.data.sector', this.state.data.sector)
      let selectedSector = null
      for (let i = 0; i < sectorList.length; i++) {
        const element = sectorList[i];
        if (this.state.data.sector == element.name) {
          selectedSector = element
          break;
        }
      }
      let areaList = []
      if (selectedSector.maintenanceArea) {
        areaList = selectedSector.maintenanceArea.map((item) => {
          return { label: item, value: item }
        })
      }
      console.log('areaList aa', areaList)
      this.setState({
        data: {
          ...this.state.data,
          sectorList,
          areaList
        }
      })
    } catch (error) {
      this.setState({
        data: {
          ...this.state.data,
          // selectedSector: null,
          sector: '', 
          sectorList: [], 
        }
      })
    }
  }

  fetchData = async () => {
    try {
      const { sessionId } = this.props.match.params;
      const snapshot = await this.props.firestore.get({
        collection: 'region',
        doc: this.props.region.toLowerCase(),
        subcollections: [{
          collection: 'maintenance',
          doc: sessionId
        }],
      });
      if (!snapshot.exists) {

      } else {
        const {
          images = [],
          date,
          ...restData
        } = snapshot.data()
        let imageUrls = images.map((e) => e.url)
        let data = {
          id: snapshot.id,
          images: imageUrls,
          imagesData: images,
          date: date ? moment(date.toDate()).format("DD MMMM YYYY") : "",
          ...restData
        }
        this.setState({ data: { ...this.state.data, ...data } }, () => {
          this.fetchDataSector();
        });
      }
    } catch (error) {
      console.log('error', error)
      this.setState({
        errorInfo: true,
      })
    }

    this.setState({ loading: false })
  }
  goBack = () => {
    this.props.history.push({
      pathname: `/dashboard/maintenance/${this.state.data.id}`,
      state: { region: this.props.selectedOption }
    })
  }

  render() {
    if (!this.props.uid) return null
    if (this.state.loading) return <LoadingScreen />
    return (
      <React.Fragment>
        <div className="box-header px-5 mt-3" style={{ backgroundColor: "#EFF2F7" }}>
          <div className="row">
            <a onClick={this.goBack}><i class="fa fa-arrow-left" aria-hidden="true"></i></a>
            <h3 className="ml-3 mt-1" style={{ color: "#3B4859" }}>UPDATE KEGIATAN HARIAN</h3>
          </div>
        </div>
        <div className="box-body">
          <React.Fragment>
            {
              this.state.errorInfo &&
              <ModalError
                errorInfo={this.state.errorInfo}
                handleErrorInfo={this.handleErrorInfo}
              />
            }
          </React.Fragment>
          <React.Fragment>
            <UpdateSession onSubmit={this.onSubmit} initialValues={this.state.data} />
          </React.Fragment>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    uid: state.firebase.auth.uid,
    region: state.region,
  }
}

export default compose(
  connect(mapStateToProps),
  withFirestore,
)(UpdateScreen)
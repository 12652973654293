import React from 'react'
import { Link } from 'react-router-dom'

const NotFound = () => (
    <div className="app-body amber bg-auto w-full">
        <div className="text-center pos-rlt p-y-md">
            <h1 className="text-shadow m-0 text-white text-4x">
                <span className="text-2x font-bold block m-t-lg">404</span>
            </h1>
            <h2 className="h1 m-y-lg text-black">OOPS!</h2>
            <p className="h5 m-y-lg text-u-c font-bold text-black">Sorry! the page you are looking for doesn't exist.</p>
            <Link to="/dashboard" className="md-btn amber-700 md-raised p-x-md">
                <span className="text-white">Go to the home page</span>
            </Link>
        </div>
    </div>
) 

export default NotFound
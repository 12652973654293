import React from "react"
import NumberFormat from 'react-number-format'
import moment from "moment";

const TableItem = (props) => {
    // let date_transaction = moment(props.item.date_purchase).format("DD MMM YYYY");
    // let day = moment(props.item.date_purchase, "YYYY-MM-DD").format("dddd");
    return (
        <tr key={props.item.id} className="text-center">
            <td>{props.item.date_transaction}</td>
            <td>{props.item.domestic.qty ||"-"}</td>
            <td>
                <NumberFormat 
                    value={props.item.domestic.amount}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>
            <td>{props.item.domestic_group.qty ||"-"}</td>
            <td>
                <NumberFormat 
                    value={props.item.domestic_group.amount}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>
            <td>{props.item.asing.qty ||"-"}</td>
            <td>
                <NumberFormat 
                    value={props.item.asing.amount}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>
            <td>{props.item.asing_group.qty ||"-"}</td>
            <td>
                <NumberFormat 
                    value={props.item.asing_group.amount}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>
            <td>
                <NumberFormat 
                    value={props.item.total || "0"}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>
        </tr>
    )
}

{/* <tr key={props.item.id} className="text-center">
<td>{date_transaction}</td>
<td>{props.item.transaction_number}</td>
<td>-</td>
<td>{props.item.tariff}</td>
<td>{props.item.payment}</td>
<td>{props.item.qty || "-"}</td>
<td>
    <NumberFormat 
        value={props.item.price}
        displayType={'text'} 
        thousandSeparator={true} 
        prefix={'Rp'} 
        suffix={",-"} 
        defaultValue="0" 
    />
</td>
<td>-</td>
<td>
    <NumberFormat 
        value={props.item.assurance}
        displayType={'text'} 
        thousandSeparator={true} 
        prefix={'Rp'} 
        suffix={",-"} 
        defaultValue="0" 
    />
</td>
<td>
    <NumberFormat 
        value={props.item.tax}
        displayType={'text'} 
        thousandSeparator={true} 
        prefix={'Rp'} 
        suffix={",-"} 
        defaultValue="0" 
    />
</td>
<td>
    <NumberFormat 
        value={props.item.fix_price}
        displayType={'text'} 
        thousandSeparator={true} 
        prefix={'Rp'} 
        suffix={",-"} 
        defaultValue="0" 
    />
</td>
</tr> */}

export default TableItem
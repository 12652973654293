import React from "react"
import NumberFormat from 'react-number-format'
import { Link } from "react-router-dom"
import moment from "moment";

const TableItemSubTotal = (props) => {
    return (
        <tr key={props.item.id} className="text-center">
            <td>{props.index + 1}</td>
            <td>{props.item.name}</td>
            <td>{props.item.qty}</td>
            <td>lbr</td>
            <td>
                <NumberFormat 
                    value={props.item.assurance}
                    displayType={'text'} 
                    thousandSeparator={true} 
                    prefix={'Rp'} 
                    suffix={",-"} 
                    defaultValue="0" 
                />
            </td>
        </tr>
    )
}

export default TableItemSubTotal
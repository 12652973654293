import React, { Component } from 'react'
import { Tabs, Tab, Modal } from 'react-bootstrap'
import { Link } from "react-router-dom"
import { connect } from 'react-redux'
import moment from 'moment'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'

import html2canvas from 'html2canvas'
import jsPdf from 'jspdf'
import ListItem from './ListItem'
import LoadingTable from "../../components/loading-table"
import { firestore } from 'firebase'

function generateDuration (startTime, endTime) {

    const diff = moment(endTime.toDate()).second(0).diff(moment(startTime.toDate()).second(0))
    const dur = moment.duration(diff).asMilliseconds();

    const hour = moment.duration(dur).hours();
    const minutes = moment.duration(dur).minutes();

    return  `${hour}h ${minutes}m`
}

class Activities extends Component {
    state = {
        loading: true,
        data: [],
        activityType: "harian",
        today: moment().format("DD MMMM YYYY"),
        todayDate: moment(),
        canEdit: true,
        isDownload: false,
        isManager: false
    }
    getUserData = async (region) => {
        const snapshot = await firestore().collection('region').doc(region.toLowerCase()).collection('employee').doc(this.props.uid).get();
        if (!snapshot.exists) {
            return {}
        }
        return {
            id: snapshot.id,
            ...snapshot.data()
        }
    }

    setCanEdit(canEdit) {
        this.setState({ canEdit })
    }

    fetchData = async (props) => {
        // const userData = await this.getUserData(this.props.region)
        this.setState({ isLoading: true, data: [] })
        const { activityId, userId } = this.props.match.params
        
        let uid = props.uid
        // let isManager = false;

        // if (userData.position == "f1" || userData.position == "f2") {
        //     // uid = userId
        //     // isManager = true
        // }
        
        const uidDate = activityId ? activityId : moment().format("DDMMYYYY");

            
        const date = moment(uidDate, "DDMMYYYY").endOf('day')
        const currentDate = moment();
        if (currentDate.isAfter(date)) {
            this.setCanEdit(false)
        } else {
            this.setCanEdit(true)
        }
        
        try {

            const regionRef = firestore().collection('region').doc(props.region.toLowerCase())
            const userRef = regionRef.collection("employee").doc(uid)
            const facilityReport = userRef.collection("facilityReport").doc(uidDate)

            const reportRef = facilityReport.collection("report")

            let ref = reportRef.where("type", "==", this.state.activityType)
            const snapshots = await ref.get()
            if (!snapshots.empty) {
                const data = [];
                snapshots.forEach((doc) => {
                    const { date, startTime, endTime, ...restData } = doc.data()
                    const newDate = startTime ? moment(startTime.toDate()) : ""
                    const newStartDate = startTime ? moment(startTime.toDate()).format("HH:mm") : "-";
                    const newEndDate = endTime ? moment(endTime.toDate()).format("HH:mm") : "-";

                    let duration = "";
                    
                    if (startTime && endTime) {
                        duration = generateDuration(startTime, endTime);
                    }

                    data.push({
                        id: doc.id,
                        date: newDate,
                        startTime: newStartDate,
                        endTime: newEndDate,
                        duration: duration,
                        dataRef: doc.ref,
                        ...restData,
                    });
                });

                this.setState({
                    data,
                });

            }

            this.setState({
                today: moment(uidDate, "DDMMYYYY").format("DD MMMM YYYY"),
                isLoading: false,
                uid,
                // isManager
            });

        } catch (error) {
            console.error(error)

            this.setState({
                today: moment(uidDate, "DDMMYYYY").format("DD MMMM YYYY"),
                isLoading: false,
            });
        }
    }

    navigateDetail = sessionId => {
        const { activityId } = this.props.match.params
        const uidDate = activityId ? activityId : moment().format("DDMMYYYY");
        this.props.history.push(`/dashboard/facilities_employee/${this.state.uid}/activities/${uidDate}/report/${sessionId}`)
    }
    navigateUpdate = sessionId => {
        const { activityId } = this.props.match.params
        const uidDate = activityId ? activityId : moment().format("DDMMYYYY");
        this.props.history.push(`/dashboard/facilities_employee/${this.state.uid}/activities/${uidDate}/report/${sessionId}/update`)
    }
    
    renderListItem = (item, i) => {
        
        const { activityId } = this.props.match.params
        const uidDate = activityId ? activityId : moment().format("DDMMYYYY");
        return (
            <ListItem
                key={item.id}
                index={i}
                activityId={uidDate}
                navigateDetail={this.navigateDetail}
                navigateUpdate={this.navigateUpdate}
                dataRef={item.dataRef}
                {...item}
            />
        )
    }


    savePdf = async () => {
        this.setState({ isDownload: true })
        const domElement = document.getElementById('detail-page')
        try {
    
          const canvas = await html2canvas(domElement, { 
            onclone: (document) => {
              document.getElementById('detail-page').style.backgroundColor = '#ffff';
              if (this.state.canEdit) document.getElementById('add-employee-button').hidden = true;
              document.getElementById('download-button').style.visibility = 'hidden';
            },
            scale: 2,
          });
          const img = await canvas.toDataURL('image/png')
    
          const pdf = new jsPdf({
            compress: true
          })  
          const imgProps = pdf.getImageProperties(img);
          const width = pdf.internal.pageSize.getWidth();
          const height = (imgProps.height * width) / imgProps.width;
    
          pdf.addImage(img, 'JPEG', 0, 0, width, height, undefined, 'FAST')
          // setTimeout(() => {
            pdf.save(`report-fasilitas-kegiatan-harian-${moment().format("DD MMMM YYYY")}.pdf`)
            this.setState({ isDownload: false })
          // }, 4000);
        } catch (error) {
          console.error(error)
          this.setState({ isDownload: false })
        }
      }
    
    componentDidMount() {
        this.fetchData(this.props)
    }

    render() {
        const { activityId } = this.props.match.params
        const uidDate = activityId ? activityId : moment().format("DDMMYYYY");
        return (
            <div id="detail-page">
                <div className="box-header px-5 mt-3" style={{ backgroundColor: "#EFF2F7" }}>
                    <h3 style={{ color: "#3B4859" }}>KEGIATAN</h3>
                </div>
                <div className="container-fluid p-5 spacing-resp">
                    <Tabs activeKey={this.state.activityType} onSelect={(eventKey) => this.setState({ activityType: eventKey }, () => this.fetchData(this.props))} style={{ borderBottom: "1px solid #D9D2D2" }} defaultActiveKey="harian" id="uncontrolled-tab-example">
                        <Tab tabClassName="px-5" eventKey="harian" title="Harian">
                        </Tab>
                        <Tab tabClassName="px-5" eventKey="tambahan" title="Tambahan">
                        </Tab>
                    </Tabs>

                    <div className="box mb-0 p-4 mt-5" style={{ borderRadius: 10 }}>
                        <div className="row">
                            <div className="col-md-3">
                                <h6 className="mb-0 mt-2">
                                    <span style={{ color: "#579264", fontWeight: "bold" }}>Tanggal</span>
                                    {
                                        this.state.isLoading ?
                                                <span style={{ color: "#979D99", fontWeight: "bold", marginLeft: 15 }}>Loading...</span>
                                            :
                                                <span style={{ color: "#979D99", fontWeight: "bold", marginLeft: 15 }}>{this.state.today}</span>
                                    }
                                </h6>
                            </div>
                            <div className="col-md-3 offset-md-6 text-right">
                                {
                                    this.state.canEdit === true &&
                                    <Link to={`/dashboard/facilities_employee/${this.state.uid}/activies/${uidDate}/report/add?type=${this.state.activityType}`} className="nav-link" id="add-employee-button" >
                                        <button className="md-btn md-raised m-b-sm kr-color px-4" style={{ borderRadius: 5, fontSize: "0.7rem", fontWeight: "inherit" }}>
                                            <div style={{ color: "white" }}>
                                                <i className="fa fa-fw fa-plus" style={{ opacity: 1, marginRight: 5, color: "white" }} />
                                                Tambah Pekerjaan
                                            </div>
                                        </button>
                                    </Link>

                                } 
                            {
                                this.state.isDownload !== true ?
                                <a href="#" className="nav-link">
                                    <button
                                        type="button"
                                        id="download-button"
                                        onClick={this.savePdf}
                                        className="md-btn m-b-sm md-raised success px-4"
                                        style={{ backgroundColor: "#22313F", borderRadius: 5, fontWeight: "inherit", fontSize: "0.8em", padding: ".5em 1em" }}>
                                            {/* <i className="fa fa-download mr-1"></i> */}
                                        Download
                                    </button>
                                </a>
                            :
                                null
                            }
                            </div>
                        </div>
                    </div>

                    <div className="box mb-0 p-4 mt-4" style={{ borderRadius: 10 }}>
                        <h6 className="mb-0" style={{ color: "#579264", fontWeight: "bold" }}>Task List</h6>

                        <div className="table-responsive mt-5">
                            <table className="table table-no-bordered m-a-0">
                                <thead style={{ color: "#579264" }}>
                                    <tr>
                                        <th scope="col" className="text-center">No</th>
                                        <th scope="col" className="text-left">List</th>
                                        <th scope="col" className="text-center">Jam Mulai</th>
                                        <th scope="col" className="text-center">Jam Selesai</th>
                                        <th scope="col" className="text-center">Durasi</th>
                                        <th scope="col" className="text-center"></th>
                                    </tr>
                                </thead>
                                <tbody style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                    {
                                        this.state.isLoading
                                            ?
                                            <React.Fragment>
                                                <LoadingTable column={5} />
                                                <LoadingTable column={5} />
                                                <LoadingTable column={5} />
                                                <LoadingTable column={5} />
                                            </React.Fragment>
                                            : this.state.data.length === 0
                                                ?
                                                <tr>
                                                    <td className="text-center" colSpan="9">
                                                        <p>Tidak ada data!</p>
                                                    </td>
                                                </tr>
                                                :
                                                this.state.data.map(this.renderListItem)
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <LoadingModal isDownload={this.state.isDownload} />
            </div>
        );
    }
}



function LoadingModal(props) {
    const [show, setShow] = React.useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    React.useEffect(() => {
      setShow(props.isDownload)
    }, [props.isDownload])
    // console.log('show', show)
    return (
      show ?
        <>
        {/* <Button variant="primary" onClick={handleShow}>
          Launch demo modal
        </Button> */}
  
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            {/* <Modal.Title>Modal heading</Modal.Title> */}
          </Modal.Header>
          <Modal.Body><h2 className="text-center">Loading...</h2></Modal.Body>
        </Modal>
        </>
      :
      null
    );
  }

const mapStateToProps = state => {
    return {
        uid: state.firebase.auth.uid,
        region: state.region,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect(),
)(Activities)
import React from "react"
import { Switch, Route } from 'react-router-dom'

import FacilitiesScreen from '../../screens/Facilities/TableListScreen';
import FacilitiesScreenHistory from '../../screens/Facilities/History/TableListScreen';
import AddFacilitiesDataScreen from '../../screens/Facilities/AddScreen';
import FacilitiesDetailScreen from '../../screens/Facilities/Detail';
import UpdateFacilitiesDataScreen from '../../screens/Facilities/UpdateScreen';

import EmployeeListScreen from '../../screens/Facilities/EmployeeList';
import EmployeeHistoryScreen from '../../screens/Facilities/EmployeeList/EmployeeHistory';
import EmployeeTaskPerDayScreen from '../../screens/Facilities/EmployeeList/EmployeeHistory/EmployeeTaskPerDay';
import EmployeeReportScreen from '../../screens/Facilities/EmployeeList/EmployeeHistory/EmployeeTaskPerDay/Detail';

const FacilitiesNav = (props) => (
    <Switch>
        <Route 
            exact={true}
            path={`${props.match.path}/:userId/activities/:activityId`}
            render={(routeProps) => <FacilitiesScreen {...routeProps} />}
        />
        <Route 
            exact={true}
            path={`${props.match.path}/:userId/activies/:activityId/report/add`}
            render={(routeProps) => <AddFacilitiesDataScreen {...routeProps} />}
        />
        <Route 
            exact={true}
            path={`${props.match.path}/:userId/activities/:activityId/report/:sessionId`}
            render={(routeProps) => <FacilitiesDetailScreen {...routeProps} />}
        />
        <Route 
            exact={true}
            path={`${props.match.path}/:userId/activities/:activityId/report/:sessionId/update`}
            render={(routeProps) => <UpdateFacilitiesDataScreen {...routeProps} />}
        />
        <Route 
            exact={true}
            path={`${props.match.path}/history/:userId`}
            render={(routeProps) => <FacilitiesScreenHistory {...routeProps} />}
        />

        <Route 
            exact={true}
            path={`${props.match.path}/employee_list`}
            render={(routeProps) => <EmployeeListScreen {...routeProps} />}
        />
        <Route 
            exact={true}
            path={`${props.match.path}/employee_list/:employeeId`}
            render={(routeProps) => <EmployeeHistoryScreen {...routeProps} />}
        />
        <Route 
            exact={true}
            path={`${props.match.path}/employee_list/:employeeId/activities/:activityId`}
            render={(routeProps) => <EmployeeTaskPerDayScreen {...routeProps} />}
        />
        <Route 
            exact={true}
            path={`${props.match.path}/employee_list/:employeeId/activities/:activityId/report/:reportId`}
            render={(routeProps) => <EmployeeReportScreen {...routeProps} />}
        />
    </Switch>
)

export default FacilitiesNav
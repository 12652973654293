import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import { Link } from "react-router-dom"
import { Alert } from 'react-bootstrap'
import { debounce } from 'lodash'
import LoadingTable from "../../components/loading-table"
import Pagination from '../../components/Pagination'

import DataList from './DataList'

class TableListScreen extends Component {
  state = {
    acceptInfo: false,
    errorInfo: false,
    show: false,
    data: [],
    selectedDepartment: '',
    departmentList: [],
    currentData: [],
    currentPage: null,
    rawData: [],
    search: '',
    deleteId: null,
    isLoading: true,
    status: "published",
    selectedOption: "bogor",
  };
  handleAcceptInfo = () => {
    this.setState({ acceptInfo: !this.state.acceptInfo });
  }
  showErrorInfo = () => {
    this.setState({ errorInfo: !this.state.errorInfo });
  }
  renderTableHead = () => (
    <tr style={{ color: "#579264" }}>
      <th className="text-center">Nama</th>
      <th className="text-center">
        Department
        <Link as="button" data-toggle="modal" data-target="#exampleModalCenter">
          <i style={{ fontSize: 10 }} className='mx-3 fa fa-sort-amount-desc'></i>
        </Link>
      </th>
      <th className="text-center">Jabatan</th>
      <th className="text-center">Email</th>
      <th className="text-center"></th>
    </tr>
  )
  componentDidMount() {
    if (this.props.region) {
      
      this.fetchData(this.props);
    }
  }

  componentWillReceiveProps(nextProps) {
    console.log({ nextProps, props: this.props })
    if (nextProps.region !== this.props.region) {
      this.setState({ region: nextProps.region })
      this.fetchData(nextProps)
    }
  }

  fetchData = async (props) => {
    console.log("fetching data....")
    try {
      let data = []
      const region = props.region.toLowerCase()
      this.setState({ data: [], isLoading: true })
      const snapshots = await this.props.firestore.get({
        collection: 'region',
        doc: region,
        subcollections: [{
          collection: 'employee',
          where: ['isAdmin', '==', false],
          orderBy: ['name'],
        }],
      });
      if (!snapshots.empty) {
        const lastVisible = snapshots.docs[snapshots.docs.length - 1];
        snapshots.forEach((doc) => {
          data.push({ id: doc.id, ...doc.data(), dataRef: doc.ref });
        });

        let departmentList = data.map((item) => item.department)

        departmentList = [...new Set(departmentList)]
        this.setState({
          data,
          departmentList,
          rawData: data,
          region,
          isLoading: false,
          lastVisible
        });
      } else {
        this.setState({
          data: [],
          rawData: [],
          isLoading: false
        });
      }
      
    } catch (error) {
      console.error(error)
    }
  }

  searchData = debounce(search => {
    const tempData = this.state.rawData
    const filtered = tempData.filter((item) => {
      return item.name.toLowerCase().includes(search.toLowerCase())
    })
    this.setState({ data: filtered })
  }, 250)

  onChangeText = (e) => {
    this.setState({ search: e.target.value })
    this.searchData(e.target.value)
  }
  onChange = (value) => {
    this.setState({
      selectedOption: value,
    }, () => this.fetchData())
  }
  onChangeFilterDepartment = (e) => this.setState({ selectedDepartment: e.target.value })

  filterDepartment = (value) => {
    let selectedDepartment = value
    this.setState({
      selectedDepartment
    }, () => this.filter())
  }

  filter = () => {
    let tempData = this.state.rawData

    let filteredData = []
    const { selectedDepartment } = this.state;
    if (!selectedDepartment) {
      filteredData = this.state.rawData
    } else {
      filteredData = tempData.filter(({ department }) => department == selectedDepartment)
    }
    this.setState({
      data: filteredData
    })
  }

  //2. pagination
  onPageChanged = dataChanged => {
    const { data } = this.state;
    const { currentPage, totalPages, pageLimit } = dataChanged;

    const offset = (currentPage - 1) * pageLimit;
    const currentData = data.slice(offset, offset + pageLimit);

    this.setState({ currentPage, currentData, totalPages });
  }


  render() {
    return (
      <React.Fragment>

        <div className="box-header px-5 mt-3" style={{ backgroundColor: "#EFF2F7" }}>
          <h3 style={{ color: "#3B4859" }}>INFORMASI KARYAWAN</h3>
        </div>
        <div className="box-body" style={{ backgroundColor: "transparent" }}>
          <div className="col-12">
            <div style={{ borderRadius: 5 }}>
              <div className="row mx-2">
                <div className="col-10">
                  <div className="col-md-3 input-group md-form form-sm form-1 py-1 px-2" style={{ backgroundColor: "white", color: "#999999", border: "1px solid #999999", borderRadius: 7 }}>
                    <div className="input-group-prepend">
                      <span className="input-group-text lighten-3">
                        <i className="fas fa-search mt-1" style={{ color: "#999999", fontSize: "0.7rem" }} aria-hidden="true"></i>
                      </span>
                    </div>
                    <input
                      value={this.state.search}
                      onChange={this.onChangeText}
                      className="form-control my-0 py-1"
                      style={{ borderColor: "transparent", fontSize: "0.8rem", color: "#999999", zIndex: "unset" }} type="text"
                      placeholder="Cari nama karyawan"
                      aria-label="Search"
                    />
                  </div>
                </div>
                {
                  ((this.props.profile.token && this.props.profile.token.claims.admin == true) ||
                    (this.props.profile.token && this.props.profile.token.claims.department == "Human Resource")) ?
                    <React.Fragment>
                      <div className="col-xl-2 col-lg-12 col-md-12 col-sm-12">
                        <ul className="nav navbar-nav text-right">
                          <li className="nav-item">
                            <Link
                              to={{
                                pathname: `/dashboard/employee/add`,
                                state: { region: this.state.selectedOption }
                              }}
                              className="nav-link">
                              <button className="md-btn md-raised m-b-sm kr-color px-4" style={{ borderRadius: 5, fontSize: "0.7rem", fontWeight: "inherit" }}>
                                <div style={{ color: "white" }}>
                                  <i className="fa fa-fw fa-plus" style={{ opacity: 1, marginRight: 5, color: "white" }} />
                                    Tambah Karyawan
                                </div>
                              </button>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </React.Fragment>
                    :
                    null
                }
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="box" style={{ borderRadius: 5 }}>
              {
                this.state.acceptInfo &&
                <Alert dismissible variant="success" onClose={this.handleAcceptInfo}>
                  <Alert.Heading>Success!</Alert.Heading>
                  <p style={{ color: '#3c763d' }}>
                    Successfully deleted a data.
                </p>
                </Alert>
              }

              {
                this.state.errorInfo &&
                <Alert dismissible variant="danger" onClose={this.handleErrorInfo}>
                  <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                  <p style={{ color: '#a94442' }}>
                    An error occured while processing your request! Please try again.
                </p>
                </Alert>
              }

              <div className="box p-3 mb-0" style={{ borderRadius: 10 }}>
                <div className="table-responsive mt-3">
                  <table className="table" style={{ borderRadius: "10px" }}>
                    <thead>
                      {this.renderTableHead()}
                    </thead>
                    <tbody>
                      {
                        this.state.isLoading
                          ?
                          <React.Fragment>
                            <LoadingTable column={5} />
                            <LoadingTable column={5} />
                            <LoadingTable column={5} />
                            <LoadingTable column={5} />
                          </React.Fragment>
                          : this.state.currentData.length == 0
                            ?
                            <tr>
                              <td className="text-center" colSpan="9">
                                <p>No data available!</p>
                              </td>
                            </tr>
                            : <DataList
                              data={this.state.currentData}
                              handleAcceptInfo={() => this.handleAcceptInfo()}
                              showErrorInfo={() => this.showErrorInfo()}
                              selectedOption={this.state.selectedOption}
                              departmentList={this.state.departmentNameList}
                              selectedDepartment={this.state.selectedDepartment}
                            />
                      }
                    </tbody>
                  </table>

                  <Pagination
                    totalRecords={this.state.data.length}
                    pageLimit={10}
                    pageNeighbours={1}
                    onPageChanged={this.onPageChanged}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content" style={{ borderRadius: 15 }}>
              <div className="modal-header text-right" style={{ border: "none" }}>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body px-5">
                <div className="text-center">
                  <h6 style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold" }}>FILTER</h6>
                </div>
                <div className="mt-4">
                  <p style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: 500 }}>Kategori Nama Department</p>
                  <select value={this.state.selectedDepartment} onChange={this.onChangeFilterDepartment} className="browser-default custom-select" style={{ width: "100%", color: "rgba(124, 122, 122, 0.87)" }}>
                    <option value="">Semua Department</option>
                    {
                      this.state.departmentList.map((item) => (
                        <option value={item}>{item}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
              <div className="modal-footer mt-3 justify-content-center" style={{ border: "none" }}>
                <button onClick={() => this.filterDepartment(this.state.selectedDepartment)} data-dismiss="modal" type="button" className="btn w-25" style={{ backgroundColor: "#579264", color: "white", fontSize: "0.8em" }}>
                  Set
                      </button>
                <button type="button" className="btn w-25" data-dismiss="modal" style={{ backgroundColor: "#969595", color: "white", fontSize: "0.8em" }}>
                  Cancel
                      </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  region: state.region,
  profile: state.firebase.profile
})

export default compose(
  connect(mapStateToProps),
  firestoreConnect()
)(TableListScreen)
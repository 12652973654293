import React from "react"
import NumberFormat from "react-number-format"
import ErrorMessage from "./ErrorMessage"

const IntegerInput = (field) => {
  const { input: { value, onChange } } = field
  const onValueChange = (numberValues) => {
    const { formattedValue, value } = numberValues;
    onChange(parseInt(value))
  }
  return (
    <div className="form-group mb-4">
      <label 
        htmlFor={`input-${field.label}`} 
        className="form-control-label" 
        style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold" }}
      >
        {field.label}
      </label>
      <NumberFormat
        {...field.input}
        id={`input-${field.label}`}
        style={{ fontSize: "0.9em", backgroundColor: "#f0f0f0" }}
        className="form-control field-box"
        placeholder={field.placeholder}
        onValueChange={onValueChange}
      />
      <ErrorMessage {...field} />
    </div>
  )
}

export default IntegerInput
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'

import SubmitButton from '../../components/SubmitButton'
import LoaderModal from '../../components/LoaderModal'
import validate from './validate'
import AvatarPicker from '../../components/AvatarPicker'
import PlantTextInput from '../../components/Form/PlantTextInput'
import PlantTextArea from '../../components/Form/PlantTextArea'
import SelectMenu from '../../components/Form/SelectMenuInput'
import DraftInput from '../../components/Form/DraftInput'
import SelectAreaInput from '../../components/Form/SelectAreaInput'
import { firestoreConnect } from 'react-redux-firebase'
import SelectSectorInput from '../../components/Form/SelectSectorInput'
import MultiCheckboxInput from '../../components/Form/MultiCheckboxInput'

const categories = [
  { id: "1", label: "Berkelompok", value: "Berkelompok"},
  { id: "2", label: "Individu", value: "Individu"},
  { id: "3", label: "Baik", value: "Baik"},
  { id: "4", label: "Buruk", value: "Buruk"}
]

let UpdateForm = ({ handleSubmit, pristine, submitting, valid, sectorList, sector, region, ...otherProps }) => {
  const [ areaList, setAreaList ] = useState([])
  useEffect(() => {
    setAreaList(otherProps.areaList)
  }, [otherProps.areaList])
  const fetchArea = async (selectedItem) => {
    console.log('selectedItem', selectedItem)
    let { id, maintenanceArea } = selectedItem
    try {
      let areaList = maintenanceArea.map((item) => {
        return { label: item, value: item }
      })
      setAreaList(areaList)
    } catch (error) {
      setAreaList([])
    }
  }
  return (
    
    <form role="form" onSubmit={handleSubmit}>
      <div className="col-sm-12">
        <div className="p-5" style={{ borderRadius: 5 }}>

          <Field
            component={AvatarPicker}
            label="Tambah Foto"
            name="avatar"
          />

          <hr />

          <div className="form-group row mb-4">
            <label 
              className="col-md-2 form-control-label" 
              style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold" }}
            >
              Region
            </label>
            <div className="col-md-10">
              <p>Bogor</p>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-lg-3 col-sm-xs-12 mb-sm-3 mb-xs-3">
              <Field
                name="name"
                component={PlantTextInput}
                placeholder="Nama Lokal"
                label="Nama Lokal"
                style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
              />
            </div>

            <div className="col-lg-3 col-sm-xs-12 mb-sm-3 mb-xs-3">
              <Field
                name="collectionNumber"
                component={PlantTextInput}
                placeholder="Nomor Koleksi"
                label="Nomor Koleksi"
                style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
              />
            </div>
            <div className="col-lg-3 col-sm-xs-12 mb-sm-3 mb-xs-3">
              <div className="form-group mb-4">
                <label 
                  htmlFor={`input-status`} 
                  className="form-control-label" 
                  style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold" }}
                >
                  Kategori Tumbuhan
                </label>
                <Field 
                  name="category" 
                  component="select" 
                  className="form-control input-c" 
                  style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}>
                  <option value="">Pilih Kategori Tumbuhan</option>
                  <option value="Tanaman Koleksi">Tanaman Koleksi</option>
                  <option value="Tanaman Non Koleksi">Tanaman Non Koleksi</option>
                  <option value="Tanaman Hias">Tanaman Hias</option>
                </Field>
              </div>

            </div>
          </div>

          <div className="row mb-3">
            <div className="col-lg-3 col-sm-xs-12 mb-sm-3 mb-xs-3">
              <Field
                name="species"
                component={DraftInput}
                placeholder="Spesies"
                label="Spesies"
                style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
              />
            </div>

            <div className="col-lg-3 col-sm-xs-12 mb-sm-3 mb-xs-3">
              <Field
                name="genus"
                component={DraftInput}
                placeholder="Suku"
                label="Suku"
                style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
              />
            </div>
          </div>
          
          <div className="row mb-3">
            <div className="col-lg-3 col-sm-xs-12 mb-sm-3 mb-xs-3">
              <Field
                name="englishName"
                component={PlantTextInput}
                placeholder="Nama Inggris"
                label="Nama Inggris"
                style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
              />
            </div>
            <div className="col-lg-3 col-sm-xs-12 mb-sm-3 mb-xs-3">
              <div className="form-group mb-4">
                <label className="form-control-label"  style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold" }}>
                  Wilayah
                </label>
                <div className="container px-0">
                  <Field
                    component={SelectSectorInput}
                    label="Sektor"
                    placeholder="Pilih Sektor"
                    items={sectorList}
                    fetchData={fetchArea}
                    name="sector"
                    formName="updatePlantForm"
                    area="vak"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-xs-12 mb-sm-3 mb-xs-3">
                <div className="form-group mb-4">
                  <label className="form-control-label"  style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold" }}>
                    Vak
                  </label>
                  <div className="container px-0">
                    <Field
                      component={SelectAreaInput}
                      label="Vak"
                      placeholder="Pilih Vak"
                      items={areaList}
                      name="vak"
                    />
                  </div>
                </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-3 col-sm-xs-12 mb-sm-3 mb-xs-3">
              <Field
                name="specimenTotal"
                component={PlantTextInput}
                placeholder="Jumlah Spesimen"
                label="Jumlah Spesimen"
                style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
              />
            </div>
            <div className="col-lg-3 col-sm-xs-12 mb-sm-3 mb-xs-3">
              <div className="form-group mb-4">
                <label 
                  htmlFor={`input-status`} 
                  className="form-control-label" 
                  style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold" }}
                >
                  Status
                </label>
                <Field 
                  name="status" 
                  component="select" 
                  className="form-control input-c" 
                  style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}>
                  <option value="">Pilih Status</option>
                  <option value="Hidup">Hidup</option>
                  <option value="Mati">Mati</option>
                </Field>
              </div>

            </div>
            <div className="col-lg-3 col-sm-xs-12 mb-sm-3 mb-xs-3">
              <Field
                name="habitus"
                component={PlantTextInput}
                placeholder="Perawakan"
                label="Perawakan"
                style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
              />
            </div>
          </div>
          <div className="mt-5">
            <h6 style={{ color: "#579264", fontWeight: "bold" }}>Pilih Kondisi Tumbuhan</h6>
            <Field name="conditions" component={MultiCheckboxInput} areaList={categories} />
          </div>
          <Field
            name="deployment"
            component={PlantTextArea}
            placeholder="Persebaran"
            label="Persebaran"
            rows={5}
            style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
          />
          <Field
            name="usability"
            component={PlantTextArea}
            placeholder="Kegunaan"
            label="Kegunaan"
            rows={5}
            style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
          />
          <Field
            name="description"
            component={PlantTextArea}
            placeholder="Ciri-ciri"
            label="Ciri-ciri"
            rows={5}
            style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
          />
          <Field
            name="place"
            component={PlantTextArea}
            placeholder="Habitat"
            label="Habitat"
            rows={5}
            style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
          />

          <div className="col-lg-6 col-xs-12 px-0">
            <div className="col-lg-3 col-xs-12 px-0 mt-5 text-center">
              <SubmitButton disabled={!valid || submitting} />
            </div>
            <LoaderModal submitting={submitting} />
          </div>
        </div>
      </div>
    </form>
  )
}

UpdateForm = reduxForm({
  form: 'updatePlantForm',
  validate,
  enableReinitialize: true,
})(UpdateForm)

const selector = formValueSelector('updatePlantForm')
UpdateForm = connect(
  state => {

    const sectorList = selector(state, 'sectorList') || [];
    const areaList = selector(state, 'areaList') || [];
    const sector = selector(state, 'sector') || [];
    const region = selector(state, 'region') || "";

    
    return {
      sectorList,
      areaList,
      sector,
      region,
    }
  }
)(UpdateForm)

export default firestoreConnect()(UpdateForm)
import { firestore } from "firebase"

function emailIsValid (email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export default function validate(values) {
  
  const errors = {}
  if (!values.sector) {
    errors.sector = 'Sector wajib diisi'
  }
  if (!values.area) {
    errors.area = 'Area wajib diisi'
  }
  if (!values.images) {
    errors.images = 'Images wajib diisi'
  }

  return errors
      
}
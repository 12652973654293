import React from 'react'
import { Field } from 'redux-form'

const TextInput = ({ input, label, type, meta: { touched, error } }) => (
  <React.Fragment>
    <textarea {...input} type={type} placeholder={label} className="form-control" style={{ height: 130 }} />
    {touched && error && <span>{error}</span>}
  </React.Fragment>
);

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const MenuInput = ({ fields, meta, label }) => {
  return (
    <React.Fragment>
      <ul id="list" className="p-0">
        {fields.map((field, index) => 
            <li key={index}>
              <label className="form-control-label" style={{ color: "#159588", fontWeight: "bold" }} >
                {days[index]}
              </label>
              <Field
                name={`${field}.menu`}
                type="text"
                component={TextInput}
                label={`${label}`} />
            </li>
              // <li>
              //   { meta.error && meta.touched && <div className="invalid-feedback"> {meta.error} </div>}
              // </li>
        )}
      </ul>
    </React.Fragment>
  );
}

export default MenuInput
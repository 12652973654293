import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { withFirestore } from 'react-redux-firebase'

import ListItem from './ListItem'

class DataList extends Component {
  
  static defaultProps = {
    data: []
  }

  navigateDetail = sessionId => {
    this.props.history.push(`/dashboard/daily_report/${sessionId}/update`)
  }

  navigateDetail = id => {
    const { sessionId } = this.props.match.params;
    const { selectedRegion } = this.props.location.state;
    this.props.history.push({
      pathname: `/dashboard/plant_info/${sessionId}/daily_report/${id}/update`,
      state: { selectedRegion: selectedRegion }
    })
  }

  renderListItem = (item) => {
    return (
      <ListItem 
        key={item.id}
        navigateDetail={this.navigateDetail}
        sessionType={this.props.sessionType}
        handleAcceptInfo={this.props.handleAcceptInfo}
        showErrorInfo={this.props.showErrorInfo}
        {...item}
      />
    )
  }
  
  render() {
    return this.props.data.map(this.renderListItem)
  }
}

export default compose(
  withRouter,
  withFirestore,
)(DataList)
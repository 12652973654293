import React, { Component } from 'react';
import { DateRangePicker, isInclusivelyAfterDay } from 'react-dates';
import { connect } from 'react-redux'
import { compose } from 'redux'
import moment from 'moment'
import queryString from 'query-string'
import { firestoreConnect } from 'react-redux-firebase'
import { debounce }from 'lodash'

import DataTableStall from './DataTableStall'

import { compare, upperFirstCase } from '../../../../../../helpers/common'
import DownloadReport from '../../../../../../components/DownloadReport'
import DatePicker from '../../../../../../components/DatePicker';

class TicketingDetail extends Component {
    state = {
        data: [],
        rawData: [],
        foodNameList: [],
        stallNameList: [],
        selectedFood: '',
        selectedStall: '',
        fileName: '',
        search: '',
        startDate: moment(),
        endDate: moment(),
        loading: true,
        regionList: [
            'Bogor',
            'Cibodas',
            'Purwodadi',
            'Bali'
        ],
        region: 'Bogor'
    }
    valuesDirty = false;

    fetchDataEvent = async () => {
        this.setState({
            loading: true,
            rawData: [],
            data: [],
            foodNameList: [],
            stallNameList: [],
            selectedFood: ''
        })

        try {
            let snapshots = await this.props.firestore.get({
                collection: "region",
                doc: this.state.region.toLowerCase(), //hardcode
                subcollections: [{
                    collection: 'dataFasilitas',
                    where: [
                        ['date', '>=', moment(this.state.startDate).tz("Asia/Jakarta").startOf('day').toDate()],
                        ['date', '<=', moment(this.state.endDate).tz("Asia/Jakarta").startOf('day').toDate()],
                    ],
                    orderBy: ['date', 'asc'],
                }]
            });
            let tempData = []
            snapshots.forEach((doc) => {
                const { data, date } = doc.data()

                let newData = data.map((item) => {
                    return {
                        date: moment(date.toDate()).format("D MMMM YYYY"),
                        ...item,
                    }
                })
                newData.sort(compare) // sort product name

                tempData = [...tempData, ...newData]
            })
            console.log({ tempData })
            
            const customFields = [
                { label: 'Tanggal', value: 'date' },
                { label: 'Stall', value: 'stall' },
                { label: 'Nama Makanan', value: 'productName' },
                { label: 'Pax', value: 'qty' },
                { label: 'Harga Satuan', value: 'priceUnit' },
                { label: 'Subtotal', value: 'subtotal' },
                { label: 'Service', value: 'serviceAmount' },
                { label: 'Tax', value: 'taxAmount' },
                { label: 'Total', value: 'subTotalIncl' },
            ]
            
            let foodList = tempData.map((item) => item.productName)
            let stallList = tempData.map((item) => item.stall)
            let foodNameList = [...new Set(foodList)]
            let stallNameList = [...new Set(stallList)]

            let sortedFoodList = foodNameList.sort()
            let sortedStallList = stallNameList.sort()


            this.setState({
                data: tempData,
                rawData: tempData,
                customFields,
                fileName: 'report-fasilitas-detail-' + this.state.region.toLowerCase(),
                stallNameList: sortedStallList,
                foodNameList: sortedFoodList,
                loading: false
            })
        } catch (error) {
            console.error(error)
            this.setState({
                dataRevenue: [],
                loading: false
            })
        }
    }

    componentDidMount() {
        let { region = "bogor" } = this.props.match.params;
        const values = queryString.parse(this.props.location.search)
        const startDate = values.startDate ? moment(values.startDate, "YYYY-MMMM-DD") : moment()
        const endDate = values.endDate ? moment(values.endDate, "YYYY-MMMM-DD") : moment()
        region = upperFirstCase(region);
        this.setState({ 
            region,
            fileName: this.state.fileName + '-' + region,
            startDate,
            endDate,
        }, () => this.fetchDataEvent())
    }



    filterFoodName = (value) => {
        let selectedFood = value
        this.setState({
            selectedFood
        }, () => this.filter())
    }

    filterStall = (value) => {
        let selectedStall = value
        this.setState({
            selectedStall
        }, () => this.filter())
    }


    filter = () => {
        let tempData = this.state.rawData
        //kalau tidak ada yang di filter... raw data
        // kalau ada yang di filter, 1
        let filteredData = []
        const { selectedFood, selectedStall } = this.state;
        if (!selectedFood && !selectedStall) {
            filteredData = this.state.rawData
        } else {
            filteredData = tempData.filter(({ productName, stall }) =>
                selectedFood && selectedFood === productName && selectedStall && selectedStall === stall
                || selectedFood && !selectedStall && selectedFood === productName
                || !selectedFood && selectedStall && selectedStall === stall)

        }
        this.setState({
            data: filteredData
        })
    }
    
    onDatesChange = ({ startDate, endDate }) => {
        this.setState({ startDate, endDate }, () => this.fetchDataEvent())
    }
    
    onChangeRegion = (e) => {
        this.setState({ region: e.target.value }, () => this.fetchDataEvent())
    }
    searchData = debounce(search => {
        const tempData = this.state.rawData
        const filtered = tempData.filter((item) => {
            return item.productName.toLowerCase().includes(search.toLowerCase())
        })
        this.setState({ data: filtered })
    }, 250)
    onChangeText = (e) => {
        this.setState({ search: e.target.value })
        this.searchData(e.target.value)
    }
    
    render() {
        return (
            <React.Fragment>
                <div className="box-header px-5 mt-3" style={{ backgroundColor: "#EFF2F7" }}>
                    <h3 style={{ color: "#3B4859" }}>REVENUE (SEWA FASILITAS LAPORAN DETAIL)</h3>
                </div>
                <div className="container-fluid p-5 spacing-resp">
                    <div className="row mb-4">
                        <div className="col-lg-4 col-md-6 mt-3">
                            <div className="row">
                                <div className="col-5"><h6 style={{ fontSize: "0.8rem", color: "#3B4859" }}>Dari</h6></div>
                                <div className="col-5"><h6 style={{ fontSize: "0.8rem", color: "#3B4859" }}>Sampai</h6></div>
                            </div>
                            <DatePicker 
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                onDatesChange={this.onDatesChange} 
                            />
                        </div>
                        <div className="col-md-2 d-flex align-items-end mt-3">
                            <select className="form-control input-c" value={this.state.region} onChange={this.onChangeRegion} style={{ borderRadius: "6px", border: "1px solid #A9A9AB" }}>
                                {
                                    this.state.regionList.map((region, i) => (<option key={i} value={region}>{region}</option>))
                                }
                            </select>
                        </div>
                        <div className="col-lg-3 col-md-6 d-flex align-items-end mt-3">
                            <DownloadReport
                                data={this.state.data}
                                fields={this.state.customFields}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                fileName={this.state.fileName}
                            />
                        </div>
                    </div>

                    <div className="col-md-3 input-group md-form form-sm form-1 py-1 px-2" style={{ backgroundColor: "white", color: "#999999", border: "1px solid #999999", borderRadius: 7 }}>
                        <div className="input-group-prepend">
                            <span className="input-group-text lighten-3">
                                <i className="fas fa-search mt-1" style={{ color: "#999999", fontSize: "0.7rem" }} aria-hidden="true"></i>
                            </span>
                        </div>
                        <input 
                            value={this.state.search} 
                            onChange={this.onChangeText}
                            className="form-control my-0 py-1" 
                            style={{ borderColor: "transparent", fontSize: "0.8rem", color: "#999999", zIndex: "unset" }} type="text" 
                            placeholder="Cari nama produk" 
                            aria-label="Search" 
                            />
                    </div>

                    <div className="py-3">
                        <div className="box p-3 mb-0" style={{ borderRadius: 10 }}>
                            <div className="table-responsive">
                                <DataTableStall
                                    stallNameList={this.state.stallNameList}
                                    foodNameList={this.state.foodNameList}
                                    data={this.state.data}
                                    loading={this.state.loading}
                                    selectedFood={this.state.selectedFood}
                                    selectedStall={this.state.selectedStall}
                                    filterFoodName={this.filterFoodName}
                                    filterStall={this.filterStall}
                                />
                            </div>
                        </div>
                    </div>
                    
                </div>
            </React.Fragment>
        );
    }
}


const mapStateToProps = state => {
    return { 
        auth: state.firebase.auth, 
        profile: state.firebase.profile
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect()
)(TicketingDetail)
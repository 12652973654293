import { storage } from "firebase";

import { splitSlash } from "../../utils/common";

export const storageRef = storage().ref();

const getFilePath = (path, file) => {
  const fileExt = splitSlash(file.type);
  const imgName = `banner`;

  return `${path}/${imgName}.${fileExt}`;
};

export const uploadBanner = async (savePath, file) => {

  const metadata = { contentType: file.type };
  const filePath = getFilePath(savePath, file);

  const snapshot = await storageRef.child(filePath).put(file, metadata);
  const { name: snapshotName, fullPath } = snapshot.ref;

  const name = snapshotName.toString();
  const path = fullPath.toString();
  const url = await storageRef.child(path).getDownloadURL();

  const banner = { path, name, url };
  
  return banner;
};

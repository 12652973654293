import Axios from "axios";
import firebaseConfig from "../firebaseConfig";


const getDataParams = (data) => {
    const dataParams = Object.keys(data).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
    }).join('&');
    return dataParams;
}

export async function getNewAccessToken(refresh_token) {
    try {

        const bodyData = {
            "grant_type": "refresh_token",
            "refresh_token": refresh_token
        }
        const queryParams = getDataParams(bodyData)
        const url = `https://securetoken.googleapis.com/v1/token?key=${firebaseConfig.apiKey}`

        const response = await Axios.post(url,
            queryParams,
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            })

        if (response.status != 200) {
            throw new Error('Failed To get access token!');
        }

        console.log('response.data', response.data)
        const isSame = response.data.id_token=== response.data.access_token
        return response.data.id_token
    } catch (error) {
        console.error("error", error)
    }
}
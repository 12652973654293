import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Link } from "react-router-dom"
import { Alert } from 'react-bootstrap'
import { stateToHTML } from 'draft-js-export-html'; 
import { convertFromRaw } from 'draft-js';
import Fuse from 'fuse.js'
import LoadingTable from "../../components/loading-table"

import DataList from './DataList'
import Pagination from '../../components/Pagination'

const categories = [
  "Tanaman Koleksi",
  "Tanaman Non Koleksi",
  "Tanaman Hias"
]
class TableListScreen extends Component {
  state = { 
    acceptInfo: false,
    errorInfo: false,
    show: false,
    data: [],
    rawData: [],
    isLoading: true,
    showMore: false,
    status: "published",
    selectedRegion: "bogor",
    searchText: "",
    selectedCategory: '',
    currentData: [], 
    currentPage: null, 
    totalPages: null,
    categories,
    selectedSector: "", 
    sectors: []
  };
  handleAcceptInfo = () => {
    this.setState({ acceptInfo: !this.state.acceptInfo });
  }
  showErrorInfo = () => {
    this.setState({ errorInfo: !this.state.errorInfo });
  }
  renderTableHead = () => (
    <tr style={{ color: "#579264" }}>
      <th className="text-center">Foto</th>
      <th className="text-center">No Koleksi</th>
      <th scope="col" style={{ fontWeight: "bold" }} className="text-center">
         Kategori Tumbuhan
        <Link as="button" data-toggle="modal" data-target="#exampleModalCenter">
          <i style={{ fontSize: 10 }} className='mx-3 fa fa-sort-amount-desc'></i>
        </Link>
      </th>
      <th className="text-center">Spesies</th>
      <th className="text-center">Nama Lokal</th>
      <th className="text-center">Suku</th>
      <th className="text-center">Wilayah
      
      <Link as="button" data-toggle="modal" data-target="#modalSector">
          <i style={{ fontSize: 10 }} className='mx-3 fa fa-sort-amount-desc'></i>
        </Link>
      </th>
      <th></th>
    </tr>
  )
  
  componentDidMount() {
    this.fetchData(this.props);
  }

  convertCommentFromJSONToHTML = (text) => stateToHTML(convertFromRaw(text))

  componentWillReceiveProps(nextProps) {
    if (nextProps.date !== this.props.date ||
      nextProps.region !== this.props.region
      ) {
      this.setState({ region: nextProps.region })
      this.fetchData(nextProps)
    }
  }
  fetchData = async (props) => {
    let data = []
    this.setState({ 
      isLoading: true,
      data: [],
      rawData: [],
    })
    try {
      const snapshots = await this.props.firestore.get({ 
        collection: 'region',
        doc: props.region.toLowerCase(),
        subcollections: [{ 
          collection: 'plants', 
          orderBy: [
            ['name', 'asc']
          ],
          // limit: 10
        }]
      });
      if (!snapshots.empty) {
        let tempSectors = [];
        const lastVisible = snapshots.docs[snapshots.docs.length - 1];
        snapshots.forEach((doc) => {
          const { avatar, genus, species, ...restData } = doc.data()
          
          let newGenus = typeof genus === "object" ? this.convertCommentFromJSONToHTML(genus): "-"
          let newSpecies = typeof genus === "object" ?  this.convertCommentFromJSONToHTML(species): "-"

          data.push({ 
            id: doc.id,
            avatar: avatar ? avatar.url : "",
            genus: newGenus,
            species: newSpecies,
            dataRef: doc.ref, 
            ...restData,
          });
          if (doc.data().sector) {
            
            tempSectors.push(doc.data().sector)
          }
        });
        let sectors = [...new Set(tempSectors)]
        sectors.sort()
        this.setState({
          data,
          rawData: data,
          isLoading: false,
          lastVisible,
          showMore: true,
          sectors,
        });
      } else {
        this.setState({
          showMore: false,
          isLoading: false,
        });
      }
    } catch (error) {
      console.error(error)
      this.setState({
        showMore: false,
        isLoading: false,
      });
    }
  }
  
  onChange = (value) => {
    this.setState({ selectedRegion : value }, () => 
    this.fetchData())
  }
  togglePublish = (dataRef, published) => {
    dataRef.update({ published })
  }
  onChangeSearchText = (event) => {
    let searchText = event.target.value

    const options = {
      threshold: 0.2,
      keys: ['name', 'genus', 'collectionNumber']
    }
    if (searchText.length <=3 || searchText.length == 0) {
      this.setState({
        searchText,
        data: this.state.rawData,
      });
      
      return;
    }
    const fuse = new Fuse(this.state.rawData, options)
    
    const filtered = fuse.search(searchText).map((data) => data.item)
    
    // const filtered = result.map((data) => data.item)
    
    this.setState({
      searchText,
      data: filtered,
    });
  };

  onChangeFilterCategory = (e) => this.setState({ selectedCategory: e.currentTarget.value })
  onChangeFilterSector = (e) => this.setState({ selectedSector: e.currentTarget.value })
  //2. pagination
  onPageChanged = dataChanged => {
      const { data } = this.state;
      const { currentPage, totalPages, pageLimit } = dataChanged;
  
      const offset = (currentPage - 1) * pageLimit;
      const currentData = data.slice(offset, offset + pageLimit);
  
      this.setState({ currentPage, currentData, totalPages });
  }

  filterFoodName = (value) => {
      let selectedCategory = value
      this.setState({
          selectedCategory
      }, () => this.filter())
  }

  filterSectorName = (value) => {
      let selectedSector = value
      this.setState({
        selectedSector
      }, () => this.filter())
  }

  filter = () => {
      let tempData = this.state.rawData
      let filteredData = []
      const { selectedCategory, selectedSector } = this.state;
      if (!selectedCategory && !selectedSector) {
          filteredData = this.state.rawData
      } else {
        filteredData = tempData.filter(({ category, sector }) =>
          selectedCategory && selectedCategory === category && selectedSector && selectedSector === sector
            || selectedCategory && !selectedSector && selectedCategory === category
            || !selectedCategory && selectedSector && selectedSector === sector)

      }
      this.setState({
          data: filteredData
      })
  }

  render() {
    return (
      <React.Fragment>
        <div className="box-header px-5 mt-3" style={{ backgroundColor: "#EFF2F7" }}>
          <h3 style={{ color: "#3B4859" }}>KONSERVASI TUMBUHAN</h3>
        </div>
      <div className="box-body" style={{ background: "#F9FAFC"}}>
        <div className="col-12">
          <div style={{ borderRadius: 5 }}>
            <div className="row mx-2 mt-5">
              <div className="col-md-4 mt-3">
                <div className="form-group l-h m-a-0">
                  <div className="input-group">
                    <input 
                      type="text" 
                      className="form-control form-control-sm p-x b-a rounded has-value" 
                      placeholder="Cari Nama Tanaman, Suku dan Wilayah..." 
                      value={this.state.searchText} 
                      onChange={this.onChangeSearchText} 
                    />
                  </div>
                </div>

              </div>
              <div className="col-md-2 offset-md-6 mt-3">
                
              {
                ((this.props.profile.token && this.props.profile.token.claims.admin === true) ||
                    (this.props.profile.token && this.props.profile.token.claims.department === "Konservasi")
                ) ?
                  <Link to="/dashboard/plant_info/add" className="nav-link">
                    <button className="md-btn md-raised m-b-sm kr-color px-4" style={{ borderRadius: 5, fontSize: "0.7rem", fontWeight: "inherit" }}>
                      <div style={{ color: "white" }}>
                        <i className="fa fa-fw fa-plus" style={{ opacity: 1, marginRight: 5, color: "white" }} />
                          Tambah Tumbuhan
                      </div>
                    </button>
                  </Link>
                :
                null
              }
              </div>
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="box" style={{ borderRadius: 5 }}>
            {
              this.state.acceptInfo &&
              <Alert dismissible variant="success" onClose={this.handleAcceptInfo}>
                <Alert.Heading>Success!</Alert.Heading>
                <p style={{color: '#3c763d'}}>
                  Berhasil Hapus Data.
                </p>
              </Alert>
            }

            {
              this.state.errorInfo &&
              <Alert dismissible variant="danger" onClose={this.handleErrorInfo}>
                <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                <p style={{color: '#a94442'}}>
                  Kesalahan saat memproses! Silahkan coba kembali.
                </p>
              </Alert>
            }

            <div className="box p-3 mb-0" style={{ borderRadius: 10 }}>
              <div className="table-responsive mt-3">
                <table className="table">
                  <thead>
                    {this.renderTableHead()}
                  </thead>
                  <tbody>
                    { 
                      this.state.isLoading 
                      ? 
                        <React.Fragment>
                          <LoadingTable column={8} />
                          <LoadingTable column={8} />
                          <LoadingTable column={8} />
                          <LoadingTable column={8} />
                        </React.Fragment>
                      : this.state.data.length == 0 
                        ? 
                          <tr>
                            <td className="text-center" colSpan="9">
                              <p>Tidak ada data!</p>
                            </td>
                          </tr>
                        : <DataList 
                            data={this.state.currentData}
                            handleAcceptInfo={() => this.handleAcceptInfo()}
                            showErrorInfo={() => this.showErrorInfo()}
                            selectedRegion={this.state.selectedRegion}
                            togglePublish={this.togglePublish}
                        />
                    }
                  </tbody>
                </table>
              </div>
            </div>

            <Pagination 
              totalRecords={this.state.data.length} 
              pageLimit={10} 
              pageNeighbours={1} 
              onPageChanged={this.onPageChanged} 
            />

          </div>
        </div>
      </div>
      <div className="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content" style={{ borderRadius: 15 }}>
                  <div className="modal-header text-right" style={{ border: "none" }}>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
                  <div className="modal-body px-5">
                      <div className="text-center">
                          <h6 style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold" }}>FILTER</h6>
                      </div>
                      <div className="mt-4">
                          <p style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: 500 }}>Kategori Tumbuhan</p>
                          <select value={this.state.selectedCategory} onChange={this.onChangeFilterCategory} className="browser-default custom-select" style={{ width: "100%", color: "rgba(124, 122, 122, 0.87)" }}>
                              <option value="">Semua Tumbuhan</option>
                              {
                                  this.state.categories.map((item) => (
                                      <option value={item}>{item}</option>
                                  ))
                              }
                          </select>
                      </div>
                  </div>
                  <div className="modal-footer mt-3 justify-content-center" style={{ border: "none" }}>
                      <button onClick={() => this.filterFoodName(this.state.selectedCategory)}  data-dismiss="modal" type="button"className="btn w-25" style={{ backgroundColor: "#579264", color: "white", fontSize: "0.8em" }}>
                          Set
                      </button>
                      <button type="button" className="btn w-25" data-dismiss="modal" style={{ backgroundColor: "#969595", color: "white", fontSize: "0.8em" }}>
                          Cancel
                      </button>
                  </div>
              </div>
          </div>
      </div>
      <div className="modal fade" id="modalSector" tabindex="-1" role="dialog" aria-labelledby="modalSector" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content" style={{ borderRadius: 15 }}>
                  <div className="modal-header text-right" style={{ border: "none" }}>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
                  <div className="modal-body px-5">
                      <div className="text-center">
                          <h6 style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold" }}>FILTER</h6>
                      </div>
                      <div className="mt-4">
                          <p style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: 500 }}>Wilayah</p>
                          <select value={this.state.selectedSector} onChange={this.onChangeFilterSector} className="browser-default custom-select" style={{ width: "100%", color: "rgba(124, 122, 122, 0.87)" }}>
                              <option value="">Semua Wilayah</option>
                              {
                                  this.state.sectors.map((item) => (
                                      <option value={item}>{item}</option>
                                  ))
                              }
                          </select>
                      </div>
                  </div>
                  <div className="modal-footer mt-3 justify-content-center" style={{ border: "none" }}>
                      <button onClick={() => this.filterSectorName(this.state.selectedSector)}  data-dismiss="modal" type="button"className="btn w-25" style={{ backgroundColor: "#579264", color: "white", fontSize: "0.8em" }}>
                          Set
                      </button>
                      <button type="button" className="btn w-25" data-dismiss="modal" style={{ backgroundColor: "#969595", color: "white", fontSize: "0.8em" }}>
                          Cancel
                      </button>
                  </div>
              </div>
          </div>
      </div>
      </React.Fragment>
    )
  }
}
const mapStateToProps = state => {
  return {
    region: state.region,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}


export default compose(
  connect(mapStateToProps),
  firestoreConnect()
)(TableListScreen)


export default function validate(values) {

  const errors = {}
  
  if (!values.composeList) {
    errors.description = 'Deskripsi harus diisi.'
  }
  if (!values.qty) {
    errors.qty = 'Jumlah harus diisi field.'
  }
  if (!values.images) {
    errors.images = 'Gambar harus diisi.'
  }

  return errors
      
}
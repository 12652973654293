import { SET_FILTER } from "./actionTypes";

// var initialState = window.localStorage.getItem('region') || "";
// if (initialState === null) {
//   localStorage.setItem('region', "");
// }
// var newState = window.localStorage.getItem('region');

// const initialRegionState = initialState;

export const regionReducer = (state = "", action) => {
  switch (action.type) {
    case SET_FILTER: {
      // localStorage.setItem('region', action.payload.filter);
      return action.payload.filter;
    }
    default: {
      return state;
    }
  }
};
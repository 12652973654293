import React, { Component } from 'react'
import moment from 'moment-timezone'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import DataTable from './DataTable'
import DatePicker from '../../../components/DatePicker';

const sampleData = [
    { 
        date_purchase: "2020-01-01", 
        domestic: {
            qty: 9999,
            amount: 9999,
        }, 
        asing: {
            qty: 9999,
            amount: 9999,
        },
        total: 9999,
    },
    { 
        date_purchase: "2020-01-02", 
        domestic: {
            qty: 9999,
            amount: 9999,
        }, 
        asing: {
            qty: 9999,
            amount: 9999,
        },
        total: 9999,
    },
    { 
        date_purchase: "2020-01-03", 
        domestic: {
            qty: 9999,
            amount: 9999,
        }, 
        asing: {
            qty: 9999,
            amount: 9999,
        },
        total: 9999,
    },
    { 
        date_purchase: "2020-01-04", 
        domestic: {
            qty: 9999,
            amount: 9999,
        }, 
        asing: {
            qty: 9999,
            amount: 9999,
        },
        total: 9999,
    },
    { 
        date_purchase: "2020-01-05", 
        domestic: {
            qty: 9999,
            amount: 9999,
        }, 
        asing: {
            qty: 9999,
            amount: 9999,
        },
        total: 9999,
    },
    { 
        date_purchase: "2020-01-06", 
        domestic: {
            qty: 9999,
            amount: 9999,
        }, 
        asing: {
            qty: 9999,
            amount: 9999,
        },
        total: 9999,
    },
    { 
        date_purchase: "2020-01-07", 
        domestic: {
            qty: 9999,
            amount: 9999,
        }, 
        asing: {
            qty: 9999,
            amount: 9999,
        },
        total: 9999,
    },
]

class TicketRevenue extends Component {
    state = {
        dataTicket: [],
        loading: true,
        startDate: moment(),
        endDate: moment(),
        regionList: [
            'Bogor',
            'Cibodas',
            'Purwodadi',
            'Bali'
        ],
        region: 'bogor'
    }
    getFormData = (data) => {
        const dataParams = Object.keys(data).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
        }).join('&');
        return dataParams;
    }

    fetchDataEvent = async () => {
        this.setState({ loading: true, dataTicket: [] })
        try {
            const snapshots = await this.props.firestore.get({ 
                collection: 'region',
                doc: this.state.region.toLowerCase(),
                subcollections: [{ 
                collection: 'revenueTickets', 
                orderBy: [
                    ['timestamp', 'asc']
                ],
                where: [
                  ['timestamp', '>=', moment(this.state.startDate).tz("Asia/Jakarta").startOf('day').toDate()],
                  ['timestamp', '<=', moment(this.state.endDate).tz("Asia/Jakarta").startOf('day').toDate()]
                ]
                }]
            });
            let dataTicket = []
            let tempData = []
            snapshots.forEach((snap) => {
                let { tariff_transactions } = snap.data().tariff
                tempData.push({
                    id: snap.id,
                    ...snap.data()
                })
                let ticketData = {
                    date_transaction: moment(snap.data().timestamp.toDate()).format("DD MMMM YYYY"),
                    asing: {},
                    asing_group: {},
                    domestic: {},
                    domestic_group: {},
                    mobil: {},
                    sepeda: {},
                    sepeda_motor: {},
                    total: 0,
                }
                for (let index = 0; index < tariff_transactions.length; index++) {
                    const item = tariff_transactions[index];
                    console.log("tariff type: ", item.tariff)
                    switch (item.tariff) {
                        case "Asing":
                            ticketData.asing = item
                            ticketData.total += item.amount
                            break;
                        case "Asing Group":
                            ticketData.asing_group = item
                            ticketData.total += item.amount
                            break;
                        case "Domestik":
                            ticketData.domestic = item
                            ticketData.total += item.amount
                            break;
                        case "Domestik Group":
                            ticketData.domestic_group = item
                            ticketData.total += item.amount
                            break;
                        case "Mobil":
                            ticketData.mobil = item
                            break;
                        case "Sepeda":
                            ticketData.sepeda = item
                            break;
                        case "Sepeda Motor":
                            ticketData.sepeda_motor = item
                            break;
                        default:
                            break;
                    }
                }

                dataTicket.push(ticketData)
            })
            console.log({ tempData })
            if (dataTicket == null) dataTicket = []
            this.setState({
                dataTicket,
                loading: false
            })
        } catch (error) {
            console.error(error)
            this.setState({
                dataTicket: [],
                loading: false
            })
        }
    }
    componentDidMount() {
        this.fetchDataEvent();
    }
      
    onDatesChange = ({ startDate, endDate }) => {
        this.setState({ startDate, endDate }, () => this.fetchDataEvent())
    }
    onChangeRegion = (e) => {
        this.setState({ region: e.target.value }, () => this.fetchDataEvent())
    }
    render() {
        return (
            <React.Fragment>
                <div className="box-header px-5 mt-3" style={{ backgroundColor: "#EFF2F7" }}>
                    <h3 style={{ color: "#3B4859" }}>REVENUE (PENGUNJUNG)</h3>
                </div>
                <div className="container-fluid p-5 spacing-resp">              
                    <div className="row mb-4">
                        <div className="col-lg-4 col-md-6">
                            <div className="row">
                                <div className="col-5"><h6 style={{ fontSize: "0.8rem", color: "#3B4859" }}>Dari</h6></div>
                                <div className="col-5"><h6 style={{ fontSize: "0.8rem", color: "#3B4859" }}>Sampai</h6></div>
                            </div>
                            <DatePicker 
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                onDatesChange={this.onDatesChange} 
                            />
                        </div>
                        <div className="col-md-2 d-flex align-items-end mt-3">
                            <select class="form-control input-c" value={this.state.region} onChange={this.onChangeRegion} style={{ borderRadius: "6px", border: "1px solid #A9A9AB" }}>
                                {
                                    this.state.regionList.map((region, i) => (<option key={i} value={region}>{region}</option>))
                                }
                            </select>
                        </div>
                        <div className="col-lg-3 col-md-6 d-flex align-items-center">
                            {/* <Link
                                // to={{
                                //     pathname: `/dashboard/employee/add`,
                                //     state: { region: this.state.selectedOption }
                                // }}
                             >
                                <button className="md-btn md-raised m-b-sm kr-color px-4" style={{ borderRadius: 5, fontSize: "0.7rem", fontWeight: "inherit" }}>
                                    <div style={{ color: "white" }}>
                                    <i className="fa fa-fw fa-download" style={{ opacity: 1, marginRight: 5, color: "white" }} />
                                        Unduh Laporan
                                    </div>
                                </button>
                            </Link> */}
                        </div>
                    </div>

                    <div className="py-3">
                        <div className="box p-3 mb-0" style={{ borderRadius: 10 }}>
                            <div className="table-responsive">
                                <DataTable data={this.state.dataTicket} loading={this.state.loading} />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        region: state.region
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect()
)(TicketRevenue)
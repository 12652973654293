import React, { Component } from 'react'
import { DateRangePicker, isInclusivelyAfterDay } from 'react-dates'

import { connect } from 'react-redux'
import moment from 'moment'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'

import DataTable from './DataTable';
import DownloadReport from '../../../../components/DownloadReport'
import DatePicker from '../../../../components/DatePicker'
import { fetchDataMerch } from '../../../../utils/nonfnb'
import { totalValuesFromObj } from '../../../../utils/common'

class MerchandiseRevenue extends Component {
    state = {
        data: [],
        loading: true,
        fileName: "merchandise-4-region-report-data",
        startDate: moment(),
        endDate: moment(),
        showDownload: false
    }
    fetchDataEvent = async () => {
        this.setState({ loading: true, data: [] })
        try {
            
            const startDate = moment(this.state.startDate).startOf('day')
            const endDate = moment(this.state.endDate).startOf('day')
            const timeDiff = endDate - startDate;

            const duration = moment.duration(timeDiff).asDays();

            // loop hari
            const arrDays = []
            for (let i = 0; i <= duration; i++) {
                arrDays.push(moment(startDate)
                .startOf('day')
                .add(i, 'day'))
            }
            let tempData = []
            for (let i = 0; i < arrDays.length; i++) {
                const currDate = arrDays[i];
                let data = await fetchDataMerch(currDate)
                let total = totalValuesFromObj(data.amounts[0]);
                const currData = {
                    date: moment(currDate).format("D MMMM YYYY"),
                    day: moment(currDate).format("dddd"),
                    ...data.amounts[0],
                    total
                }
                tempData.push(currData)
            }

            const customFields = [
                { label: 'Tanggal', value: 'date' },
                { label: 'Bogor', value: 'bogor' },
                { label: 'Cibodas', value: 'cibodas' },
                { label: 'Purwodadi', value: 'purwodadi' },
                { label: 'Bali', value: 'bali' },
                { label: 'Total', value: 'total' },
            ]

            this.setState({
                data: tempData,
                customFields,
                loading: false
            })
        } catch (error) {
            console.error(error)
            this.setState({
                data: [],
                loading: false
            })
        }
    }
    onDatesChange = ({ startDate, endDate }) => {
        this.setState({ startDate, endDate }, () => this.fetchDataEvent())
    }
    componentDidMount() {
        this.fetchDataEvent();
    }
    render() {
        return (
            <React.Fragment>
               <div className="box-header px-5 mt-3" style={{ backgroundColor: "#EFF2F7" }}>
                    <h3 style={{ color: "#3B4859" }}>REVENUE (MERCHANDISE)</h3>
                </div>
                <div className="container-fluid p-5 spacing-resp">
                    <div className="row mb-4">
                        <div className="col-lg-4 col-md-6 mt-3">
                            <div className="row">
                                <div className="col-6"><h6 style={{ fontSize: "0.8rem", color: "#3B4859" }}>Pilih Tanggal</h6></div>
                            </div>
                            <DatePicker 
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                onDatesChange={this.onDatesChange} 
                            />
                        </div>

                        <div className="col-lg-3 col-md-6 d-flex align-items-end mt-3">
                            <DownloadReport
                                data={this.state.data}
                                fields={this.state.customFields}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                fileName={this.state.fileName}
                            />
                        </div>
                    </div>

                    <div className="box mb-0 p-3 mt-5" style={{ borderRadius: 10 }}>
                        <div className="table-responsive">
                            <DataTable data={this.state.data} loading={this.state.loading} />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => {
    return { 
        auth: state.firebase.auth, 
        profile: state.firebase.profile
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect()
)(MerchandiseRevenue)
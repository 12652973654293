import React from 'react'
import { DateRangePicker, isInclusivelyAfterDay, isInclusivelyBeforeDay } from 'react-dates'
import moment from 'moment';

export default class DatePicker extends React.Component {
    valuesDirty = false;
    state = {
        startDate: moment(),
        endDate: moment()
    }
    componentDidMount() {
        const startDate = moment.isMoment(this.props.startDate) ? moment(this.props.startDate) : moment()
        const endDate = moment.isMoment(this.props.endDate) ? moment(this.props.endDate) : moment()
        this.setState({ startDate, endDate })
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.startDate !== this.props.startDate ||
            nextProps.endDate !== this.props.endDate) {
            const startDate = moment.isMoment(nextProps.startDate) ? moment(nextProps.startDate) : moment()
            const endDate = moment.isMoment(nextProps.endDate) ? moment(nextProps.endDate) : moment()
            this.setState({ startDate, endDate })
        }
    }

    afterStateChange = () => {
        let { startDate, endDate, focusedInput } = this.state;

        // fire onChange if either date has changed and calendar has closed (whether automatic or click away)
        if (!focusedInput && this.valuesDirty) {
            this.valuesDirty = false;
            this.props.onDatesChange && this.props.onDatesChange({ startDate: startDate, endDate: endDate });
        }
    }

    onDatesChange = ({ startDate, endDate }) => {
        // check if either date has actually changed
        if (startDate && !startDate.isSame(this.state.startDate)) {
            this.valuesDirty = true;
        }
        if (endDate && !endDate.isSame(this.state.endDate)) {
            this.valuesDirty = true;
        }

        this.setState({ startDate, endDate }, this.afterStateChange);
    }

    handleFocusChange = focusedInput => {
        this.setState({ focusedInput: focusedInput }, this.afterStateChange);
    }
    
    onChangeRegion = (e) => {
        this.setState({ region: e.target.value }, () => this.fetchDataEvent())
    }

    restrictDateRange = (day) => {
        
        if (this.props.newVersion) {
            // if (this.state.focusedInput == "startDate") {
            //     return isInclusivelyAfterDay(day, moment().add(1, 'days'))
            // }
            // if (this.state.focusedInput === "endDate") {
            //     return moment(day).isBefore(moment(day).endOf('month'))
            // }
            // return isInclusivelyAfterDay(day, moment().add(1, 'days')) || day.isBefore(moment(this.state.startDate).subtract(30, 'days'))
            // return false;
            if (this.props.restrictedMode) {
                return isInclusivelyAfterDay(day, moment().add(1, 'days')) 
                || 
                    day.isBefore(moment().startOf('year'))
            }
            
            return isInclusivelyAfterDay(day, moment().add(1, 'days')) 
            // this.state.focusedInput === "endDate" && (day.isBefore(this.state.startDate) || day.isAfter(this.state.startDate.clone().add(7, 'days')))

        } else {

            return isInclusivelyAfterDay(day, moment().add(1, 'days')) 
            || 
                day.isBefore(moment().startOf('year'))
            // || 
            //     day.isBefore(moment().startOf('month').add(-3, 'month'))
        }
    }


    render() {
        console.log('this.state.focusedInput', this.state.focusedInput)
        return (
            <DateRangePicker
                customArrowIcon={<i style={{ fontSize: 10 }} className='mx-3 fas'>&#xf362;</i>}
                numberOfMonths={this.props.newVersion ? 1 : 2}
                displayFormat="DD/MM/YYYY"
                isDayBlocked={() => false}
                startDate={this.state.startDate}
                startDateId="start-datepicker"
                endDateId="end-datepicker"
                endDate={this.state.endDate}
                // enableOutsideDays={true}
                isOutsideRange={this.restrictDateRange}
                focusedInput={this.state.focusedInput}
                onDatesChange={this.onDatesChange}
                onFocusChange={this.handleFocusChange}
            />
        )
    }
}

import React, { Component } from 'react';
// import { DateRangePicker, SingleDatePicker } from 'react-dates';
// import { ButtonToolbar } from 'react-bootstrap';
// import { auth, db } from "../../config/constants";
// import {
//     BarChart, ResponsiveContainer, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
// } from 'recharts';

import SelectMenu from "../../../components/SelectMenu"
import DataTable from './DataTable';
import { DateRangePicker } from 'react-dates';

const allData = [
    {
        date: 'Jan 1 2020', 
        ticket: 9999, 
        fnb: 9999, 
        facilitiesRent: 0, 
        tourPack: 0, 
        merchandiseProduct: 0,
        membership: 0,
        total: 0
    },
    {
        date: 'Jan 2 2020', 
        ticket: 9999, 
        fnb: 9999, 
        facilitiesRent: 0, 
        tourPack: 0, 
        merchandiseProduct: 0,
        membership: 0,
        total: 0
    },
    {
        date: 'Jan 3 2020', 
        ticket: 9999, 
        fnb: 9999, 
        facilitiesRent: 0, 
        tourPack: 0, 
        merchandiseProduct: 0,
        membership: 0,
        total: 0
    },
    {
        date: 'Jan 4 2020', 
        ticket: 9999, 
        fnb: 9999, 
        facilitiesRent: 0, 
        tourPack: 0, 
        merchandiseProduct: 0,
        membership: 0,
        total: 0
    },
    {
        date: 'Jan 5 2020', 
        ticket: 9999, 
        fnb: 9999, 
        facilitiesRent: 0, 
        tourPack: 0, 
        merchandiseProduct: 0,
        membership: 0,
        total: 0
    },
    {
        date: 'Jan 6 2020', 
        ticket: 9999, 
        fnb: 9999, 
        facilitiesRent: 0, 
        tourPack: 0, 
        merchandiseProduct: 0,
        membership: 0,
        total: 0
    },
    {
        date: 'Jan 7 2020', 
        ticket: 9999, 
        fnb: 9999, 
        facilitiesRent: 0, 
        tourPack: 0, 
        merchandiseProduct: 0,
        membership: 0,
        total: 0
    },
    {
        date: 'Jan 8 2020', 
        ticket: 9999, 
        fnb: 9999, 
        facilitiesRent: 0, 
        tourPack: 0, 
        merchandiseProduct: 0,
        membership: 0,
        total: 0
    },
    {
        date: 'Jan 9 2020', 
        ticket: 9999, 
        fnb: 9999, 
        facilitiesRent: 0, 
        tourPack: 0, 
        merchandiseProduct: 0,
        membership: 0,
        total: 0
    },
    {
        date: 'Jan 10 2020', 
        ticket: 9999, 
        fnb: 9999, 
        facilitiesRent: 0, 
        tourPack: 0, 
        merchandiseProduct: 0,
        membership: 0,
        total: 0
    }
];

const options = [
    {
        label: "All Report", value: "All Report"
    },
    {
        label: "Value", value: "Value"
    }
]

export default class AllReportRevenue extends Component {
    state = {
        date: new Date(),
        dataEvent: allData,
        selectedOption: options[0].value
    }
    onChange = date => this.setState({ date })
    onClickDay = (value) => alert('Happy Birthday To Jane Doe')
    // fetchDataEvent = async () => {
    //   this.setState({ loading: true,  })
    //   try {
    //     const snapshots = await db.collection("events")
    //     //   .where("ownerId", "==", auth().currentUser.uid)
    //       .where("currentStatus.status", "==", "accepted")
    //       .orderBy("timestamp", "desc")
    //       .limit(3)
    //       .get();
    //     if (snapshots.empty) {
    //       this.setState({
    //         dataEvent: [],
    //         loading: false
    //       })
    //       return null
    //     }
    //     let dataEvent = [];
    //     snapshots.forEach((snap) => {
    //         const { timestamp, lastUpdate, eventDate, ...restData } = snap.data()
    //       dataEvent.push({
    //         id: snap.id,
    //         ...restData,
    //         timestamp: moment(timestamp.toDate()).format("DD MMMM YYYY"),
    //         lastUpdate: lastUpdate ? moment(lastUpdate.toDate()).format("DD MMMM YYYY") : "",
    //         eventDate: {
    //             start: eventDate ? eventDate.start ? moment(eventDate.start.toDate()).format("DD MMMM YYYY") : "-": "-",
    //             end: eventDate ? eventDate.end ? moment(eventDate.end.toDate()).format("DD MMMM YYYY") : "-": "-",
    //         },
    //         ref: snap.ref,
    //       })
    //     })
    //     console.log('dataEvent', dataEvent)
    //     this.setState({
    //       dataEvent,
    //       loading: false
    //     })
    //   } catch (error) {
    //     console.error(error)
    //     this.setState({
    //       data: [],
    //       loading: false
    //     })
    //   }
    // }
    componentDidMount() {
        //   this.fetchDataEvent();
    }
    onChange = (value) => {
        this.setState({ selectedOption : value})
    }
    render() {
        return (
            <React.Fragment>
                <div className="box-header px-5 mt-3" style={{ backgroundColor: "#EFF2F7" }}>
                    <h3 style={{ color: "#3B4859" }}>REVENUE</h3>
                </div>
                <div className="container-fluid pt-3 pb-5 px-5">
                    <div className="row">
                        <div className="col-md-2 col-sm-12 mt-4">
                            <SelectMenu items={options} value={this.state.selectedOption} onChange={this.onChange} />
                        </div>
                    </div>
                    
                    <div className="row mt-5">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-3"><h6 style={{ color: "#3B4859" }}>Dari</h6></div>
                                <div className="col-md-3"><h6 className="ml-3" style={{ color: "#3B4859" }}>Sampai</h6></div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <DateRangePicker
                                numberOfMonths={2}
                                // withPortal={true}
                                displayFormat="DD/MM/YYYY"
                                isDayBlocked={() => false}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                isOutsideRange={() => false}
                                enableOutsideDays={true}
                                // isOutsideRange={day => isInclusivelyAfterDay(day, moment().add(1, 'days'))}
                                focusedInput={this.state.focusedInput}
                                onDatesChange={this.onDatesChange}
                                // isOutsideRange={(day) => (
                                //     this.state.focusedInput === "endDate" && (day.isBefore(this.state.startDate) || day.isAfter(this.state.startDate.clone().add(10, 'days')))
                                // )} //max days
                                onFocusChange={focusedInput => this.setState({ focusedInput })}
                            />
                        </div>
                    </div>
                    {/* <div className="row">
                    <div className="col-md-2 col-sm-12 mt-4">
                        <div className="dropdown">
                            <button 
                                className="btn border dropdown-toggle w-100" 
                                type="button" id="dropdownMenuButton" 
                                data-toggle="dropdown" 
                                aria-haspopup="true" 
                                aria-expanded="false"
                                style={{ backgroundColor: "transparent", fontSize: "0.8em", border:"1px solid rgba(124, 122, 122, 0.87)" }}
                            >
                                Semua Wilayah
                            </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a className="dropdown-item" href="#">Bogor</a>
                                    <a className="dropdown-item" href="#">Bali</a>
                                    <a className="dropdown-item" href="#">Something else here</a>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className="py-3">
                        <div className="box">
                            <div className="table-responsive">
                                <DataTable data={allData} />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
import React, { Component } from 'react';
import moment from 'moment';
import TableItem from "./TableItem"
// import { auth, db } from "../../config/constants";

class DataTable extends Component {
    renderItem = (item, index) => {
        return (
            <TableItem
                key={item.id}
                index={index}
                item={item}
            />
        )
    }
    render() {
        return (
            <table className="table table-bordered m-a-0">
                <thead style={{ color: "#579264"}}>
                    <tr>
                        <th>Tanggal</th>
                        <th>Total Pengunjung</th>
                        <th>Total IDR</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                    {
                        this.props.data.map(this.renderItem)
                    }
                </tbody>
            </table>
        )
    }
}

export default DataTable
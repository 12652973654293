import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Delete from './Delete';

const ListItem = (props) => {
    const [canEdit, setCanEdit] = useState(false)
    useEffect(() => {
        const date = moment(props.date, "DD MMMM YYYY").endOf('day')
        const currentDate = moment();
        if (currentDate.isAfter(date)) {
            setCanEdit(false)
        } else {
            setCanEdit(true)
        }
        return () => true;
    }, [props.date])
    
    return (
        <tr className="text-center">
            <td>{props.index + 1}</td>
            <td className="text-left">{props.task}</td>
            <td>{props.startTime}</td>
            <td>{props.endTime}</td>
            <td>{props.duration}</td>
            <td>
                {
                    // props.isManager === true  || 
                    canEdit === false || props.status === "finished" ?
                        <button onClick={() => props.navigateDetail(props.id)} style={{ fontSize: "0.7rem", backgroundColor: "#5094D2", borderRadius: 10, cursor: "pointer" }} type="button" className="btn btn-primary">Lihat
                        </button> : null
                }
                {
                    // props.isManager !== true  && 
                    canEdit === true && props.status === "started" ?
                        <button onClick={() => props.navigateUpdate(props.id)} style={{ fontSize: "0.7rem", backgroundColor: "#579264", borderRadius: 10, cursor: "pointer", borderColor: "white" }} type="button" className="btn btn-primary">Laporkan
                        </button> : null
                }
                {
                    // props.isManager !== true  && 
                    canEdit === true &&
                    <Delete 
                        id={props.id}
                        deleteRef={props.dataRef}
                    />
                }
            </td>
        </tr>
    )
}

ListItem.defaultProps = {
    id: "",
    name: "",
    position: "",
    email: "",
    sector: "",
}

ListItem.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    sector: PropTypes.string.isRequired,
}


const mapStateToProps = state => {
    return {
        uid: state.firebase.auth.uid,
        region: state.region,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default compose(
    connect(mapStateToProps)
)(ListItem)

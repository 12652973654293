import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withFirestore } from 'react-redux-firebase'

import AddSession from './AddForm';
import ModalError from '../../../../components/ModalError';

const collection = "mealPlan";

class AddScreen extends Component {
  static propTypes = {
    uid: PropTypes.string,
    firestore: PropTypes.shape({
      add: PropTypes.func.isRequired
    }).isRequired
  }
  
  state = {
    errorInfo: false,
    data: {
      detail: [
        { day: 'Monday', menu: '' },
        { day: 'Tuesday', menu: '' },
        { day: 'Wednesday', menu: '' },
        { day: 'Thursday', menu: '' },
        { day: 'Friday', menu: '' },
        { day: 'Saturday', menu: '' },
        { day: 'Sunday', menu: '' }
      ]
    }
  }

  handleErrorInfo = () => {
    this.setState({ errorInfo: !this.state.errorInfo });
  }
  
  createFormData = (values) => {
    const data = {
      timestamp: new Date(),
      detail: values.detail,
    };
    return data;
  }

  onSubmit = async (values) => {
    console.log("values", values);
    const formData = this.createFormData(values)
    console.log('formData', formData)

    const { sessionId } = this.props.match.params;
    const { selectedRegion } = this.props.location.state;
    console.log('sessionId', sessionId)
    console.log('selectedRegion', selectedRegion)

    try {
      const { selectedRegion } = this.props.location.state;
      const { sessionId } = this.props.match.params;
      const docRef = await this.props.firestore.add({ 
        collection: 'region',
        doc: selectedRegion,
        subcollections: [{ 
          collection: 'plants', 
          doc: sessionId,
          subcollections: [{ 
            collection: 'fertilizerPlan',
          }]
        }]
      }, formData);
      const { id } = docRef;

      console.log("Document written with an ID: ", id);

      this.props.history.goBack();
    } catch (error) {
      this.setState({ errorInfo: true })

      console.error("Failed to add meal plan data: ", error);
    }
  }
  fetchData = async () => {

    try {
      const { animalId } = this.props.match.params;
      const snapshot =  await this.props.firestore.get({ collection, doc: animalId })
      if (snapshot.exists) {
        const data = snapshot.data();

        let newData = {
          animalId,
          nickName: data.nickName,
          species: data.species,
        }

        this.setState({ 
          data: {
            ...this.state.data, 
            ...newData
          }
        });
      }
    } catch (error) {
      console.error(error)
    }

    this.setState({ loading: false })
  }
  componentDidMount() {
    this.fetchData();
  }
  render() {
    if (!this.props.uid) return null
    return (
      <React.Fragment>
        {
          this.state.errorInfo &&
            <ModalError 
              errorInfo={this.state.errorInfo}
              handleErrorInfo={this.handleErrorInfo}
            />
        }
        <div className="box-body">
          <AddSession onSubmit={this.onSubmit} initialValues={this.state.data} />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    uid: state.firebase.auth.uid,
  }
}

export default compose(
  connect(mapStateToProps),
  withFirestore,
)(AddScreen)
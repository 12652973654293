import React, { Component } from 'react';
import { Link } from "react-router-dom"
import { SingleDatePicker, isInclusivelyAfterDay } from 'react-dates';
import moment from 'moment'
import firebase from 'firebase'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firebaseConnect } from 'react-redux-firebase'
import DataTable from './DataTable';
import DataTableCategory from './DataTableCategory';
import { fetchDataReport } from '../../../../utils/report';
const axios = require('axios').default;

const defaultDataCategory = [
        {
            id: "1",
            name:"Domestik",
            total_qty:0,
            total_amount:0,
        },
        {
            id: "2",
            name:"Domestik Grup",
            total_qty:0,
            total_amount:0,
        },
        {
            id: "3",
            name:"Asing",
            total_qty:0,
            total_amount:0,
        },
        {
            id: "4",
            name:"Asing Grup",
            total_qty:0,
            total_amount:0,
        },
        {
            id: "5",
            name:"Mobil",
            total_qty:0,
            total_amount:0,
        },
        {
            id: "6",
            name:"Motor",
            total_qty:0,
            total_amount:0,
        },
        {
            id: "7",
            name:"Sepeda",
            total_qty:0,
            total_amount:0,
        }
    ]

const defaultDataTicket =  [ 
    {
        tarif_transaction: []
    },
    {
        tarif_transaction: []
    },
    {
        tarif_transaction: []
    },
    {
        tarif_transaction: []
    },
    {
        tarif_transaction: []
    },
    {
        tarif_transaction: []
    },
    {
        tarif_transaction: []
    },
]

const defaultSummaryData = {
        total_qty: 0,
        total_amount: 0,
        total_tax: 0,
        total_assurance: 0,
        total_admin: 0,
        total_others: 0,
        total_nett: 0,
        domestic: {
            total_qty: 0,
            total_amount: 0,
        },
        domestic_group: {
            total_qty: 0,
            total_amount: 0,
        },
        asing: {
            total_qty: 0,
            total_amount: 0,
        },
        asing_group: {
            total_qty: 0,
            total_amount: 0,
        },
        mobil: {
            total_qty: 0,
            total_amount: 0,
        },
        sepeda_motor: {
            total_qty: 0,
            total_amount: 0,
        },
        sepeda: {
            total_qty: 0,
            total_amount: 0,
        }
}

class TicketRevenueReportDay extends Component {
    state = {
        date: new moment(),
        summaryData: defaultSummaryData,
        dataTicket: defaultDataTicket,
        dataCategory: defaultDataCategory,
        loading: true,
        // startDate: new moment(),
        // endDate: new moment(),
        regionList: [
            'Bogor',
            'Cibodas',
            'Purwodadi',
            'Bali'
        ],
        region: 'bogor',
    }
    getFormData = (data) => {
        const dataParams = Object.keys(data).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
        }).join('&');
        return dataParams;
    }


    fetchDataEvent = async (props) => {
        this.setState({ loading: true, dataTicket: defaultDataTicket, dataCategory: defaultDataCategory,
            summaryData: defaultSummaryData, })
        const { accessToken } = this.props.auth.stsTokenManager;
        const data = {
            "region": this.state.region.toLowerCase(), //bug region
            "date_start": moment(this.state.date).format("YYYY-MM-DD").toString(),
            "date_end": moment(this.state.date).format("YYYY-MM-DD").toString()
        }
        console.log('data', data)
        try {
            
            // var deviceDataApi = await firebase.functions().httpsCallable('deviceData');
            // const result = await deviceDataApi({ data })
            const result = await fetchDataReport(data)
            const { device_transactions, ...restData } = result
            let dataTicket = []
            dataTicket = device_transactions
            if (dataTicket == null) {
                this.setState({
                    summaryData: defaultSummaryData,
                    dataTicket: defaultDataTicket,
                    dataCategory: defaultDataCategory,
                    loading: false
                })
                return 0;
            }
 
            let summaryData = this.state.summaryData

            for (let i = 0; i < dataTicket.length; i++) {
                const element = dataTicket[i];
                const { tarif_transaction } = element
                for (let j = 0; j < tarif_transaction.length; j++) {
                    const item = tarif_transaction[j];
                    switch (item.tariff) {
                        case "Asing":
                            summaryData.asing = {
                                total_qty: summaryData.asing.total_qty + item.qty,
                                total_amount: summaryData.asing.total_amount + item.amount,
                            }
                            break;
                        case "Asing Group":
                            summaryData.asing_group = {
                                total_qty: summaryData.asing_group.total_qty + item.qty,
                                total_amount: summaryData.asing_group.total_amount + item.amount,
                            }
                            break;
                        case "Domestik":
                            summaryData.domestic = {
                                total_qty: summaryData.domestic.total_qty + item.qty,
                                total_amount: summaryData.domestic.total_amount + item.amount,
                            }
                            break;
                        case "Domestik Group":
                            summaryData.domestic_group = {
                                total_qty: summaryData.domestic_group.total_qty + item.qty,
                                total_amount: summaryData.domestic_group.total_amount + item.amount,
                            }
                            break;
                        case "Mobil":
                            summaryData.mobil = {
                                total_qty: summaryData.mobil.total_qty + item.qty,
                                total_amount: summaryData.mobil.total_amount + item.amount,
                            }
                            break;
                        case "Sepeda":
                            summaryData.sepeda = {
                                total_qty: summaryData.sepeda.total_qty + item.qty,
                                total_amount: summaryData.sepeda.total_amount + item.amount,
                            }
                            break;
                        case "Sepeda Motor":
                            summaryData.sepeda_motor = {
                                total_qty: summaryData.sepeda_motor.total_qty + item.qty,
                                total_amount: summaryData.sepeda_motor.total_amount + item.amount,
                            }
                            break;
                        default:
                            break;
                    }
                }
            }

            summaryData = {
                ...summaryData,
                // ...restData,
            }

            let tempDataCategory = this.state.dataCategory
            tempDataCategory[0] = { 
                ...tempDataCategory[0],
                ...summaryData.domestic,
            }
            tempDataCategory[1] = { 
                ...tempDataCategory[1],
                ...summaryData.domestic_group,
            }
            tempDataCategory[2] = { 
                ...tempDataCategory[2],
                ...summaryData.asing,
            }
            tempDataCategory[3] = { 
                ...tempDataCategory[3],
                ...summaryData.asing_group,
            }
            tempDataCategory[4] = { 
                ...tempDataCategory[4],
                ...summaryData.mobil,
            }
            tempDataCategory[5] = { 
                ...tempDataCategory[5],
                ...summaryData.sepeda_motor,
            }
            tempDataCategory[6] = { 
                ...tempDataCategory[6],
                ...summaryData.sepeda,
            }
            // tempDataCategory[7] = { 
            //     ...tempDataCategory[7],
            //     total_qty: summaryData.total_qty,
            //     total_amount: summaryData.total_amount
            // }

            this.setState({
                summaryData,
                dataTicket,
                dataCategory: tempDataCategory,
                loading: false
            })
        } catch (error) {
            console.error("error",error)
            // this.setState({
            //     dataTicket: [],
            //     loading: false
            // })
        }
    }

    componentDidMount() {
        this.fetchDataEvent(this.props);
    }
    
    onDateChange = (date) => {
        console.log('date', date)
        this.setState({ date }, () => this.fetchDataEvent())
    }

    onChangeRegion = (e) => {
        this.setState({ region: e.target.value }, () => this.fetchDataEvent())
    }

    restrictDateRange = (day) => {
        return isInclusivelyAfterDay(day, moment().add(1, 'days')) 
        || 
            day.isBefore(moment().startOf('year'))
        // || 
        //     day.isBefore(moment().startOf('month').add(-3, 'month'))
    }

    render() {
        return (
            <React.Fragment>
                <div className="box-header px-5 mt-3" style={{ backgroundColor: "#EFF2F7" }}>
                    <h3 style={{ color: "#3B4859" }}>REVENUE (HARIAN PER KR)</h3>
                </div>
                <div className="container-fluid p-5 spacing-resp">
                    <div className="row mb-4">
                        <div className="col-lg-2 col-md-6 mt-3">
                            {/* <DateRangePicker
                                numberOfMonths={2}
                                // withPortal={true}
                                displayFormat="DD/MM/YYYY"
                                isDayBlocked={() => false}
                                startDate={this.state.startDate}
                                endDate={this.state.startDate}
                                isOutsideRange={() => false}
                                enableOutsideDays={true}
                                // isOutsideRange={day => isInclusivelyAfterDay(day, moment().add(1, 'days'))}
                                focusedInput={this.state.focusedInput}
                                onDatesChange={this.onDatesChange}
                                onFocusChange={focusedInput => this.setState({ focusedInput })}
                            /> */}
                            
                            <SingleDatePicker
                                numberOfMonths={2}
                                // withPortal={true}
                                id="single_date_report" // PropTypes.string.isRequired,
                                displayFormat="DD/MM/YYYY"
                                isDayBlocked={() => false}
                                date={this.state.date}
                                // isOutsideRange={() => false}
                                enableOutsideDays={true}
                                isOutsideRange={this.restrictDateRange}
                                focused={this.state.focusedInput}
                                onDateChange={this.onDateChange}
                                onFocusChange={({ focused: focusedInput }) => this.setState({ focusedInput })}
                            />
                        </div>

                        <div className="col-md-2 d-flex align-items-end mt-3">
                            <select className="form-control input-c" value={this.state.region} onChange={this.onChangeRegion} style={{ borderRadius: "6px", border: "1px solid #A9A9AB" }}>
                                {
                                    this.state.regionList.map((region, i) => (<option key={i} value={region}>{region}</option>))
                                }
                            </select>
                        </div>
                        <div className="col-lg-3 col-md-6 d-flex align-items-end mt-3">
                            {/* <button
                                // onClick={this.handleOpenDownloadData}
                                className="md-btn md-raised py-2 kr-color px-4" 
                                style={{ 
                                    borderRadius: 5, 
                                    fontSize: "0.7rem", 
                                    height: "calc(2.25rem + 2px)", 
                                    fontWeight: "inherit", 
                                    marginBottom: 0 }}>
                                <div style={{ color: "white" }}>
                                <i className="fa fa-fw fa-download" style={{ opacity: 1, marginRight: 5, color: "white" }} />
                                    Unduh Laporan
                                </div>
                            </button> */}
                        </div>
                    </div>

                    <div className="py-3 mt-2">
                        <div className="box p-3 mb-0" style={{ borderRadius: 10 }}>
                            <div className="table-responsive mt-3">
                                <DataTable 
                                    data={this.state.dataTicket} 
                                    summaryData={this.state.summaryData} 
                                    loading={this.state.loading} />
                            </div>
                        </div>

                        <div className="col-lg-6 px-0 mt-5">
                            <div className="box p-3 mb-0" style={{ borderRadius: 10 }}>
                                <div className="table-responsive mt-3">
                                    <DataTableCategory
                                        data={this.state.dataCategory} 
                                        summaryData={this.state.summaryData} 
                                        loading={this.state.loading} 
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        region: state.region
    }
}

export default compose(
    connect(mapStateToProps),
    firebaseConnect()
)(TicketRevenueReportDay)
import { storage } from "firebase";
export const storageRef = storage().ref();

export const deleteImages = (images) => {
  try {
    console.log('images', images)
    const promises = [];
    for (let i = 0; i < images.length; i++) {
      const p = storageRef.child(images[i].path).delete();
      promises.push(p);
    }
    return Promise.all(promises);
  } catch (error) {
    console.error("error delete images: ", error)
    return 0;
  }
};
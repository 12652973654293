import React from "react"
import { Switch, Route } from 'react-router-dom'

import EmployeeScreen from '../../screens/Employee/TableListScreen';
import AddEmployeeDataScreen from '../../screens/Employee/AddScreen';
import UpdateEmployeeDataScreen from '../../screens/Employee/UpdateScreen';
import EmployeeDetailScreen from '../../screens/Employee/Detail';

const EmployeeNav = (props) => (
    <Switch>
        <Route 
            exact 
            path={`${props.match.path}/`}
            render={(routeProps) => <EmployeeScreen {...routeProps} />}
        /> 
        <Route 
            exact 
            path={`${props.match.path}/add`}
            render={(routeProps) => <AddEmployeeDataScreen {...routeProps} />}
        />
        <Route 
            exact 
            path={`${props.match.path}/:sessionId`}
            render={(routeProps) => <EmployeeDetailScreen {...routeProps} />}
        />
        <Route 
            exact 
            path={`${props.match.path}/:sessionId/update`}
            render={(routeProps) => <UpdateEmployeeDataScreen {...routeProps} />}
        />
    </Switch>
)

export default EmployeeNav
import React, { useState } from "react";
import moment from "moment"
import { SingleDatePicker } from 'react-dates';
import ErrorMessage from "./ErrorMessage"

const DatePicker = (field) => {
    const { input: { value, onChange } } = field
    const [focused, setFocused] = useState(false)
    const onValueChange = (date) => onChange(date)
    const onFocusChange = ({ focused }) => setFocused(focused)
    
    const returnYears = () => {
        let years = []
        for (let i = moment().year() - 100; i <= moment().year(); i++) {
            years.push(<option value={i}>{i}</option>);
        }
        return years;
    }
    const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) =>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div>
                <select
                    value={month.month()}
                    onChange={(e) => onMonthSelect(month, e.target.value)}
                >
                    {moment.months().map((label, value) => (
                        <option value={value}>{label}</option>
                    ))}
                </select>
            </div>
            <div>
                <select value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)}>
                    {returnYears()}
                </select>
            </div>
        </div>
    return (
        <div className="form-group mb-4">
            <label 
                htmlFor={`input-${field.label}`} 
                className="form-control-label" 
                style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold" }}
            >
            {field.label}
            </label>
            {
                field.isOutsideDays ? 
                <SingleDatePicker
                    date={value && moment(value)}
                    onDateChange={onValueChange}
                    numberOfMonths={1}
                    displayFormat="DD/MM/YYYY"
                    placeholder={field.placeholder}
                    focused={focused}
                    className="form-control field-box"
                    onFocusChange={onFocusChange}
                    id={`input-${field.label}`}
                    renderMonthElement={renderMonthElement}
                    isDayBlocked={() => false}
                    isOutsideRange={() => false}
                    enableOutsideDays={true}
                />
                :
                <SingleDatePicker
                    date={value && moment(value)}
                    onDateChange={onValueChange}
                    numberOfMonths={1}
                    displayFormat="DD/MM/YYYY"
                    placeholder={field.placeholder}
                    focused={focused}
                    renderMonthElement={renderMonthElement}
                    className="form-control field-box" 
                    onFocusChange={onFocusChange}
                    id={`input-${field.label}`}
                />
            }
            <ErrorMessage {...field} />
      </div>
    )
}

export default DatePicker
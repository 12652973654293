import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { withFirestore } from 'react-redux-firebase'

import ListItem from './ListItem'

class DataList extends Component {
  static defaultProps = {
    uid: "",
    data: []
  }

  static propTypes = {
    uid: PropTypes.string,
  }
  
  navigateDetail = sessionId => {
    this.props.history.push(`/dashboard/plant_info/kompos/${sessionId}`)
  }

  renderListItem = (item) => {
    return (
      <ListItem 
        key={item.id}
        navigateDetail={this.navigateDetail}
        handleAcceptInfo={this.props.handleAcceptInfo}
        showErrorInfo={this.props.showErrorInfo}
        dataRef={item.dataRef}
        {...item}
      />
    )
  }
  
  render() {
    return this.props.data.map(this.renderListItem)
  }
}

export default compose(
  withRouter,
  withFirestore,
)(DataList)
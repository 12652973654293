import { firestore } from 'firebase';
import React from 'react';
import Select from 'react-select';

class SelectPositionInput extends React.Component {
  state = { selectedOptions: null, items: [] }

  handleChange = (selectedItem) => {
    const { input: { onChange }, change } = this.props
    this.setState({ selectedOptions: selectedItem });
    
    const value = selectedItem === null ? '' : selectedItem.label;
    const roleValue = selectedItem === null ? '' : selectedItem.value;
    
    onChange(value)
    change("roleId", roleValue)
    if (typeof this.props.fetchData == "function") {
      this.props.fetchData(selectedItem)
    }
  };

  fetchDataPosition = async (selectedItem) => {
    try {
      const snapshots = await firestore().collection("region")
        .doc(this.props.region.toLowerCase())
        .collection("position")
        .where("subDepartmentId", "==", selectedItem)
        .get()
      

      let tempPositionList = []
      if (snapshots.empty) {
        tempPositionList[0] = {
          label: "Others",
          value: "Others",
        }
      } else {
        
        snapshots.forEach((snap) => {
          tempPositionList.push({
            label: snap.data().positionId,
            value: snap.data().roleId,
          })
        });

      }

      return tempPositionList
    } catch (error) {
      console.error(error)
      return []
    }
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.dataId !== this.props.dataId) {
      const items = await this.fetchDataPosition(nextProps.dataId)
      this.setState({ items: items })
    }
  }

  async componentDidMount() {
    //REDUX FORM INTEGRATION
    const { input: { onChange, value } } = this.props
    if (this.props.dataId) {
      const items = await this.fetchDataPosition(this.props.dataId)
      this.setState({ items: items })
      if (this.props.value) {
        for (let i = 0; i < items.length; i++) {
          const element = items[i];
          if(value === element.label) {
            this.setState({
              selectedOptions: element
            })
          }
        }
      }
    }
  }
  render() {
    const { placeholder, disabled } = this.props;
    const { items } = this.state
    return (
      <Select
        isDisabled={disabled}
        onChange={this.handleChange}
        options={items}
        className="field-box"
        value={this.state.selectedOptions}
        placeholder={placeholder}
        clearValue={this.clearQuery}
      />
    );
  }
}

export default SelectPositionInput;

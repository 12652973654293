import React, { Component } from 'react';
// import moment from 'moment';
import TableItem from "./TableItem"

// import LoadingTableTicket from "../../../components/LoadingTableTicket"
import LoadingTableSponsor from '../../../components/loading-table';
import Pagination from '../../../components/Pagination';
// import { auth, db } from "../../config/constants";

class DataTable extends Component {
    
    //1. pagination
    state = { 
        currentData: [], 
        currentPage: null, 
        totalPages: null 
    }

    renderItem = (item, index) => {
        return (
            <TableItem
                key={item.id}
                index={index}
                item={item}
            />
        )
    }

    //2. pagination
    onPageChanged = dataChanged => {
      const { data } = this.props;
      const { currentPage, totalPages, pageLimit } = dataChanged;
  
      const offset = (currentPage - 1) * pageLimit;
      const currentData = data.slice(offset, offset + pageLimit);
  
      this.setState({ currentPage, currentData, totalPages });
    }

    render() {
        return (
            <React.Fragment>
            <table className="table table-bordered m-a-0">
               <thead style={{ color: "#579264" }}>
                    <tr>
                        <th scope="col" className="text-center">Tanggal</th>
                        <th scope="col" className="text-center">Hari</th>
                        <th scope="col" className="text-center">Sewa Fasilitas</th>
                        <th scope="col" className="text-center">Merchandise</th>
                        <th scope="col" className="text-center">Total</th>
                    </tr>
                </thead>
                <tbody style={{ color: "rgba(0, 0, 0, 0.54)" }}>

                    {
                        this.props.loading ?
                            <React.Fragment>
                                <LoadingTableSponsor column={5} />
                                <LoadingTableSponsor column={5} />
                                <LoadingTableSponsor column={5} />
                                <LoadingTableSponsor column={5} />
                            </React.Fragment>
                            :
                                this.props.data.length == 0 ? 
                                <tr>
                                    <td className="text-center" colSpan="9">
                                        <p>Tidak ada data!</p>
                                    </td>
                                </tr>
                            : this.state.currentData.map(this.renderItem)
                    }
                </tbody>
            </table>
            
            {/* 3.pagination */}
            <Pagination 
                totalRecords={this.props.data.length} 
                pageLimit={10} 
                pageNeighbours={1} 
                onPageChanged={this.onPageChanged} 
            />
            </React.Fragment>
        )
    }
}

export default DataTable
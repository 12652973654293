import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Delete from './Delete';

const ListItem = (props) => {
    return (
        <tr key={props.id} style={{ color: "rgba(124, 122, 122, 0.87)" }}>
            <td onClick={() => props.navigateDetail(props.id)} style={{ cursor: 'pointer' }}>{props.name}</td>
            <td>{props.department}</td>
            <td>{props.position}</td>
            <td>{props.email}</td>
            <td>
                {
                    ((props.profile.token && props.profile.token.claims.admin == true) ||
                        (props.profile.token && props.profile.token.claims.department == "Human Resource"))
                        ?
                        <Delete
                            id={props.id}
                        />
                        : null
                }
            </td>
        </tr>
    )
}

ListItem.defaultProps = {
    id: "",
    name: "",
    position: "",
    email: "",
    sector: "",
}

ListItem.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    sector: PropTypes.string.isRequired
}


const mapStateToProps = state => ({
    region: state.region,
    profile: state.firebase.profile
})

export default connect(mapStateToProps)(ListItem)

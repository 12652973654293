import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import ModalDelete from './Delete';

const ListItem = (props) => {
    return (
        <tr key={props.id}>
            <td>{props.date ? moment(props.date.toDate()).format('DD MMMM YYYY') : "-"}</td>
            <td>{props.activity || ""}</td>
            <td>{props.description || "-"}</td>
            <td colSpan="2">
                <button 
                    onClick={() => props.navigateDetail(props.id)} 
                    className="md-btn md-raised m-b-sm kr-color px-4"
                    style={{ borderRadius: 5, }}>
                    <div style={{ color: "white", fontSize: "0.7rem", fontWeight: "100"  }}>
                        View Detail 
                    </div>
                </button>
            </td>
            <td>
                <ModalDelete
                    dataRef={props.dataRef}
                />
            </td>
        </tr>
    )
}

ListItem.defaultProps = {
    id: "",
    nickName: "",
    description: "",
}

ListItem.propTypes = {
    id: PropTypes.string.isRequired,
    nickName: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
}

export default ListItem

import React from "react"
import { Switch, Route } from 'react-router-dom'

import AllRegionScreen from '../../screens/Revenue/AllReport';
import TicketScreen from '../../screens/Revenue/Ticket';
import TicketAnalyticsScreen from '../../screens/Revenue/Ticket/Analytics';
// import TicketDetailScreen from '../../screens/Revenue/Ticket/Detail';
import TicketReportScreen from '../../screens/Revenue/Ticket/Report';
import TicketReportDetailScreen from '../../screens/Revenue/Ticket/Report/Detail';
import TicketReportDayScreen from '../../screens/Revenue/Ticket/ReportDay';
import TicketInsuranceScreen from '../../screens/Revenue/Ticket/Insurance';
import TicketRetributionScreen from '../../screens/Revenue/Retribution';
// import TicketNettScreen from '../../screens/Revenue/Ticket/Nett';
// import TicketNettRegionScreen from '../../screens/Revenue/Ticket/Nett/NettByRegion';

import FnbScreen from '../../screens/Revenue/Fnb';
import FnBReportScreen from '../../screens/Revenue/Fnb/Report';
import FnBReportDetailScreen from '../../screens/Revenue/Fnb/Report/Detail';

// Merchandise
import NonFnbScreen from '../../screens/Revenue/NonFnb';
import MerchandiseScreen from '../../screens/Revenue/NonFnb/Merchandise';
import MerchandiseReportScreen from '../../screens/Revenue/NonFnb/Merchandise/Report';
import MerchandiseReportDetailScreen from '../../screens/Revenue/NonFnb/Merchandise/Report/Detail';
// import MerchandiseScreen from "../../screens/Revenue/Merchandise";

// Facilities
import RentFacilitiesScreen from '../../screens/Revenue/NonFnb/RentFacilities';
import FacilitiesReportScreen from '../../screens/Revenue/NonFnb/RentFacilities/Report';
import FacilitiesReportDetailScreen from '../../screens/Revenue/NonFnb/RentFacilities/Report/Detail';

import TicketVisitorScreen from "../../screens/Revenue/Visitor";

const RevenueNav = (props) => (
    <Switch>
        <Route 
            exact={true}
            path={`${props.match.path}/`}
            render={(routeProps) => <AllRegionScreen {...routeProps} />}
        /> 
        <Route 
            exact={true}
            path={`${props.match.path}/ticket`}
            render={(routeProps) => <TicketScreen {...routeProps} />}
        />
        {/* <Route 
            exact={true}
            path={`${props.match.path}/ticket/:sessionId`}
            render={(routeProps) => <TicketDetailScreen {...routeProps} />}
        /> */}
        <Route 
            exact={true}
            path={`${props.match.path}/ticket/analytics`}
            render={(routeProps) => <TicketAnalyticsScreen {...routeProps} />}
        />
        <Route 
            exact={true}
            path={`${props.match.path}/ticket/visitor`}
            render={(routeProps) => <TicketVisitorScreen {...routeProps} />}
        />
        <Route 
            exact
            path={`${props.match.path}/ticket/report`}
            render={(routeProps) => <TicketReportScreen {...routeProps} />}
        />
        <Route 
            exact={true}
            path={`${props.match.path}/ticket/report/:region`}
            render={(routeProps) => <TicketReportDetailScreen {...routeProps} />}
        />
        <Route 
            exact={true}
            path={`${props.match.path}/ticket/report_day`}
            render={(routeProps) => <TicketReportDayScreen {...routeProps} />}
        />
        <Route 
            exact={true}
            path={`${props.match.path}/ticket/revenue/insurance`}
            render={(routeProps) => <TicketInsuranceScreen {...routeProps} />}
        />
        <Route 
            exact={true}
            path={`${props.match.path}/retribution`}
            render={(routeProps) => <TicketRetributionScreen {...routeProps} />}
        />
        {/* <Route 
            exact={true}
            path={`${props.match.path}/ticket/nett`}
            render={(routeProps) => <TicketNettScreen {...routeProps} />}
        />
        <Route
            exact={true}
            path={`${props.match.path}/ticket/nett/:regionId`}
            render={(routeProps) => <TicketNettRegionScreen {...routeProps} />}
        /> */}
        <Route 
            exact={true}
            path={`${props.match.path}/fnb`}
            render={(routeProps) => <FnbScreen {...routeProps} />}
        />
        {/* <Route 
            exact={true}
            path={`${props.match.path}/fnb/analytics`}
            render={(routeProps) => <FnBAnalyticsScreen {...routeProps} />}
        /> */}
        <Route 
            exact
            path={`${props.match.path}/fnb/report`}
            render={(routeProps) => <FnBReportScreen {...routeProps} />}
        />
        <Route 
            exact={true}
            path={`${props.match.path}/fnb/report/:region`}
            render={(routeProps) => <FnBReportDetailScreen {...routeProps} />}
        />
        {/* Non Fnb */}
        {/* Merchandise */}
        <Route 
            exact={true}
            path={`${props.match.path}/non_fnb`}
            render={(routeProps) => <NonFnbScreen {...routeProps} />}
        />
        <Route 
            exact={true}
            path={`${props.match.path}/non_fnb/merchandise`}
            render={(routeProps) => <MerchandiseScreen {...routeProps} />}
        />
        {/* <Route 
            exact={true}
            path={`${props.match.path}/non_fnb/merchandise/analytics`}
            render={(routeProps) => <MerchandiseAnalyticsScreen {...routeProps} />}
        /> */}
        <Route 
            exact
            path={`${props.match.path}/non_fnb/merchandise/report`}
            render={(routeProps) => <MerchandiseReportScreen {...routeProps} />}
        />
        <Route 
            exact={true}
            path={`${props.match.path}/non_fnb/merchandise/report/:region`}
            render={(routeProps) => <MerchandiseReportDetailScreen {...routeProps} />}
        />
        {/* Facilities Rent */}
        <Route 
            exact={true}
            path={`${props.match.path}/non_fnb/rent_facilities`}
            render={(routeProps) => <RentFacilitiesScreen {...routeProps} />}
        />
        {/* <Route 
            exact={true}
            path={`${props.match.path}/non_fnb/rent_facilities/analytics`}
            render={(routeProps) => <FacilitiesAnalyticsScreen {...routeProps} />}
        /> */}
        <Route 
            exact
            path={`${props.match.path}/non_fnb/rent_facilities/report`}
            render={(routeProps) => <FacilitiesReportScreen {...routeProps} />}
        />
        <Route 
            exact={true}
            path={`${props.match.path}/non_fnb/rent_facilities/report/:region`}
            render={(routeProps) => <FacilitiesReportDetailScreen {...routeProps} />}
        />
        {/* <Route 
            exact={true}
            path={`${props.match.path}/fnb/:sessionId`}
            render={(routeProps) => <FnbDetailScreen {...routeProps} />}
        /> */}
    </Switch>
)

export default RevenueNav
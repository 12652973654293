import React from "react";
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'
function validate(values) {

    let errors = {}
  
    if (!values.email) {
      errors.email = 'Email is Required'
    }
  
    if (!values.password) {
      errors.password = 'Password is Required'
    }
  
    return errors
      
}

const TextInput = (field) => (
    <div className="md-form-group float-label">
        <input {...field.input} className="md-input" />
        <label>{field.placeholder}</label>
        {field.meta.touched && field.meta.error &&
        <span className="text-danger">{field.meta.error}</span>}
    </div>
)

const PasswordInput = (field) => (
    <div className="md-form-group float-label">
        <input {...field.input} type="password" className="md-input" />
        <label>{field.placeholder}</label>
        {field.meta.touched && field.meta.error &&
        <span className="text-danger">{field.meta.error}</span>}
    </div>
)

const SigninForm = ({ handleSubmit, pristine, submitting, valid, form, ...otherProps }) => (
    <form onSubmit={handleSubmit}>
        <Field name="email" type="email" placeholder="Email" component={TextInput} />
        <Field name="password" type="password" placeholder="Password" component={PasswordInput} />
        <button type="submit" className="btn btn-fw primary btn-block" disabled={submitting} style={{cursor: "pointer", backgroundColor: "#579264"}}>Sign in</button>
        <Link to="/forgot_password"><p className="mt-2 text-center">Reset Password</p></Link>
    </form>
)

export default reduxForm({
    form: 'signinForm',
    enableReinitialize: true,
    validate,
})(SigninForm)
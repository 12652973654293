import React from "react"
import NumberFormat from 'react-number-format'
import { Link } from "react-router-dom"
const TableItem = (props) => {
    return (
        <tr key={props.item.id}>
            <td>{props.item.date}</td>
            {
                props.item.region.map((item) => (
                    <React.Fragment>
                        <td>{item.qty}</td>
                        <td>{item.totalPrice}</td>
                    </React.Fragment>
                ))
            }

            <td>
                <NumberFormat
                    value={props.item.totalVisitor}
                    displayType={'text'}
                    thousandSeparator={true}
                    defaultValue="0"
                />
            </td>
            <td>
                <NumberFormat
                    value={props.item.totalPrice}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'Rp'}
                    suffix={",00"}
                    defaultValue="0"
                />
            </td>
            <td>
                <Link as="button" to="/dashboard/ticketing/a01" type="button" class="btn btn-success" style={{backgroundColor: "#579264"}}>Detail</Link>
            </td>
        </tr>
    )
}

export default TableItem
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firebaseConnect, firestoreConnect } from 'react-redux-firebase'
import Avatar from '../assets/img/placeholder_person.png';
import { withRouter } from 'react-router-dom'

class AuthButton extends Component {
  state = {
    currentStatus: "accepted",
    dataCount: 0,
    isLoading: false,
    dataUser: {
      id: null,
      name: "-",
      position: "-",
      phone: "-",
      email: "-",
      avatar: "",
    }
  };
  static propTypes = {
    auth: PropTypes.object,
    clearFirestore: PropTypes.func.isRequired,
    firebase: PropTypes.shape({
      login: PropTypes.func.isRequired,
      logout: PropTypes.func.isRequired,
    }),
  }

  onLogoutClick = async () => {
    try {
      localStorage.removeItem('region')
      this.props.clearFirestore()
      await this.props.firebase.logout()
    } catch (error) {
      console.error(error)
    }
  }

  viewProfile = () => {
    this.props.history.push("/dashboard/profile")
  }

  
  fetchDataUser = async (props) => {
    this.setState({ isLoading: true })
    const { auth } = props;
    // console.log('auth.uid', auth.uid)
    try {
        const snapshots = await this.props.firestore.get({
            // collection: 'region',
            // doc: region,
            collectionGroup: 'employee',
            where: ['uid', '==', auth.uid],
        });

        if (snapshots.empty) {
            this.setState({
                isLoading: false
            })
            return null
        }

        let dataUser = { 
            id: snapshots.docs[0].id, 
            ...snapshots.docs[0].data(), 
            avatar: snapshots.docs[0].data().avatar ? snapshots.docs[0].data().avatar.url : Avatar
        }
        // console.log('dataUser', dataUser)
        this.setState({
            dataUser,
            isLoading: false
        })
    } catch (error) {
        console.error(error)
        this.setState({
            isLoading: false
        })
    }
  }

  componentDidMount() {
    this.fetchDataUser(this.props)
  }

  render() {
    return (
      <React.Fragment>
        <p className="text-black mt-3">{this.state.dataUser.name}</p>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle clear" href="#" data-toggle="dropdown">
            {/* <span className="hidden-sm-down _500 mr-2">Hi, Ilham</span> */}
            <span><i className="account_circle"></i></span>
            <span className="avatar w-32" style={{ marginLeft: 0}}>
              <img src={this.state.dataUser.avatar} alt="..." />
              <i className="on b-white bottom"></i>
            </span>
          </a>
          <div className="dropdown-menu dropdown-menu-overlay pull-right">
            {/* <a className="dropdown-item" onClick={this.viewProfile}>{this.state.dataUser.name}</a> */}
            <a className="dropdown-item" onClick={this.viewProfile}>View Profile</a>
            <a className="dropdown-item" onClick={this.onLogoutClick}>Sign out</a>
          </div>
        </li>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return { auth: state.firebase.auth }
}
  
const mapDispatchToProps = dispatch => {
  return {
    clearFirestore: () => dispatch({ type: '@@reduxFirestore/CLEAR_DATA' })
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  firestoreConnect()
)(AuthButton)
import React from 'react';
import Select from 'react-select';
import { change } from 'redux-form';
import { connect } from 'react-redux'
import ErrorMessage from './ErrorMessage';

class SelectSector extends React.Component {
    state = { selectedOptions: null }
    handleChange = (selectedItem) => {
      const { input: { onChange } } = this.props
      this.setState({ selectedOptions: selectedItem });
      const value = selectedItem === null ? '' : selectedItem.value;
      onChange(value)
      if (typeof this.props.fetchData == "function") {
        this.props.resetArea()
        this.props.fetchData(selectedItem)
      }
    };
    UNSAFE_componentWillReceiveProps(nextProps) {
      if(nextProps.value !== this.props.value) {
        //REDUX FORM INTEGRATION
        const { input: { onChange, value } } = nextProps
        for (let i = 0; i < nextProps.items.length; i++) {
          const element = nextProps.items[i];
          if(value === element.value) {
            this.setState({
              selectedOptions: element
            })
            break;
          }
        }
      }
    }
    componentDidMount() {
      //REDUX FORM INTEGRATION
      const { input: { onChange, value } } = this.props
      if (value) {
        this.setState({
          selectedOptions: { label: value, value }
        })
      }
    }
    render() {
      const { items, currentRefinement, placeholder, disabled, meta } = this.props;
      return (
        <React.Fragment>
          <Select
            isDisabled={disabled}
            onChange={this.handleChange}
            options={items}
            className="field-box"
            value={this.state.selectedOptions}
            placeholder={placeholder}
            clearValue={this.clearQuery}
          />
         {meta.error ?
              <div className="text-danger" style={{ fontSize: "0.9em" }}>
                  {meta.error}
              </div>
          : null}
        </React.Fragment>
      );
    }
}

const customStyles = {
  option: () => ({
    fontSize: 13,
    padding: 10,
  }),
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    resetArea: () => dispatch(change(ownProps.formName, ownProps.area, ""))
  }
}

export default connect(null, mapDispatchToProps)(SelectSector);

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withFirestore } from 'react-redux-firebase'

import AddSession from './AddForm';
import ModalError from '../../components/ModalError';
import { uploadGalleryBase64 } from '../../helpers/uploadGallery';


class AddScreen extends Component {
  static propTypes = {
    uid: PropTypes.string,
    firestore: PropTypes.shape({
      add: PropTypes.func.isRequired
    }).isRequired
  }

  state = {
    errorInfo: false,
    collection: "maintenance",
    data: {
      taskList: [],
      description: "",
      images: [],
      arealist: [],
      sectorList: [],
      sector: "",
      area: ""
    }
  }

  handleErrorInfo = () => {
    this.setState({ errorInfo: !this.state.errorInfo });
  }

  createFormData = ({ images, arealist, sectorList, fetchArea, areaList, ...restData }) => {
    const data = {
      timestamp: new Date(),
      date: new Date(),
      userId: this.props.uid,
      ...restData
    };
    return data;
  }

  getDataParams = (data) => {
    const dataParams = Object.keys(data).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
    }).join('&');
    return dataParams;
  }

  onSubmit = async (values) => {
    try {
      const formData = this.createFormData(values)
      const docSnapshot = await this.props.firestore.add({ 
        collection: 'region',
        doc: this.props.region.toLowerCase(),
        subcollections: [{ collection: this.state.collection }]
      }, formData);
      
      if (values.images) {
        const path = `region/${this.props.region.toLowerCase()}/${this.state.collection}/${docSnapshot.id}`
        const imagesUploaded = await uploadGalleryBase64(docSnapshot.id, path, values.images);
          
        await this.props.firestore.update({ 
          collection: 'region',
          doc: this.props.region.toLowerCase(),
          subcollections: [{ collection: this.state.collection, doc: docSnapshot.id, }],
        }, {images: imagesUploaded});

      }
      this.props.history.goBack();
    } catch (error) {
      this.setState({ errorInfo: true })
    }
  }

  fetchData = async (props) => {
    try {
      this.setState({ 
        data: {
          taskList: [],
          description: "",
          images: [],
          arealist: [],
          sectorList: [],
          sector: "",
          area: "" 
        }, 
      })
      const docSnapshots = await this.props.firestore.get({
        collection: 'region',
        doc: props.region.toLowerCase(),
        subcollections: [{ 
          collection: 'sector',
          orderBy: ['name', 'asc']
        }],
      });
      if (docSnapshots.empty) {
        console.log("kosong")
        this.setState({
          data: {
            ...this.state.data, 
            sector: '', 
            areaList: [], 
            sectorList: [], 
          }
        })
        return;
      }
      let sectorList = []
      docSnapshots.forEach((doc) => {
        sectorList.push({
          id: doc.id,
          label:doc.data().name,
          value: doc.data().name,
          ...doc.data()
        })
      })
      this.setState({
        data: {
          ...this.state.data, 
          sectorList,
        }
      })
    } catch (error) {
      this.setState({
        data: {
          ...this.state.data, 
          sector: '', 
          sectorList: [], 
        }
      })
    }
  }

  componentDidMount() {
    this.fetchData(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.region !==  this.props.region) {
      this.fetchData(nextProps)
    }
  }

  goBack = () => {
    this.props.history.push(`/dashboard/maintenance`)
  }

  render() {
    if (!this.props.uid) return null
    console.log('this.state.data', this.state.data)
    return (
      <React.Fragment>
        <div className="box-header px-5 mt-3" style={{ backgroundColor: "#EFF2F7" }}>
          <div className="row">
            <a onClick={this.goBack}><i class="fa fa-arrow-left" aria-hidden="true"></i></a>
            <h3 className="ml-3 mt-1" style={{ color: "#3B4859" }}>TAMBAH KEGIATAN HARIAN</h3>
          </div>
        </div>
        {
          this.state.errorInfo &&
          <ModalError
            errorInfo={this.state.errorInfo}
            handleErrorInfo={this.handleErrorInfo}
            error={this.state.error}
          />
        }
        <div className="box-body">
          <AddSession onSubmit={this.onSubmit} initialValues={this.state.data} />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    uid: state.firebase.auth.uid,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    region: state.region
  }
}

export default compose(
  connect(mapStateToProps),
  withFirestore,
)(AddScreen)
import React, { useState, useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firebaseConnect, firestoreConnect } from 'react-redux-firebase';

import Header from '../components/Header'
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import NotFound from '../screens/NotFound';

import DashboardScreen from '../screens/Dashboard/DashboardScreen'
import ProfileNav from './profile';
import EmployeeNav from './employee';
import TicketingNav from './ticketing';
import RevenueNav from './revenue';
import PlantInfoNav from './plant';
import MaintenanceNav from './maintenance';
import FacilitiesNav from './facilities';

const MainRouter = ({ employee = {}, ...props }) => {
  return (
    <React.Fragment>
      <div id="aside" className="app-aside modal nav-dropdown">
        <Sidebar />
      </div>
      
      <div id="content" className="app-content box-shadow-z0" role="main"  >
        <div className="app-header dark box-shadow">
          <Header />
        </div>

        <div className="app-footer" >
          <Footer />
        </div>
        
        <div className="app-body" id="view">
            <Switch>
              <Route exact path="/dashboard" render={() => {
                  if ((props.profile.token && props.profile.token.claims.department == "Konservasi")) {
                    return <Redirect to="/dashboard/plant_info" />
                  } else if (
                    (props.profile.token && props.profile.token.claims.admin == true) || 
                    (props.profile.token && props.profile.token.claims.department == "Finance")) {
                    return <DashboardScreen />
                  } else {
                    return null
                  }
              }} />

              <Route  
                path="/dashboard/employee"
                render={(routeProps) => <EmployeeNav {...routeProps} />}
              />
              <Route  
                path="/dashboard/ticketing"
                render={(routeProps) => <TicketingNav {...routeProps} />}
              />
              <Route  
                path="/dashboard/revenue"
                render={(routeProps) => <RevenueNav {...routeProps} />}
              />
              <Route
                path={`/dashboard/profile`} 
                render={(routeProps) => <ProfileNav {...routeProps} />}
              />

              <Route
                path={`/dashboard/plant_info`} 
                render={(routeProps) => <PlantInfoNav {...routeProps} />}
              />

              <Route
                path={`/dashboard/maintenance`} 
                render={(routeProps) => <MaintenanceNav {...routeProps} />}
              />

              <Route
                path={`/dashboard/facilities_employee`} 
                render={(routeProps) => <FacilitiesNav {...routeProps} />}
              />

              <Route component={NotFound} />
            </Switch>
        </div>
      </div>
    </React.Fragment>
  )
}


const mapStateToProps = state => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
})

export default compose(
  connect(mapStateToProps)
)(MainRouter)
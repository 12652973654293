import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withFirestore } from 'react-redux-firebase'

// import { uploadBanner } from './uploadBanner'
import UpdateSession from './UpdateForm'
// import { deleteImages } from '../../../helpers/deleteGallery'
import { uploadGalleryBase64 } from '../../../helpers/uploadGallery'
import LoadingScreen from '../../../components/LoadingScreen'
import ModalError from '../../../components/ModalError'
import moment from 'moment'
import { deleteImages } from '../../../helpers/deleteGallery'

const collection = "region"

class UpdateScreen extends Component {
  static propTypes = {
    uid: PropTypes.string,
    firestore: PropTypes.shape({
      update: PropTypes.func.isRequired
    }).isRequired
  }

  state = {
    data: {
      taskList: [],
      description: "",
      images: [],
      area: ""
    },
    collection: "region",
    loading: true,
    errorInfo: false,
  }

  handleErrorInfo = () => {
    this.setState({ errorInfo: !this.state.errorInfo });
  }

  createFormData = ({ date, images, imagesData, ...values }) => {
    const data = {
      ...values,
      date: date ? moment().toDate() : "",
    };
    return data;
  }

  onSubmit = async (values) => {
    const { sessionId } = this.props.match.params
    const formData = this.createFormData(values)

    try {
      const sfDoc = await this.props.firestore.get({
        collection: this.state.collection,
        doc: this.props.region.toLowerCase(),
        subcollections: [{ collection: 'compose', doc: sessionId }],
      }, formData);

      if (!sfDoc.exists) {
        throw new Error("Document does not exist!");
      }

      //update data
      await this.props.firestore.update({ 
        collection: this.state.collection, 
        doc: this.props.region.toLowerCase(),
        subcollections: [{ collection: 'compose', doc: sessionId }],
      }, formData);
      // console.log("Successfully update document field. doc id: ", sessionId);
    
      await deleteImages(values.imagesData);
      const path = `${this.state.collection}/${this.props.region.toLowerCase()}/compose/${sessionId}`
      const imagesUploaded = await uploadGalleryBase64(sessionId, path, values.images);
              
      await this.props.firestore.update({ 
        collection: this.state.collection, 
        doc: this.props.region.toLowerCase(),
        subcollections: [{ collection: 'compose', doc: sessionId }],
      }, { images: imagesUploaded });
      // console.log("Document Successfully updated! doc id: ", sessionId);
      // console.log("Document Successfully updated! doc id: ", sessionId);

      return this.props.history.push(`/dashboard/plant_info/kompos/${sessionId}`)
    } catch (error) {
      console.error(error)
      this.setState({
        errorInfo: true,
      })
    }
  }

  componentDidMount() {
    this.fetchData(this.props);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.fetchData(nextProps)
  }

  fetchData = async (props) => {
    try {
      const { sessionId } = this.props.match.params;
      const snapshot = await this.props.firestore.get({
        collection: 'region',
        doc: props.region.toLowerCase(),
        subcollections: [{
          collection: 'compose',
          doc: sessionId
        }],
      });
      if (!snapshot.exists) {

      } else {
        const {
          images = [],
          date,
          ...restData
        } = snapshot.data()
        let imageUrls = images.map((e) => e.url)
        let data = {
          id: snapshot.id,
          images: imageUrls,
          imagesData: images,
          date: date ? moment(date.toDate()).format("DD MMMM YYYY") : "",
          ...restData
        }
        this.setState({ data: { ...this.state.data, ...data } });
      }
    } catch (error) {
      this.setState({
        errorInfo: true,
      })
    }

    this.setState({ loading: false })
  }
  goBack = () => {
    const { sessionId } = this.props.match.params;
    this.props.history.push({
      pathname: `/dashboard/plant_info/kompos/${sessionId}`,
      state: { region: this.props.selectedOption }
    })
  }

  render() {
    if (!this.props.uid) return null
    if (this.state.loading) return <LoadingScreen />
    return (
      <React.Fragment>
        <div className="box-header px-5 mt-3" style={{ backgroundColor: "#EFF2F7" }}>
          <div className="row">
            <a onClick={this.goBack}><i class="fa fa-arrow-left" aria-hidden="true"></i></a>
            <h3 className="ml-3 mt-1" style={{ color: "#3B4859" }}>UPDATE LAPORAN</h3>
          </div>
        </div>
        <div className="box-body">
          <React.Fragment>
            {
              this.state.errorInfo &&
              <ModalError
                errorInfo={this.state.errorInfo}
                handleErrorInfo={this.handleErrorInfo}
              />
            }
          </React.Fragment>
          <React.Fragment>
            <UpdateSession onSubmit={this.onSubmit} initialValues={this.state.data} />
          </React.Fragment>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    uid: state.firebase.auth.uid,
    region: state.region,
  }
}

export default compose(
  connect(mapStateToProps),
  withFirestore,
)(UpdateScreen)
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withFirestore } from 'react-redux-firebase'

import AddSession from './AddForm';
import ModalError from '../../../../components/ModalError';

class AddSessionScreen extends Component {
  static propTypes = {
    uid: PropTypes.string,
    firestore: PropTypes.shape({
      add: PropTypes.func.isRequired
    }).isRequired
  }
  
  state = {
    errorInfo: false,
    category: "All",
    isLoading: true,
    data: {
      date: new Date(),
      activity: "",
      description: "",
    }
  }

  handleErrorInfo = () => {
    this.setState({ errorInfo: !this.state.errorInfo });
  }
  
  createFormData = (values) => {

    const { sessionId } = this.props.match.params;
    const { selectedRegion } = this.props.location.state;
    const data = {
      timestamp: new Date(),
      date: new Date(),
      uidSector: selectedRegion,
      uidPlant: sessionId,
      activity: values.activity,
      description: values.description,
    };  

    return data;
  }

  onSubmit = async (values) => {
    const formData = this.createFormData(values)

    try {
      const { selectedRegion } = this.props.location.state;
      const { sessionId } = this.props.match.params;
      const docRef = await this.props.firestore.add({ 
        collection: 'region',
        doc: selectedRegion,
        subcollections: [{ 
          collection: 'plants', 
          doc: sessionId,
          subcollections: [{ 
            collection: 'dailyReport',
          }]
        }]
      }, formData);
      const { id } = docRef;
      this.props.history.goBack()

    } catch (error) {
      this.setState({ errorInfo: true })

    }
  }
  goBack = () => {
    this.props.history.goBack()
  }
  render() {
    if (!this.props.uid) return null
    return (
      <React.Fragment>
          <div className="box-header px-5 mt-3" style={{ backgroundColor: "#EFF2F7" }}>
              <div className="row">
                <a onClick={this.goBack}><i class="fa fa-arrow-left" aria-hidden="true"></i></a>
                <h3 className="ml-3 mt-1" style={{ color: "#3B4859" }}>TAMBAH LAPORAN</h3>
              </div>
          </div>
        {
          this.state.errorInfo &&
            <ModalError 
              errorInfo={this.state.errorInfo}
              handleErrorInfo={this.handleErrorInfo}
            />
        }          
        <div className="box-body p-0">
          <AddSession 
            onSubmit={this.onSubmit} 
            initialValues={this.state.data} 
          />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    uid: state.firebase.auth.uid,
  }
}

export default compose(
  connect(mapStateToProps),
  withFirestore,
)(AddSessionScreen)
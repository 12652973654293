import React, { Component } from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import { compose } from 'redux'
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase'
import NumberFormat from 'react-number-format'
import { connect } from 'react-redux'
import { fetchDataMerch } from '../../utils/nonfnb';

const initialData = {
  bogor: 0,
  cibodas: 0,
  purwodadi: 0,
  bali: 0
}

const initialDataRegion = {
  qty: 0,
  amount: 0,
}

class BarChartNonFnBMerch extends Component {
  state = {
    qty: [initialData],
    dataTicket: {
      bogor: initialDataRegion,
      cibodas: initialDataRegion,
      purwodadi: initialDataRegion,
      bali: initialDataRegion,
    },
    loading: true,
    total: 0,
  };
  fetchDataEvent = async (props) => {
    this.setState({
      loading: true,
      qty: [initialData],
      dataTicket: {
        bogor: initialDataRegion,
        cibodas: initialDataRegion,
        purwodadi: initialDataRegion,
        bali: initialDataRegion,
      },
    })

    try {

      let data = await fetchDataMerch(props.date)

      if (!data) {

        this.setState({
          data: [initialData],
          dataTicket: {
            bogor: initialDataRegion,
            cibodas: initialDataRegion,
            purwodadi: initialDataRegion,
            bali: initialDataRegion,
          },
          loading: false
        });
        return;
      }
      const { qty, amounts, tickets } = data
      // console.log({ qty, amounts, tickets })
      this.setState({
        qty,
        dataTicket: tickets,
        amounts,
        loading: false
      })

    } catch (error) {
      console.error(error)
      this.setState({
        dataTicket: {},
        loading: false
      })
    }
  }
  async componentDidMount() {
    this.fetchDataEvent(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.date !== this.props.date) {
      this.fetchDataEvent(nextProps)
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="ph-item p-0 mb-0 border-0">
          <div className="ph-picture mb-0" style={{ height: "500px" }}>
            <div className="ph-col-12"></div>
          </div>
        </div>
      )
    }
    return (
      <React.Fragment>
        <div className="col-12">
          <div className="box-header" style={{ borderBottom: "none !important" }}>
            <h2 style={{
              color: "#3B4859",
              fontWeight: 500,
              fontSize: "20px",
              lineHeight: "24px"
            }}>Non FnB (Merchandise)
                </h2>
          </div>
          <div className="row mt-4">
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="box p-a" style={{ borderRadius: 5, background: "#D7CABF", "boxShadow": "0px 2px 4px #EFF2F7", "borderRadius": "10px", color: "#ffff" }}>
                <div className="container">
                  <div className="row">
                    <span className="text-sm ml-2" style={{ display: "flex", fontWeight: "bold", justifyContent: "center", alignItems: "center" }}>
                      BOGOR
                        </span>
                  </div>
                </div>
                <div className="clear">
                  <h4 className="m-a text-md">
                    {this.state.loading ? "loading..." : 
                        <NumberFormat
                          value={this.state.dataTicket.bogor.amount}
                          displayType='text'
                          thousandSeparator
                          defaultValue='0'
                        />
                    }
                  </h4>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="box p-a" style={{ borderRadius: 5, background: "#00ACD9", boxShadow: "0px 2px 4px #EFF2F7", borderRadius: "10px", color: "white" }}>
                <div className="container">
                  <div className="row">
                    <span className="text-sm ml-2" style={{ display: "flex", fontWeight: "bold", justifyContent: "center", alignItems: "center" }}>
                      CIBODAS
                        </span>
                  </div>
                </div>
                <div className="clear">
                  <h4 className="m-a text-md">
                    {
                      this.state.loading ?
                        "loading..."
                        :
                        <NumberFormat
                          value={this.state.dataTicket.cibodas.amount}
                          displayType='text'
                          thousandSeparator
                          defaultValue='0'
                        />
                    }</h4>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="box p-a" style={{ borderRadius: 5, background: "#B3B731", boxShadow: "0px 2px 4px #EFF2F7", borderRadius: "10px", color: "white" }}>
                <div className="container">
                  <div className="row">
                    <span className="text-sm ml-2" style={{ display: "flex", fontWeight: "bold", justifyContent: "center", alignItems: "center" }}>
                      PURWODADI
                        </span>
                  </div>
                </div>
                <div className="clear">
                  <h4 className="m-a text-md">
                    {
                      this.state.loading ?
                        "loading..."
                        :
                        <NumberFormat
                          value={this.state.dataTicket.purwodadi.amount}
                          displayType='text'
                          thousandSeparator
                          defaultValue='0'
                        />
                    }</h4>
                </div>
              </div>
            </div>


            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="box p-a" style={{ borderRadius: 5, background: "#E4A314", boxShadow: "0px 2px 4px #EFF2F7", borderRadius: "10px", color: "white" }}>
                <div className="container">
                  <div className="row">
                    <span className="text-sm ml-2" style={{ display: "flex", fontWeight: "bold", justifyContent: "center", alignItems: "center" }}>
                      BALI
                        </span>
                  </div>
                </div>
                <div className="clear">
                  <h4 className="m-a text-md">
                    {
                      this.state.loading ?
                        "loading..."
                        :
                        <NumberFormat
                          value={this.state.dataTicket.bali.amount}
                          displayType='text'
                          thousandSeparator
                          defaultValue='0'
                        />
                    }</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12">
          <div className="container">
            <ResponsiveContainer maxHeight={500} aspect={4.0 / 3.0}>
              <BarChart
                data={this.state.qty}
                barSize={100}
                margin={{
                  top: 35,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="timestamp" />
                {/* <YAxis label={{ value: 'Jumlah Tiket', angle: -90, position: 'insideLeft' }} /> */}
                <Tooltip />
                <Legend />
                <Bar dataKey="bogor" fill="#D7CABF" name="Bogor" />
                <Bar dataKey="cibodas" fill="#00ACD9" name="Cibodas" />
                <Bar dataKey="purwodadi" fill="#B3B731" name="Purwodadi" />
                <Bar dataKey="bali" fill="#E4A314" name="Bali" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    region: state.region
  }
}
export default compose(
  connect(mapStateToProps),
  firestoreConnect()
)(BarChartNonFnBMerch)

import React, { Component } from 'react';
import TableItemSubTotal from "./TableItemSubTotal"
import LoadingTableSponsor from '../../../../components/loading-table';

class DataTableSubTotal extends Component {
    renderItem = (item, index) => {
        return (
            <TableItemSubTotal
                key={item.id}
                index={index}
                item={item}
            />
        )
    }
    render() {
        return (
            <table className="table table-bordered m-a-0">
                <thead style={{ color: "#579264"}}>
                    <tr>
                        <th scope="col" className="text-center">No</th>
                        <th scope="col" colSpan="3" className="text-center">Uraian</th>
                        <th scope="col" className="text-center">Jumlah</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.props.loading ?
                            <React.Fragment>
                                <LoadingTableSponsor column={6} />
                                <LoadingTableSponsor column={6} />
                                <LoadingTableSponsor column={6} />
                                <LoadingTableSponsor column={6} />
                            </React.Fragment>
                            :
                                this.props.data.length == 0 ? 
                                <tr>
                                    <td className="text-center" colSpan="9">
                                        <p>Tidak ada data!</p>
                                    </td>
                                </tr>
                            : this.props.data.map(this.renderItem)
                    }
                </tbody>
            </table>
        )
    }
}

export default DataTableSubTotal
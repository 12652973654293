import React, { PureComponent, Component } from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import moment from 'moment';
import { withFirestore } from 'react-redux-firebase'

const dayList = [
  {
    name: 'Senin'
  },
  {
    name: 'Selasa'
  },
  {
    name: 'Rabu'
  },
  {
    name: 'Kamis'
  },
  {
    name: 'Jumat'
  },
  {
    name: 'Sabtu'
  },
  {
    name: 'Minggu'
  },
]

class BarChartTicketDaily extends Component {

  state = {
    dataTicket: [],
    loading: true,
  }


  fetchDataEvent = async (props) => {
    let dataTicket = props.data
    this.setState({ loading: true, dataTicket: [] })
    let newData = []
    for (let i = 0; i < dayList.length; i++) {
      let day = dayList[i];
      let categories = {
        asing: 0,
        asing_group: 0,
        domestic: 0,
        domestic_group: 0
      }

      for (let j = 0; j < dataTicket.length; j++) {
        let ticket = dataTicket[j];
        let timestamp = ticket.timestamp.toDate()
        // let startDate = moment(month).toDate()
        // let endDate = moment(month).endOf('month').toDate()

        let currentDay = moment(timestamp).format("dddd")
        
        if (currentDay == day.name) {
          let { tariff_transactions } = ticket.tariff
          for (let index = 0; index < tariff_transactions.length; index++) {
            const element = tariff_transactions[index];
            switch (element.tariff) {
              case "Asing":
                categories.asing = categories.asing + element.qty
                break;
              case "Asing Group":
                categories.asing_group = categories.asing_group + element.qty
                break;
              case "Domestik":
                categories.domestic = categories.domestic + element.qty
                break;
              case "Domestik Group":
                categories.domestic_group = categories.domestic_group + element.qty
                break;
              default:
                break;
            }
          }
        }
      }

      newData.push({
        name: day.name,
        ...categories
      })

    }
    // console.log('newData', newData)
    this.setState({
      dataTicket: newData,
      loading: false
    })
  }

  componentDidMount() {
    this.fetchDataEvent(this.props)
    
  }
    
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      this.fetchDataEvent(nextProps)
    }
  }
  
  render() {
    if (this.props.loading) {
      return (
        <div className="ph-item p-0 mb-0 border-0">
          <div className="ph-picture mb-0" style={{ height: "500px" }}>
              <div className="ph-col-12"></div>
          </div>
        </div>
      )
    }
    return (
      <ResponsiveContainer maxHeight={500} aspect={4.0 / 3.0}>
        <BarChart
          data={this.state.dataTicket}
          barGap="0"
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis type="number" domain={[0, 100000]} label={{ value: 'Tiket Terjual', fontWeight: "bold", angle: -90, position: 'insideLeft' }} />
          <Tooltip cursor={{fill: 'transparent'}}/>
          {/* <Legend /> */}
          <Bar dataKey="domestic" label="Domestik" fill="#96CEB4" />
          <Bar dataKey="domestic_group" label="Domestik Grup" fill="#FFEEAD" />
          <Bar dataKey="asing" label="Asing" fill="#FF6F69" />
          <Bar dataKey="asing_group" label="Asing Grup" fill="#D177FC" />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

export default withFirestore(BarChartTicketDaily)

// const [focusBar, setFocusBar] = useState(null);
// return (
//   <BarChart
//     data={data}
//     onMouseMove={state => {
//       if (state.isTooltipActive) {
//         setFocusBar(state.activeTooltipIndex);
//       } else {
//         setFocusBar(null);
//       }
//     }}
//   >
//     <Tooltip />
//     <Bar dataKey="value">
//       {data.map((entry, index) => (
//         <Cell key={`cell-${index}`} fill={focusBar === index ? '#0057B6' : '#007AFF'} />
//       ))}
//     </Bar>
//   </BarChart>
// );
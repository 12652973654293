
import firebase from 'firebase'
import moment from 'moment-timezone'

const initialDataRegion = {
    qty: 0,
    amount: 0,
}

export async function fetchFnbAllRegion(date) {
    const snapshots =
      await firebase.firestore().collectionGroup('dataFnb')
        .where('date', "==", moment(date).tz("Asia/Jakarta").startOf('day').toDate())
        .get()
    if (snapshots.empty) {
      return null;
    }
    const tickets = {
      bogor: initialDataRegion,
      cibodas: initialDataRegion,
      purwodadi: initialDataRegion,
      bali: initialDataRegion,
    }
    const amounts = [{}]
    const qty = [{}]

    snapshots.forEach(element => {

      const region = element.ref.parent.parent.id
      const { totalQty = 0, totalSubTotalIncl = 0 } = element.data()

      tickets[region] = {
        region,
        id: element.id,
        qty: totalQty,
        amount: totalSubTotalIncl
      }

      amounts[0][region] = totalSubTotalIncl
      qty[0][region] = totalQty

    });

    // console.log({ tickets, amounts, qty })

    return { tickets, amounts, qty }

  }
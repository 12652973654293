import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Link } from "react-router-dom"
import { Alert } from 'react-bootstrap'
import firebase from 'firebase'
import LoadingTable from "../../components/loading-table"

import DataList from './DataList'
import FilterMenu from '../../components/FilterMenu';
import moment from 'moment';
import { isInclusivelyAfterDay, SingleDatePicker } from 'react-dates'
import DatePicker from '../../components/DatePicker'
import Pagination from '../../components/Pagination'

const db = firebase.firestore();
class TableListScreen extends Component {
  state = { 
    acceptInfo: false,
    errorInfo: false,
    show: false,
    data: [],
    startDate: moment(),
    endDate: moment(),
    deleteId: null,
    isLoading: true,
    showMore: false,
    isSelected: false,
    status: "published",
    selectedOption: "",
    sectors: [],
    currentData: [], 
    currentPage: null, 
    totalPages: null 
  };
  handleAcceptInfo = () => {
    this.setState({ acceptInfo: !this.state.acceptInfo });
  }
  showErrorInfo = () => {
    this.setState({ errorInfo: !this.state.errorInfo });
  }
  renderTableHead = () => (
    <tr style={{ color: "#579264" }}>
      <th style={{textAlign: "center"}}>Tanggal</th>
      <th style={{textAlign: "center"}}>Sektor</th>
      <th style={{textAlign: "center"}}>Area</th>
      {/* <th style={{textAlign: "center"}}>Region</th>
      <th style={{textAlign: "center"}}>Wilayah</th>
      <th style={{textAlign: "center"}}>Kebun</th>
      <th style={{textAlign: "center"}}>Tanggal</th> */}
      <th colSpan="2" style={{textAlign: "center"}}>Action</th>
    </tr>
  )
  componentDidMount() {
    this.fetchData(this.props);
    this.fetchSectors(this.props);
  }
  //2. pagination
  onPageChanged = dataChanged => {
      const { data } = this.state;
      const { currentPage, totalPages, pageLimit } = dataChanged;
  
      const offset = (currentPage - 1) * pageLimit;
      const currentData = data.slice(offset, offset + pageLimit);
  
      this.setState({ currentPage, currentData, totalPages });
  }

  
  componentWillReceiveProps(nextProps) {
    if (nextProps.date !== this.props.date ||
      nextProps.region !== this.props.region
      ) {
      this.setState({ region: nextProps.region })
      this.fetchData(nextProps)
      this.fetchSectors(nextProps);
    }
  }

  fetchSectors = async (props) => {
    
    let sectors = []
    this.setState({ isLoading: true, sectors: [] })
    const snapshots = await this.props.firestore.get({
      collection: 'region',
      doc: props.region.toLowerCase(),
      subcollections: [{ 
        collection: 'sector',
        // orderBy: [ ['name'] ]
      }],
    });
    if (snapshots.empty) {
      this.setState({
        sectors: [],
        selectedOption: "",
        showMore: false,
        isLoading: false,
      });
      return;
    }
    snapshots.forEach((doc) => {
      const { date, name } = doc.data()
      sectors.push({ 
        id: doc.id,
        label: name,
        value: name,
      });
    });
    sectors.unshift({
      id: "0",
      label: "All",
      value: "",
    })
    const selectedOption = sectors[0].value
    const selectedSector = sectors[0]
    console.log({selectedOption,
      selectedSector})
    this.setState({
      sectors,
      isLoading: false,
      selectedOption,
      selectedSector,
    });
  }
  
  fetchData = async (props) => {
    
    let data = []
    this.setState({ isLoading: true, data: [] })

    const region = props.region.toLowerCase();
    
    const startDate = moment(this.state.startDate).startOf('day').toDate();
    const endDate = moment(this.state.endDate).endOf('day').toDate();

    const snapshots = await db.collection('region').doc(region).collection('maintenance')
      .where('date', ">=", startDate)
      .where('date', "<=", endDate)
      .orderBy('date', "desc")
      // .limit(10)
      .get()
      
    if (!snapshots.empty) {
      const lastVisible = snapshots.docs[snapshots.docs.length - 1];
      snapshots.forEach((doc) => {
        const {date, ...restData } = doc.data()
        data.push({ 
          id: doc.id, 
          dateDisplay: date ? moment(date.toDate()).format("DD MMMM YYYY") : "", 
          date: date ? moment(date.toDate()) : "", 
          ...restData, 
          dataRef: doc.ref 
        });
      });
      this.setState({
        data,
        rawData: data,
        isLoading: false,
        lastVisible,
        showMore: true,
      });
    } else {
      this.setState({
        showMore: false,
        isLoading: false,
      });
    }
  }
  loadMoreData = async (props) => {
    this.setState({ isLoadMore: true })
    // const snapshots = await this.props.firestore.get({ 
    //   collection: 'region',
    //   doc: props.region.toLowerCase(),
    //   subcollection: [{
    //     collection: 'maintenance',
    //     orderBy: [
    //       ['date', 'desc']
    //     ],
    //     startAfter: this.state.lastVisible,
    //     limit: 10
    //   }]
    // });

    const region = props.region.toLowerCase();
    
    const startDate = moment(this.state.startDate).startOf('day').toDate();
    const endDate = moment(this.state.endDate).endOf('day').toDate();

    const snapshots = await db.collection('region').doc(region).collection('maintenance')
      .where('date', ">=", startDate)
      .where('date', "<=", endDate)
      .orderBy('date', "desc")
      .startAfter(this.state.lastVisible)
      .limit(10)
      .get()
    let data = [];
    if (!snapshots.empty) {
      const lastVisible = snapshots.docs[snapshots.docs.length - 1];
      snapshots.forEach((doc) => {
        const {date, ...restData } = doc.data()
        data.push({ 
          id: doc.id, 
          dateDisplay: date ? moment(date.toDate()).format("DD MMMM YYYY") : "", 
          date: date ? moment(date.toDate()) : "", 
          ...restData, 
          dataRef: doc.ref 
        });
      });
      this.setState({
        data: [...this.state.data, ...data],
        rawData: [...this.state.data, ...data],
        isLoading: false,
        isLoadMore: false,
        lastVisible,
        showMore: true,
      });
    } else {
      this.setState({
        showMore: false,
        isLoading: false,
        isLoadMore: false
      });
    }
  }
  onChange = (value, selectedItem) => {
    this.setState({ selectedOption: value, selectedSector: selectedItem }, () => this.filter())
  }
  filter = () => {
    let tempData = this.state.rawData
    //kalau tidak ada yang di filter... raw data
    // kalau ada yang di filter, 1
    let filteredData = []
    const { selectedOption } = this.state;
    if (!selectedOption) {
        filteredData = this.state.rawData
    } else {
        filteredData = tempData.filter(({ sector }) => sector == selectedOption)
    }
    this.setState({
        data: filteredData
    })
  }

  restrictDateRange = (day) => {
      return isInclusivelyAfterDay(day, moment().add(1, 'days')) 
      || 
          day.isBefore(moment().startOf('year'))
      // || 
      //     day.isBefore(moment().startOf('month').add(-3, 'month'))
  }
  // onDateChange = (date) => {
  //     console.log('date', date)
  //     this.setState({ date }, () => this.fetchData(this.props))
  // }
  onDatesChange = ({ startDate, endDate }) => {
      this.setState({ startDate, endDate }, () => this.fetchData(this.props))
  }
  render() {
    return (
      <React.Fragment>
        
        <div className="box-header px-5 mt-3" style={{ backgroundColor: "#EFF2F7" }}>
          <h3 style={{ color: "#3B4859" }}>KEGIATAN HARIAN</h3>
        </div>
      <div className="box-body" style={{ backgroundColor: "transparent"}}>
        <div className="col-12">
          <div style={{ borderRadius: 5 }}>
            <div className="row">

                
               
                <div className="col-lg-4 col-md-6">
                  <div className="row">
                    <div className="col-6"><h6 style={{ fontSize: "0.8rem", color: "#3B4859" }}>Pilih Tanggal</h6></div>
                  </div>
                  <DatePicker
                    newVersion={true}
                    // restrictedMode
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    onDatesChange={this.onDatesChange}
                  />
                </div>
                <div className="col-md-2 col-sm-12 mt-4 mb-1 ml-2">
                  { 
                    this.state.isLoading === false 
                    ? 
                  <FilterMenu
                    items={this.state.sectors} 
                    value={this.state.selectedOption}
                    onChange={this.onChange} 
                  />
                  :null}
                </div>
            </div>
            <div className="row mx-2">
              <div className="col-10"></div>
              <div className="col-xl-2 col-lg-12 col-md-12 col-sm-12">
                <ul className="nav navbar-nav text-right">
                  <li className="nav-item">
                         
                  {
                    ((this.props.profile.token && this.props.profile.token.claims.admin === true) ||
                        (this.props.profile.token && this.props.profile.token.claims.department === "Maintenance Infrastructure & GA") || 
                        (this.props.profile.token && this.props.profile.token.claims.department == "Konservasi")) ?
                      <Link to={{
                        pathname: "/dashboard/maintenance/add",
                        state: { selectedSector: this.state.selectedSector }
                        }} className="nav-link">
                        <button className="md-btn md-raised m-b-sm kr-color px-4" style={{ borderRadius: 5, fontSize: "0.7rem", fontWeight: "inherit" }}>
                          <div style={{ color: "white" }}>
                            <i className="fa fa-fw fa-plus" style={{ opacity: 1, marginRight: 5, color: "white" }} />
                              Tambah Laporan
                          </div>
                        </button>
                      </Link>
                      : null
                    }
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="box" style={{ borderRadius: 5 }}>
            {
              this.state.acceptInfo &&
              <Alert dismissible variant="success" onClose={this.handleAcceptInfo}>
                <Alert.Heading>Berhasil!</Alert.Heading>
                <p style={{color: '#3c763d'}}>
                  Berhasil menambah data.
                </p>
              </Alert>
            }

            {
              this.state.errorInfo &&
              <Alert dismissible variant="danger" onClose={this.handleErrorInfo}>
                <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                <p style={{color: '#a94442'}}>
                  Kesalahan saat memproses data! Silahkan coba kembali.
                </p>
              </Alert>
            }

            <div className="box p-3 mb-0" style={{ borderRadius: 10 }}>
              <div className="table-responsive mt-3">
                <table className="table" style={{ borderRadius: "10px"}}>
                  <thead>
                    {this.renderTableHead()}
                  </thead>
                  <tbody>
                    { 
                      this.state.isLoading 
                      ? 
                        <React.Fragment>
                            <LoadingTable column={5} />
                            <LoadingTable column={5} />
                            <LoadingTable column={5} />
                            <LoadingTable column={5} />
                        </React.Fragment>
                      : this.state.data.length === 0 
                        ? 
                        <tr>
                          <td className="text-center" colSpan="9">
                            <p>Tidak ada data!</p>
                          </td>
                        </tr>
                        : <DataList 
                            data={this.state.currentData}
                            handleAcceptInfo={() => this.handleAcceptInfo()}
                            showErrorInfo={() => this.showErrorInfo()} 
                        />
                    }
                  </tbody>
                </table>
              </div>
            </div>
            
            {/* {this.state.showMore && (
              <footer className=" p-a">
                <div className="row">
                <td>
                    <button className="btn btn-danger" style={{ margin: 10, backgroundColor: "rgb(21, 149, 136)" }} onClick={() => this.loadMoreData(this.props)}>
                      More
                    </button>
                </td>
                {this.state.isLoadMore && <p className="my-auto">Loading...</p>}
                </div>
              </footer>
            )} */}
            
            <Pagination 
              totalRecords={this.state.data.length} 
              pageLimit={10} 
              pageNeighbours={1} 
              onPageChanged={this.onPageChanged} 
            />
          </div>
        </div>
      </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    uid: state.firebase.auth.uid,
    region: state.region,
    auth: state.firebase.auth,
    profile: state.firebase.profile
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect(),
)(TableListScreen)
import React, { Component } from 'react';
import moment from 'moment';
import TableItem from "./TableItem"
// import { auth, db } from "../../config/constants";

class DataTable extends Component {
    renderItem = (item, index) => {
        return (
            <TableItem
                key={item.id}
                index={index}
                item={item}
            />
        )
    }
    render() {
        return (
            <table className="table table-bordered m-a-0">
                <thead style={{ color: "#579264"}}>
                    <tr>
                        <th scope="col" rowSpan="2" className="text-center" style={{ verticalAlign: "top" }}>Tanggal</th>
                        <th scope="col" colSpan="2" className="text-center">Bogor</th>
                        <th scope="col" colSpan="2" className="text-center">Purwodadi</th>
                        <th scope="col" colSpan="2" className="text-center">Cibodas</th>
                        <th scope="col" colSpan="2" className="text-center">Bali</th>
                    </tr>
                    <tr>
                        <th scope="col" className="text-center">Banyaknya</th>
                        <th scope="col" className="text-center">IDR</th>
                        <th scope="col" className="text-center">Banyaknya</th>
                        <th scope="col" className="text-center">IDR</th>
                        <th scope="col" className="text-center">Banyaknya</th>
                        <th scope="col" className="text-center">IDR</th>
                        <th scope="col" className="text-center">Banyaknya</th>
                        <th scope="col" className="text-center">IDR</th>
                        <th scope="col" className="text-center">Total Pengunjung</th>
                        <th scope="col" className="text-center">Total IDR</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.props.data.map(this.renderItem)
                    }
                </tbody>
            </table>
        )
    }
}

export default DataTable
import React, { Component } from 'react';
import NumberFormat from 'react-number-format'
import TableItem from "./TableItem"
import LoadingTableSponsor from '../../../../components/loading-table';

class DataTable extends Component {
    renderItem = (item, index) => {
        return (
            <TableItem
                key={item.id}
                index={index}
                item={item}
            />
        )
    }
    renderItemPOS = (item, index) => {
        return(
            <th key={index} scope="col" colSpan="2" className="text-center">{item.device || "-"}</th>
        )
    }
    renderItemCashier = (item, index) => {
        return (
            <React.Fragment key={index}>
                <th scope="col" colSpan="2" className="text-center">-</th>
            </React.Fragment>
        )
    }
    renderItemDetail = (item, index) => {
        return (
            <React.Fragment key={index}>
                <th scope="col" className="text-center">Qty</th>
                <th scope="col" className="text-center">Amount</th>
            </React.Fragment>
        )
    }
    renderItemRowTotal = (item, index) => {
        return (
            <React.Fragment key={index}>
                <td scope="col" className="font-weight-bold text-center">{item.qty || "-"}</td>
                <td scope="col" className="font-weight-bold text-center">
                    <NumberFormat 
                        value={item.amount || "-"}
                        displayType={'text'} 
                        thousandSeparator={true} 
                        prefix={'Rp'} 
                        suffix={",-"} 
                        defaultValue="0" 
                    />
                </td>
            </React.Fragment>
        )
    }
    renderItemCat = (item, index, tariff) => {
        for (let i = 0; i < item.tarif_transaction.length; i++) {
            const element = item.tarif_transaction[i];
            if (element.tariff == tariff) {
                return (
                    <React.Fragment key={index}>
                        <td scope="col" className="text-center">{element.qty}</td>
                        <td scope="col" className="text-center">
                            <NumberFormat 
                                value={element.amount || "-"}
                                displayType={'text'} 
                                thousandSeparator={true} 
                                prefix={'Rp'} 
                                suffix={",-"} 
                                defaultValue="0" 
                            />
                        </td>
                    </React.Fragment>
                ) 
            }
        }
        return (
            <React.Fragment key={index}>
                <td scope="col" className="text-center">-</td>
                <td scope="col" className="text-center">-</td>
            </React.Fragment>
        )
    }
    render() {
        return (
            <table className="table table-bordered m-a-0">
                <thead>
                    {
                        this.props.loading ?
                            <React.Fragment>
                                <LoadingTableSponsor column={15} />
                                <LoadingTableSponsor column={15} />
                                <LoadingTableSponsor column={15} />
                                <LoadingTableSponsor column={15} />
                                <LoadingTableSponsor column={15} />
                                <LoadingTableSponsor column={15} />
                                <LoadingTableSponsor column={15} />
                                <LoadingTableSponsor column={15} />
                            </React.Fragment>
                            :
                    <React.Fragment>
                        <tr style={{ color: "#579264"}}>
                            <th scope="col" className="text-left">MPOS</th>
                            {
                                this.props.data.map(this.renderItemPOS)
                            }
                            <th scope="col" colSpan="2" rowSpan="2" className="text-center align-middle">Total</th>
                        </tr>
                        <tr style={{ color: "#579264"}}>
                            <th scope="col" className="text-left">KASIR</th>
                            {
                                this.props.data.map(this.renderItemCashier)
                            }
                        </tr>
                        <tr style={{ color: "#579264"}}>
                            <th scope="col" className="text-left">Kategori</th>

                            {
                                this.props.data.map(this.renderItemDetail)
                            }
                            <th scope="col" className="text-center">Qty</th>
                            <th scope="col" className="text-center">Amount</th>
                            
                        </tr>
                        <tr>
                            <th>Domestik</th>
                            {
                                this.props.data.map((item, index) => this.renderItemCat(item, index, "Domestik"))
                            }

                            <td scope="col" className="text-center">{this.props.summaryData.domestic.total_qty  || "-"}</td>                            
                            <td scope="col" className="text-center">
                                <NumberFormat 
                                    value={this.props.summaryData.domestic.total_amount  || "-"}
                                    displayType={'text'} 
                                    thousandSeparator={true} 
                                    prefix={'Rp'} 
                                    suffix={",-"} 
                                    defaultValue="0" 
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>Domestik Grup</th>
                            {
                                this.props.data.map((item, index) => this.renderItemCat(item, index, "Domestik Group"))
                            }
                            <td scope="col" className="text-center">{this.props.summaryData.domestic_group.total_qty  || "-"}</td>
                                                        
                            <td scope="col" className="text-center">
                                <NumberFormat 
                                    value={this.props.summaryData.domestic_group.total_amount  || "-"}
                                    displayType={'text'} 
                                    thousandSeparator={true} 
                                    prefix={'Rp'} 
                                    suffix={",-"} 
                                    defaultValue="0" 
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>Asing</th>
                            {
                                this.props.data.map((item, index) => this.renderItemCat(item, index, "Asing"))
                            }
                            <td scope="col" className="text-center">{this.props.summaryData.asing.total_qty  || "-"}</td>
                            <td scope="col" className="text-center">
                                <NumberFormat 
                                    value={this.props.summaryData.asing.total_amount  || "-"}
                                    displayType={'text'} 
                                    thousandSeparator={true} 
                                    prefix={'Rp'} 
                                    suffix={",-"} 
                                    defaultValue="0" 
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>Asing Grup</th>
                            {
                                this.props.data.map((item, index) => this.renderItemCat(item, index, "Asing Group"))
                            }
                            <td scope="col" className="text-center">{this.props.summaryData.asing_group.total_qty  || "-"}</td>
                            <td scope="col" className="text-center">
                                <NumberFormat 
                                    value={this.props.summaryData.asing_group.total_amount || "-"}
                                    displayType={'text'} 
                                    thousandSeparator={true} 
                                    prefix={'Rp'} 
                                    suffix={",-"} 
                                    defaultValue="0" 
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>Mobil</th>
                            {
                                this.props.data.map((item, index) => this.renderItemCat(item, index, "Mobil"))
                            }
                            <td scope="col" className="text-center">{this.props.summaryData.mobil.total_qty  || "-"}</td>
                            <td scope="col" className="text-center">
                                <NumberFormat 
                                    value={this.props.summaryData.mobil.total_amount || "-"}
                                    displayType={'text'} 
                                    thousandSeparator={true} 
                                    prefix={'Rp'} 
                                    suffix={",-"} 
                                    defaultValue="0" 
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>Motor</th>
                            {
                                this.props.data.map((item, index) => this.renderItemCat(item, index, "Sepeda Motor"))
                            }
                            <td scope="col" className="text-center">{this.props.summaryData.sepeda_motor.total_qty || "-"}</td>
                            <td scope="col" className="text-center">
                                <NumberFormat 
                                    value={this.props.summaryData.sepeda_motor.total_amount || "-"}
                                    displayType={'text'} 
                                    thousandSeparator={true} 
                                    prefix={'Rp'} 
                                    suffix={",-"} 
                                    defaultValue="0" 
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>Sepeda</th>
                            {
                                this.props.data.map((item, index) => this.renderItemCat(item, index, "Sepeda"))
                            }
                            <td scope="col" className="text-center">{this.props.summaryData.sepeda.total_qty || "-"}</td>
                            <td scope="col" className="text-center">
                                <NumberFormat 
                                    value={this.props.summaryData.sepeda.total_amount || "-"}
                                    displayType={'text'} 
                                    thousandSeparator={true} 
                                    prefix={'Rp'} 
                                    suffix={",-"} 
                                    defaultValue="0" 
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>Total</th>
                            {
                                this.props.data.map((item, index) => this.renderItemRowTotal(item, index, "Sepeda"))
                            }
                            <td scope="col" className="font-weight-bold text-center">{this.props.summaryData.total_qty || "-"}</td>
                            <td scope="col" className="font-weight-bold text-center">
                                <NumberFormat 
                                    value={this.props.summaryData.total_amount || "-"}
                                    displayType={'text'} 
                                    thousandSeparator={true} 
                                    prefix={'Rp'} 
                                    suffix={",-"} 
                                    defaultValue="0" 
                                />
                            </td>
                        </tr>

                    </React.Fragment>
                }
                </thead>
            </table>
        )
    }
}

export default DataTable
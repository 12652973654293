import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import TableItem from "./TableItem"

import LoadingTableSponsor from '../../../../../../components/loading-table'
import Pagination from '../../../../../../components/Pagination'

class DataTable extends Component {
    state = {
        selectedFood: '',
        selectedStall: '',
        currentData: [], 
        currentPage: null, 
        totalPages: null 
    }
    renderItem = (item, index) => {
        return (
            <TableItem
                key={item.id}
                index={index}
                item={item}
            />
        )
    }
    onChangeFilterFood = (e) => this.setState({ selectedFood: e.target.value })
    onChangeFilterStall = (e) => this.setState({ selectedStall: e.target.value })
    
    //2. pagination
    onPageChanged = dataChanged => {
        const { data } = this.props;
        const { currentPage, totalPages, pageLimit } = dataChanged;
    
        const offset = (currentPage - 1) * pageLimit;
        const currentData = data.slice(offset, offset + pageLimit);
    
        this.setState({ currentPage, currentData, totalPages });
    }
    
    render() {
        return (
            <React.Fragment>
                <table className="table table-bordered m-a-0">
                    <thead style={{ color: "#579264" }}>
                        <tr>
                            <th scope="col" style={{ fontWeight: "bold" }} className="text-center px-5">Tanggal</th>
                            <th scope="col" style={{ fontWeight: "bold" }} className="text-center">
                                Stall
                                <Link as="button" data-toggle="modal" data-target="#exampleModalStall">
                                    <i style={{ fontSize: 10 }} className='mx-3 fa fa-sort-amount-desc'></i>
                                </Link>
                            </th>
                            <th scope="col" style={{ fontWeight: "bold" }} className="text-center">
                                Nama Fasilitas
                                <Link as="button" data-toggle="modal" data-target="#exampleModalCenter">
                                    <i style={{ fontSize: 10 }} className='mx-3 fa fa-sort-amount-desc'></i>
                                </Link>
                            </th>
                            <th scope="col" style={{ fontWeight: "bold" }} className="text-center">Pax</th>
                            <th scope="col" style={{ fontWeight: "bold" }} className="text-center">Harga Satuan</th>
                            <th scope="col" style={{ fontWeight: "bold" }} className="text-center">Subtotal</th>
                            <th scope="col" style={{ fontWeight: "bold" }} className="text-center">Service</th>
                            <th scope="col" style={{ fontWeight: "bold" }} className="text-center">Tax</th>
                            <th scope="col" style={{ fontWeight: "bold" }} className="text-center">Total</th>
                        </tr>
                    </thead>
                    <tbody style={{ color: "rgba(0, 0, 0, 0.54)" }}>

                        {
                            this.props.loading ?
                                <React.Fragment>
                                    <LoadingTableSponsor column={9} />
                                    <LoadingTableSponsor column={9} />
                                    <LoadingTableSponsor column={9} />
                                    <LoadingTableSponsor column={9} />
                                </React.Fragment>
                                :
                                this.props.data.length == 0 ?
                                    <tr>
                                        <td className="text-center" colSpan="9">
                                            <p>Tidak ada data!</p>
                                        </td>
                                    </tr>
                                    : this.state.currentData.map(this.renderItem)
                        }
                    </tbody>
                </table>

                <Pagination 
                    totalRecords={this.props.data.length} 
                    pageLimit={10} 
                    pageNeighbours={1} 
                    onPageChanged={this.onPageChanged} 
                />

                {/* MODAL */}
                <div className="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content" style={{ borderRadius: 15 }}>
                            <div className="modal-header text-right" style={{ border: "none" }}>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body px-5">
                                <div className="text-center">
                                    <h6 style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold" }}>FILTER</h6>
                                </div>
                                <div className="mt-4">
                                    <p style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: 500 }}>Kategori Nama Fasilitas</p>
                                    <select value={this.state.selectedFood} onChange={this.onChangeFilterFood} className="browser-default custom-select" style={{ width: "100%", color: "rgba(124, 122, 122, 0.87)" }}>
                                        <option value="">Semua Fasilitas</option>
                                        {
                                            this.props.foodNameList.map((item) => (
                                                <option value={item}>{item}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="modal-footer mt-3 justify-content-center" style={{ border: "none" }}>
                                <button  onClick={() => this.props.filterFoodName(this.state.selectedFood)}  data-dismiss="modal" type="button" className="btn w-25" style={{ backgroundColor: "#579264", color: "white", fontSize: "0.8em" }}>
                                    Set
                                </button>
                                <button type="button" className="btn w-25" data-dismiss="modal" style={{ backgroundColor: "#969595", color: "white", fontSize: "0.8em" }}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="exampleModalStall" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content" style={{ borderRadius: 15 }}>
                            <div className="modal-header text-right" style={{ border: "none" }}>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body px-5">
                                <div className="text-center">
                                    <h6 style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold" }}>FILTER</h6>
                                </div>
                                <div className="mt-4">
                                    <p style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: 500 }}>Stall</p>
                                    <select value={this.state.selectedStall} onChange={this.onChangeFilterStall} className="browser-default custom-select" style={{ width: "100%", color: "rgba(124, 122, 122, 0.87)" }}>
                                        <option selected value="">Semua Stall</option>
                                        {
                                            this.props.stallNameList.map((item) => (
                                                <option value={item}>{item}</option>

                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="modal-footer mt-3 justify-content-center" style={{ border: "none" }}>
                                <button onClick={() => this.props.filterStall(this.state.selectedStall)} data-dismiss="modal" type="button" className="btn w-25" style={{ backgroundColor: "#579264", color: "white", fontSize: "0.8em" }}>
                                    Set
                                </button>
                                <button type="button" className="btn w-25" data-dismiss="modal" style={{ backgroundColor: "#969595", color: "white", fontSize: "0.8em" }}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default DataTable
import React, { Component, useState, useEffect } from "react"

const AvatarPicker = (field) => {
    const [preview, setPreview] = useState("")
    const [loading, setLoading] = useState(false)
    const { input: { value, onChange } } = field

    const onSelectBanner = (event) => {
        if (event.currentTarget.files && event.currentTarget.files[0]) {
            setLoading(true)
            const file = event.currentTarget.files[0];
            // setFieldValue(field.name, file);
            onChange(file)
            const reader = new FileReader();
            reader.onload = (e) => {
                setPreview(e.target.result)
                setLoading(false)
            };
            reader.readAsDataURL(file);
        }
    };
    useEffect(() => {
        setPreview(value)
        return () => {};
    }, [])
    return (
        <React.Fragment>

            <div className="d-flex justify-content-center mt-3 mb-5">
            <label htmlFor="upload" style={{ cursor: "pointer"}}>
                {preview ?
                    <div className="d-flex justify-content-center align-items-center">
                        <img src={preview} className="circle-dot border-0" alt="Banner Preview" style={{ objectFit: 'cover' }} />
                    </div> :
                    <React.Fragment>
                        <div className="circle-dot d-flex justify-content-center align-items-center" style={{ backgroundColor: 'rgb(239, 242, 247)'}}>
                            <i className="glyphicon glyphicon-plus mr-2" style={{ color: "rgba(0, 0, 0, 0.54)" }}></i><span style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold"}}>Foto</span>
                        </div>
                    </React.Fragment>}
                {loading && (
                    <p>Loading..</p>
                )}
                <input type="file" id="upload" style={{ "display": "none" }} onChange={onSelectBanner} />
            </label>
            </div>

            {field.meta.touched && field.meta.error &&
            <div className="text-danger" style={{ fontSize: "0.9em" }}>
                {field.meta.error}
            </div>}
        </React.Fragment>
    );
}

export default AvatarPicker
import React, { Component } from 'react';
import TableItem from "./TableItem"
import LoadingTableSponsor from '../../../components/loading-table';

class DataTable extends Component {
    renderItem = (item, index) => {
        return (
            <TableItem
                key={item.id}
                index={index}
                item={item}
            />
        )
    }
    render() {
        return (
            <table className="table table-bordered m-a-0">
                <thead style={{ color: "#579264" }}>
                    <tr>
                        <th scope="col" rowSpan="2" className="text-center" style={{ verticalAlign: "middle" }}>Tanggal</th>
                        <th scope="col" colSpan="2" className="text-center">Domestik</th>
                        <th scope="col" colSpan="2" className="text-center">Domestik Group</th>
                        <th scope="col" colSpan="2" className="text-center">Asing</th>
                        <th scope="col" colSpan="2" className="text-center">Asing Group</th>
                        <th scope="col" rowSpan="2" className="text-center" style={{ verticalAlign: "middle" }}>Total</th>
                    </tr>
                    <tr>
                        <th scope="col" className="text-center">Qty</th>
                        <th scope="col" className="text-center">Amount</th>
                        <th scope="col" className="text-center">Qty</th>
                        <th scope="col" className="text-center">Amount</th>
                        <th scope="col" className="text-center">Qty</th>
                        <th scope="col" className="text-center">Amount</th>
                        <th scope="col" className="text-center">Qty</th>
                        <th scope="col" className="text-center">Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.props.loading ?
                            <React.Fragment>
                                <LoadingTableSponsor column={10} />
                                <LoadingTableSponsor column={10} />
                                <LoadingTableSponsor column={10} />
                                <LoadingTableSponsor column={10} />
                            </React.Fragment>
                            :
                                this.props.data.length == 0 ? 
                                <tr>
                                    <td className="text-center" colSpan="10">
                                        <p>Tidak ada data!</p>
                                    </td>
                                </tr>
                            : this.props.data.map(this.renderItem)
                    }
                </tbody>
            </table>
        )
    }
}

export default DataTable
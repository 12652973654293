import React from 'react'

const SubmitButton = ({ disabled, label }) => (
  <button 
    type="submit" 
    className="md-btn md-raised m-b-sm w-xs dark" 
    style={{ width: "100%", backgroundColor: "#579264", borderRadius: 5, fontSize: "0.8em",fontWeight: "inherit" }} disabled={disabled}>
    {label || "Simpan"}
  </button>
)

export default SubmitButton
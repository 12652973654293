import React, { PureComponent } from 'react';
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import moment from 'moment';
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { getFormData } from '../../../../helpers/common';
import axios from 'axios';

const data = [
  {
    name: '2020', domestik: 110, domestikGrup: 120, asing: 130, asingGrup: 120
  },
];

class BarChartTicketYearly extends PureComponent {
  // static jsfiddleUrl = 'https://jsfiddle.net/alidingling/30763kr7/';

  state = {
    startDate: moment().startOf('year'),
    endDate: moment(),
    dataTicket: [],
    loading: true
  }

  // fetchDataEvent = async (props) => {
  //   const data = {
  //     "region": props.region.toLowerCase(),
  //     "date_start": moment(this.state.startDate, "YYYY-MM-DD").format("YYYY-MM-DD").toString(),
  //     "date_end": moment(this.state.endDate, "YYYY-MM-DD").format("YYYY-MM-DD").toString()
  //   }
    // const formData = getFormData(data)

    // if (this.props.region == "All Region") {
    // this.fetchDataAllRegion(formData);
    // } else {
    //   this.fetchDataSelectedRegion(formData);
    // }
  // }

  // fetchDataAllRegion = async (formData) => {
  //   const { accessToken } = this.props.auth.stsTokenManager;
  //   try {
  //     const response = await axios({
  //       method: 'POST',
  //       headers: {
  //         'Authorization': 'Bearer ' + accessToken
  //       },
  //       url: 'https://us-central1-krmos-sandbox.cloudfunctions.net/revenue/api/summary/all_region/total',
  //       data: formData
  //     })
  //     const { data } = response
  //     let dataTicket = []
  //     // dataTicket = data.data.daily.daily_transactions
  //     if (data == null) data = []

  //     let dataCategory = {
  //       name: "2020",
  //       asing: 0,
  //       asing_group: 0,
  //       domestic: 0,
  //       domestic_group: 0
  //     }
  //     console.log('data', data)

  //     data.forEach(element => {
  //       //boilerplate filter kategori

  //       let { tariff_transactions } = element.data.tariff
  //       for (let index = 0; index < tariff_transactions.length; index++) {
  //         const element = tariff_transactions[index];
  //         switch (element.tariff) {
  //           case "Asing":
  //             dataCategory.asing = dataCategory.asing + element.qty
  //             break;
  //           case "Asing Group":
  //             dataCategory.asing_group = dataCategory.asing_group + element.qty
  //             break;
  //           case "Domestik":
  //             dataCategory.domestic = dataCategory.domestic + element.qty
  //             break;
  //           case "Domestik Group":
  //             dataCategory.domestic_group = dataCategory.domestic_group + element.qty
  //             break;
  //           default:
  //             break;
  //         }
  //       }
  //     })




  //     console.log('dataCategory', dataCategory)

  //     dataTicket.push(dataCategory)

  //     console.log('dataTicket', dataTicket)

  //     this.setState({
  //       dataTicket: dataTicket,
  //       loading: false
  //     })

  //   } catch (error) {
  //     console.error(error)
  //     this.setState({
  //       dataTicket: [],
  //       loading: false
  //     })
  //   }
  // }

  // fetchDataSelectedRegion = async (formData) => {
  //   const { accessToken } = this.props.auth.stsTokenManager;
  //   try {
  //     const response = await axios({
  //       method: 'POST',
  //       headers: {
  //         'Authorization': 'Bearer ' + accessToken
  //       },
  //       url: 'https://us-central1-krmos-sandbox.cloudfunctions.net/revenue/api/summary',
  //       data: formData
  //     })
  //     const { data } = response
  //     let { tariff } = data.data
  //     let dataTicketCategory = tariff.tariff_transactions

  //     let dataTicket = [{
  //       name: "2020",
  //       asing: 0,
  //       asing_group: 0,
  //       domestic: 0,
  //       domestic_group: 0
  //     }]
  //     for (let index = 0; index < dataTicketCategory.length; index++) {
  //       const element = dataTicketCategory[index];
  //       switch (element.tariff) {
  //         case "Asing":
  //           dataTicket[0].asing = element.qty
  //           break;
  //         case "Asing Group":
  //           dataTicket[0].asing_group = element.qty
  //           break;
  //         case "Domestik":
  //           dataTicket[0].domestic = element.qty
  //           break;
  //         case "Domestik Group":
  //           dataTicket[0].domestic_group = element.qty
  //           break;
  //         default:
  //           break;
  //       }
  //     }

  //     this.setState({
  //       dataTicket: dataTicket,
  //       loading: false
  //     })

  //   } catch (error) {
  //     console.error(error)
  //     this.setState({
  //       dataTicket: [],
  //       loading: false
  //     })
  //   }
  // }

  fetchDataAllRegion = (props) => {
    
    this.setState({ loading: true, dataTicket: [] })
    let dataYearly = [{
      name: "2020",
      asing: 0,
      asing_group: 0,
      domestic: 0,
      domestic_group: 0
    }]

    console.log('this.props.data', props.data)

    props.data.forEach(item => {
      let { tariff_transactions } = item.tariff
      for (let index = 0; index < tariff_transactions.length; index++) {
        const element = tariff_transactions[index];
        switch (element.tariff) {
          case "Asing":
            dataYearly[0].asing = dataYearly[0].asing + element.qty
            break;
          case "Asing Group":
            dataYearly[0].asing_group = dataYearly[0].asing_group + element.qty
            break;
          case "Domestik":
            dataYearly[0].domestic = dataYearly[0].domestic + element.qty
            break;
          case "Domestik Group":
            dataYearly[0].domestic_group = dataYearly[0].domestic_group + element.qty
            break;
          default:
            break;
        }
      }
    })
    this.setState({
      dataTicket: dataYearly,
      loading: false
    })

  }

  componentDidMount() {
    this.fetchDataAllRegion(this.props);
  }


  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.region !== this.props.region || nextProps.data !== this.props.data) {
      this.fetchDataAllRegion(nextProps)
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="ph-item p-0 mb-0 border-0">
          <div className="ph-picture mb-0" style={{ height: "500px" }}>
            <div className="ph-col-12"></div>
          </div>
        </div>
      )
    }
    return (
      <ResponsiveContainer maxHeight={500} aspect={4.0 / 3.0}>
        <BarChart
          data={this.state.dataTicket}
          barGap="0"
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis type="number" interval={"preserveStart"} domain={[dataMin => 0, dataMax => 200000]} label={{ value: 'Tiket Terjual', fontWeight: "bold", angle: -90, position: 'insideLeft' }} />
          <Tooltip />
          {/* <Legend /> */}
          <Bar dataKey="domestic" label="Domestik" fill="#96CEB4" />
          <Bar dataKey="domestic_group" label="Domestik Grup" fill="#FFEEAD" />
          <Bar dataKey="asing" label="Asing" fill="#FF6F69" />
          <Bar dataKey="asing_group" label="Asing Grup" fill="#D177FC" />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}


const mapStateToProps = state => ({
  profile: state.firebase.profile,
  auth: state.firebase.auth
})

export default compose(
  connect(mapStateToProps),
  firebaseConnect()
)(BarChartTicketYearly)
import React, { PureComponent } from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import moment from 'moment';
import { withFirestore } from 'react-redux-firebase'

class BarChartTicketMonthly extends PureComponent {

  state = {
    dataTicket: [],
    loading: true,
  }

  fetchDataEvent = async (props) => {
    let dataTicket = props.data
    this.setState({ loading: true, dataTicket: [] })
    try {
      let currentMonth = parseInt(moment().startOf('month').format("M"))
      //loop bulan
      let arrMonths = []
      for (let i = 0; i < currentMonth; i++) {
        arrMonths.push(moment().startOf('year').startOf('day').add(i, 'month'))
      }

      let newData = []
      for (let i = 0; i < arrMonths.length; i++) {
        let month = arrMonths[i];
        let categories = {
          asing: 0,
          asing_group: 0,
          domestic: 0,
          domestic_group: 0
        }

        for (let j = 0; j < dataTicket.length; j++) {
          let ticket = dataTicket[j];
          let timestamp = ticket.timestamp.toDate()
          let startDate = moment(month).toDate()
          let endDate = moment(month).endOf('month').toDate()

          let isDateSameOrAfter = moment(timestamp).isSameOrAfter(startDate)
          let isDateSameOrBefore = moment(timestamp).isSameOrBefore(endDate)

          if (isDateSameOrAfter && isDateSameOrBefore) {
            let { tariff_transactions } = ticket.tariff
            for (let index = 0; index < tariff_transactions.length; index++) {
              const element = tariff_transactions[index];
              switch (element.tariff) {
                case "Asing":
                  categories.asing = categories.asing + element.qty
                  break;
                case "Asing Group":
                  categories.asing_group = categories.asing_group + element.qty
                  break;
                case "Domestik":
                  categories.domestic = categories.domestic + element.qty
                  break;
                case "Domestik Group":
                  categories.domestic_group = categories.domestic_group + element.qty
                  break;
                default:
                  break;
              }
            }
          }
        }

        newData.push({
          name: moment(month).format("MMMM YYYY"),
          ...categories
        })

      }
      this.setState({
        dataTicket: newData,
        loading: false
      })

    } catch (error) {
      console.error(error)
      this.setState({
        dataTicket: [],
        loading: false
      })
    }
  }

  componentDidMount() {
    this.fetchDataEvent(this.props)
  }
    
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      this.fetchDataEvent(nextProps)
    }
  }
  

  render() {
    if (this.state.loading) {
      return (
        <div className="ph-item p-0 mb-0 border-0">
          <div className="ph-picture mb-0" style={{ height: "500px" }}>
              <div className="ph-col-12"></div>
          </div>
        </div>
      )
    }
    return (
      <ResponsiveContainer maxHeight={500} aspect={4.0 / 3.0}>
        <BarChart
          data={this.state.dataTicket}
          barGap="0"
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis type="number" domain={[0, 100000]} label={{ value: 'Tiket Terjual', fontWeight: "bold", angle: -90, position: 'insideLeft' }} />
          <Tooltip />
          {/* <Legend /> */}
          <Bar dataKey="domestic" label="Domestik" fill="#96CEB4" />
          <Bar dataKey="domestic_group" label="Domestik Grup" fill="#FFEEAD" />
          <Bar dataKey="asing" label="Asing" fill="#FF6F69" />
          <Bar dataKey="asing_group" label="Asing Grup" fill="#D177FC" />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

export default withFirestore(BarChartTicketMonthly)
import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import moment from 'moment'
const { parseAsync } = require('json2csv')

export default class DownloadReport extends Component {

    static defaultProps = {
        data: [],
        columns: []
    }

    state = { showDownload: false }

    handleOpenDownloadData = () => this.setState({ showDownload: true })
    handleCloseDownloadData = () => this.setState({ showDownload: false })

    parseCsvData = () => {
        const { fields, data } = this.props
        if (fields) { // have specify custom fields
            return parseAsync(data, { fields })
        }
        return parseAsync(data)
    }

    downloadToCSV = async () => {
        this.setState({ loadingDownload: true })

        const startDate = moment(this.props.startDate).format('DDMMYYYY')
        const endDate = moment(this.props.endDate).format('DDMMYYYY')
            
        try {

            const csvReport = await this.parseCsvData()

            let filename = startDate + "-" + endDate + "-" + this.props.fileName + ".csv";

            let csvData = new Blob([csvReport], { type: 'text/csv;charset=utf-8;' });
            if (window.navigator.msSaveOrOpenBlob)  {
                // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
                window.navigator.msSaveBlob(csvData, filename);
            } else {
                let a = window.document.createElement("a");
                a.href = window.URL.createObjectURL(csvData, { type: "text/plain" });
                a.download = filename;
                document.body.appendChild(a);
                a.click();  // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
                document.body.removeChild(a);
            }

            this.setState({ loadingDownload: false })
            this.handleCloseDownloadData()

        } catch (error) {
            console.error(error)

            this.setState({ loadingDownload: false })
            this.handleCloseDownloadData()
            
        }
    }

    render() {
        return (
            <React.Fragment>
                <button
                    onClick={this.handleOpenDownloadData}
                    className="md-btn md-raised py-2 kr-color px-4"
                    style={{
                        borderRadius: 5,
                        fontSize: "0.7rem",
                        height: "calc(2.25rem + 2px)",
                        fontWeight: "inherit",
                        marginBottom: 0
                    }}>
                    <div style={{ color: "white" }}>
                        <i className="fa fa-fw fa-download" style={{ opacity: 1, marginRight: 5, color: "white" }} />
                        Unduh Laporan
                    </div>
                </button>
                <Modal show={this.state.showDownload} onHide={this.handleCloseDownloadData}>
                    <Modal.Header closeButton>
                        {/* <Modal.Title>Modal heading</Modal.Title> */}
                    </Modal.Header>
                    <Modal.Body>
                        <div class="modal-body">
                            <div class="block" style={{ boxShadow: "none" }}>
                                <div class="block-header block-header-default" style={{ backgroundColor: "transparent" }}>
                                    <h3 class="block-title">Download Data Report</h3>
                                </div>
                                <div class="block-content">
                                    <h3><b>Are you sure you want to download this data?</b></h3>
                                    <div class="my-3 pt-3">
                                        <div class="d-flex justify-content-end">
                                            <button class="btn btn-outline-danger btn-noborder mr-3 js-tooltip-enabled" type="button" onClick={this.handleCloseDownloadData} title="" data-original-title="Cancel">
                                                <span>No </span>
                                            </button>
                                            <button class="btn btn-outline-success js-tooltip-enabled" type="submit" data-toggle="tooltip" onClick={this.downloadToCSV} title="" data-original-title="Submit">
                                                <span>Yes</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        )
    }
}

import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, FieldArray, formValueSelector } from 'redux-form'

import SubmitButton from '../../../../components/SubmitButton'
import LoaderModal from '../../../../components/LoaderModal'
import validate from './validate'
import MenuInput from '../../../../components/MenuInput';

let AddForm = ({ handleSubmit, pristine, submitting, valid, dataAnimals, nickName,
  animalId,
  species, ...otherProps }) => {
  return (
    <React.Fragment>
      <div className="col-12" style={{ marginTop: 15 }}>
        <div className="box" style={{ borderRadius: 5 }}>
          <div className="box-header">
            <h2 className="m-2" style={{ color: "#159588", fontWeight: "bold" }}>Add Fertilizer Plan</h2>
          </div>
        </div>
      </div>

      <form role="form" onSubmit={handleSubmit}>
        <div className="col-sm-12">
          <div className="box p-5" style={{ borderRadius: 5 }}>
            
            {/* { !isLoaded(dataAnimals) && <td>Loading...</td> } */}
            {/* { !isEmpty(dataAnimals) && <td>No Data.</td> } */}

            {/* <div className="col-lg-4 col-sm-xs-12">
              <label htmlFor="animalInput" style={{ color: "#159588", fontWeight: "bold" }}>Animal*</label>
              <label htmlFor="animalInput">(You can only select an animal that didn't have meal plan.)</label>
              <div className="input-group mb-3"> */}
                {/* {
                  isEmpty ?
                  <Field name="animalId" className="form-control c-select" component="select" disabled>
                    <React.Fragment>
                      <option style={{ fontSize: "11px!important" }} value="">Please select an animal...</option>
                      { dataAnimals.map((animal, i) => <option key={i} value={animal.id}>{animal.nickName}</option> )}
                    </React.Fragment>
                  </Field>
                  :
                  <Field name="animalId" className="form-control c-select" component="select">
                    <React.Fragment>
                      <option style={{ fontSize: "11px!important" }} value="">Please select an animal...</option>
                      { dataAnimals.map((animal, i) => <option key={i} value={animal.id}>{animal.nickName}</option> )}
                    </React.Fragment>
                  </Field>
                } */}
              {/* </div> */}
            {/* </div> */}

            {/* <div className="box" style={{ borderRadius: 5 }}>
              <div className="box-header p-4">
                <div className="row">
                  <div className="col-md-4">
                    <label htmlFor="placeInput" style={{ color: "#159588", fontWeight: "bold", fontSize: '0.875rem' }}>
                      Name
                    </label>
                    <div className="input-group mb-4">
                      <p className="my-0" style={{ fontWeight: 'bold' }}>{nickName}</p>
                    </div>
                  </div>

                  <div className="col-md-4"> */}
                    {/* <label htmlFor="placeInput" style={{ color: "#159588", fontWeight: "bold", fontSize: '0.875rem' }}>
                      Animal Id
                    </label>
                    <div className="input-group mb-4">
                      <p className="my-0" style={{ fontWeight: 'bold' }}>{animalId}</p>
                    </div> */}
                  {/* </div>
                  <div className="col-md-4">
                    <label htmlFor="placeInput" style={{ color: "#159588", fontWeight: "bold", fontSize: '0.875rem' }}>
                      Plant Species  
                    </label>
                    <div className="input-group mb-3">
                      <p className="my-0" style={{ fontWeight: 'bold' }}>{species}</p>
                    </div>
                  </div>

                </div>

              </div>
            </div> */}
            <div className="col-md-12 col-xs-12">
              <FieldArray name="detail" component={MenuInput} label="Food Menu" />
            </div>

            <div className="col-lg-6 col-xs-12 px-0">
              <div className="col-lg-3 col-xs-12 px-0 mt-5 text-center">
                <SubmitButton disabled={!valid || submitting} />
              </div>
              <LoaderModal submitting={submitting} />
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  )
}

AddForm = reduxForm({
  form: 'addMealPlanForm',
  validate,
  enableReinitialize: true,
})(AddForm)

const selector = formValueSelector('addMealPlanForm')
AddForm = connect(
  state => {

    const nickName = selector(state, 'nickName') || "";
    const animalId = selector(state, 'animalId') || "";
    const species = selector(state, 'species') || "";
    
    return {
      nickName,
      animalId,
      species,
    }
  }
)(AddForm)

export default AddForm
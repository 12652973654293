import React from "react"
import ErrorMessage from "./ErrorMessage"

const PlantTextInput = (field) => (
  <div className="form-group mb-4">
    <label 
      htmlFor={`input-${field.label}`} 
      className="form-control-label" 
      style={{ color: "rgba(124, 122, 122, 0.87)", fontWeight: "bold" }}
    >
      {field.label}
    </label>
    <input
        {...field.input}
        type={field.type || "text"}
        placeholder={field.placeholder}
        className="form-control field-box" 
        style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
        id={`input-${field.label}`} autoComplete={field.autoComplete || "off"}
    />
    <ErrorMessage {...field} />
  </div>
)

export default PlantTextInput
import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Delete from './Delete';

const ListItem = (props) => {
    const [ canEdit, setCanEdit ] = useState(false)
    useEffect(() => {
        const date = moment(props.date).endOf('day').toDate()
        const currentDate = moment().toDate();
        if (moment(currentDate).isAfter(date)) {
            setCanEdit(false)
        } else {
            setCanEdit(true)
        }
        return () => true;
    }, [props.date])
    const canDelete = ((props.profile.token && props.profile.token.claims.admin === true) ||
    (props.profile.token && props.profile.token.claims.department === "Konservasi")
    ) && canEdit === true
    return (
        <tr key={props.id} style={{ color: "rgba(124, 122, 122, 0.87)" }}>
            <td className="text-center">{props.dateDisplay || "-"}</td>
            <td className="text-center">{props.sector || "-"}</td>
            <td className="text-center">{props.area || "-"}</td>
            <td className="text-center">
                <Button
                    variant="danger"
                    style={{ fontSize: "0.8em", backgroundColor: "#579264", color: "white", borderColor: "transparent" }}
                    onClick={() => props.navigateDetail(props.id)}
                    role="button"
                >
                    Lihat Detail
                </Button>
            </td>
            <td>
            { canDelete && canEdit === true && 
                <Delete 
                    id={props.id}
                    deleteRef={props.dataRef}
                />
            }
            </td>
        </tr>
    )
}

ListItem.defaultProps = {
    id: "",
    name: "",
    position: "",
    email: "",
    sector: "",
}

ListItem.propTypes = {
    id: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    sector: PropTypes.string.isRequired,
}


const mapStateToProps = state => {
    return {
      uid: state.firebase.auth.uid,
      region: state.region,
      auth: state.firebase.auth,
      profile: state.firebase.profile
    }
}

export default compose(
    connect(mapStateToProps)
)(ListItem)

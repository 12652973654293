import React, { useState } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'

import { validateAddForm } from './validate'
import moment from 'moment'
import TaskList from './TaskList'
import SubmitButton from '../../components/SubmitButton'
import LoaderModal from '../../components/LoaderModal'
import SelectSectorFacilities from '../../components/Form/SelectSectorFacilities'
import { withRouter } from 'react-router-dom'

let AddForm = ({ handleSubmit, submitting, sectorList, taskList, type, ...props }) => {
  const [ areaList, setAreaList ] = useState([])
  const fetchArea = async (selectedItem) => {
    let { id, facilitiesArea } = selectedItem
    try {
      let areaList = facilitiesArea.map((item) => {
        return { 
          label: item, 
          value: item
        }
      })
      setAreaList(areaList)
    } catch (error) {
      setAreaList([])
    }
  }
  const goBack = () => {
    const { activityId } = props.match.params
    props.history.goBack();
  }
  return (
    <React.Fragment>
      <form role="form" onSubmit={handleSubmit}>
        <div className="box mb-2" style={{ borderRadius: 10 }}>
          <div className="form-group row py-3 ml-2">
            <div className="col-md-2">
              <h5
                htmlFor={`input-region`}
                className="mb-0"
                style={{ color: "#579264", fontWeight: "bold", lineHeight: "30px" }}
              >
                Tanggal
              </h5>
            </div>
            <div className="col-md-10">
              <p
                id={`input-region`}
                style={{
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: "20px",
                  lineHeight: "30px",
                  letterSpacing: "0.15px",
                  color: "#979D99"
                }}
                className="mb-0"
              >
                {moment().format("DD MMMM YYYY")}
              </p>
            </div>
          </div>
        </div>

        <div className="box mb-0 p-4" style={{ borderRadius: 10 }}>
          <div className="col-md-3 px-0 mt-2">
            <h6 style={{ color: "#579264", fontWeight: "bold" }}>Pilih Pekerjaan</h6>
            {
              type == "harian" ?
                <Field 
                  name="task" 
                  component="select" 
                  className="form-control input-c mt-3" 
                  style={{ borderRadius: "6px", border: "1px solid #A9A9AB" }}
                >
                  <option value="">Pilih Pekerjaan...</option>
                  {
                    taskList.map((item) => <option value={item.value}>{item.value}</option>)
                  }
                </Field>
              :
              null
          }
          {
            type == "tambahan" ?
                <Field 
                  name="task" 
                  component="input" 
                  placeholder="Isi Pekerjaan..." 
                  className="form-control input-c mt-3" 
                  style={{ borderRadius: "6px", border: "1px solid #A9A9AB" }}
                />
              : null
            }
          </div>

          <hr className="my-4" style={{ backgroundColor: "#7C7A7A" }} />

          <div className="col-md-3 px-0">
            <h6 style={{ color: "#579264", fontWeight: "bold" }}>Pilih Wilayah</h6>
            <div className="mt-3">
              <Field
                component={SelectSectorFacilities}
                placeholder="Pilih Wilayah"
                items={sectorList}
                fetchData={fetchArea}
                name="sector"
                formName="addInfraForm"
                area="area"
              />
            </div>
            
          </div>
            {
              areaList.length > 0 &&
                <div className="mt-5">
                  <h6 style={{ color: "#579264", fontWeight: "bold" }}>Pilih Lokasi</h6>
                  <Field name="area" component={TaskList} areaList={areaList} />
                </div>
            }

          <div className="row mt-5">
            <div className="col-md-2 offset-8">
              <SubmitButton disabled={submitting} label="Mulai" />
            </div>
            <div className="col-md-2">
              <button
                type="button"
                onClick={goBack}
                className="md-btn md-raised m-b-sm w-xs success"
                style={{ width: "100%", backgroundColor: "#969595", borderRadius: 5, fontWeight: "inherit", fontSize: "0.8em" }}
              >
                Kembali
                </button>
            </div>
          </div>
        </div>
      </form>
      <LoaderModal submitting={submitting} />
    </React.Fragment>
  )
} 

AddForm = reduxForm({
  form: 'addInfraForm',
  validate: validateAddForm,
  enableReinitialize: true,
})(AddForm)

const selector = formValueSelector('addInfraForm')
AddForm = connect(
  state => {

    const taskList = selector(state, 'taskList') || [];
    const areaList = selector(state, 'areaList') || [];
    const sector = selector(state, 'sector') || '';
    const type = selector(state, 'type') || '';
    const sectorList = selector(state, 'sectorList') || [];

    return {
      sector,
      sectorList,
      taskList,
      areaList,
      type,
    }
  }
)(AddForm)

export default withRouter(AddForm)
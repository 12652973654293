import React from "react"

const ErrorMessage = (field) => (
    field.meta.touched && field.meta.error ?
        <div className="text-danger" style={{ fontSize: "0.9em" }}>
            {field.meta.error}
        </div>
    : null
)

export default ErrorMessage
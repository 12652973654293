import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'

import SubmitButton from '../../components/SubmitButton'
import LoaderModal from '../../components/LoaderModal'
import validate from './validate'
// import TextInput from '../../components/Form/TextInput'
import TextAreaInput from '../../components/Form/TextAreaInput'
// import NumberInput from '../../components/Form/NumberInput'
// import AvatarPicker from '../../components/AvatarPicker'
// import RadioButton from '../../components/Form/RadioButton'
// import moment from 'moment'
import TaskList from './TaskList'
import GalleryPicker from '../../components/Form/GalleryPicker'
import SelectSectorInput from '../../components/Form/SelectSectorInput'
import SelectAreaInput from '../../components/Form/SelectAreaInput'

let UpdateForm = ({ handleSubmit, pristine, submitting, valid, taskList, date, region, sectorList, ...otherProps }) => {
  const [ areaList, setAreaList ] = useState([])
  useEffect(() => {
    setAreaList(otherProps.areaList)
  }, [otherProps.areaList])
  const fetchArea = async (selectedItem) => {
    let { id, maintenanceArea } = selectedItem
    try {
      maintenanceArea.sort()
      let areaList = maintenanceArea.map((item) => {
        return { label: item, value: item }
      })
      setAreaList(areaList)
    } catch (error) {
      setAreaList([])
    }
  }
  return (
    <React.Fragment>
      <div className="box">      
        <div className="form-group row px-5 pb-2 pt-4 ml-2">
          <h6 
            htmlFor={`input-region`} 
            className="col-md-2" 
            style={{ color: "#579264", fontWeight: "bold" }}
          >
            Tanggal
          </h6>
          <div className="col-md-10">
            <h6 id={`input-region`} style={{ 
              fontWeight: "bold",
              color: "#979D99" 
            }}>{date}</h6>
          </div>
        </div>
      </div>
      
      <form role="form" onSubmit={handleSubmit}>
        <div className="col-sm-12 box">
          <div className="px-5 pb-5 pt-3" style={{ borderRadius: 5 }}>
            <div className="form-group row mb-4">
              <div className="col-md-2 d-flex align-items-center">
                <h6 className="mb-0" style={{ color: "#579264", fontWeight: "bold" }}>
                  Sektor
                </h6>
              </div>
              <div className="col-md-4">
                <Field
                  component={SelectSectorInput}
                  label="Sektor"
                  placeholder="Pilih Sektor"
                  items={sectorList}
                  fetchData={fetchArea}
                  name="sector"
                  formName="updateMaintenanceForm"
                  area="area"
                />
              </div>
            </div>
              <div className="form-group row mb-4">
                <div className="col-md-2 d-flex align-items-center">
                  <h6 className="mb-0" style={{ color: "#579264", fontWeight: "bold" }}>
                    Area
                  </h6>
                </div>
                <div className="col-md-4">
                  <Field
                    component={SelectAreaInput}
                    label="Area"
                    placeholder="Pilih area"
                    items={areaList}
                    name="area"
                  />
                </div>
              </div>
          </div>
        </div>
        <div className="col-sm-12 box" style={{ borderRadius: 5 }}>
          <div className="px-5 pb-5 pt-3">
            <div className="row mb-4">
              <div className="col-md-8">
                <div className="table-responsive">
                  <table className="table table-no-bordered m-a-0">
                    <thead style={{ color: "#579264" }}>
                      <tr>
                        <th scope="col">Daftar Pekerjaan</th>
                        <th scope="col">Keterangan</th>	
                        <th scope="col">Karyawan</th>	
                      </tr>
                    </thead>
                    <tbody>
                      {
                        taskList.length ?
                        <React.Fragment>
                          {taskList.map((item) => (
                            <tr>
                              <td>
                                <p>{item.value}</p>
                              </td>
                              <td>
                                <p>{item.description}</p>
                              </td>
                              <td>
                                <p>{item.employeeName}</p>
                              </td>
                            </tr>
                          ))}
                        </React.Fragment>
                        :
                          <p className="text-center">Tidak ada daftar pekerjaan.</p>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-md-4">
                <Field name="taskList" component={TaskList} />
              </div>
            </div>
          </div>
        </div>
        <div className="box p-5">
          <Field
            component={GalleryPicker}
            label="Foto"
            name="images"
          />
        </div>
            
            
        <div className="col-sm-12 box">
         <div className="px-5 pb-5 pt-3" style={{ borderRadius: 5 }}>
          <Field
            name="description"
            component={TextAreaInput}
            placeholder="Catatan"
            label="Catatan"
            rows={4}
            style={{ backgroundColor: "#E8E8E8", fontSize: "0.9em" }}
          />

            <div className="col-lg-6 col-xs-12 px-0">
              <div className="col-lg-3 col-xs-12 px-0 mt-5 text-center">
                <SubmitButton disabled={!valid || submitting} />
              </div>
              <LoaderModal submitting={submitting} />
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  )
}

UpdateForm = reduxForm({
  form: 'updateMaintenanceForm',
  validate,
  enableReinitialize: true,
})(UpdateForm)

const selector = formValueSelector('updateMaintenanceForm')
UpdateForm = connect(
  state => {

    const sectorList = selector(state, 'sectorList') || [];
    const areaList = selector(state, 'areaList') || [];
    const region = selector(state, 'region') || [];
    const taskList = selector(state, 'taskList') || [];
    const date = selector(state, 'date') || [];
    
    return {
      sectorList,
      areaList,
      region,
      taskList,
      date, 
    }
  }
)(UpdateForm)

export default connect()(UpdateForm)
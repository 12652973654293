import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { firestoreConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux'
import Delete from './Delete';

import placeholderPlant from "../../assets/img/plant_placeholder.png"
const ListItem = (props) => {
    const handleImageError = () => {
        setAvatar(placeholderPlant)
    }
    useEffect(() => {
        setAvatar(props.avatar)
        return () => null
    }, [props.avatar])
    const [avatar, setAvatar] = useState(props.avatar)
    return (
        <tr key={props.id} style={{ color: "rgba(124, 122, 122, 0.87)" }}>
            <td>
                <img onError={handleImageError} src={avatar} style={{width:100, height:80, objectFit:"cover" }} />
            </td>
            <td>{props.collectionNumber || "-"}</td>
            <td>{props.category || "-"}</td>
            <td><div dangerouslySetInnerHTML={{ __html: props.species }}></div></td>
            <td onClick={() => props.navigateDetail(props.id)} style={{ cursor: 'pointer' }}>{props.name || "-"}</td>
            <td><div dangerouslySetInnerHTML={{ __html: props.genus }}></div></td>
            <td>{props.sector || "-"}</td>
            <td className="text-center">
                {
                    ((props.profile.token && props.profile.token.claims.admin === true) ||
                        (props.profile.token && props.profile.token.claims.department === "Konservasi")
                    ) ?
                        <Delete deleteRef={props.dataRef} />
                    :
                        null
                }
            </td>
        </tr>
    )
}

const mapStateToProps = state => {
    return {
      region: state.region,
      auth: state.firebase.auth,
      profile: state.firebase.profile,
    }
}

ListItem.defaultProps = {
    id: "",
    name: "",
    position: "",
    email: "",
    sector: "",
}

ListItem.propTypes = {
    id: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    sector: PropTypes.string.isRequired,
}



export default compose(
    connect(mapStateToProps),
    firestoreConnect()
)(ListItem)

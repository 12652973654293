
import React, { Component } from "react";
import Dropzone from "react-dropzone";

import Thumbnail from "./Thumbnail";
import ErrorMessage from "./ErrorMessage";
import { change } from "redux-form";

const toDataURL = url => fetch(url)
  .then(response => response.blob())
  .then(blob => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(blob)
  })
  .then((value) => Promise.resolve(value)))

const fileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});
export default class GalleryPicker extends Component {
  state = {
    value: [],
    maxPhotos: 10,
  }

  async componentDidMount() {
    const { input: { value, onChange } } = this.props;
    if (value) {
      const imgBase64 = []
      for (let i = 0; i < value.length; i++) {
        const url = value[i];
        imgBase64.push(await toDataURL(url))
      }
      
      this.setState({
        value: imgBase64,
      })
      onChange(imgBase64)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { input: { value, onChange } } = this.props;
    if (value) {
      const { input: { value: newValue, onChange } } = nextProps;
      
        if (value !== newValue) {
          this.setState({
            value: newValue
          })
        }
    }
  }

  onDrop = async (acceptedFiles) => {
    this.setState({ errorLimit: false });

    const { input: { value, onChange }, change } = this.props;
    if (acceptedFiles.length === 0) return;
    let imgBase64 = []
    for (let i = 0; i < acceptedFiles.length; i++) {
      const url = acceptedFiles[i];
      imgBase64.push(await fileToBase64(url))
    }

    const size = imgBase64.length + this.state.value.length;
    if (size <= 10) {
      this.setState({ errorLimit: false });
      onChange(this.state.value.concat(imgBase64))
      return;
    } else {
      this.setState({ errorLimit: true });
    }
  };

  deleteGallery = () => {
    const { input: { value, onChange } } = this.props;
    onChange([]);
  };

  onDelete = (index) => {
    const { input: { value, onChange } } = this.props;
    let tempValue = this.state.value;
    const newValue = tempValue.filter((item, i) => i !== index)
    
    onChange(newValue);
  }

  render() {
    const { label } = this.props;
    const { value } = this.state;
    
    return (
      <div className="form-group margin-top-40">
        <label htmlFor="inputGallery">
          <h6 style={{color: '#579264' }}><b>{label || "Photo"}</b></h6>
        </label>
        <Dropzone
          className="dropzone"
          ref="dropzoneRef"
          accept="image/*"
          onDrop={this.onDrop}
          id="inputGallery"
        >
          {({ isDragActive, isDragReject }) => {
            if (isDragActive) {
              return "This image is authorized";
            }
            if (isDragReject) {
              return "This image is not authorized";
            }
            if (value && value.length === 0) {
              return (
                <p 
                  style={{ color: "rgba(156, 151, 151, 0.87)", fontFamily: "Montserrat" }} 
                  className="text-center mt-3"
                >
                  Klik untuk unggah...
                </p>
              );
            }
            return value.map((file, i) => <Thumbnail file={file} key={file.name ? file.name + "-" + i : file + "-" + i} onDelete={() => this.onDelete(i)}  />);
          }}
        </Dropzone>
        <ErrorMessage {...this.props} />
        {this.state.errorLimit && <small className="text-danger">Max upload is {this.state.maxPhotos} photos!</small>}
        <div>
          {/* <button type="button" onClick={() => this.refs.dropzoneRef.open()}>
            Open File Dialog
          </button> */}
          <button
            type="button"
            onClick={() => this.deleteGallery()}
            className="btn btn-secondary"
            style={{float: "right", marginTop: 10, fontSize: "0.9em"}}
          >
            Hapus Foto
          </button>
        </div>
      </div>
    );
  }
}
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withFirestore } from 'react-redux-firebase'

import UpdateSession from './UpdateForm'
// import LoadingScreen from '../../../components/LoadingScreen'
import ModalError from '../../../../components/ModalError';

const collection = "mealPlan"

class UpdateScreen extends Component {
  static propTypes = {
    uid: PropTypes.string,
    firestore: PropTypes.shape({
      update: PropTypes.func.isRequired
    }).isRequired
  }
  handleErrorInfo = () => {
    this.setState({ errorInfo: !this.state.errorInfo });
  }

  createFormData = (values) => {
    const data = {
      detail: values.detail,
    };
    return data;
  }

  onSubmit = async (values) => {
    const formData = this.createFormData(values)
    console.log("formData:", formData)

    const { sessionId, planId } = this.props.match.params;
    const { selectedRegion } = this.props.location.state;
    console.log('sessionId', sessionId)
    console.log('selectedRegion', selectedRegion)

    try {
      await this.props.firestore.update({ 
        collection: 'region',
        doc: selectedRegion,
        subcollections: [{ 
          collection: 'plants', 
          doc: sessionId,
          subcollections: [{ 
            collection: 'fertilizerPlan',
            doc: planId
          }]
        }]
      }, formData);
      console.log('Successfully update Meal Plan data');

      this.props.history.goBack()
    } catch (error) {
      this.setState({ errorInfo: true })

      console.error("Failed to update Ticketing Entry Data: ", error)

    }
  }

  state = {
    data: {
      nickName: "",
      detail: [],
      status: "",
      animalId: ""
    },
    loading: true,
    errorInfo: false,
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {

    try {
      const { sessionId, planId } = this.props.match.params;
      const { selectedRegion } = this.props.location.state;

      const snapshot = await this.props.firestore.get({ 
        collection: 'region',
        doc: selectedRegion,
        subcollections: [{ 
          collection: 'plants', 
          doc: sessionId,
          subcollections: [{ 
            collection: 'fertilizerPlan',
            doc: planId
          }]
        }]
      });
      if (snapshot.exists) {
        const data = snapshot.data();
        let mealPlanData = {
          detail: data.detail || "",
          status: data.status || "",
        }
        console.log('mealPlanData', mealPlanData)
        this.setState({ data: mealPlanData });
      } else {
        console.log("Doesnt exists")
      }
    } catch (error) {
      console.error(error)
    }

    this.setState({ loading: false })
  }

  render() {
    if (!this.props.uid) return null
    // if (this.state.loading) return <LoadingScreen />
    return (
      <div className="box-body">
        <React.Fragment>
          {
            this.state.errorInfo &&
            <ModalError 
              errorInfo={this.state.errorInfo}
              handleErrorInfo={this.handleErrorInfo}
            />
          }
        </React.Fragment>
        <React.Fragment>
          <UpdateSession onSubmit={this.onSubmit} initialValues={this.state.data} />
        </React.Fragment>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    uid: state.firebase.auth.uid,
  }
}

export default compose(
  connect(mapStateToProps),
  withFirestore,
)(UpdateScreen)